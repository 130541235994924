import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { Heading1, Heading2, Heading3 } from '../theme/themeComponents/fonts';
import Calendar from 'react-calendar';
import BasicCard from '../components/BasicCard/BasicCard';
import cookie from 'js-cookie';
import { COOKIES } from '../constants/cookies_type';
import * as Sentry from '@sentry/react';

// We can use moment to deal with time !! why we don't use it ?
import moment from 'moment';
import { theme } from '../theme';
import { displayToastNotification } from '../utils/toastNotification';

const getSurgeryByIdQuery = loader('../graphql/getSurgeryById.graphql');
const getReservationsQuery = loader('../graphql/getReservations.graphql');
const updateReservationQuery = loader(
  '../graphql/updateSurgeryReservation.graphql'
);
const updateOldReservationQuery = loader(
  '../graphql/updateOldSurgeryReservation.graphql'
);

const getParentQuery = loader('../graphql/getParent.graphql');

const weekdayLabels = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
const monthsLabels = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

const displayDate = (d: Date) =>
  capitalize(
    d.toLocaleDateString('fr-FR', {
      day: 'numeric',
      weekday: 'long',
      month: 'short',
    })
  );

const displayTime = (x: Date) =>
  `${x.getHours()}h${('0' + x.getMinutes()).slice(-2)}`;

const displayPhone = (x: string) => `${x}`;

const displayAny = (x: any) =>
  x instanceof Date ? displayTime(x) : displayPhone(x);

const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const today = new Date();

const CallDayBefore = () => {
  const history = useHistory();
  const location = useLocation();

  const [day, setDay] = useState(new Date());
  const [stage, setStage] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const [currentReservation, setCurrentReservation] = useState<
    | {
        dateFormat: string;
        duration: string;
      }
    | undefined
  >(undefined);

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);

  const [getParent, { data: parentData }] = useLazyQuery(getParentQuery);

  const [
    getReservations,
    { data: reservationsData, refetch: refetchReservationsData },
  ] = useLazyQuery(getReservationsQuery);

  const [sid, setSid] = useState('');

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (!parentData?.parent) {
      getParent({ variables: { parentId: cookie.get(COOKIES.ID) } }); // TODO check if s usefull because all infos are maybe in context ?
    }
    // if (isDisabled) {
    //   getReservations({ variables: { surgeryId } }); //TODO why this disabled logic ???
    //   return;
    // }
    if (surgeryId) {
      setSid(surgeryId);
      getSurgeryById({ variables: { surgeryId } });
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (dataSurgery) {
      if (dataSurgery.surgery?.length > 0) {
        const surgeryId = dataSurgery.surgery[0].id;
        const organizationId = dataSurgery.surgery[0].organization.id;
        getReservations({
          variables: { surgeryId: surgeryId, organizationId: organizationId },
        });
        setLoading(false);
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [dataSurgery]);

  useEffect(() => {
    if (reservationsData) {
      const currentReservation = reservationsData.reservation.find(
        (e: any) => e.surgery_id
      );
      if (currentReservation) {
        if (stage !== 4) {
          setStage(-1);
        }
        setCurrentReservation({
          dateFormat: moment(currentReservation.start_time).format('LLL'),
          duration: moment(currentReservation.end_time)
            .diff(moment(currentReservation.start_time), 'minutes')
            .toString(),
        });
      }
    }
  }, [reservationsData]);

  useEffect(() => {
    if (stage === 4) {
      refetchReservationsData!();
    }
  }, [stage]);

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && (
        <>
          <Title>L’appel de la veille</Title>
          <Subtitle>
            Ce créneau indique votre préférence à l’équipe pour vous rappeler.
            En cas de forte activité, l’équipe pourra vous rappeler en dehors de
            ce créneau.
          </Subtitle>
          <CardContainer>
            <BasicCard
              title={`Sélectionnez le jour et l'heure`}
              titleSeparator
              horizontalAlign={true}
            >
              <CalendarMainContainer>
                <CalendarContainer>
                  <Calendar
                    next2Label={null}
                    prev2Label={null}
                    minDate={today}
                    onChange={(e: any) => {
                      setDay(e);
                      setStage(1);
                    }}
                    value={day}
                    tileDisabled={(x) => {
                      if (isDisabled) return isDisabled;
                      for (const a of reservationsData?.reservation || [])
                        if (
                          isSameDay(x.date, new Date(a.start_time)) &&
                          x.date.getTime() > today.getTime()
                        )
                          return false;
                      return true;
                    }}
                    view={'month'}
                    defaultView={'month'}
                    formatShortWeekday={(_l, d) => weekdayLabels[d.getDay()]}
                    formatMonth={(_l, d) => monthsLabels[d.getMonth()]}
                    formatMonthYear={(_l, d) =>
                      monthsLabels[d.getMonth()] + ' ' + d.getFullYear()
                    }
                  />
                </CalendarContainer>
                <CallSelectorContainer>
                  <CallSelector
                    reservations={reservationsData?.reservation.filter(
                      (x: any) =>
                        !x.surgery_id && isSameDay(new Date(x.start_time), day)
                    )}
                    stage={stage}
                    setStage={setStage}
                    currentDay={day}
                    setIsDisabled={setIsDisabled}
                    phones={[parentData.parent[0].phone]}
                    sid={sid}
                    currentReservation={currentReservation}
                  />
                </CallSelectorContainer>
              </CalendarMainContainer>
            </BasicCard>
          </CardContainer>
        </>
      )}
    </PageWrapper>
  );
};

const dateToCall = (r: any, hour: Date) =>
  r?.find((x: any) => new Date(x.start_time).getTime() === hour.getTime());

const dateToDuration = (r: any, hour: Date) => {
  const d = dateToCall(r, hour);
  if (!d) return 0;
  return (
    (new Date(d.end_time).getTime() - new Date(d.start_time).getTime()) / 60000
  );
};

const CallSelector = ({
  stage,
  setStage,
  currentDay,
  reservations,
  setIsDisabled,
  phones,
  sid,
  currentReservation,
}: {
  stage: any;
  setStage: Function;
  currentDay: Date;
  reservations?: any[];
  setIsDisabled: Function;
  phones: string[];
  sid: string;
  currentReservation?: {
    dateFormat: string;
    duration: string;
  };
}) => {
  const [updateReservation] = useMutation(updateReservationQuery);
  const [updateOldReservation] = useMutation(updateOldReservationQuery);
  const [hour, setHour] = useState(new Date());
  const [call, setCall] = useState<any>(null);
  const [loadingUpdateReseservation, setLoadingUpdateReseservation] =
    useState(false);

  //TODO wip refacto thibaut's work
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {((s) => {
        switch (s) {
          case -1:
            return (
              <CallSelectorSubtitle>
                Vous avez déja un appel de prévu le
                <br />
                {currentReservation?.dateFormat}
                <br />
                <br />
                Durée: {currentReservation?.duration} minutes
                <br />
                <br />
                <div style={{ color: theme.colors.redL4 }}>
                  La prise d'un nouveau créneau entraînera la suppression de
                  celui-ci
                </div>
              </CallSelectorSubtitle>
            );
          case 0:
            return (
              <CallSelectorSubtitle>
                Vous n'avez pas encore d'appel prévu
              </CallSelectorSubtitle>
            );
          case 1:
            return (
              <div style={{ width: '100%' }}>
                <CallSelectorSubtitle>
                  {displayDate(currentDay)}
                </CallSelectorSubtitle>
                <HourScroll
                  height={'230px'}
                  list={
                    [
                      ...new Set(
                        (reservations || []).map(
                          (x: any) => new Date(x.start_time)
                        )
                      ),
                    ].sort() as Date[]
                  }
                  onClick={(x: any) => {
                    setStage(2);
                    setHour(x.value);
                    setCall(dateToCall(reservations, x.value));
                  }}
                />
              </div>
            );
          case 2:
            return (
              <Step2Container>
                <p>L'appel de la veille</p>
                <p>{displayDate(currentDay)}</p>
                <p>{`Heure: ${displayTime(hour)}`}</p>
                <p>Durée: {`${dateToDuration(reservations, hour)}min`}</p>
              </Step2Container>
            );
          case 3:
            return (
              <div style={{ color: 'black', fontWeight: 'bold' }}>
                <CallSelectorSubtitle>
                  Sur quel numéro souhaitez-vous être rappelé ? (Vous pouvez
                  changer de numéro dans la page paramètres)
                </CallSelectorSubtitle>
                <HourScroll
                  height={'auto'}
                  list={phones}
                  loadingUpdateReseservation={loadingUpdateReseservation}
                  onClick={async (_x: any) => {
                    //TODO function for this !
                    if (call) {
                      try {
                        setLoadingUpdateReseservation(true);
                        await updateOldReservation({
                          variables: {
                            surgery_id: sid,
                          },
                        });
                        await updateReservation({
                          variables: {
                            reservation_id: call.id,
                            surgery_id: sid,
                          },
                        });
                        displayToastNotification(
                          'success',
                          'Réservation enregistrée'
                        );
                        setLoadingUpdateReseservation(false);
                        setStage(4);
                      } catch (err) {
                        Sentry.captureException(err);
                        displayToastNotification(
                          'error',
                          'Une erreur est survenue'
                        );
                      }
                      // supprimer le slot qui suit ? //TODO thibaut s comment why ?
                    }
                  }}
                />
                {loadingUpdateReseservation && (
                  <LoaderContainer>
                    <Loader size="sm" />
                  </LoaderContainer>
                )}
              </div>
            );
          case 4:
            return (
              <div>
                <CallSelectorSubtitle
                  style={{ color: theme.colors.darkGreenL4 }}
                >
                  Appel confirmé
                </CallSelectorSubtitle>
                <br />
                <CallSelectorSubtitle>
                  {displayDate(currentDay)}
                </CallSelectorSubtitle>
                <CallSelectorSubtitle>{`Heure: ${displayTime(
                  hour
                )}`}</CallSelectorSubtitle>
                {/* <CallSelectorSubtitle>
                  Durée: {`${dateToDuration(reservations, hour)}min`}
                </CallSelectorSubtitle> */}
              </div>
            );
          default:
            return <div />;
        }
      })(stage)}

      {stage === 2 && (
        <ButtonContainer>
          <Button
            onClick={() => setStage(stage + 1)}
            backgroundColor="#2E666D"
            color="white"
          >
            Suivant
          </Button>
        </ButtonContainer>
      )}
      {stage > 0 && stage !== 4 && !loadingUpdateReseservation && (
        <ButtonContainer>
          <Button
            onClick={() => {
              if (currentReservation) {
                setStage(-1);
              } else {
                setStage(stage - 1);
              }
            }}
          >
            Retour
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

const HourScroll = ({
  onClick,
  list,
  height,
  loadingUpdateReseservation,
}: {
  onClick: Function;
  list: any[];
  height: string | undefined;
  loadingUpdateReseservation?: boolean;
}) => {
  const [isSelected, setIsSelected] = useState(-1);
  return (
    <HourButtonContainer height={height}>
      {list.map((x, i) => (
        <div key={i} style={{ display: 'flex', flexDirection: 'row' }}>
          <HourButton
            style={
              isSelected === i
                ? { width: '50%', backgroundColor: '#AED1CD' }
                : {}
            }
            key={i}
            onClick={() => setIsSelected(isSelected === i ? -1 : i)}
          >
            {displayAny(x)}
          </HourButton>
          {isSelected === i && (
            <HourButton
              style={{
                color: 'white',
                width: '50%',
                backgroundColor: '#2E666D',
              }}
              disabled={loadingUpdateReseservation}
              onClick={() => onClick({ value: x, index: i })}
            >
              Confirmer
            </HourButton>
          )}
        </div>
      ))}
    </HourButtonContainer>
  );
};

const HourButtonContainer = styled.div<{ height?: string }>`
  margin: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: ${({ height }) => height ?? '230px'};
  position: relative;
`;

const HourButton = styled.button`
  font-weight: bold;
  border-radius: 10px;
  background-color: #dfedeb;
  border: none;
  margin: 5px 10px;
  width: 100%;
  height: 64px;
`;

const Button = styled.button<any>`
  color: ${(props) => props.color || 'black'};
  font-weight: bold;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor || '#DFEDEB'};
  border: none;
  padding: 10px 32px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const CardContainer = styled.div`
  margin: 10px;
  position: relative;
  height: 466px;
  width: 800px;
  /* @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    width: 800px;
  }*/

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 460px;
    height: auto;
  }
`;

const CallSelectorSubtitle = styled.div`
  ${Heading3};
  margin: 10px 20px;
  text-align: center;
`;

const CallSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 340px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    margin-top: 30px;
  }
`;

const CalendarContainer = styled.div`
  width: 340px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const CalendarMainContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const Step2Container = styled.div`
  ${Heading3}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const LoaderContainer = styled.div``;
export default CallDayBefore;
