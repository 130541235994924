import React from 'react';
import { ReactComponent as Logo } from '../../theme/images/logo/logoRow.svg';
import { FormHeadContainer } from '../../theme/themeComponents/containerComponents';
import styled from 'styled-components';
import { Heading1, Heading3 } from '../../theme/themeComponents/fonts';

interface FormHeadProps {
  title: string;
  subtitle: string;
}

const FormHead: React.FunctionComponent<FormHeadProps> = ({
  title,
  subtitle,
}) => {
  return (
    <FormHeadContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </FormHeadContainer>
  );
};

const LogoContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${Heading1}
  margin-top: 25px;
`;
const Subtitle = styled.div`
  ${Heading3}
  margin-top: 25px;
`;

export default FormHead;
