import React, { useState, useEffect, useContext } from 'react';
import { getSurveyTypeVariables } from './surveyVariables';
import CustomModal from '../CustomModal/CustomModal';
import HelpButton from '../HelpButton/HelpButton';
import QuestionAnnotation from '../QuestionAnnotation/QuestionAnnotation';
import RadioGroupComponent from '../RadioGroupComponent/RadioGroupComponent';
import SurveyButtons from '../SurveyButtons/SurveyButtons';
import Fade from 'react-bootstrap/Fade';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';

interface SurveyFormComponentProps {
  handleSubmit: (e: any) => void;
  questions: any;
  handleAnswerRadioButtonChange: (answerLabel: any, question: any) => void;
  values: any;
}

const SurveyFormComponent: React.FunctionComponent<SurveyFormComponentProps> =
  ({ handleSubmit, questions, handleAnswerRadioButtonChange, values }) => {
    const { t } = useTranslation();
    const surgeryId = new URLSearchParams(window.location.search).get('sid');
    const surveyVariables = getSurveyTypeVariables(
      questions[0].survey_type.value
    );
    const dentalFirstSurvey =
      questions[0].survey_type.value === 'dental-first-survey';
    const [questionIndex, setQuestionindex] = useState(0);
    const [currQuestionNumber, setCurrQuestionNumber] = useState(1);
    const [answers, setAnswers] = useState(
      questions[questionIndex].survey_type.answers
    );
    const [displayDialog, setDisplayDialog] = useState(true);
    const questionNumber = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '5.1',
      '5.2',
      '5.3',
      '5.4',
      '6',
      '6.1',
      '7',
    ];
    const [transition, setTransition] = useState(true);
    const localStorageQuestionIndex = localStorage.getItem(
      surveyVariables.localeStorageQuestionIndex + surgeryId
    );
    const transitionTimeout = 250;

    const closeDialog = () => {
      setDisplayDialog(false);
    };

    const openDialog = () => {
      setDisplayDialog(true);
    };

    const nextTransition = () => {
      setTransition(true);
    };

    useEffect(() => {
      /**
       * Set some questions to conditionnal and/or skippable for surveys
       * that need it
       */
      if (dentalFirstSurvey) {
        values.questions[4].conditionnal = true;
        values.questions[5].skippable = true;
        values.questions[6].skippable = true;
        values.questions[7].conditionnal = true;
        values.questions[7].skippable = true;
        values.questions[8].skippable = true;
        values.questions[9].conditionnal = true;
        values.questions[10].skippable = true;
      }
    }, []);

    useEffect(() => {
      /**
       * Set current question index into local storage then wait for lisibility
       * before move to next question
       */
      if (localStorageQuestionIndex) {
        if (values.questions[0].answer_id) {
          const storedQuestionIndex = JSON.parse(localStorageQuestionIndex);
          setQuestionindex(storedQuestionIndex);
          closeDialog();
          if (storedQuestionIndex == questionIndex) {
            const timer = setTimeout(() => {
              nextQuestion();
            }, transitionTimeout / 2);
            return () => clearTimeout(timer);
          }
        }
      } else {
        const timer = setTimeout(() => {
          nextQuestion();
        }, transitionTimeout / 2);
        return () => clearTimeout(timer);
      }
      if (values && values.questions && !values.questions[0].answer_id) {
        openDialog();
      }
    }, [values]);

    const previousQuestion = () => {
      if (questionIndex > 0) {
        let currIndex = questionIndex;
        do {
          currIndex -= 1;
        } while (values.questions[currIndex].skipped === true);
        setTransition(false);
        /** Wait for transition out to end before loading previous question */
        const timer = setTimeout(() => {
          setQuestionindex(currIndex);
        }, transitionTimeout / 2);
        if (values.questions[questionIndex].skippable === false)
          setCurrQuestionNumber(currQuestionNumber - 1);
        localStorage.setItem(
          surveyVariables.localeStorageQuestionIndex + surgeryId,
          JSON.stringify(currIndex)
        );
        return () => clearTimeout(timer);
      }
    };

    const nextQuestion = () => {
      if (values.questions && values.questions[questionIndex].answer_id) {
        if (questionIndex < questions.length - 1) {
          let currIndex = questionIndex + 1;
          if (
            values.questions[currIndex - 1].conditionnal === true &&
            values.questions[currIndex - 1].answer_value === 'Non'
          ) {
            while (values.questions[currIndex].skippable === true) {
              values.questions[currIndex].skipped = true;
              delete values.questions[currIndex].answer_id;
              delete values.questions[currIndex].answer_value;
              currIndex += 1;
            }
          } else values.questions[currIndex].skipped = false;
          setTransition(false);
          /** Wait for transition out to end before loading next question */
          const timer = setTimeout(() => {
            setQuestionindex(currIndex);
          }, transitionTimeout / 2);
          if (values.questions[currIndex].skippable === false)
            setCurrQuestionNumber(currQuestionNumber + 1);
          setAnswers(questions[currIndex].survey_type.answers);
          localStorage.setItem(
            surveyVariables.localeStorageQuestionIndex + surgeryId,
            JSON.stringify(currIndex)
          );
          localStorage.setItem(
            surveyVariables.localeStorageAnswers + surgeryId,
            JSON.stringify(values)
          );
          return () => clearTimeout(timer);
        }
      }
    };

    const clearLocalStorage = () => {
      localStorage.removeItem(
        surveyVariables.localeStorageQuestionIndex + surgeryId
      );
      localStorage.removeItem(surveyVariables.localeStorageAnswers + surgeryId);
    };

    const getSurveyTitle = (surveyStyle: string) => {
      if (surveyStyle === 'phbq')
        return t('titles_and_subtitles:phbqEvaluationTitle');
      if (surveyStyle === 'parent-anxiety-test')
        return t('titles_and_subtitles:parentAnxietyEvaluationTitle');
      if (surveyStyle === 'symptomatology-d-3')
        return t('titles_and_subtitles:symptomatologyTitle');
      if (surveyStyle === 'pain-test')
        return t('titles_and_subtitles:painEvaluationTitle');
      if (surveyStyle === 'modified-dental-anxiety')
        return t('titles_and_subtitles:modifiedDentalAnxietyTitle');
      if (surveyStyle === 'stai-now')
        return t('titles_and_subtitles:staiNowTitle');
      if (surveyStyle === 'stai-global')
        return t('titles_and_subtitles:staiGlobalTitle');
      if (surveyStyle === 'dental-first-survey')
        return t('titles_and_subtitles:dentalFirstSurveyTitle');
    };

    return (
      <SurveyFormContainer>
        <SurveyFormHeader>
          <SurveyFormTitle>
            {getSurveyTitle(questions[0]?.survey_type.value)}
          </SurveyFormTitle>
        </SurveyFormHeader>
        <form className="needs-validation" onSubmit={handleSubmit}>
          <FormContent>
            <CustomModal
              showModal={displayDialog}
              textContent={surveyVariables.dialogDescription}
              buttonContent="Fermer"
              handleCloseModal={closeDialog}
              backgroundColor={theme.colors.creamL1}
            />
            <Fade
              in={transition}
              timeout={transitionTimeout}
              onExited={nextTransition}
            >
              <SurveyContainer>
                <QuestionAnnotation
                  currQuestion={
                    !dentalFirstSurvey
                      ? questions[questionIndex].order
                      : questionNumber[questionIndex]
                  }
                  totalQuestions={!dentalFirstSurvey ? questions.length : '7'}
                />
                <Question>{questions[questionIndex].value}</Question>
                <RadioGroupComponent
                  question={questions[questionIndex]}
                  currentValue={values.questions[questionIndex].answer_value}
                  answers={
                    !dentalFirstSurvey
                      ? answers
                      : answers.filter(
                          (e: any) =>
                            e.question_id === questions[questionIndex].id
                        )
                  }
                  handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
                />
                <SurveyButtons
                  displayPrevBtn={questionIndex > 0}
                  displayNextBtn={questionIndex < questions.length - 1}
                  previousQuestion={previousQuestion}
                  nextQuestion={nextQuestion}
                  clearLocalStorage={clearLocalStorage}
                />
              </SurveyContainer>
            </Fade>
          </FormContent>
          {/*<HelpButton openDialog={openDialog}/>*/}
        </form>
      </SurveyFormContainer>
    );
  };

const SurveyFormContainer = styled.div``;

const SurveyFormHeader = styled.div`
  height: 35vh;
`;

const SurveyFormTitle = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  padding-top: 50px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Question = styled.h4`
  font-weight: bold;
`;

const SurveyContainer = styled.div`
  width: 80%;
  margin-right: 3%;
`;

export default SurveyFormComponent;
