import { LANGUAGES } from '../constants/languages_types';
import { getFirebaseToken } from './auth';

export const createSurveyNotification = async (
  surveyId: string
): Promise<{
  error: string | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/create-survey-notification`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          surveyId,
        }),
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return {
        error: null,
      };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error while creating survey notification' };
  }
};

export const surveyQuestionAndAnswerByLanguage = (
  array: Array<{
    id: string;
    value: string;
    language: { language_code: string };
  }>,
  language: string
) => {
  let arrayTmp = array.filter((e) => e.language.language_code === language);
  if (arrayTmp.length === 0) {
    arrayTmp = array.filter(
      (e) => e.language.language_code === LANGUAGES.FR_FR
    );
    if (arrayTmp.length === 0) {
      return '';
    }
  }
  return arrayTmp[0].value;
};
