import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { CardStyle1 } from '../../theme/components/ThemeComponents';
import { Heading2, Heading3 } from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';
import CustomModal from '../CustomModal/CustomModal';

//TODO props + refacto surveyForm, too mesy
interface CovidCheckerFormProps {
  questions: any;
  handleSubmit: any;
  handleAnswerRadioButtonChange: any;
}

const CovidCheckerForm: React.FC<CovidCheckerFormProps> = ({
  questions,
  handleSubmit,
  handleAnswerRadioButtonChange,
}) => {
  const [showModal, setShowModal] = useState(true);
  const handleChange = (question: any) => (event: any) => {
    handleAnswerRadioButtonChange(
      event.target.checked ? 'true' : 'false',
      question
    );
  };

  useEffect(() => {
    questions.forEach((question: any) => {
      handleAnswerRadioButtonChange('false', question);
    });
  }, []);

  return (
    <Container>
      <CardContainer>
        <ListContainer>
          <ListHeader>
            <ListHeaderTitleFirstCol>Nom du symptôme</ListHeaderTitleFirstCol>
            <ListHeaderTitleSecondCol>Checker</ListHeaderTitleSecondCol>
          </ListHeader>
          <Separator />

          <ListMain>
            {questions?.map((question: any, i: number) => (
              <FormControl key={i}>
                <ListRow>
                  <ListLabel>{question.value}</ListLabel>
                  <ListToggle>
                    <FormControlLabel
                      label={null}
                      onChange={handleChange(question)}
                      control={<Switch />}
                      labelPlacement="top"
                    />
                  </ListToggle>
                </ListRow>
              </FormControl>
            ))}
          </ListMain>
          <Separator />
        </ListContainer>
        <ButtonContainer>
          <CustomButton
            content="Soumettre"
            backgroundColor={theme.colors.darkGreenMain}
            borderRadius="24px"
            handleOnClick={handleSubmit}
          />
        </ButtonContainer>
      </CardContainer>
      <CustomModal
        showModal={showModal}
        backgroundColor={theme.colors.white}
        borderRadius={48}
        component={
          <ModalContent>
            <ModalText>
              Ce questionnaire permet de determiner si votre enfant présente des
              signes du COVID-19. <br /> <br />
              Il comporte 15 questions.
            </ModalText>
            <ButtonContainer>
              <CustomButton
                content="Commencer"
                backgroundColor={theme.colors.darkGreenMain}
                borderRadius="24px"
                handleOnClick={() => setShowModal(false)}
              />
            </ButtonContainer>
          </ModalContent>
        }
      />
    </Container>
  );
};

export default CovidCheckerForm;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  ${CardStyle1}
  width: 780px;
  padding: 20px 60px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 10px;
  }
`;

const ListContainer = styled.div``;

const ListHeader = styled.div`
  width: 100%;
  display: flex;
  ${Heading3}
  text-transform: uppercase;
  padding: 20px 0;
`;

const ListHeaderTitleFirstCol = styled.div`
  width: 80%;
`;

const ListHeaderTitleSecondCol = styled.div`
  width: 20%;
`;

const Separator = styled.div`
  background: ${({ theme }) => theme.colors.darkGreenMain};
  height: 2px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 10px 0;
`;

const ListMain = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ListRow = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
`;

const ListLabel = styled.div`
  width: 80%;
  ${Heading3}
`;

const ListToggle = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

const ModalContent = styled.div`
  padding: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 10px;
  }
`;

const ModalText = styled.div`
  text-align: center;
  ${Heading2};
`;
