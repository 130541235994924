import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import MemoryGame from '../games/MemoryGame/MemoryGame';
import BreathingImage from '../games/KoalouBreathing/KoalouBreathing/assets/plane.png';
import Expression1 from '../theme/images/anxiety-evaluation/expression_1.png';
import { Heading1, Heading2, Heading3 } from '../theme/themeComponents/fonts';
import { useTranslation } from 'react-i18next';
import ApplicationContext from '../utils/context';
import { Loader } from '../components/Loader/Loader';

const Games: React.FunctionComponent<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [showMainMenu, setShowMainMenu] = useState(true);
  const [showMemoryGame, setShowMemoryGame] = useState(false);
  const [grantAccess, setGrantAccess] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      currentParentAndChildrenCxt.currentParentAndChildrenToEdit
        .hasActivatedEmotionalHealth
    ) {
      setGrantAccess(true);
    }
    setLoading(false);
  }, [currentParentAndChildrenCxt?.currentParentAndChildrenToEdit]);

  const handleClickLaunchMemoryGame = () => {
    setShowMainMenu(false);
    setShowMemoryGame(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      handleOnClickPreviousPage={() =>
        history.push(
          `/children/home?cidx=${new URLSearchParams(location.search).get(
            'cidx'
          )}`
        )
      }
    >
      {showMainMenu && (
        <div>
          <Title>{t('game:pageTitle')}</Title>
          {!grantAccess ? (
            <LockText>
              Tes parents peuvent débloquer cette fonctionnalité dans leur
              espace !
            </LockText>
          ) : (
            <GamesContainer>
              <GameCardContainer
                onClick={() =>
                  history.push(
                    `/children/games/first-game?cidx=${new URLSearchParams(
                      location.search
                    ).get('cidx')}`
                  )
                }
              >
                <LeftPart>
                  <img
                    src={BreathingImage}
                    style={{ width: '100%', height: '70%' }}
                  />
                </LeftPart>
                <RightPart>
                  <RightPartTitle>
                    {t('game:breathingPlaneGame.presentationTitle')}
                  </RightPartTitle>
                  <RightPartText>
                    {t('game:breathingPlaneGame.presentationSubtitle')}
                  </RightPartText>
                </RightPart>
              </GameCardContainer>
              <GameCardContainer onClick={() => handleClickLaunchMemoryGame()}>
                <LeftPart>
                  <img
                    src={Expression1}
                    style={{ width: '100%', height: '90%' }}
                  />
                </LeftPart>
                <RightPart>
                  <RightPartTitle>
                    {t('game:memoryGame.presentationTitle')}
                  </RightPartTitle>
                  <RightPartText>
                    {t('game:memoryGame.presentationSubtitle')}
                  </RightPartText>
                </RightPart>
              </GameCardContainer>
            </GamesContainer>
          )}
        </div>
      )}
      {showMemoryGame && <MemoryGame />}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 30px 0;
  ${Heading1}
`;

const GamesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GameCardContainer = styled.div`
  width: 574px;
  height: 309px;
  margin: 20px;
  display: flex;
  background: ${(props) => props.theme.colors.white};
  border-radius: 48px;
  cursor: pointer;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    height: 309px;
    margin: 20px 0;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    height: auto;
  }
`;

const LeftPart = styled.div`
  width: 50%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 48px 0 0 48px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

const RightPart = styled.div`
  width: 50%;
  padding: 27px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;

const RightPartTitle = styled.div`
  margin-bottom: 22px;
  ${Heading2};
`;

const RightPartText = styled.div`
  ${Heading3}
`;

const LockText = styled.div`
  color: ${(props) => props.theme.colors.darkMain};
  ${Heading2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;

export default Games;
