import Phaser from 'phaser';
import koalou from '../../../../../theme/images/homeChildren/expression5.png';
import nextBtn from '../../assets/nextBtn.png';

export default class AskForFeelings extends Phaser.Scene {
  data = null;

  textIdx = 0;

  constructor() {
    super('ask-for-feelings');
  }

  init(data) {
    this.data = data.data;
    this.textIdx = 0;
  }

  preload() {
    const koalouFace = new Image();
    const next = new Image();

    this.load.image('koalou', (koalouFace.src = koalou));
    this.load.image('nextBtn', (next.src = nextBtn));
  }

  create() {
    this.add
      .graphics()
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(
        100,
        this.scale.height / 2 - 100,
        this.scale.width - 200,
        200
      )
      .setDepth(100);

    this.add
      .image(this.scale.width, this.scale.height / 2 + 100, 'koalou')
      .setScale(0.5)
      .setOrigin(1, 1)
      .setDepth(100);

    this.add
      .image(this.scale.width / 2 + 150, this.scale.height / 2 + 80, 'nextBtn')
      .setScale(0.05)
      .setOrigin(1, 1)
      .setDepth(100)
      .setInteractive()
      .on('pointerdown', () => this.nextText());

    const fontStyle = {
      fontFamily: 'Raleway',
      color: 'black',
      fontSize: 36,
      wordWrap: { width: this.scale.width - 400, useAdvanceWrap: true },
    };

    this.currText = this.add
      .text(100, this.scale.height / 2 - 100, this.data[0], fontStyle)
      .setPadding(16)
      .setScrollFactor(0)
      .setDepth(110);
  }

  nextText() {
    if (this.textIdx < this.data.length - 1) {
      this.textIdx++;
      this.currText.text = this.data[this.textIdx];
    } else {
      this.game.scene.stop('ask-for-feelings');
      this.game.scene.resume('game');
    }
  }
}
