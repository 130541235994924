import { css } from 'styled-components';

export const Heading1 = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.heading.h1.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.heading.h1.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.heading.h1.lineHeight};
`;

export const Heading2 = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.heading.h2.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.heading.h2.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.heading.h2.lineHeight};
`;

export const Heading3 = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.heading.h3.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.heading.h3.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.heading.h3.lineHeight};
`;

export const Heading4 = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.heading.h4.lineHeight};
`;

export const BodyExtraLarge = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.body.extraLarge.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.body.extraLarge.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.body.extraLarge.lineHeight};
`;

export const BodyLarge = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.body.large.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.body.large.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.body.large.lineHeight};
`;

export const BodySmall = css`
  font-size: ${(props) =>
    props.theme.textStyles.typography.body.small.fontSize};
  font-weight: ${(props) =>
    props.theme.textStyles.typography.body.small.fontWeight};
  line-height: ${(props) =>
    props.theme.textStyles.typography.body.small.lineHeight};
`;
