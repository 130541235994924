import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CircleBackground from '../components/CircleBackground/CircleBackground';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { Heading1, Heading3 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';
import { Loader } from '../components/Loader/Loader';
import { Mixpanel } from '../lib/mixpanel';
import { theme } from '../theme';
import { ReactComponent as IconPlus } from '../theme/icons/plus.svg';

import Avatar1 from '../theme/images/avatars/avatar1.png';
import Avatar2 from '../theme/images/avatars/avatar2.png';
import Avatar3 from '../theme/images/avatars/avatar3.png';
import Avatar4 from '../theme/images/avatars/avatar4.png';
import Avatar5 from '../theme/images/avatars/avatar5.png';
import Avatar6 from '../theme/images/avatars/avatar6.png';
import Avatar7 from '../theme/images/avatars/avatar7.png';
import Avatar8 from '../theme/images/avatars/avatar8.png';
import Avatar9 from '../theme/images/avatars/avatar9.png';
import Avatar10 from '../theme/images/avatars/avatar10.png';
import Avatar11 from '../theme/images/avatars/avatar11.png';
import Avatar12 from '../theme/images/avatars/avatar12.png';
import Avatar13 from '../theme/images/avatars/avatar13.png';
import Avatar14 from '../theme/images/avatars/avatar14.png';
import Avatar15 from '../theme/images/avatars/avatar15.png';
import Avatar16 from '../theme/images/avatars/avatar16.png';
import Avatar17 from '../theme/images/avatars/avatar17.png';
import Avatar18 from '../theme/images/avatars/avatar18.png';
import Avatar19 from '../theme/images/avatars/avatar19.png';
import Avatar20 from '../theme/images/avatars/avatar20.png';
import CustomModal from '../components/CustomModal/CustomModal';
import CreateChildForm from '../components/Forms/CreateChildForm';

const avatarsImages = [
  { image: Avatar1, name: 'avatar1' },
  { image: Avatar2, name: 'avatar2' },
  { image: Avatar3, name: 'avatar3' },
  { image: Avatar4, name: 'avatar4' },
  { image: Avatar5, name: 'avatar5' },
  { image: Avatar6, name: 'avatar6' },
  { image: Avatar7, name: 'avatar7' },
  { image: Avatar8, name: 'avatar8' },
  { image: Avatar9, name: 'avatar9' },
  { image: Avatar10, name: 'avatar10' },
  { image: Avatar11, name: 'avatar11' },
  { image: Avatar12, name: 'avatar12' },
  { image: Avatar13, name: 'avatar13' },
  { image: Avatar14, name: 'avatar14' },
  { image: Avatar15, name: 'avatar15' },
  { image: Avatar16, name: 'avatar16' },
  { image: Avatar17, name: 'avatar17' },
  { image: Avatar18, name: 'avatar18' },
  { image: Avatar19, name: 'avatar19' },
  { image: Avatar20, name: 'avatar20' },
];

interface WelcomeProps {}

interface ParentState {
  id: string;
  name: string;
  avatar: string | null;
  children: Array<{
    id: string;
    name: string;
    surname: string;
    surgeries: Array<{
      id: string;
      date: string;
      type: string;
      organizationName: string;
    }>;
    avatar: string | null;
  }>;
}

export const Welcome: React.FC<WelcomeProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const { currentParentAndChildrenCxt, currentChildIndexCxt, onboarding } =
    useContext<any>(ApplicationContext);

  const [loading, setLoading] = useState(true);
  const [parent, setParent] = useState<ParentState | null>(null);

  const [showModalCreateChild, setShowModalCreateChild] = useState(false);

  const getAvatar = (avatarName: string | null) => {
    const avatar = avatarsImages.filter((e) => e.name === avatarName);
    if (avatar.length > 0) {
      return avatar[0].image;
    }
    return '';
  };

  useEffect(() => {
    if (currentParentAndChildrenCxt?.currentParentAndChildrenToEdit?.id) {
      setParent({
        ...currentParentAndChildrenCxt?.currentParentAndChildrenToEdit,
      });
      setLoading(false);
    }
  }, [currentParentAndChildrenCxt.currentParentAndChildrenToEdit]);

  useEffect(() => {
    const showOnboarding = new URLSearchParams(location.search).get(
      'showOnboarding'
    );
    if (showOnboarding === 'direct-signup') {
      onboarding.setOnboardingState({
        show: true,
        type: 'direct-signup',
      });
    }
  }, [location]);

  return (
    <PageWrapper logoutIconOn={true} settingsIconOn={true}>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!loading && parent && (
        <MainContainer>
          <ProfilesContainer>
            <ProfilesCardContainer>
              <ProfileCardBox data-tut="reactour__welcome__parent-card">
                <ProfileCard
                  onClick={() => {
                    Mixpanel?.changeProfileToParent();
                    const surgeries = parent.children.filter(
                      (e) => e.surgeries.length > 0
                    );
                    if (surgeries.length > 0) {
                      history.push('/parent/surgeries');
                    } else {
                      history.push('/parent/emotional-health/home');
                    }
                  }}
                  size={'large'}
                >
                  <img
                    src={getAvatar(parent?.avatar)}
                    style={{ width: '100%' }}
                  />
                </ProfileCard>
                <ProfileCardText style={{ color: theme.colors.white }}>
                  {parent?.name}
                </ProfileCardText>
              </ProfileCardBox>
            </ProfilesCardContainer>
          </ProfilesContainer>
          <ProfilesContainer style={{ marginTop: '4%' }}>
            <ProfilesCardContainer>
              {parent?.children.map((e, i) => (
                <ProfileCardBox key={i}>
                  <ProfileCard
                    onClick={() => {
                      Mixpanel?.changeProfileToChildren();
                      currentChildIndexCxt.setCurrentChildIndex(i);
                      if (e.surgeries.length > 0) {
                        history.push(`/children/story?cidx=${i}`);
                      } else {
                        history.push(`/children/home?cidx=${i}`);
                      }
                    }}
                    size={'medium'}
                  >
                    <img src={getAvatar(e?.avatar)} style={{ width: '100%' }} />
                  </ProfileCard>
                  <ProfileCardText style={{ color: theme.colors.blackMain }}>
                    {e.name}
                  </ProfileCardText>
                </ProfileCardBox>
              ))}
              <AddChildrentContainer>
                <span onClick={() => setShowModalCreateChild(true)}>
                  <IconPlusContainer>
                    <IconPlus />
                  </IconPlusContainer>
                  <AddChildrenText>Ajouter un enfant</AddChildrenText>
                </span>
              </AddChildrentContainer>
            </ProfilesCardContainer>
          </ProfilesContainer>
          <CustomModal
            showModal={showModalCreateChild}
            handleCloseModal={() => setShowModalCreateChild(false)}
            borderRadius={36}
            backgroundColor={theme.colors.darkGreenL1}
            component={
              <CreateChildForm
                handleCancelForm={() => setShowModalCreateChild(false)}
              />
            }
          />
        </MainContainer>
      )}
    </PageWrapper>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: pink; */
  height: 100%;
`;

const ProfilesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 50%; */
  /* background-color: yellow; */
`;

const ProfilesCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-top: 3%; */
`;

const ProfileCardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const ProfileCard = styled.div<{ size: 'large' | 'medium' }>`
  user-select: none;
  border-radius: 50%;
  width: ${(props) => (props.size === 'large' ? '288px' : '230px')};
  height: ${(props) => (props.size === 'large' ? '288px' : '230px')};
  cursor: pointer;
  background: #ffffff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25),
    inset -2px -2px 10px rgba(165, 165, 165, 0.25);
`;

const ProfileCardText = styled.div`
  ${Heading1}
  margin-top: 15px;
`;
const LoaderContainer = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
`;

const AddChildrentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  align-items: center;
  & > span {
    cursor: pointer;
  }
`;

const AddChildrenText = styled.div`
  ${Heading3}
  color: ${({ theme }) => theme.colors.darkGreenMain};
  margin-top: 10px;
`;

const IconPlusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
