import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import InputField from '../InputField/InputField';
import { Heading3 } from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';
import * as Yup from 'yup';
import { createChild } from '../../utils/user';
import moment from 'moment';
import { Loader } from '../Loader/Loader';
import { displayToastNotification } from '../../utils/toastNotification';
import ApplicationContext from '../../utils/context';

interface FormValues {
  name: string;
  birthDate: string;
}

interface CreateChildFormProps {
  handleCancelForm: () => void;
}

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Ce champ est requis').trim(),
    birthDate: Yup.string().required('Veuillez choisir une date'),
  });

const CreateChildForm: React.FC<CreateChildFormProps> = ({
  handleCancelForm,
}) => {
  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    const res = await createChild({
      ...values,
      birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
    });

    if (res.child) {
      const childrenTmp = [
        ...currentParentAndChildrenCxt.currentParentAndChildrenToEdit.children,
        res.child,
      ];
      //update context currentParentAndChildrenCxt
      currentParentAndChildrenCxt.setCurrentParentAndChildrenToEdit({
        ...currentParentAndChildrenCxt.currentParentAndChildrenToEdit,
        children: childrenTmp,
      });
      displayToastNotification(
        'success',
        'Le profil de votre enfant a bien été créé'
      );
    } else {
      console.log('Error while creating child');
      displayToastNotification('error', 'Une erreur est survenue');
    }
    setLoading(false);
    handleCancelForm();
  };

  return (
    <Container>
      <Formik
        initialValues={{ name: '', birthDate: '' }}
        validationSchema={() => validationSchema()}
        onSubmit={async (values) => await handleSubmit(values)}
      >
        {(props) => {
          return (
            <Form>
              <Title>Ajouter un enfant</Title>
              {!loading && (
                <>
                  {/* <InputFieldContainer>
                    <Field
                      name="birthDate"
                      placeholder={'jj/mm/aaaa'}
                      label="Date de naissance"
                      component={DatePickerForm}
                    />
                  </InputFieldContainer> */}
                  <InputFieldContainer>
                    <Field
                      name="birthDate"
                      inputType="date"
                      inputLabel="Date de naissance"
                      inputPlaceholder="jj/mm/aaaa"
                      component={InputField}
                    />
                  </InputFieldContainer>
                  <InputFieldContainer>
                    <Field
                      name="name"
                      component={InputField}
                      inputLabel="Prénom"
                      inputName="Prénom"
                      inputPlaceholder="Le prénom de votre enfant"
                    />
                  </InputFieldContainer>

                  <ButtonContainer>
                    <CustomButton
                      content="Annuler"
                      handleOnClick={handleCancelForm}
                      disabled={props.isSubmitting}
                      size="sm"
                    />
                    <CustomButton
                      type="submit"
                      content="Valider"
                      disabled={props.isSubmitting}
                      size="sm"
                    />
                  </ButtonContainer>
                </>
              )}
              {loading && <Loader />}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CreateChildForm;

const Container = styled.div`
  overflow-y: hidden;
  width: 350px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 250px;
  }
`;

const InputFieldContainer = styled.div`
  margin: 26px 0;
`;

const Title = styled.div`
  text-align: center;
  ${Heading3}
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blackMain};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 80px;
`;
