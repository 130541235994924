import { useQuery } from '@apollo/client';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { loader } from 'graphql.macro';
import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';

const getInstructionTypesQuery = loader(
  '../../graphql/getInstructionTypes.graphql'
);

interface TimelineProps {
  steps: string[];
  activeStep: number;
  timelineLabels: string[] | undefined;
  handleClickTimeline: (type: string, index: number, title: string) => void;
}

const Timeline: React.FunctionComponent<TimelineProps> = ({
  steps,
  activeStep,
  timelineLabels,
  handleClickTimeline,
}) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(getInstructionTypesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { language: i18n.language },
  });

  if (loading) {
    return <div title="loader">{t('common:loading')}</div>;
  }

  return (
    <>
      <StepperStyled activeStep={-1} alternativeLabel>
        {steps.map((step: string, index: number) => {
          const instructionType = data.instruction_type_i18n.filter(
            (e: { id: string; value: string; label: string }) =>
              e.value === step
          )?.[0];

          return (
            <StepStyled
              key={index}
              active={activeStep === index}
              onClick={() =>
                handleClickTimeline(
                  instructionType?.value,
                  index,
                  instructionType?.label
                )
              }
            >
              <StepLabel
                icon={
                  index > 0 ? (
                    timelineLabels && timelineLabels[index]
                  ) : (
                    <LocalHospitalIconStyled
                      iconcolor={
                        activeStep === index
                          ? theme.colors.timelineActive
                          : 'silver'
                      }
                    />
                  )
                }
              >
                <LabelTypo>{instructionType?.value}</LabelTypo>
              </StepLabel>
            </StepStyled>
          );
        })}
      </StepperStyled>
    </>
  );
};

const StepperStyled = styled(Stepper)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 0 !important;
`;

const StepStyled = styled(Step)`
  .MuiSvgIcon-root {
    width: 87px;
    height: 87px;
    position: relative;
    top: -35px;
    z-index: 10;
    cursor: pointer;
  }
  .MuiStepIcon-root {
    color: ${(props) => props.theme.colors.darkGreenL1};
    display: block;
  }
  .MuiStepIcon-text {
    font-size: 7px;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${(props) => props.theme.colors.timelineActive};
  }
  .MuiStepLabel-labelContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const LocalHospitalIconStyled = styled(LocalHospitalIcon)<{
  iconcolor: string;
}>`
  color: ${(props) => props.theme.colors.white};
`;

const LabelTypo = styled.p`
  font-size: 1.3rem;
  position: relative;
  top: -20px;
  left: -2px;
  max-width: 50px;
`;

export default Timeline;
