import React from 'react';
import styled from 'styled-components';
import {
  BodyLarge,
  Heading1,
  Heading3,
} from '../../theme/themeComponents/fonts';

interface CustomCardProps {
  title?: string;
  titleSize?: string;
  subtitle?: string;
  content?: string;
  handleOnClick?: () => void;
  image?: any;
  imageComponent?: any;
  backgroundImage?: any;
  backSide?: boolean;
  icon?: any;
  displayMode?: 'center' | 'space-between' | 'space-around';
  titleDownPosition?: boolean;
}

/* TODO component become to generic. We have to split the logic */
const CustomCard: React.FC<CustomCardProps> = ({
  title,
  titleSize,
  handleOnClick,
  image,
  imageComponent,
  subtitle,
  backgroundImage,
  backSide = false,
  content,
  icon,
  displayMode = 'center',
  titleDownPosition,
  children,
}) => {
  return (
    <Container
      onClick={handleOnClick}
      backSide={backSide}
      displayMode={displayMode}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {title && !titleDownPosition && (
        <Title titleSize={titleSize}>{title}</Title>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {image && (
        <ImageContainer>
          <img src={image} style={{ width: '100%' }} />
        </ImageContainer>
      )}
      {imageComponent && (
        <ImageComponentContainer>{imageComponent}</ImageComponentContainer>
      )}
      {title && titleDownPosition && <Title>{title}</Title>}
      {backgroundImage && (
        <BackgroundImageContainer>
          <img
            src={backgroundImage}
            style={{ width: '100%', height: '100%' }}
          />
        </BackgroundImageContainer>
      )}
      {content && <ContentContainer>{content}</ContentContainer>}
      {children}
    </Container>
  );
};

const Container = styled.div<{ backSide: boolean; displayMode: string }>`
  width: 100%;
  height: 100%;
  background: #fdfaf6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  display: flex;
  justify-content: ${(props) => props.displayMode};
  align-items: center;
  flex-direction: column;
  padding: 30px 10px;
  cursor: pointer;
  transform: ${(props) =>
    props.backSide ? 'rotateY(180deg)' : 'rotateY(0deg)'};
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  position: absolute;
`;

const IconContainer = styled.div``;

const Title = styled.div<{ titleSize?: string }>`
  ${(props) => (props.titleSize === 'md' ? Heading3 : Heading1)}
  text-align: center;
  z-index: 10;
  word-break: break-word;
`;

const Subtitle = styled.div`
  ${BodyLarge}
  text-align: center;
  margin: 10px 0;
`;

const ImageContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const ContentContainer = styled.div`
  text-align: center;
  height: 35%;
  padding: 0 10px;
`;

const ImageComponentContainer = styled.div`
  width: 80%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CustomCard;
