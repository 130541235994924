import styled, { css } from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';

//Flex
const FlexColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlexColumnStart = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FlexColumnCenterStart = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const FlexRowCenter = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

//Size
const FullView = css`
  width: ${(props) => props.theme.size.view.fullWidth};
  height: ${(props) => props.theme.size.view.fullHeight};
`;

const FullSize = css`
  width: ${(props) => props.theme.size.full};
  height: ${(props) => props.theme.size.full};
`;

export const NavContainer = styled.div`
  ${FlexColumnCenter}
  ${FullView}
  min-height: ${(props) => props.theme.size.height.desktop};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    min-height: ${(props) => props.theme.size.height.tablet};
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    min-height: ${(props) => props.theme.size.height.mobile};
  }
`;

export const AuthContainer = styled.div`
  width: ${(props) => props.theme.size.large};
`;

export const FormContainer = styled.form`
  ${FlexColumnStart}
  width: ${(props) => props.theme.size.full};
`;

export const FormHeadContainer = styled.div`
  margin-bottom: ${(props) => props.theme.margins.medium};
  width: ${(props) => props.theme.size.full};
`;

export const CardContentContainer = styled.div`
  ${FlexColumnCenterStart}
  width: ${(props) => props.theme.size.full};
  overflow-y: auto;
`;

export const CardContentImgContainer = styled.div`
  ${FlexColumnCenterStart}
  width: ${(props) => props.theme.size.full};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: ${(props) => props.theme.margins.small};
  width: ${(props) => props.theme.size.full};
`;

export const ImgFullSize = styled.div`
  ${FlexColumnCenter}
  ${FullSize}
`;

export const ErrorMessageContainer = styled.div``;

export const PageContainer = styled.div<{ image: any }>`
  background-image: ${(props) => `url(${props.image})`};
  /* background-image: ${(props) => props.theme.background.page.image}; */
  background-repeat: ${(props) => props.theme.background.page.repeat};
  background-attachment: ${(props) => props.theme.background.page.attachement};
  background-size: ${(props) => props.theme.background.page.size};
`;

export const SurveyBtnsContainer = styled.div`
  width: ${(props) => props.theme.size.full};
  ${FlexRowCenter}
`;

export const SurveyContainer = styled.div`
  width: auto;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 5em;
`;

export const RadioGroupContainer = styled(RadioGroup)`
  height: 57%;
  width: 250px;
  display: flex;
  flex-direction: column;
`;

export const DialogTitleContainer = styled.div`
  padding: 2%;
  display: flex;
  justify-content: space-between;
`;

export const HelpBtnContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
`;
