export const COOKIES = {
  TOKEN: 'koalou-parent-token',
  ID: 'koalou-parent-id',
  ROLE: 'koalou-parent-role',
  ALLOWED_ROLES: 'koalou-parent-allowed-roles',
  EMAIL: 'koalou-parent-email',
  EXPIRATION_SESSION: 'koalou-parent-expiration-session',
};

//TODO do the same for localstorage
