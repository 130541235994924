/*
  This file is a .js because react-phone-number-input/input doesn't work with
  typescript file
*/
import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessageContainer } from '../../theme/themeComponents/containerComponents';
import { ErrorMessageTypo } from '../../theme/themeComponents/typoComponents';

/*interface PhoneInputFieldProps {
  inputLabel: string;
  inputDefaultCountry: string;
  inputPlaceholder: string;
  inputDisabled: boolean;
  field: any;
  form: any;
}

const PhoneInputField: React.FunctionComponent<PhoneInputFieldProps> = ({
  inputLabel,
  inputDefaultCountry,
  inputPlaceholder,
  inputDisabled,
  field,
  form,
}) => {*/

const PhoneInputField = ({
  inputLabel,
  //inputDefaultCountry,
  inputPlaceholder,
  placeholderColor,
  icon,
  iconColor,
  inputDisabled,
  field,
  form,
}) => {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <InputContainer>
      {inputLabel && (
        <InputFieldHeader>
          <Label>{inputLabel}</Label>
        </InputFieldHeader>
      )}
      <InputFieldBody fullHeight={!inputLabel}>
        {icon && <InputFieldIcon iconColor={iconColor} icon={icon} />}
        <PhoneInputFieldInput
          {...field}
          defaultCountry="FR"
          placeholder={inputPlaceholder}
          placeholdercolor={placeholderColor}
          disabled={inputDisabled}
          onChange={(e) => form.setFieldValue('phone', e)}
          smartCaret={false}
          useNationalFormatForDefaultCountryValue={false}
        />
      </InputFieldBody>
      {touched && error && (
        <ErrorMessageContainer>
          <ErrorMessageTypo>{error}</ErrorMessageTypo>
        </ErrorMessageContainer>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div``;

const InputFieldHeader = styled.div``;

const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const InputFieldBody = styled.div`
  height: 48px;
  background: white;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:focus-visible {
    background: red;
  }
`;

const InputFieldIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.iconColor};
  font-size: 1rem;
  margin-right: 8px;
`;

const PhoneInputFieldInput = styled(PhoneInput)`
  width: calc(100% - 16px);
  height: 100%;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  &::placeholder {
    color: ${(props) => props.placeholdercolor};
    font-weight: bold;
  }
  &:focus-visible {
    border: none !important;
  }
`;

/*const PhoneInputStyled = styled(PhoneInput)`
  .PhoneInputInput {
    border-width: ${(props) => props.theme.border.small};
    border-style: ${(props) => props.theme.border.solid};
    border-color: ${(props) => props.theme.colors.border};
    border-left-width: 1px !important;
    border-radius: 0 1rem 1rem 0;
    width: 100%;
    height: 5rem;
    font-size: ${(props) => props.theme.fonts.size.inputField};
    font-family: ${(props) => props.theme.fonts.family.theme};
    padding-left: 10px;
    border: none;
  }
  @.phoneinputcountry {
    border-width: ${(props) => props.theme.border.small};
    border-style: ${(props) => props.theme.border.solid};
    border-color: ${(props) => props.theme.colors.border};
    border-right-width: 1px !important;
    border-radius: 1rem 0 0 1rem;
    margin-right: 0;
    padding-left: 8px;
    padding-right: 4px;
  }
  @.phoneinputcountryselect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) black,
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) black;
  }
  @.phoneinputcountryselect:focus + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: black;
  }
`;*/

export default PhoneInputField;
