import React from 'react';
import { useHistory } from 'react-router-dom';
import FormHead from '../components/FormHead/FormHead';
import { ResetPasswordCard } from '../theme/themeComponents/cardComponents';
import {
  AuthContainer,
  CardContentContainer,
  NavContainer,
  PageContainer,
} from '../theme/themeComponents/containerComponents';
import loginBackgroundImg from '../theme/images/loginBackground.png';
import CustomButton from '../components/CustomButton/CustomButton';

const SessionExpired: React.FunctionComponent<{}> = () => {
  let history = useHistory();
  return (
    <PageContainer image={loginBackgroundImg}>
      <NavContainer>
        <ResetPasswordCard>
          <CardContentContainer>
            <AuthContainer>
              <FormHead
                title="Vous avez été déconnecté en toute sécurité."
                subtitle="Pour des raisons de sécurité, vos sessions Koalou se terminent après 2 heures. Vous avez donc été automatiquement déconnecté"
              />
            </AuthContainer>
            <CustomButton
              handleOnClick={() => history.push('/')}
              content={'Se connecter'}
            ></CustomButton>
          </CardContentContainer>
        </ResetPasswordCard>
      </NavContainer>
    </PageContainer>
  );
};

export default SessionExpired;
