import styled, { css } from 'styled-components';

//Margin
const VerticalCenter = css`
  margin-top: ${(props) => props.theme.margins.auto};
  margin-bottom: ${(props) => props.theme.margins.auto};
`;

export const PatientAuthImg = styled.img`
  ${VerticalCenter}
  width: ${(props) => props.theme.size.full};
`;

export const GamePreviewImg = styled.img`
  width: ${(props) => props.theme.size.gamePreview};
`;
