import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BtnBlueLarge } from '../../theme/themeComponents/btnComponents';
import { ErrorMessageTypo } from '../../theme/themeComponents/typoComponents';
import { savingNewPassword, validatePassword } from '../../utils/auth';
import InputField from '../InputField/InputField';
import { ValidateNewPasswordSchema } from '../../utils/validationRules';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton/CustomButton';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';
import { displayToastNotification } from '../../utils/toastNotification';

const NewPasswordForm: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const oobCode = new URLSearchParams(history.location.search).get('oobCode');
  const [error, setError] = useState<string>('');
  const [passwordReset, setPasswordReset] = useState(false);

  useEffect(() => {
    if (passwordReset) {
      setTimeout(() => {
        history.push('/');
      }, 3000);
    }
  }, [passwordReset]);

  const handleSubmitNewPassword = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    if (!validatePassword(values.password)) {
      // setError(t('common:passwordInvalid'));
      displayToastNotification('error', t('common:passwordWeak'), 15000);
      return;
    }
    if (values.password !== values.confirmPassword) {
      setError('Veuillez entrer 2 fois le même mot de passe');
      return;
    }
    try {
      //@ts-ignore
      await savingNewPassword(values, oobCode);
      setPasswordReset(true);
    } catch (err: any) {
      console.error(err);
      if (err.error) {
        setError(err.error);
      }
    }
  };

  return (
    <>
      {passwordReset ? (
        <>
          <SuccessMessage>
            Votre mot de passe a été réinitialisé. <br />
            Vous allez être redirigé vers la page de connexion.
          </SuccessMessage>
          <LoaderContainer>
            <div title="loader" className="loader" />
          </LoaderContainer>
        </>
      ) : (
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={() => ValidateNewPasswordSchema(t)}
          onSubmit={(values: { password: string; confirmPassword: string }) =>
            handleSubmitNewPassword(values)
          }
        >
          {(props: any) => (
            <Form>
              <InputFieldContainer>
                <Field
                  name="password"
                  component={InputField}
                  inputLabel={t('common:newPasswordInputLabel')}
                  inputName="password"
                  inputType="password"
                  inputPlaceholder={t('common:newPasswordInputPlaceholder')}
                />
              </InputFieldContainer>
              <InputFieldContainer>
                <Field
                  name="confirmPassword"
                  component={InputField}
                  inputLabel={t('common:confirmPasswordInputLabel')}
                  inputName="confirmPassword"
                  inputType="password"
                  inputPlaceholder={t('common:confirmPasswordInputPlaceholder')}
                />
              </InputFieldContainer>
              <PasswordStrengthMeter password={props.values.password} />
              <CustomButtonContainer>
                <CustomButton
                  type="submit"
                  content={t('auth:resetPasswordButton')}
                />
              </CustomButtonContainer>
              {error && <ErrorMessageTypo>{error}</ErrorMessageTypo>}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const SubmitButton = BtnBlueLarge;

const InputFieldContainer = styled.div`
  margin-bottom: 16px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const CustomButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const SuccessMessage = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.darkGreenL3};
`;

export default NewPasswordForm;
