import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import InstructionList from '../components/InstructionList/InstructionList';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import i18n from '../i18n';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';

const getSurgeryByIdQuery = loader('../graphql/getSurgeryById.graphql');

const getInstructionTypesQuery = loader(
  '../graphql/getInstructionTypes.graphql'
);

const WhatPutInCase = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);
  const [getInstructionTypes, { data: dataInstructionTypes }] = useLazyQuery(
    getInstructionTypesQuery
  );

  const [sid, setSid] = useState('');
  const [loading, setLoading] = useState(true);
  const [surgeryCategoryId, setSurgeryCategoryId] = useState('');

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (surgeryId) {
      setSid(surgeryId);
      getSurgeryById({ variables: { surgeryId } });
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (dataSurgery) {
      if (dataSurgery.surgery?.length > 0) {
        setSurgeryCategoryId(
          dataSurgery.surgery[0].surgery_type.surgery_category.id
        );
        getInstructionTypes({
          variables: {
            language: i18n.language,
          },
        });
        setLoading(false);
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [dataSurgery]);

  const [papierAdministratif, setPapierAdministratif] =
    useState<{
      id: string;
      label: string;
      value: string;
    }>();
  const [valiseEnfant, setValiseEnfant] =
    useState<{
      id: string;
      label: string;
      value: string;
    }>();
  const [valiseParent, setValiseParent] =
    useState<{
      id: string;
      label: string;
      value: string;
    }>();

  useEffect(() => {
    if (dataInstructionTypes) {
      setPapierAdministratif(
        dataInstructionTypes.instruction_type_i18n.find(
          (elt: { id: string; label: string; value: string }) => {
            return elt.value === 'checklist-papier-administratif';
          }
        )
      );
      setValiseEnfant(
        dataInstructionTypes.instruction_type_i18n.find(
          (elt: { id: string; label: string; value: string }) => {
            return elt.value === 'checklist-enfant';
          }
        )
      );
      setValiseParent(
        dataInstructionTypes.instruction_type_i18n.find(
          (elt: { id: string; label: string; value: string }) => {
            return elt.value === 'checklist-parent';
          }
        )
      );
    }
  }, [dataInstructionTypes]);

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && (
        <>
          <Title>{t('titles_and_subtitles:whatPutInCaseTitle')}</Title>
          <Subtitle>{t('titles_and_subtitles:whatPutInCaseSubtitle')}</Subtitle>
          {valiseEnfant && valiseParent && papierAdministratif && (
            <CustomLayout>
              <InstructionList
                listType="checkbox"
                title={valiseEnfant.label}
                type={valiseEnfant.value}
                surgeryCategoryId={surgeryCategoryId}
              />
              <InstructionList
                listType="checkbox"
                title={valiseParent.label}
                type={valiseParent.value}
                surgeryCategoryId={surgeryCategoryId}
              />
              <InstructionList
                listType="checkbox"
                title={papierAdministratif.label}
                type={papierAdministratif.value}
                surgeryCategoryId={surgeryCategoryId}
              />
            </CustomLayout>
          )}
        </>
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const CustomLayout = styled.div<{}>`
  display: flex;
  flex: 1;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

export default WhatPutInCase;
