/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import * as _ from 'lodash';

/* TODO Big refacto of this component ! */
const useForm = (
  callback,
  validate,
  questions /* questions added because values were not loaded when useEffect on init of child components to set default answer for questions */
) => {
  const [values, setValues] = useState(
    // Object.assign({}, values, { questions })
    Object.assign({}, {}, { questions })
  );
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else if (Object.keys(errors).length > 0) {
      // eslint-disable-next-line no-console
      console.info('Validation done for: ', JSON.stringify(values));
      // eslint-disable-next-line no-console
      console.error('validation errors: ', JSON.stringify(errors));
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(values));
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAnswerRadioButtonChange = (answerLabel, question) => {
    let updatedQuestions = _.cloneDeep(values.questions);
    if (
      values.questions &&
      values.questions.find((elt) => elt.id === question.id)
    ) {
      const questionToUpdate = updatedQuestions.find(
        (elt) => elt.id === question.id
      );
      const answer = question.survey_type.answers.find(
        (answer) => answer.value === answerLabel
      );
      questionToUpdate.answer_id = answer.id;
      questionToUpdate.answer_value = answer.value;
      // updatedQuestions = values.questions.filter(elt => elt.id !== question.id);
      // updatedQuestions.push(questionToUpdate);
      // updatedQuestions.sort((q1, q2) => {
      //   if (q1.order < q2.order) return -1;
      //   if (q1.order > q2.order) return 1;

      //   return 0;
      // });
      setValues((values) => {
        const valuesTmp = _.cloneDeep(values.questions);
        const index = valuesTmp.findIndex((v) => v.id == questionToUpdate.id);
        valuesTmp[index] = { ...questionToUpdate };

        return {
          ...values,
          questions: valuesTmp,
        };
      });
    } else {
      setValues((values) => ({
        ...values,
        questions: updatedQuestions,
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    handleAnswerRadioButtonChange,
    values,
    setValues,
    errors,
    setErrors,
  };
};

export default useForm;
