export default class PlaneTrajectory {
  /** @type {Phaser.Scene} */
  scene;

  /** @type {Phaser.Physics.Arcade.Sprite} */
  plane;

  /**
   *
   * @param {Phaser.Scene} scene
   * @param {Phaser.Physics.Arcade.Sprite} plane
   */
  constructor(scene, plane) {
    this.scene = scene;
    this.plane = plane;
  }

  update() {
    const acceleration = 60;

    if (this.plane.y <= this.scene.cameras.main.height / 2) {
      this.plane.setAcceleration(0, acceleration);
    } else if (this.plane.y >= this.scene.cameras.main.height / 2) {
      this.plane.setAcceleration(0, -acceleration);
    }
    if (this.plane.body.velocity.y >= -100 && this.plane.body.velocity.y <= 100)
      this.plane.setAngle(this.plane.body.velocity.y / 2 + 10);
  }
}
