import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormHead from '../components/FormHead/FormHead';
import ForgotPasswordForm from '../components/Forms/ForgotPasswordForm';
import LoginForm from '../components/Forms/LoginForm';
import authImg from '../theme/images/authImg.png';
import loginBackgroundImg from '../theme/images/loginBackground.png';
import { AuthCard } from '../theme/themeComponents/cardComponents';
import {
  AuthContainer,
  CardContentContainer,
  CardContentImgContainer,
  ImgFullSize,
  NavContainer,
  PageContainer,
} from '../theme/themeComponents/containerComponents';
import { PatientAuthImg } from '../theme/themeComponents/imgComponents';

const AuthPage = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    title: t('auth:loginTitle'),
    subtitle: t('auth:loginSubtitle'),
    assistanceButtonToggle: false,
    assistanceButtonText: t('auth:forgotPasswordLink'),
  });

  const handleAssistanceButton = () => {
    const forgotPassword = t('auth:forgotPasswordTitle');
    const backToLogin = t('auth:loginLink');
    const loginTitle = t('auth:loginTitle');
    const loginSubtitle = t('auth:loginSubtitle');
    const forgotPasswordTitle = t('auth:forgotPasswordTitle');
    const forgotPasswordSubtitle = t('auth:forgotPasswordSubtitle');
    if (userData.assistanceButtonToggle === true) {
      setUserData(
        Object.assign(
          {},
          userData,
          { title: loginTitle },
          { subtitle: loginSubtitle },
          { assistanceButtonText: forgotPassword },
          { assistanceButtonToggle: false }
        )
      );
    } else {
      setUserData(
        Object.assign(
          {},
          userData,
          { title: forgotPasswordTitle },
          { subtitle: forgotPasswordSubtitle },
          { assistanceButtonText: backToLogin },
          { assistanceButtonToggle: true }
        )
      );
    }
  };

  return (
    <PageContainer image={loginBackgroundImg}>
      <NavContainer>
        <AuthCard>
          <CardContentContainer>
            <AuthContainer>
              <FormHead title={userData.title} subtitle={userData.subtitle} />
              {userData.assistanceButtonToggle ? (
                <>
                  <ForgotPasswordForm
                    handleAssistanceButton={handleAssistanceButton}
                    assistanceButtonText={userData.assistanceButtonText}
                  />
                </>
              ) : (
                <>
                  <LoginForm
                    handleAssistanceButton={handleAssistanceButton}
                    assistanceButtonText={userData.assistanceButtonText}
                  />
                </>
              )}
            </AuthContainer>
          </CardContentContainer>
          <CardContentImgContainer>
            <ImgFullSize>
              <PatientAuthImg src={authImg} />
            </ImgFullSize>
          </CardContentImgContainer>
        </AuthCard>
      </NavContainer>
    </PageContainer>
  );
};

export default AuthPage;
