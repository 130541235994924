import { RadioGroup } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AnxietyAnswer from "../AnxietyAnswer/AnxietyAnswer";

const AnxietyTest = ({ question, answers, handleAnswerRadioButtonChange }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
    handleAnswerRadioButtonChange(event.target.value, question);
  };
  return (
    <>
      <RadioGroup name="gender1" value={value} onChange={handleChange}>
        <Grid container spacing={2}>
          {answers &&
            answers.length > 0 &&
            answers.map((answer) => (
              <AnxietyAnswer
                key={answer.order}
                answer={answer}
                checked={value === answer.value}
                imageNumber={answer.order}
                name={answer.id}
                onChange={handleChange}
                value={answer.value}
              />
            ))}
        </Grid>
      </RadioGroup>
    </>
  );
};

AnxietyTest.propTypes = {
  question: PropTypes.any.isRequired,
  answers: PropTypes.array.isRequired,
  handleAnswerRadioButtonChange: PropTypes.func.isRequired,
};

export default AnxietyTest;
