export default class LandingAnimation {
  /** @type {Phaser.Scene} */
  scene;

  /** @type {Phaser.Physics.Arcade.Sprite} */
  plane;

  animationCompleted = false;

  /**
   *
   * @param {Phaser.Scene} scene
   * @param {Phaser.Physics.Arcade.Sprite} plane
   */
  constructor(scene, plane) {
    this.scene = scene;
    this.plane = plane;
  }

  start() {
    console.log('Start landing');
    this.plane.setAcceleration(0, 20);
  }

  onCompleted() {
    return this.animationCompleted;
  }

  update() {
    if (this.plane.body.velocity.x >= 40 && this.plane.body.velocity.x <= 90)
      this.plane.setAngle((this.plane.body.velocity.x - 40) * 0.4 - 10);
    else if (this.plane.body.velocity.x >= 90) {
      this.plane.setAngle(this.plane.body.velocity.y / 2 + 10);
    }
    if (this.plane.body.velocity.y <= 0) {
      this.plane.setDrag(10);
    } else if (this.plane.y >= this.scene.scale.height / 2) {
      this.plane.setAcceleration(0).setDrag(0, 7);
    }
    if (this.plane.body.velocity.x <= 0 && this.plane.body.velocity.y <= 0) {
      this.animationCompleted = true;
    }
  }
}
