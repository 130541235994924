import React from 'react'
import PropTypes from 'prop-types'
import { LeftTextStyled } from './styles'

const LeftText = ({ leftText }) => <LeftTextStyled>{leftText}</LeftTextStyled>

LeftText.propTypes = {
  leftText: PropTypes.string.isRequired,
}

export default LeftText
