import React from 'react'
import PropTypes from 'prop-types'
import { RightTextStyled } from './styles'

const RightText = ({ rightText }) => (
  <RightTextStyled>{rightText}</RightTextStyled>
)

RightText.propTypes = {
  rightText: PropTypes.string.isRequired,
}

export default RightText
