import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import emoji1 from '../../theme/images/emojis/emoji1Small.png';
import emoji2 from '../../theme/images/emojis/emoji2Small.png';
import emoji3 from '../../theme/images/emojis/emoji3Small.png';
import emoji4 from '../../theme/images/emojis/emoji4Small.png';
import emoji5 from '../../theme/images/emojis/emoji5Small.png';

interface EmotionChartProps {
  label: string;
  data: number[];
  currDay: number;
}

const e1 = new Image();
e1.src = emoji1;
const e2 = new Image();
e2.src = emoji2;
const e3 = new Image();
e3.src = emoji3;
const e4 = new Image();
e4.src = emoji4;
const e5 = new Image();
e5.src = emoji5;
const arr = [e1, e2, e3, e4, e5];

const getPointStyleArr = (data: number[]) => {
  return [
    '',
    arr[data[1] - 1],
    arr[data[2] - 1],
    arr[data[3] - 1],
    arr[data[4] - 1],
    arr[data[5] - 1],
    arr[data[6] - 1],
    arr[data[7] - 1],
  ];
};

const options = {
  maintainAspectRatio: false,
  events: [],
  layout: { padding: 16 },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          suggestedMin: 0.5,
          suggestedMax: 5,
          min: 0.5,
        },
        gridLines: { color: 'grey', drawOnChartArea: false },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: 'black',
        },
        gridLines: { color: 'grey', display: false },
      },
    ],
  },
};

const legend = {
  display: false,
};

const setData = (label: string, data: number[], currDay: number) => {
  const weekDays: string[] = ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'];
  const pointStyleArr = getPointStyleArr(data);

  return {
    labels: [
      '',
      weekDays[currDay],
      weekDays[(currDay + 1) % 7],
      weekDays[(currDay + 2) % 7],
      weekDays[(currDay + 3) % 7],
      weekDays[(currDay + 4) % 7],
      weekDays[(currDay + 5) % 7],
      weekDays[(currDay + 6) % 7],
    ],
    datasets: [
      {
        label: label,
        fill: false,
        lineTension: 0,
        borderDash: [],
        data: data,
        borderColor: 'grey',
        pointRadius: 0,
        pointStyle: pointStyleArr,
      },
    ],
  };
};

const EmotionChart: React.FunctionComponent<EmotionChartProps> = ({
  label,
  data,
  currDay,
}) => {
  const chartData = setData(label, data, currDay);

  return (
    <ChartContainer>
      <Line data={chartData} legend={legend} options={options} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default EmotionChart;
