export default class CountdownController {
  /** @type {Phaser.Scene} */
  scene;

  /** @type {Phaser.GameObjects.Text} */
  label;

  /** @type {Phaser.Time.TimerEvent} */
  timerEvent;

  duration = 0;

  repeat = 0;

  running = false;

  /**
   *
   * @param {Phaser.Scene} scene
   * @param {Phaser.GameObjects.Text} label
   */
  constructor(scene, label) {
    this.scene = scene;
    this.label = label;
  }

  /**
   *
   * @param {() => void} callback
   * @param {number} duration
   */
  start(callback, duration = 45000, repeat = 0) {
    this.stop();

    this.duration = duration;
    this.repeat = repeat;

    this.timerEvent = this.scene.time.addEvent({
      delay: duration,
      callback: () => {
        this.stop();
        if (callback) callback();
      },
      repeat: repeat,
    });
    this.running = true;
  }

  stop() {
    if (this.timerEvent) {
      if (!this.timerEvent.getRepeatCount()) {
        this.timerEvent.destroy();
        this.timerEvent = undefined;
        this.running = false;
      }
    }
  }

  isRunning() {
    return this.running;
  }

  getRepeatCount() {
    return this.timerEvent.getRepeatCount();
  }

  update() {
    if (!this.label || !this.timerEvent || this.duration <= 0) return;

    const elapsed = this.timerEvent.getElapsed();
    const remaining = this.duration - elapsed;
    const minutes = Math.floor(remaining / 60000);
    const seconds = Math.floor(remaining / 1000) % 60;

    this.label.text =
      minutes + ':' + (seconds > 9 ? '' : '0') + seconds.toFixed(0);
  }
}
