import React from "react";
import PropTypes from "prop-types";
import { ProfessorTextStyled } from "./styles";

const ProfessorText = ({ professorText }) =>
  professorText ? (
    <ProfessorTextStyled>{professorText}</ProfessorTextStyled>
  ) : null;

export default ProfessorText;
