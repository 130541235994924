import styled, { css } from 'styled-components';

/***************** GENERICS STYLED COMPONENTS FOR CARDS ******************/

export const CardStyle1 = css`
  background: ${(props) => props.theme.colors.creamL1};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 48px;
`;

/* Linear-gradient text */

export const LinearGradientText = css`
  background: -webkit-linear-gradient(#095c66, #209aa2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
