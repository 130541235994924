import styled, { createGlobalStyle } from 'styled-components';
import Chalkdusterttf from '../../theme/fonts/Chalkduster.ttf';

const FONT_STYLE = {
  // size: '1rem',
  color: '#c8fe8a',
};

const Arrow = styled.div`
  align-items: center;
  display: flex;

  a {
    display: block;
    height: 100%;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }
`;

const BackArrow = styled(Arrow)`
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 8;
  grid-row-start: 6;
  padding-left: 2rem;
`;

const Background = styled.div`
  /* background-image: url("../../theme/stories/${(props) =>
    props.storyImageNumber}.jpg"); */
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.forceShowFullImage ? 'contain' : 'cover'};
  grid-column-start: 1;
  grid-row-end: 7;
  grid-row-start: 1;
  grid-column-end: 9;
  grid-auto-rows: minmax(min-content, 2fr);
`;

const BottomTextStyled = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Chalkduster';
  grid-column-end: ${(props) => (props.professor ? 7 : 8)};
  grid-column-start: 2;
  grid-row-end: 8;
  grid-row-start: 7;
  justify-content: center;
  margin-left: 1rem;
  margin-right: ${(props) => (props.professor ? '4rem' : 0)};
  padding: 1rem;

  p {
    color: ${FONT_STYLE.color};
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    p {
      font-size: 1rem;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Chalkduster';
        url(${Chalkdusterttf}) format('truetype'),
    font-weight: normal;
    font-style: normal;
  }
`;

const HomeArrowStyled = styled(BackArrow)`
  a {
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const NextArrowStyled = styled(Arrow)`
  grid-column-end: 8;
  grid-column-start: 9;
  grid-row-end: 8;
  grid-row-start: 6;
  padding-right: 2rem;

  a {
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const EndArrowStyled = styled(NextArrowStyled)`
  a {
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const PreviousArrowStyled = styled(BackArrow)`
  a {
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const ProfessorStyled = styled.div`
  align-items: flex-end;
  display: flex;
  grid-column-end: 8;
  grid-column-start: 6;
  grid-row-end: 9;
  grid-row-start: 7;
  position: relative;

  span {
    background-image: ${(props) => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    display: block;
    height: 17rem;
    width: 100%;
    margin: 0 2rem 2rem 0;
  }
`;

const ProfessorTextStyled = styled.p`
  background-color: #000;
  border-radius: 10px;
  bottom: 22rem;
  color: #c8fe8a;
  display: inline-block;
  font-family: 'Chalkduster';
  font-size: 1.5rem;
  left: 5rem;
  margin-bottom: 0;
  padding: 15px;
  position: absolute;
  text-align: center;
  z-index: 12;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    font-size: 1rem;
  }

  &:after {
    border-bottom: 0 solid transparent;
    border-left: 4rem solid transparent;
    border-right: 0 solid transparent;
    border-top: 5rem solid #000;
    bottom: -4rem;
    content: '';
    display: block;
    height: 0;
    left: 13rem;
    position: absolute;
    width: 0;
  }
`;

const RightTextStyled = styled.p`
  background-color: #000;
  color: ${FONT_STYLE.color};
  font-family: 'Chalkduster';
  font-size: 2rem;
  grid-column-end: 9;
  grid-column-start: 6;
  grid-row-end: 6;
  grid-row-start: 2;
  margin: 0 3rem 0 5rem;
  overflow: scroll;
  padding: 2rem;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    p {
      font-size: 1rem;
    }
  }
`;

const LeftTextStyled = styled.p`
  background-color: #000;
  color: ${FONT_STYLE.color};
  font-family: 'Chalkduster';
  font-size: 1.5rem;
  grid-column-end: 5;
  grid-column-start: 2;
  grid-row-end: 6;
  grid-row-start: 2;
  margin-bottom: 0;
  overflow: scroll;
  padding: 2rem;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    font-size: 1rem;
  }
`;

const StorySequenceStyled = styled.div`
  background-color: black;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 1fr);
  height: 100vh;
  width: 100vw;
`;

export {
  Background,
  BottomTextStyled,
  EndArrowStyled,
  HomeArrowStyled,
  GlobalStyle,
  LeftTextStyled,
  NextArrowStyled,
  PreviousArrowStyled,
  ProfessorStyled,
  ProfessorTextStyled,
  RightTextStyled,
  StorySequenceStyled,
};
