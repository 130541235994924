export const PREFIX_URL_GOOGLE_STORAGE =
  'https://storage.googleapis.com/appkoalou-dev.appspot.com/';

export const ORGANIZATION_FILE_TYPE = {
  GUIDELINE: 'guideline',
  DOCUMENT: 'document',
};

export const ESIGN_FILE_TYPE = {
  DOCUMENT: 'esign-signed-document',
  TEMPLATE: 'esign-template',
};

export const PRESCRIPTION_FILE_TYPE = {
  PRESCRIPTION: 'prescription',
};
