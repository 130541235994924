import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomCard from '../components/CustomCard/CustomCard';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { COOKIES } from '../constants/cookies_type';
import { Mixpanel } from '../lib/mixpanel';
import { Heading1 } from '../theme/themeComponents/fonts';
import { flatten } from 'lodash';

const getAllSurgeriesQuery = loader('../graphql/getAllSurgeries.graphql');

interface SurgeriesParentProps {}

interface Surgery {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  type: string;
  date: string;
  surgery_type: {
    id: string;
    value: string;
  };
}

interface Patient {
  patient: {
    id: string;
    name: string;
    surname: string;
    surgeries: Array<Surgery>;
  };
}

interface SurgeryState {
  patientId: string;
  patientName: string;
  patientSurname: string;
  surgeryId: string;
  surgeryDate: string;
  surgeryType: string;
  organizationName: string;
}

const SurgeriesParent: React.FC<SurgeriesParentProps> = ({}) => {
  const history = useHistory();

  /* Retreive all surgeries of all children */
  const { loading, data } = useQuery(getAllSurgeriesQuery, {
    variables: { parent_id: cookie.get(COOKIES.ID) },
  });

  const [surgeries, setSurgeries] = useState<Array<SurgeryState> | null>(null);

  useEffect(() => {
    Mixpanel?.accessToMedicalAppointment();
  }, []);

  useEffect(() => {
    if (data?.parent?.[0]) {
      const surgeries = data.parent[0].parent_patients.map((e: Patient) => {
        return e.patient.surgeries.map((s: Surgery) => {
          return {
            patientId: e.patient.id,
            patientName: e.patient.name,
            patientSurname: e.patient.surname,
            surgeryId: s.id,
            surgeryDate: s.date,
            surgeryType: s.surgery_type.value,
            organizationName: s.organization?.name,
          };
        });
      });
      let surgeriesFlatten: Array<SurgeryState> = [];
      if (surgeries) {
        surgeriesFlatten = flatten(surgeries);
      }
      setSurgeries([...surgeriesFlatten]);
    }
  }, [data]);

  //TODO bug scroll Card component to inspect
  return (
    <PageWrapper>
      {loading && <Loader />}
      {!loading && surgeries && (
        <Container>
          <Title>Mes rendez-vous médicaux</Title>
          <SurgeriesContainer>
            {surgeries.length === 0 && (
              <NoSurgeriesText>
                Pas de rendez-vous médicaux. <br /> <br />
                L’horizon est dégagé.
              </NoSurgeriesText>
            )}

            {surgeries.map((surgery: SurgeryState, i: number) => {
              return (
                <CardContainer key={i}>
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health?sid=${surgery.surgeryId}`
                      )
                    }
                    title={surgery.patientName}
                    subtitle={surgery.surgeryType}
                    content={moment(surgery.surgeryDate).format('LL')}
                  />
                </CardContainer>
              );
            })}
          </SurgeriesContainer>
        </Container>
      )}
    </PageWrapper>
  );
};

export default SurgeriesParent;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const SurgeriesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const CardContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;

const NoSurgeriesText = styled.div`
  ${Heading1}
  margin-top: 20%;
  text-align: center;
`;
