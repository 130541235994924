import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import HelloSign from 'hellosign-embedded';
import cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled, { ThemeProvider } from 'styled-components';
import './App.css';
import FirstGame from './components/FirstGame/FirstGame';
import LayoutWithSidebar from './components/Layouts/WithSidebar/WithSidebar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { COOKIES } from './constants/cookies_type';
import { client } from './lib/apolloClient';
import ActivitiesChildren from './pages/ActivitiesChildren';
import AuthPage from './pages/Auth';
import ChildDevelopmentParent from './pages/ChildDevelopmentParent';
import DentalFirstSurvey from './pages/DentalFirstSurvey';
import Documents from './pages/Documents';
import EsignDocuments from './pages/EsignDocuments';
import EsignNoAuth from './pages/EsignNoAuth';
import Games from './pages/Games';
import Guidelines from './pages/Guidelines';
import HealthParent from './pages/HealthParent';
import HomeChildren from './pages/HomeChildren';
import HomeParent from './pages/HomeParent';
import ModifiedDentalAnxiety from './pages/ModifiedDentalAnxiety';
import OperationTimeline from './pages/OperationTimeline';
import PainEvaluation from './pages/PainEvaluation';
import ParentAnxietyEvaluation from './pages/ParentAnxietyEvaluation';
import PhbqEvaluation from './pages/PhbqEvaluation';
import ResetPassword from './pages/ResetPassword';
import SessionExpired from './pages/SessionExpired';
import Settings from './pages/Settings';
import Signup from './pages/Signup';
import SpaceParent from './pages/SpaceParent';
import StaiGlobal from './pages/StaiGlobal';
import StaiNow from './pages/StaiNow';
import Story from './pages/Story';
import StoryChildren from './pages/StoryChildren';
import SurgeriesParent from './pages/SurgeriesParent';
import Symptomatology from './pages/Symptomatology';
import Unauthorized from './pages/Unauthorized';
import { Welcome } from './pages/Welcome';
import WhatPutInCase from './pages/WhatPutInCase';
import CallDayBefore from './pages/CallDayBefore';
import { theme } from './theme/index';
import ApplicationContext from './utils/context';
import { ToastContainer } from 'react-toastify';
import CovidChecker from './pages/CovidChecker';
import CircleBackground from './components/CircleBackground/CircleBackground';
import EmotionalHealthLockParent from './pages/EmotionalHealthLockParent';
import CustomTour from './components/CustomTour/CustomTour';
import Prescriptions from './pages/Prescriptions';
import ArticlesParent from './pages/ArticlesParent';
import TESTprogram from './pages/TESTprogram';

declare global {
  interface Window {
    $crisp: any;
  }
}

function renderNotConnectedRoutes() {
  return (
    <Switch>
      <Route exact path={'/session-expired'}>
        <SessionExpired />
      </Route>
      <Route exact path={'/esign-no-auth'}>
        <EsignNoAuth />
      </Route>
      <Route exact path={'/reset-password'}>
        <ResetPassword />
      </Route>
      <Route exact path={'/unauthorized'}>
        <Unauthorized />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/">
        <AuthPage />
      </Route>
      <Redirect to="/" />
      {/* Do not redirect / test redirection session expiration modal error*/}
    </Switch>
  );
}
function renderConnectedRoutes() {
  Sentry.setContext('koalou user', {
    email: cookie.get(COOKIES.EMAIL),
    role: cookie.get(COOKIES.ROLE),
    id: cookie.get(COOKIES.ID),
  });
  return (
    <Switch>
      <Route exact path="/">
        <AuthPage />
      </Route>
      <Route exact path={'/session-expired'}>
        <SessionExpired />
      </Route>
      <Route exact path={'/reset-password'}>
        <ResetPassword />
      </Route>
      <Route exact path={'/unauthorized'}>
        <Unauthorized />
      </Route>
      <PrivateRoute
        exact
        path={'/children/story/part/:part'}
        component={Story}
      />
      <PrivateRoute exact path={'/welcome'} component={Welcome} />
      <LayoutWithSidebar>
        <Switch>
          <PrivateRoute
            exact
            path={'/children/home'}
            component={HomeChildren}
          />
          <PrivateRoute
            exact
            path={'/children/story'}
            component={StoryChildren}
          />
          <PrivateRoute
            exact
            path={'/children/activities'}
            component={ActivitiesChildren}
          />
          <PrivateRoute exact path={'/children/games'} component={Games} />
          <PrivateRoute
            exact
            path={'/children/games/first-game'}
            component={FirstGame}
          />
          <PrivateRoute
            exact
            path={'/parent/emotional-health/lock'}
            component={EmotionalHealthLockParent}
          />
          <PrivateRoute
            exact
            path={'/parent/emotional-health/home'}
            component={HomeParent}
          />
          <PrivateRoute
            exact
            path={'/parent/emotional-health/my-space'}
            component={SpaceParent}
          />
          <PrivateRoute
            exact
            path={'/parent/emotional-health/child-development'}
            component={ChildDevelopmentParent}
          />
          <PrivateRoute
            exact
            path={'/parent/emotional-health/articles'}
            component={ArticlesParent}
          />

          <PrivateRoute
            exact
            path={'/parent/surgeries'}
            component={SurgeriesParent}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health'}
            component={HealthParent}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/parent-anxiety-evaluation'}
            component={ParentAnxietyEvaluation}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/symptomatology'}
            component={Symptomatology}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/pain-evaluation'}
            component={PainEvaluation}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/phbq-evaluation'}
            component={PhbqEvaluation}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/modified-dental-anxiety'}
            component={ModifiedDentalAnxiety}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/stai-now'}
            component={StaiNow}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/stai-global'}
            component={StaiGlobal}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/dental-first-survey'}
            component={DentalFirstSurvey}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/covid-checker'}
            component={CovidChecker}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/guidelines'}
            component={Guidelines}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/documents'}
            component={Documents}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/esign-documents'}
            component={EsignDocuments}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/operation-timeline'}
            component={OperationTimeline}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/what-put-in-case'}
            component={WhatPutInCase}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/calldaybefore'}
            component={CallDayBefore}
          />
          <PrivateRoute
            exact
            path={'/parent/surgeries/health/prescriptions'}
            component={Prescriptions}
          />
          <PrivateRoute exact path={'/settings'} component={Settings} />
          <PrivateRoute exact path={'/test-program'} component={TESTprogram} /> //TODO delete this page when program test ok
          <Redirect to={'/welcome'} />
        </Switch>
      </LayoutWithSidebar>
    </Switch>
  );
}

function renderRoutes(isAuth: boolean) {
  if (isAuth) {
    return renderConnectedRoutes();
  } else {
    return renderNotConnectedRoutes();
  }
}

function App() {
  const { t } = useTranslation();

  /* Manage Hellosign client */
  const [helloSignClientId, setHelloSignClientId] = useState<HelloSign>();
  const [showCustomModalChildMoodTest, setShowCustomModalChildMoodTest] =
    useState(false);
  const [showCustomModalChildSurgeries, setShowCustomModalChildSurgeries] =
    useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>(null);
  const [currentChildIndex, setCurrentChildIndex] = useState(null);
  const [currentSurgeryIndex, setCurrentSurgeryIndex] = useState(null);
  const [currentSurveysToEdit, setCurrentSurveysToEdit] = useState({});
  const [currentParentAndChildrenToEdit, setCurrentParentAndChildrenToEdit] =
    useState({});

  const [onboardingState, setOnboardingState] = useState({
    show: false,
    type: null,
  });

  /* Context configuration */
  /* TODO check if all cxt are used */
  const contextValue = {
    helloSignClientCxt: { helloSignClientId, setHelloSignClientId },
    customModalCxt: {
      showCustomModalChildMoodTest,
      setShowCustomModalChildMoodTest,
      showCustomModalChildSurgeries,
      setShowCustomModalChildSurgeries,
    },
    currentUserEmailCxt: { currentUserEmail, setCurrentUserEmail },
    currentSurveysCxt: { currentSurveysToEdit, setCurrentSurveysToEdit },
    currentParentAndChildrenCxt: {
      currentParentAndChildrenToEdit,
      setCurrentParentAndChildrenToEdit,
    },
    currentChildIndexCxt: { currentChildIndex, setCurrentChildIndex },
    currentSurgeryIndexCxt: { currentSurgeryIndex, setCurrentSurgeryIndex },
    onboarding: { onboardingState, setOnboardingState },
  };

  const [firebaseAuth, setFirebaseAuth] = useState<{
    isLoading: boolean;
    isAuth: boolean;
  }>({ isLoading: true, isAuth: false });

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      /* Init HelloSign clientId */
      setHelloSignClientId(
        new HelloSign({
          clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID, //TODO env var
          skipDomainVerification: true, //TODO switch to true in prod ?
        })
      );
      if (user) {
        // If user is set, user is logged
        // console.log(user);
        setCurrentUserEmail(user.email);
        setFirebaseAuth({ isLoading: false, isAuth: true });
      } else {
        setFirebaseAuth({ isLoading: false, isAuth: false });
      }
    });
    return () => {
      // Remove firebase listener
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <ApplicationContext.Provider value={contextValue}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {!firebaseAuth.isLoading && (
                <MainContainer>
                  <CircleBackground />

                  <ToastContainer />
                  {renderRoutes(firebaseAuth.isAuth)}
                  <CookieConsentContainer
                    buttonText={t('common:cookieConsentButton')}
                    contentStyle={{ flex: '0 1 80%' }}
                  >
                    {t('common:cookieConsentText')}
                  </CookieConsentContainer>
                  <CustomTour />
                </MainContainer>
              )}
            </BrowserRouter>
          </ThemeProvider>
        </ApplicationContext.Provider>
      </ApolloProvider>
    </div>
  );
}

const MainContainer = styled.div`
  height: 100% !important;
  position: relative;
  z-index: 100; //Because of Background circle position absolute
`;

const CookieConsentContainer = styled(CookieConsent)`
  font-size: 13px;
  padding-right: 60px;
  justify-content: center;
`;

export default App;
