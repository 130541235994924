import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { resettingPassword } from '../../utils/auth';
import InputField from '../InputField/InputField';
import { ValidateEmailSchema } from '../../utils/validationRules';
import { ErrorMessageTypo } from '../../theme/themeComponents/typoComponents';
import { BtnText } from '../../theme/themeComponents/btnComponents';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from '../CustomButton/CustomButton';
import { Heading3 } from '../../theme/fonts';

interface ForgotPasswordComponentProps {
  handleAssistanceButton: (e: any) => void;
  assistanceButtonText: string;
}

const ForgotPasswordForm: React.FunctionComponent<ForgotPasswordComponentProps> =
  ({ handleAssistanceButton, assistanceButtonText }) => {
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [isEmailSent, setIsEmailSent] = useState(false);

    const handleSubmitResetPassword = async (values: { email: string }) => {
      try {
        //@ts-ignore
        const { error } = await resettingPassword(values.email);
        if (error) {
          setError(error);
        } else {
          setIsEmailSent(true);
        }
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    };

    return (
      <>
        {isEmailSent ? (
          <SuccessMessage>Un email vous a été envoyé !</SuccessMessage>
        ) : (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={() => ValidateEmailSchema(t)}
            onSubmit={(values: { email: string }) =>
              handleSubmitResetPassword(values)
            }
          >
            <Form>
              <InputFieldContainer>
                <Field
                  name="email"
                  component={InputField}
                  inputLabel={t('common:emailInputLabel')}
                  inputName="email"
                  inputType="email"
                  inputPlaceholder={t('common:emailInputPlaceholder')}
                />
              </InputFieldContainer>
              <SubmitButtonContainer>
                <CustomButton
                  type="submit"
                  content={t('auth:forgotPasswordButton')}
                />
              </SubmitButtonContainer>
              {error && <ErrorMessageTypo>{error}</ErrorMessageTypo>}
              <AssistanceButton
                onClick={handleAssistanceButton}
                style={{ marginTop: '15PX', textAlign: 'center' }}
              >
                {assistanceButtonText}
              </AssistanceButton>
            </Form>
          </Formik>
        )}
      </>
    );
  };

const AssistanceButton = styled(BtnText)``;

const InputFieldContainer = styled.div``;

const SubmitButtonContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  ${Heading3}
  color: ${({ theme }) => theme.colors.darkGreenL4};
`;

export default ForgotPasswordForm;
