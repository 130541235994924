import { useLazyQuery } from '@apollo/client';
import { Collapse } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import InstructionList from '../components/InstructionList/InstructionList';
import Flex from '../components/Layouts/Flex/Flex';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import Timeline from '../components/Timeline/Timeline';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import { getTimelineLabels } from '../utils/getTimelineLabels';

const getSurgeryByIdQuery = loader('../graphql/getSurgeryById.graphql');

const OperationTimeline: React.FunctionComponent<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);

  const [sid, setSid] = useState('');
  const [activeStep, setActiveStep] = useState<number>(-1);
  const [displayInstructions, setDisplayInstructions] =
    useState<boolean>(false);
  const steps = ['annonce-operation', 'D-7', 'D-3', 'D-1', 'D-0'];
  const [instructionType, setInstructionType] = useState<string>('');
  const [instructionListTitle, setInstructionListTitle] = useState<string>('');
  const [timelineLabels, setTimelineLabels] = useState<string[]>();
  const [surgery, setSurgery] =
    useState<{
      date: '';
      categoryId: '';
    } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (surgeryId) {
      setSid(surgeryId);
      getSurgeryById({ variables: { surgeryId } });
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (dataSurgery) {
      if (dataSurgery.surgery?.length > 0) {
        const surgeryTmp = dataSurgery.surgery[0];
        setSurgery({
          date: surgeryTmp.date,
          categoryId: surgeryTmp.surgery_type?.surgery_category?.id,
        });
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [dataSurgery]);

  useEffect(() => {
    if (surgery) {
      setTimelineLabels(getTimelineLabels(surgery.date));
    }
  }, [surgery]);

  useEffect(() => {
    if (timelineLabels) {
      setLoading(false);
    }
  }, [timelineLabels]);

  const handleClickTimeline = (type: string, index: number, title: string) => {
    setActiveStep(index);
    setInstructionType(type);
    setInstructionListTitle(title);
    if (!displayInstructions || activeStep === index)
      setDisplayInstructions(!displayInstructions);
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && (
        <>
          <Container>
            <Title>{t('titles_and_subtitles:operationTimelineTitle')}</Title>
            <Subtitle>
              {t('titles_and_subtitles:operationTimelineSubtitle')}
            </Subtitle>
          </Container>
          <Timeline
            steps={steps}
            activeStep={activeStep}
            timelineLabels={timelineLabels}
            handleClickTimeline={handleClickTimeline}
          />
          <Collapse in={displayInstructions} onExited={() => setActiveStep(-1)}>
            <InstructionContainer>
              <div>
                <InstructionTitle>{instructionListTitle}</InstructionTitle>
                <Flex justifyContent="center">
                  {instructionType && (
                    <InstructionList
                      listType="list"
                      type={instructionType}
                      surgeryCategoryId={surgery?.categoryId!}
                    />
                  )}
                </Flex>
              </div>
            </InstructionContainer>
          </Collapse>
        </>
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const InstructionTitle = styled.div`
  ${Heading2}
`;

const InstructionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default OperationTimeline;
