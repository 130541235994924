import React from 'react';
import { Link } from 'react-router-dom';
import { HomeArrowStyled } from './styles';


const HomeArrow = ({ image, childIdx, surgeryIdx }) => (
  <HomeArrowStyled image={image}>
    <Link to={`/children/story?cidx=${childIdx}&sidx=${surgeryIdx}`}></Link>
  </HomeArrowStyled>
);

export default HomeArrow;
