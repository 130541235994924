import { getFirebaseToken } from './auth';

export const getSignUrl = async (
  signatureRequestId: string
): Promise<{ error: string | null; signUrl: string | null }> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(`${process.env.REACT_APP_ESIGN_URL}/get-sign-url`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        signatureRequestId,
      }),
    });
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, signUrl: null };
    } else {
      return { error: null, signUrl: resJson.signUrl };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error', signUrl: null };
  }
};

export const getSignUrlNoAuth = async (
  signatureRequestId: string,
  signatureId: string,
  email: string
): Promise<{
  error: string | null;
  signUrl: string | null;
  templateName: string | null;
}> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ESIGN_URL}/get-sign-url-no-auth`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          signatureRequestId,
          signatureId,
          email,
        }),
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, signUrl: null, templateName: null };
    } else {
      return {
        error: null,
        signUrl: resJson.signUrl,
        templateName: resJson.templateName,
      };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error', signUrl: null, templateName: null };
  }
};
