import React from "react";
import PropTypes from "prop-types";
import { ProfessorStyled } from "./styles";
import ProfessorText from "./ProfessorText";

const Professor = ({ professorText, image }) => (
  <ProfessorStyled image={image}>
    <ProfessorText professorText={professorText} />
    <span />
  </ProfessorStyled>
);

export default Professor;
