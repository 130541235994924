import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Loader } from '../components/Loader/Loader';
import { getIndexSelectedLanguage } from '../utils/translation';
import { useTranslation } from 'react-i18next';
import { displayToastNotification } from '../utils/toastNotification';
import { intersection } from 'lodash';
import { getFirebaseToken } from '../utils/auth';

const getProgramsQuery = loader('../graphql/getPrograms.graphql');
const getPatientDayOfProgramsQuery = loader(
  '../graphql/getPatientDayOfPrograms.graphql'
);

const PATIENT_ID = 'b9658b68-0b78-4d6e-b064-25ed90a9723b';

interface TESTprogramProps {}

/**
 * Very quick and simple demo of how program feature can be implemented
 * Patient id should be dynamic
 * Need refacto for production
 * Backend req should be made when parent buys a program to insert row in DB
 * and when patient finishes a day (to udpdate patient_day_of_program bool + badges logic)
 **/

const TESTprogram: React.FC<TESTprogramProps> = () => {
  const { i18n, t } = useTranslation();

  const { data: programsData, loading: loadingProgramsData } = useQuery(
    getProgramsQuery,
    {
      variables: {
        language: i18n.language,
      },
    }
  );

  const {
    data: patientDayOfProgramsData,
    loading: loadingPatientDayOfProgramsData,
  } = useQuery(getPatientDayOfProgramsQuery, {
    variables: { language: i18n.language, patientId: PATIENT_ID },
  });

  //TODO types
  const [currentProgram, setCurrentProgram] = useState({
    show: false,
    programDayByDay: {
      id: '',
      day: '',
      dayCompleted: false,
      dailyMessage: '',
      exercise1: { name: '', type: '', details: '' },
      exercise2: { name: '', type: '', details: '' },
    },
  });

  const buyProgram = async (patientId: string, programId: string) => {
    console.log(programId);
    try {
      const token = await getFirebaseToken();
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_SERVER_URL}/buy-program`,
        // `http://localhost:3000/buy-program`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ patientId, programId }),
        }
      );
      if (res.ok) {
        displayToastNotification(
          'success',
          `Programme acheté pour ${PATIENT_ID}`
        );
      } else {
        displayToastNotification('error', 'Une erreur est survenue');
      }
    } catch (err) {
      displayToastNotification('error', 'Une erreur est survenue');
      console.log(err);
    }
  };

  const completeDay = async (patientId: string, programDayByDayId: string) => {
    try {
      const token = await getFirebaseToken();
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_SERVER_URL}/day-complete`,
        // `http://localhost:3000/day-complete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ patientId, programDayByDayId }),
        }
      );
      if (res.ok) {
        displayToastNotification('success', `Jour terminé pour ${PATIENT_ID}`);
      } else {
        displayToastNotification('error', 'Une erreur est survenue');
      }
    } catch (err) {
      displayToastNotification('error', 'Une erreur est survenue');
      console.log(err);
    }
  };

  //TODO params ts
  const checkIfPatientIsSubscribed = (program: any) => {
    const programDayByDayIds = program.program_day_by_days.map(
      (e: any) => e.id
    );
    const patientDayOfProgramIds =
      patientDayOfProgramsData.patient_day_of_program.map(
        (e: any) => e.program_day_by_day.id
      );
    if (intersection(programDayByDayIds, patientDayOfProgramIds).length > 0) {
      return true;
    }
    return false;
  };

  //TODO params ts
  const enterProgram = (programDayByDay: any, dayCompleted: boolean) => {
    const tmp = {
      id: programDayByDay.id,
      day: programDayByDay.day,
      dayCompleted: dayCompleted,
      dailyMessage:
        programDayByDay.program_daily_message.program_daily_message_i18ns[
          getIndexSelectedLanguage(
            programDayByDay.program_daily_message.program_daily_message_i18ns.map(
              (f: any) => f.language.language_code
            ),
            i18n.language
          )
        ].value,
      exercise1: {
        //TODO program_exercise_i18n same logic as before
        name: '',
        type: '',
        details: '',
      },
      exercise2: { name: '', type: '', details: '' }, //TODO program_exercise_i18n same logic as before
    };
    setCurrentProgram({ show: true, programDayByDay: tmp });
  };

  if (loadingProgramsData || loadingPatientDayOfProgramsData) {
    return <Loader />;
  }

  return (
    <div>
      <div>
        <h4>Test Camille Cali. ID: {PATIENT_ID}</h4>
        <h3>Programs available :</h3>
        {programsData?.program.map((e: any, i: number) => {
          const indexLanguage = getIndexSelectedLanguage(
            e.program_i18ns.map((f: any) => f.language.language_code),
            i18n.language
          );
          return (
            <p key={i}>
              {e.program_i18ns[indexLanguage].name}
              <br />
              durée: {e.duration} jours
              <br />
              {e.program_i18ns[indexLanguage].description}
              <br />
              <img src={e.picture} />
              <button
                onClick={() => buyProgram(PATIENT_ID, e.id)}
                disabled={checkIfPatientIsSubscribed(e)}
              >
                buy
              </button>
            </p>
          );
        })}
      </div>
      <div>
        <h3>You have subscribed to :</h3>
        {patientDayOfProgramsData?.patient_day_of_program.map(
          (e: any, i: number) => {
            const indexLanguage = getIndexSelectedLanguage(
              e.program_day_by_day.program.program_i18ns.map(
                (f: any) => f.language.language_code
              ),
              i18n.language
            );
            return (
              <div key={i} style={{ border: '1px solid black' }}>
                {e.program_day_by_day.program.program_i18ns[indexLanguage].name}
                <button
                  onClick={() =>
                    enterProgram(e.program_day_by_day, e.day_completed)
                  }
                >
                  Enter
                </button>
              </div>
            );
          }
        )}
      </div>
      <hr />
      {currentProgram.show && (
        <div>
          <p>{currentProgram.programDayByDay.dailyMessage}</p>
          <br />
          <div>
            {currentProgram.programDayByDay.dayCompleted ? (
              <p>Félicitations vous avez terminé ce jour</p>
            ) : (
              <p>Vous êtes au jour {currentProgram.programDayByDay.day}</p>
            )}
          </div>
          <br />
          <div>Exercice 1: {currentProgram.programDayByDay.exercise1.name}</div>
          <div>Details: {currentProgram.programDayByDay.exercise1.details}</div>
          <div>Type: {currentProgram.programDayByDay.exercise1.type}</div>
          <br />
          <div>Exercice 2: {currentProgram.programDayByDay.exercise2.name}</div>
          <div>Details: {currentProgram.programDayByDay.exercise2.details}</div>
          <div>Type: {currentProgram.programDayByDay.exercise2.type}</div>
          <br />
          <br />
          {}
          <button
            onClick={() =>
              completeDay(PATIENT_ID, currentProgram.programDayByDay.id)
            }
            disabled={currentProgram.programDayByDay.dayCompleted}
          >
            Terminer le jour
          </button>
        </div>
      )}
    </div>
  );
};

export default TESTprogram;
