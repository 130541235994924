import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomCard from '../components/CustomCard/CustomCard';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import PdfViewer from '../components/PdfViewer/PdfViewer';
import { Heading1, Heading2 } from '../theme/fonts';
import { loader } from 'graphql.macro';
import { PRESCRIPTION_FILE_TYPE } from '../constants/gcp_types';
import moment from 'moment';

const getPrescriptionQuery = loader('../graphql/getPrescriptions.graphql');

interface PrescriptionsProps {}

interface Prescriptions {
  id: string;
  filePath: string | null;
  date: string;
}

const Prescriptions: React.FC<PrescriptionsProps> = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [prescriptions, setPrescriptions] =
    useState<Array<Prescriptions> | null>(null);
  const [documentName, setDocumentName] = useState('');
  const [sid, setSid] = useState('');

  const [getPrescriptions, { data: dataPrescriptions }] =
    useLazyQuery(getPrescriptionQuery);

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (surgeryId) {
      setSid(surgeryId);
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (sid) {
      getPrescriptions({ variables: { surgeryId: sid } });
    }
  }, [sid]);

  useEffect(() => {
    if (dataPrescriptions) {
      const prescriptionsTmp = dataPrescriptions.prescription.map((e: any) => {
        return {
          id: e.id,
          filePath: e.file_path,
          date: moment(e.created_at).format('DD/MM/YYYY'),
        };
      });
      setPrescriptions(prescriptionsTmp);
    }
  }, [dataPrescriptions]);

  const handleModal = (name: string) => {
    setDocumentName(name);
    toggle();
  };

  const toggle = () => setModal(!modal);

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && !modal && (
        <Container>
          <Title>{t('titles_and_subtitles:prescriptionsTitle')}</Title>
          <Subtitle>{t('titles_and_subtitles:prescriptionsSubtitle')}</Subtitle>
          <DocumentCardContainer>
            {prescriptions?.map((prescription: any, i: number) => {
              return (
                <CustomCardContainer key={i}>
                  <CustomCard
                    title={`Prescription du ${prescription.date}`}
                    handleOnClick={() => handleModal(prescription.filePath)}
                  />
                </CustomCardContainer>
              );
            })}
          </DocumentCardContainer>
        </Container>
      )}
      {!loading && modal && (
        <PdfViewer
          toggle={toggle}
          documentName={documentName}
          fileCategory={PRESCRIPTION_FILE_TYPE.PRESCRIPTION}
        />
      )}
    </PageWrapper>
  );
};

export default Prescriptions;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const Container = styled.div`
  width: 100%;
`;

const DocumentCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
`;

const CustomCardContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  position: relative;
`;
