import { useQuery } from '@apollo/client';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'
import styled from 'styled-components';
import { CardLayout } from '../components/CardLayout/CardLayout';
import CustomButton from '../components/CustomButton/CustomButton';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { theme } from '../theme';
import {
  BodyLarge,
  Heading1,
  Heading2,
  Heading4,
} from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';
import { getIndexSelectedLanguage } from '../utils/translation';
import { childDevelopmentEn } from '../conf/chidlDevelopment/childDevelopment';
import { childDevelopmentFr } from '../conf/chidlDevelopment/childDevelopment';
import moment from 'moment';
import { LANGUAGES } from '../constants/languages_types';

interface ChildDevelopmentParentProps {}

interface ChildDevelopmentData {
  age: Array<string>;
  subtitle: string;
  section: Array<{
    title?: Array<string>;
    list?: Array<string>;
    secondaryText?: Array<string>;
  }>;
}

const getArticleOfTheMonthQuery = loader(
  '../graphql/getArticleOfTheMonth.graphql'
);

const ChildDevelopmentParent: React.FC<ChildDevelopmentParentProps> = ({}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { data: dataArticleOfTheMonth } = useQuery(getArticleOfTheMonthQuery, {
    variables: { language: i18n.language },
  });

  const [grantAccess, setGrantAccess] = useState(false);

  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [children, setChildren] = useState<
    Array<{ id: string; name: string; age: string }> | []
  >([]);

  const [currentChildrenIndex, setCurrentChildrenIndex] = useState(0);
  const [articleOfTheMonth, setArticleOfTheMonth] = useState({
    title: '',
    value: '',
  });

  const [childDevelopmentData, setChildDevelopmentData] =
    useState<ChildDevelopmentData | null>(null);

  const [showFullArticle, setShowFullArticle] = useState(false);

  useEffect(() => {
    const cxt = currentParentAndChildrenCxt.currentParentAndChildrenToEdit;
    if (cxt) {
      if (!cxt.hasActivatedEmotionalHealth) {
        history.push('/parent/emotional-health/lock');
      } else {
        setGrantAccess(true);
      }
    }
  }, []);

  useEffect(() => {
    if (currentParentAndChildrenCxt?.currentParentAndChildrenToEdit) {
      const childrenTmp =
        currentParentAndChildrenCxt?.currentParentAndChildrenToEdit.children.map(
          (e: any) => {
            const age = moment().diff(e.birthDate, 'years');
            return { ...e, age };
          }
        );
      setChildren(childrenTmp);
    }
  }, [currentParentAndChildrenCxt.currentParentAndChildrenToEdit]);

  useEffect(() => {
    if (dataArticleOfTheMonth) {
      const data = dataArticleOfTheMonth?.article_of_the_month;
      let indexLanguage = 0;
      if (data) {
        indexLanguage = getIndexSelectedLanguage(
          data?.[0]?.article_of_the_month_i18ns?.map(
            (f: any) => f.language.language_code
          ),
          i18n.language
        );
        setArticleOfTheMonth({
          title: data?.[0]?.article_of_the_month_i18ns?.[indexLanguage]?.title,
          value: data?.[0]?.article_of_the_month_i18ns?.[indexLanguage]?.value,
        });
      }
    }
  }, [dataArticleOfTheMonth]);

  useEffect(() => {
    if (children.length > 0) {
      let childDevelopmentLang = childDevelopmentFr;
      if (i18n.language === LANGUAGES.EN_UK) {
        childDevelopmentLang = childDevelopmentEn;
      }
      const childrenDevelopmentDataTmp = childDevelopmentLang.filter(
        (e) =>
          e.age!.indexOf(children[currentChildrenIndex].age.toString()) >= 0
      );
      if (childrenDevelopmentDataTmp.length > 0) {
        setChildDevelopmentData(childrenDevelopmentDataTmp[0]);
      } else {
        setChildDevelopmentData(null);
      }
    }
  }, [children, currentChildrenIndex]);

  const handleClickNextChild = () => {
    setCurrentChildrenIndex((currentChildrenIndex + 1) % children.length);
  };

  const openArticle = () => {
    setShowFullArticle(true);
  };

  /* If the parent did not activate the emotional health space */
  if (!grantAccess) {
    return <></>;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push('/parent/emotional-health/home')
      }
    >
      <Title>
        Développement de {children?.[currentChildrenIndex]?.name}{' '}
        {!showFullArticle && (
          <ArrowIcon
            icon={faChevronRight}
            onClick={() => handleClickNextChild()}
          />
        )}
      </Title>
      {!showFullArticle && (
        <Container>
          <CardLayoutContainer
            style={{ minHeight: '700px' }}
            data-tut="reactour__child-development-parent__main"
          >
            {childDevelopmentData && (
              <CardLayout>
                <CardChildDevelopmentTitle>
                  Age {children?.[currentChildrenIndex]?.age}
                </CardChildDevelopmentTitle>
                <CardChildDevelopmentSubtitle>
                  {childDevelopmentData.subtitle}
                </CardChildDevelopmentSubtitle>
                {childDevelopmentData.section.map((section, i) => (
                  <CardChildDevelopmentSection key={i}>
                    {section.title?.map((title, i) => (
                      <CardChildDevelopmentSectionTitle key={i}>
                        {title}
                      </CardChildDevelopmentSectionTitle>
                    ))}
                    <ul>
                      {section.list?.map((list, i) => (
                        <li key={i}>{list}</li>
                      ))}
                    </ul>
                    {section.secondaryText?.map((st, i) => (
                      <CardChildDevelopmentSectionTitle key={i}>
                        {st}
                      </CardChildDevelopmentSectionTitle>
                    ))}
                  </CardChildDevelopmentSection>
                ))}
              </CardLayout>
            )}
          </CardLayoutContainer>
          <CardLayoutContainer data-tut="reactour__child-development-parent__article-of-the-month">
            <CardLayout width={'400px'} height={'566px'}>
              <CardTitle>Article du mois</CardTitle>
              <CardContentContainer>
                <CardSubtitle>{articleOfTheMonth.title}</CardSubtitle>
                <CardContent>{articleOfTheMonth.value}</CardContent>
              </CardContentContainer>
              <CardButton>
                <CustomButton
                  content="Lire plus"
                  backgroundColor={theme.colors.darkGreenL2}
                  borderRadius={'16px'}
                  borderColor={theme.colors.darkGreenL2}
                  handleOnClick={() => openArticle()}
                />
              </CardButton>
            </CardLayout>
          </CardLayoutContainer>
        </Container>
      )}
      {showFullArticle && (
        <ContainerFullArticle>
          <CardLayout height={'85%'} width={'782px'} autoHeightMobile={true}>
            <CardContentContainer fullView={true}>
              <CardSubtitle>
                {articleOfTheMonth.title}
                <CloseArticleIcon
                  icon={faTimes}
                  onClick={() => setShowFullArticle(false)}
                />
              </CardSubtitle>
              <CardContent fullView={true}>
                {articleOfTheMonth.value}
              </CardContent>
            </CardContentContainer>
          </CardLayout>
        </ContainerFullArticle>
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    margin-top: 40px;
    margin-top: 0;
  }
`;

const CardLayoutContainer = styled.div`
  width: 40%;
  margin: 0 5%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 80%;
    margin: 40px 0 0 0;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    margin: 0;
    margin: 40px 0 0 0;
  }
`;

const CardTitle = styled.div`
  ${Heading1}
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  padding-bottom: 2px;
  border-bottom: 1px solid ${(props) => props.theme.colors.blackMain};
  height: 10%;
`;

const CardContentContainer = styled.div<{ fullView?: boolean }>`
  position: relative;
  height: ${(props) => (props.fullView ? '100%' : '65%')};
  width: 100%;
  margin-top: 5px;
`;

const CardSubtitle = styled.div`
  ${Heading2}
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  margin-top: 12px;
  margin-bottom: 19px;
  padding-right: 10px;
`;

const CardContent = styled.div<{ fullView?: boolean }>`
  max-height: 90%;
  ${BodyLarge};
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  padding-right: 10px;
  overflow-y: ${(props) => (props.fullView ? 'auto' : 'hidden')};
`;

const CardButton = styled.div`
  position: absolute;
  right: 30px;
  bottom: 20px;
`;

const ContainerFullArticle = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    height: auto;
  }
`;

const CloseArticleIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  top: -10px;
  right: 0px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    top: 0px;
    right: 20px;
  }
`;

const CardChildDevelopmentTitle = styled.div`
  ${Heading1}
  margin-bottom: 18px;
`;

const CardChildDevelopmentSubtitle = styled.div`
  ${Heading2}
  margin-bottom: 32px;
`;

const CardChildDevelopmentSection = styled.div`
  ${Heading4};
  margin: 16px 0;
`;

const CardChildDevelopmentSectionTitle = styled.div`
  margin: 22px 0;
`;

export default ChildDevelopmentParent;
