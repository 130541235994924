import React from 'react';
import { ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';

interface RadioButtonComponentProps {
  checked: boolean;
  value: string;
}

const RadioButtonComponent: React.FunctionComponent<RadioButtonComponentProps> = ({
  checked,
  value,
}) => {
  return (
    <RadioButtonContainer>
      <RadioButton type="radio" value={value} checked={checked}>
        {value}
      </RadioButton>
    </RadioButtonContainer>
  );
};

export default RadioButtonComponent;

const RadioButtonContainer = styled.div`
  width: 230px;
  margin-bottom: 12px;
`;

const RadioButton = styled(ToggleButton)`
  width: 100%;
  background: #aed1cd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  border-color: transparent;
  text-align: left;
  margin: 0;
  input {
    display: none;
  }
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  :focus,
  :hover {
    background: #82a3a7;
    border-color: transparent;
  }
`;
