import { FormControl } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  BodyLarge
} from '../../theme/themeComponents/fonts';
import Flex from '../Layouts/Flex/Flex';
import ParentAnxietyTest from '../ParentAnxietyTest/ParentAnxietyTest';
import { ButtonStyled } from './styles';

interface ParentAnxietyTestFormProps {
  handleSubmit: any;
  questions: [
    {
      id: string;
      order: number;
      survey_type: {
        answers: [any];
        value: string;
      };
      type: string;
      value: string;
    }
  ];
  handleAnswerRadioButtonChange: any;
}

const ParentAnxietyTestForm: React.FunctionComponent<ParentAnxietyTestFormProps> = ({
  handleSubmit,
  questions,
  handleAnswerRadioButtonChange,
}) => {
  return (
    <form className="needs-validation" onSubmit={handleSubmit}>
      <FormControl>
        {questions &&
          questions.length > 0 &&
          questions.map(
            (question: {
              id: string;
              order: number;
              survey_type: {
                answers: [any];
                value: string;
              };
              type: string;
              value: string;
            }) => {
              const {
                survey_type: { answers },
              } = question;

              return (
                <div key={question.id}>
                  <ParentAnxietyTest
                    key={question.id}
                    question={question}
                    answers={answers}
                    handleAnswerRadioButtonChange={
                      handleAnswerRadioButtonChange
                    }
                  />
                </div>
              );
            }
          )}
        <Flex alignItems="center" direction="column" justifyContent="center">
          <ButtonStyled color="primary" type="submit" variant="contained">
            Soumettre
          </ButtonStyled>
          {questions[0].survey_type.value === 'pain-test' && (
            <TipContainer>
              <DescriptionTypo>
                Afin d’aider votre enfant à mesurer son niveau de douleur,
                n’hésitez pas à comparer à des situations qu’il ou elle a connu
                dans le passé. Par exemple: par rapport à cette situation où tu
                t’es cogné le pied, est-ce que c’est une douleur plus importante
                ou moins importante ?
              </DescriptionTypo>
            </TipContainer>
          )}
        </Flex>
      </FormControl>
    </form>
  );
};

const TipContainer = styled.div`
  margin-top: 100px;
  border-radius: 72px;
  padding: 30px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dfedeb;
  width: 50%;
`;

const DescriptionTypo = styled.div`
  ${BodyLarge};
  text-align: center;
`;

export default ParentAnxietyTestForm;
