import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';
import { BodyLarge, Heading4 } from '../../theme/themeComponents/fonts';
import { ReactComponent as EyeActiveIcon } from '../../theme/icons/eyeActive.svg';
import { ReactComponent as EyeInactiveIcon } from '../../theme/icons/eyeInactive.svg';
import { BodyMain } from '../../theme/fonts';

//TODO refacto etienne's work props name no need input prefix
interface InputFieldProps {
  inputLabel?: string;
  inputType: string;
  inputPlaceholder?: string;
  placeholderColor?: string;
  backgroundColor?: string;
  icon?: any;
  iconColor?: string;
  inputDisabled?: boolean;
  form: any;
  field: any;
  showPasswordIcon: boolean;
  errorTopPosition?: string;
}

const InputField: React.FunctionComponent<InputFieldProps> = ({
  inputLabel,
  inputType,
  inputPlaceholder,
  placeholderColor,
  backgroundColor,
  icon,
  iconColor = theme.colors.blackMain,
  inputDisabled,
  form,
  field,
  showPasswordIcon,
  errorTopPosition,
}) => {
  const [inputTypeState, setInputTypeState] = useState(inputType);
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  const handleClickPasswordVisibility = (e: any) => {
    e.stopPropagation();
    if (inputTypeState === 'password') {
      setInputTypeState('text');
    } else {
      setInputTypeState('password');
    }
  };

  return (
    <InputContainer>
      {inputLabel && (
        <InputFieldHeader>
          <Label>{inputLabel}</Label>
        </InputFieldHeader>
      )}
      <InputFieldBody backgroundcolor={backgroundColor}>
        {icon && <InputFieldIcon iconcolor={iconColor} icon={icon} />}
        <InputFieldInput
          {...field}
          className={
            inputTypeState === 'date' && field.value
              ? 'date-input--has-value'
              : ''
          }
          type={inputTypeState}
          placeholder={inputPlaceholder}
          placeholderColor={placeholderColor}
          disabled={inputDisabled}
          rightIcon={inputType === 'password'}
        />
        {showPasswordIcon && inputTypeState === 'password' && (
          <InputRightIcon>
            <EyeInactiveIcon
              onClick={handleClickPasswordVisibility}
              width={15}
              height={15}
            />
          </InputRightIcon>
        )}
        {showPasswordIcon && inputTypeState === 'text' && (
          <InputRightIcon>
            <EyeActiveIcon
              onClick={handleClickPasswordVisibility}
              width={15}
              height={15}
            />
          </InputRightIcon>
        )}
        {touched && error && (
          <ErrorMessageContainer errorTopPosition={errorTopPosition}>
            <ErrorMessageText>{error}</ErrorMessageText>
          </ErrorMessageContainer>
        )}
      </InputFieldBody>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
`;

const InputFieldHeader = styled.div``;

const Label = styled.p`
  margin-bottom: 9px;
  ${Heading4}
`;

const InputFieldBody = styled.div<{ backgroundcolor?: string }>`
  position: relative;
  height: 48px;
  background: ${({ backgroundcolor, theme }) =>
    backgroundcolor ?? theme.colors.white};
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:focus-visible {
    background: red;
  }
`;

const InputFieldIcon = styled(FontAwesomeIcon)<{ iconcolor: string }>`
  color: ${(props) => props.iconcolor};
  font-size: 1rem;
  margin-right: 8px;
`;

const InputFieldInput = styled.input<{
  placeholderColor: string;
  rightIcon: string;
}>`
  width: calc(100% - 16px);
  height: 100%;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  padding-right: ${({ rightIcon }) => (rightIcon ? '30px' : '0')};
  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
    ${Heading4}
  }
  &:focus-visible {
    border: none !important;
  }
`;

const ErrorMessageContainer = styled.div<{
  errorTopPosition?: string;
}>`
  position: absolute;
  top: ${({ errorTopPosition }) => errorTopPosition ?? '48px'};
  left: 0;
`;

const ErrorMessageText = styled.div`
  ${BodyLarge}
  color: ${(props) => props.theme.colors.redL3};
  margin-top: 5px;
  ${BodyMain}
`;

const InputRightIcon = styled.div`
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
`;

export default InputField;
