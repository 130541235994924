import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { COOKIES } from '../../constants/cookies_type';
import ApplicationContext from '../../utils/context';
import { orderBy, capitalize } from 'lodash';
interface SurgeryTypeDB {
  id: string;
  date: string;
  surgery_type: {
    id: string;
    value: string;
    surgery_story: {
      id: string;
      value: string;
    };
  };
  organization: {
    id: string;
    name: string;
  };
}

interface ParentPatientDB {
  id: string;
  avatar: string | null;
  patient: {
    id: string;
    name: string;
    surname: string;
    birth_date: string;
    surgeries: Array<SurgeryTypeDB>;
    avatar: string | null;
  };
}

interface HydrateContextProps {}

const getParentAndChildrenQuery = loader(
  '../../graphql/getParentAndChildren.graphql'
);

//TODO CHECK COOKIE HERE ?
const HydrateContext: React.FC<HydrateContextProps> = ({ children }) => {
  const location = useLocation();
  const { data } = useQuery(getParentAndChildrenQuery, {
    variables: { parent_id: cookie.get(COOKIES.ID) },
    fetchPolicy: 'network-only',
  });

  const [loading, setLoading] = useState(true);

  const {
    currentParentAndChildrenCxt,
    currentChildIndexCxt,
    currentSurgeryIndexCxt,
  } = useContext<any>(ApplicationContext);

  useEffect(() => {
    const cidx = new URLSearchParams(location.search).get('cidx');
    const sidx = new URLSearchParams(location.search).get('sidx');
    if (cidx) {
      currentChildIndexCxt.setCurrentChildIndex(cidx);
    }
    if (sidx) {
      currentSurgeryIndexCxt.setCurrentSurgeryIndex(sidx);
    }
  }, []);

  /* TODO useEffect store in cxt cidx sidx  sid ? */
  /* TODO fix double render ... */
  useEffect(() => {
    if (data?.parent?.[0]) {
      let children = data.parent[0].parent_patients.map(
        (e: ParentPatientDB) => {
          const surgeriesTmp = e.patient.surgeries?.map((s: SurgeryTypeDB) => {
            return {
              id: s.id,
              date: s.date,
              type: s.surgery_type.value,
              organizationName: s.organization?.name,
              story: s.surgery_type.surgery_story?.value,
            };
          });
          return {
            id: e.patient.id,
            name: capitalize(e.patient.name),
            surname: e.patient.surname,
            birthDate: e.patient.birth_date,
            surgeries: [...surgeriesTmp],
            avatar: e.patient.avatar,
          };
        }
      );
      currentParentAndChildrenCxt.setCurrentParentAndChildrenToEdit({
        id: data.parent[0].id,
        name: data.parent[0].name,
        avatar: data.parent[0].avatar,
        hasActivatedEmotionalHealth:
          data.parent[0].has_activated_emotional_health,
        children: orderBy(children, ['name'], ['asc']),
      });
      setLoading(false);
    }
  }, [data]);

  return <>{!loading && children}</>;
};

export default HydrateContext;
