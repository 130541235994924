import HelloSign from 'hellosign-embedded';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from '../components/CustomButton/CustomButton';
import { ReactComponent as ErrorIcon } from '../theme/icons/error.svg';
import logo from '../theme/images/logo/logo.png';
import { ReactComponent as EsignIcon } from '../theme/icons/esign.svg';
import { Heading1, Heading4 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';
import { getSignUrlNoAuth } from '../utils/esign';
import { displayToastNotification } from '../utils/toastNotification';

interface EsignNoAuthProps {}

const EsignNoAuth: React.FC<EsignNoAuthProps> = ({}) => {
  const location = useLocation();
  const { helloSignClientCxt } = useContext<any>(ApplicationContext);
  const [currentSignUrl, setCurrentSignUrl] = useState({
    show: false,
    url: '',
    templateName: '',
  });
  const [status, setStatus] = useState({ error: false, success: false });

  useEffect(() => {
    const signatureRequestId = new URLSearchParams(location.search).get(
      'sigReqId'
    );
    const signatureId = new URLSearchParams(location.search).get('sigId');
    const email = new URLSearchParams(location.search).get('email');

    if (signatureRequestId && signatureId && email) {
      const getSignUrlAsync = async () => {
        const { signUrl, templateName, error } = await getSignUrlNoAuth(
          signatureRequestId,
          signatureId,
          email
        );
        if (error) {
          setStatus({ error: true, success: false });
        } else if (signUrl) {
          setCurrentSignUrl({
            show: true,
            url: signUrl,
            templateName: templateName!,
          });
        }
      };
      if (signatureRequestId && signatureId && email) {
        getSignUrlAsync();
      }
    } else {
      setStatus({ error: true, success: false });
    }
  }, []);

  const launchHelloSign = () => {
    setCurrentSignUrl({ ...currentSignUrl, show: false });
    helloSignClientCxt.helloSignClientId?.on('sign', () => {
      displayToastNotification('success', 'Le document a bien été signé');
      setStatus({ error: false, success: true });
      helloSignClientCxt.helloSignClientId?.off('sign');
    });
    helloSignClientCxt.helloSignClientId?.open(currentSignUrl.url, {
      testMode: parseInt(process.env.REACT_APP_HELLOSIGN_TEST_MODE!),
      locale: HelloSign.locales.FR_FR,
      container: document.getElementById(
        'container-signature-request-no-auth'
      )!,
    });
  };

  return (
    <Page>
      <Container>
        <LogoContainer>
          <img src={logo} style={{ width: '93px', height: '108px' }} />
        </LogoContainer>
        {status.error && (
          <CardContainer>
            <ErrorIcon />
            <CardTitle> Erreur</CardTitle>
            <CardSubtitle>
              Cliquez sur l'invitation qui vous a été envoyée sur votre boite
              mail pour accéder à la page
            </CardSubtitle>
          </CardContainer>
        )}
        {status.success && (
          <CardContainer>
            <CardTitle> Le document a bien été signé</CardTitle>
            <CardSubtitle>
              Merci d’avoir signé le document <br />. Vous en recevrez un
              exemplaire une fois que tous les signataires auront signé
            </CardSubtitle>
          </CardContainer>
        )}
        {currentSignUrl.show && (
          <CardContainer>
            <EsignIconContainer>
              <EsignIcon style={{ width: '100%', height: '100%' }} />
            </EsignIconContainer>
            <CardTitle>{currentSignUrl.templateName}</CardTitle>
            <CardSubtitle>
              Cliquez ci-dessous pour signer le document
            </CardSubtitle>
            <CustomButtonContainer>
              <CustomButton
                handleOnClick={launchHelloSign}
                content="Signer"
              ></CustomButton>
            </CustomButtonContainer>
          </CardContainer>
        )}
        <EsignContainer
          id="container-signature-request-no-auth"
          hidden={currentSignUrl.show || status.error || status.success}
        ></EsignContainer>
      </Container>
    </Page>
  );
};

export default EsignNoAuth;

const Page = styled.div`
  background: linear-gradient(135.02deg, #2e666d 0%, #89c9cd 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 130px;
  height: 200px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const CardContainer = styled.div`
  width: 679px;
  min-height: 300px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 24px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

const CardTitle = styled.div`
  ${Heading1}
  text-align: center;
`;

const CardSubtitle = styled.div`
  ${Heading4}
  text-align: center;
`;

const EsignContainer = styled.div<{ hidden: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

const EsignIconContainer = styled.div`
  width: 100px;
  height: 100px;
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.darkGreenMain};
  }
`;

const CustomButtonContainer = styled.div`
  margin-top: 20px;
`;
