import Phaser from '../lib/phaser.js';
import closeBtn from '../../assets/close.png';
import prevArrow from '../../../../../theme/images/stories/previous_arrow.png';
import nextArrow from '../../../../../theme/images/stories/next_arrow.png';

export default class HowToPlay extends Phaser.Scene {
  /** @type {Phaser.GameObjects.Text} */
  text;

  /** @type {Phaser.GameObjects.Image} */
  prevArrow;

  /** @type {Phaser.GameObjects.Image} */
  nextArrow;

  /** @type {Phaser.GameObjects.Image} */
  gameView;

  data = null;

  textIdx = 0;

  constructor() {
    super('how-to-play');
  }

  /**
   *
   * @param {[{text, img}]} data
   */
  init(data) {
    this.data = data.data;
  }

  preload() {
    const close = new Image();
    const prev = new Image();
    const next = new Image();

    //load close button image
    this.load.image('closeBtn', (close.src = closeBtn));

    //Load next and previous arrows
    this.load.image('prevArrow', (prev.src = prevArrow));
    this.load.image('nextArrow', (next.src = nextArrow));
  }

  create() {
    //Create how to play modal
    this.add
      .graphics()
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(
        150,
        150,
        this.scale.width - 300,
        this.scale.height - 300,
        32
      );

    //Create close button
    this.add
      .image(this.scale.width - 200, 200, 'closeBtn')
      .setScale(0.05)
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on('pointerdown', () => this.onEscDown());

    //Create previous and next arrows
    this.prevArrow = this.add
      .image(200, this.scale.height / 2, 'prevArrow')
      .setScale(0.2)
      .setOrigin(0.5, 0.5)
      .setVisible(false)
      .setInteractive()
      .on('pointerdown', () => this.onPrevArrow());

    this.nextArrow = this.add
      .image(this.scale.width - 200, this.scale.height / 2, 'nextArrow')
      .setScale(0.2)
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on('pointerdown', () => this.onNextArrow());

    //Create game view
    this.image = this.add
      .image(this.scale.width / 2, this.scale.height / 2 - 50)
      .setScale(0.4)
      .setOrigin(0.5, 0.5);

    //Create text
    const fontStyle = {
      fontFamily: 'Raleway',
      color: 'black',
      align: 'center',
      fontSize: 32,
      wordWrap: { width: this.scale.width - 500, useAdvanceWrap: true },
    };

    this.text = this.add
      .text(
        this.scale.width / 2,
        this.scale.height / 2,
        this.data[this.textIdx].text,
        fontStyle
      )
      .setOrigin(0.5, 0.5);

    //Create ESC event
    this.input.keyboard.on('keydown-' + 'ESC', () => this.onEscDown());
  }

  onPrevArrow() {
    if (this.textIdx > 0) {
      this.textIdx--;
      this.text.text = this.data[this.textIdx].text;
      this.image.setTexture(this.data[this.textIdx].img);
    }
  }

  onNextArrow() {
    if (this.textIdx < this.data.length - 1) {
      this.textIdx++;
      this.text.text = this.data[this.textIdx].text;
      this.image.setTexture(this.data[this.textIdx].img);
    }
  }

  onEscDown() {
    this.game.scene.stop('how-to-play');
    this.scene.launch('ask-for-feelings', {
      data: [
        "Salut c'est Koalou ! Avant de commencer l'exercice, je vais te poser quelques questions.",
        "D'abord, essaye de repenser à toutes les émotions que tu as ressentis aujourd'hui.",
        "Très bien ! Parmis ces émotions, lesquelles tu n'aimes pas ?",
        'Comment tu te sens maintenant ?',
        'Merci ! On va pouvoir commencer, paré au décollage !',
      ],
    });
  }

  update() {
    if (this.textIdx === 0) {
      this.prevArrow.setVisible(false);
    } else if (this.textIdx === this.data.length - 1) {
      this.nextArrow.setVisible(false);
    } else {
      this.prevArrow.setVisible(true);
      this.nextArrow.setVisible(true);
    }
    if (this.data[this.textIdx].img) {
      this.text
        .setY(this.scale.height - 270)
        .setOrigin(0.5, 0)
        .setWordWrapWidth(this.scale.width - 350);
    } else {
      this.text
        .setY(this.scale.height / 2)
        .setOrigin(0.5, 0.5)
        .setWordWrapWidth(this.scale.width - 500);
    }
  }
}
