import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import i18n from '../../../i18n';
import { surveyQuestionAndAnswerByLanguage } from '../../../utils/survey';

const CheckboxList = ({ element, labelId, type }) => {
  const [state] = React.useState(
    JSON.parse(localStorage.getItem(`${type}-checkboxes`)) || {}
  );

  const handleChange = (name) => (event) => {
    const dataInLocalStorage = localStorage.getItem(`${type}-checkboxes`);
    const dataToJson = JSON.parse(dataInLocalStorage) || {};
    const newData = { ...dataToJson, [name]: event.target.checked };
    localStorage.setItem(`${type}-checkboxes`, JSON.stringify(newData));
  };

  const value = `checkbox-${labelId}`;

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange(`${value}`)}
          value={value}
          defaultChecked={state[value]}
        />
      }
      label={surveyQuestionAndAnswerByLanguage(
        element.instruction_i18ns,
        i18n.language
      )}
    />
  );
};

CheckboxList.propTypes = {
  element: PropTypes.object.isRequired,
  labelId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CheckboxList;
