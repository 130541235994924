import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomCard from '../components/CustomCard/CustomCard';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';
import { ReactComponent as AdvicesImage } from '../theme/images/healthParent/advices.svg';
import { ReactComponent as ChecklistImage } from '../theme/images/healthParent/checklists.svg';
import { ReactComponent as CallImage } from '../theme/images/healthParent/callimage.svg';
import { ReactComponent as PrescriptionImage } from '../theme/images/healthParent/prescription.svg';
import { ReactComponent as DocumentsImage } from '../theme/images/healthParent/documents.svg';
import { ReactComponent as EsignDocumentsImage } from '../theme/images/healthParent/esignDocuments.svg';
import { ReactComponent as EvaluationsImage } from '../theme/images/healthParent/evaluations.svg';
import { ReactComponent as GuidelinesImage } from '../theme/images/healthParent/guidelines.svg';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';

interface HealthParentProps {}

const getSurgeryByIdQuery = loader('../graphql/getSurgeryById.graphql');
const getSurveysTypesBySurgeryAndAgeQuery = loader(
  '../graphql/getSurveysTypesBySurgeryAndAge.graphql'
);

const HealthParent: React.FC<HealthParentProps> = ({}) => {
  const history = useHistory();
  const location = useLocation<any>();
  const { t } = useTranslation();

  const { currentSurveysCxt, onboarding } = useContext<any>(ApplicationContext);

  const [loading, setLoading] = useState(true);
  const [showEvaluationsView, setShowEvaluationsView] = useState(false);
  const [surgeryInfos, setSurgeryInfos] = useState<{
    id: string;
    date: Date;
    name: string;
    typeId: string;
    children: {
      name: string;
    };
  } | null>(null);

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);

  const [getSurveysTypesBySurgeryAndAge, { data: dataSurveys }] = useLazyQuery(
    getSurveysTypesBySurgeryAndAgeQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (location.hash === '#showEvaluationsView') {
      setShowEvaluationsView(true);
    } else {
      setShowEvaluationsView(false);
    }
    const surgeryId = new URLSearchParams(location.search).get('sid');
    const showOnboarding = new URLSearchParams(location.search).get(
      'showOnboarding'
    );

    if (surgeryId) {
      getSurgeryById({ variables: { surgeryId } });
      if (showOnboarding === 'indirect-signup') {
        onboarding.setOnboardingState({
          show: true,
          type: 'indirect-signup',
        });
      }
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    const surgeryResponse = dataSurgery?.surgery?.[0];
    if (surgeryResponse) {
      setSurgeryInfos({
        id: surgeryResponse.id,
        date: new Date(surgeryResponse.date),
        name: surgeryResponse.surgery_type.value,
        typeId: surgeryResponse.surgery_type.id,
        children: { name: surgeryResponse.patient.name },
      });
    }
  }, [dataSurgery]);

  useEffect(() => {
    if (surgeryInfos) {
      const birthDate = dataSurgery?.surgery?.[0].patient?.birth_date;
      if (birthDate) {
        const age = moment().diff(birthDate, 'years');
        getSurveysTypesBySurgeryAndAge({
          variables: {
            surgeryTypeId: surgeryInfos.typeId,
            age,
          },
        });
      }
    }
  }, [surgeryInfos]);

  useEffect(() => {
    if (dataSurveys) {
      currentSurveysCxt.setCurrentSurveysToEdit(
        dataSurveys.survey_type_surgery_type_by_surgery_and_age
      );

      setLoading(false);
    }
  }, [dataSurveys]);

  function isNotInSevenDaysIntervalBeforeSurgery(surgeryDate: any) {
    if (surgeryDate) {
      const isSame = moment(new Date()).isSame(surgeryDate, 'day');
      if (isSame) {
        return false;
      }
      const isBefore = moment(new Date()).isBefore(surgeryDate, 'day');
      //@ts-ignore
      const diffInMillis = surgeryDate - new Date();
      const diffInDays = Math.abs(diffInMillis / (1000 * 60 * 60 * 24));
      if (!isBefore || diffInDays >= 7) {
        return true;
      }
      return false;
    }
  }

  function isNotAfterIntervention(surgeryDate: any) {
    if (surgeryDate) {
      const isAfter = moment(new Date()).isAfter(surgeryDate, 'day');
      if (isAfter) {
        return false;
      }
      return true;
    }
    return true;
  }

  function checkIfSurveyIsDisabled(surveys: any, surveyType: string) {
    return (
      surveys?.filter((e: any) => e.survey_type.value === surveyType).length ===
      0
    );
  }

  const renderSurveysList = (
    surveys: any,
    surgeryId?: string,
    surgeryDate?: Date
  ) => {
    if (!surgeryId || !surgeryDate) {
      return [];
    }
    return [
      {
        text: t('titles_and_subtitles:parentAnxietyEvaluationTitle'),
        link: `/parent/surgeries/health/parent-anxiety-evaluation?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(
          surveys,
          SURVEY_TYPE_VALUE.PARENT_ANXIETY_EVALUATION
        ),
      },
      {
        text: t('titles_and_subtitles:symptomatologyTitle'),
        link: `/parent/surgeries/health/symptomatology?sid=${surgeryId}`,
        disabled:
          checkIfSurveyIsDisabled(surveys, SURVEY_TYPE_VALUE.SYMPTOMATOLOGY) ||
          isNotInSevenDaysIntervalBeforeSurgery(surgeryDate),
      },
      {
        text: t('titles_and_subtitles:painEvaluationTitle'),
        link: `/parent/surgeries/health/pain-evaluation?sid=${surgeryId}`,
        disabled:
          checkIfSurveyIsDisabled(surveys, SURVEY_TYPE_VALUE.PAIN_EVALUATION) ||
          isNotAfterIntervention(surgeryDate),
      },
      {
        text: t('titles_and_subtitles:phbqEvaluationTitle'),
        link: `/parent/surgeries/health/phbq-evaluation?sid=${surgeryId}`,
        disabled:
          checkIfSurveyIsDisabled(surveys, SURVEY_TYPE_VALUE.PHBQ_EVALUATION) ||
          isNotAfterIntervention(surgeryDate),
      },
      {
        text: t('titles_and_subtitles:modifiedDentalAnxietyTitle'),
        link: `/parent/surgeries/health/modified-dental-anxiety?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(
          surveys,
          SURVEY_TYPE_VALUE.MODIFIED_DENTAL_ANXIETY
        ),
      },
      {
        text: t('titles_and_subtitles:staiNowTitle'),
        link: `/parent/surgeries/health/stai-now?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(surveys, SURVEY_TYPE_VALUE.STAI_NOW),
      },
      {
        text: t('titles_and_subtitles:staiGlobalTitle'),
        link: `/parent/surgeries/health/stai-global?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(
          surveys,
          SURVEY_TYPE_VALUE.STAI_GLOBAL
        ),
      },
      {
        text: t('titles_and_subtitles:dentalFirstSurveyTitle'),
        link: `/parent/surgeries/health/dental-first-survey?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(
          surveys,
          SURVEY_TYPE_VALUE.DENTAL_FIRST_SURVEY
        ),
      },
      {
        text: t('titles_and_subtitles:covidCheckerSurveyTitle'),
        link: `/parent/surgeries/health/covid-checker?sid=${surgeryId}`,
        disabled: checkIfSurveyIsDisabled(
          surveys,
          SURVEY_TYPE_VALUE.COVID_CHECKER
        ),
      },
    ];
  };

  const handleShowEvaluationsView = (value: boolean) => {
    if (value) {
      history.push(
        `/parent/surgeries/health?sid=${surgeryInfos?.id}#showEvaluationsView`
      );
    } else {
      history.push(`/parent/surgeries/health?sid=${surgeryInfos?.id}`);
    }
    // setShowEvaluationsView(value);
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        showEvaluationsView
          ? handleShowEvaluationsView(false)
          : history.push('/parent/surgeries')
      }
    >
      {loading && <Loader />}
      {!loading && (
        <>
          <Title>
            {showEvaluationsView ? 'Mes évaluations' : 'Mes Rdv médicaux'}
          </Title>
          <Subtitle>
            {surgeryInfos?.children.name} -{' '}
            {moment(surgeryInfos?.date).format('LL')}
          </Subtitle>
          <Container>
            {!showEvaluationsView ? (
              <SubContainer>
                <CardContainer data-tut="reactour__surgeries-health__evaluations">
                  <CustomCard
                    handleOnClick={() => handleShowEvaluationsView(true)}
                    title={'Évaluations'}
                    imageComponent={<EvaluationsImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__guidelines">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/guidelines?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Consignes médicales'}
                    imageComponent={<GuidelinesImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__documents">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/documents?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Documents utiles'}
                    imageComponent={<DocumentsImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__esign-documents">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/esign-documents?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Documents à signer'}
                    imageComponent={<EsignDocumentsImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__operation-timeline">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/operation-timeline?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Conseils'}
                    imageComponent={<AdvicesImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__what-put-in-case">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/what-put-in-case?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Checklist'}
                    imageComponent={<ChecklistImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__call_day_before">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/calldaybefore?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={"L'appel de la veille"}
                    imageComponent={<CallImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
                <CardContainer data-tut="reactour__surgeries-health__prescription">
                  <CustomCard
                    handleOnClick={() =>
                      history.push(
                        `/parent/surgeries/health/prescriptions?sid=${surgeryInfos?.id}`
                      )
                    }
                    title={'Prescriptions'}
                    imageComponent={<PrescriptionImage />}
                    displayMode={'space-between'}
                  />
                </CardContainer>
              </SubContainer>
            ) : (
              dataSurveys && (
                <SubContainer>
                  {renderSurveysList(
                    dataSurveys.survey_type_surgery_type_by_surgery_and_age,
                    surgeryInfos?.id,
                    surgeryInfos?.date
                  ).map((e, i) => {
                    if (e.disabled) {
                      return null;
                    } else {
                      return (
                        <CardContainer key={i}>
                          <CustomCard
                            handleOnClick={() => history.push(e.link)}
                            title={e.text}
                          />
                        </CardContainer>
                      );
                    }
                  })}
                </SubContainer>
              )
            )}
          </Container>
        </>
      )}
    </PageWrapper>
  );
};

export default HealthParent;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2};
`;

const Container = styled.div`
  margin-top: 50px;
`;

const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CardContainer = styled.div`
  width: 270px;
  height: 300px;
  margin: 10px;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;
