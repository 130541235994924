import React from 'react';
import { theme } from '../../theme';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
interface Options {
  value: string;
  label: string;
}

type IsMulti = false;

const dropdownStyle: StylesConfig<Options, IsMulti> = {
  control: (styles, { selectProps }) => ({
    ...styles,
    border: 'none',
    focus: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'unset',
    borderRadius: selectProps.customBorderRadius || '0px',
    height: '100%',

    // "&:hover": {
    //   backgroundColor: theme.colors.darkGreenL1,
    // },
    // width: selectProps.customWidth,
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'auto',
    paddingRight: 0,
    height: '100%',
    whiteSpace: 'normal',
  }),
  singleValue: (styles, { selectProps }) => ({
    ...styles,
    color: selectProps.customSingleValueColor ?? theme.colors.blackMain,
    whiteSpace: 'normal',
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? theme.colors.darkGreenL1 : '',
    color: theme.colors.blackMain,
    '&:active': {
      backgroundColor: theme.colors.darkGreenL1,
    },
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (_, { selectProps }) => ({
    color:
      selectProps.customDropdownIndicatorColor ?? theme.colors.darkGreenMain,
  }),
};

interface DropdownProps {
  options: Array<Options>;
  defaultValue?: Options;
  handleOnChange: (data: Options) => void;
  borderRadius?: string;
  dropdownIndicatorColor?: string;
  singleValueColor?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultValue,
  handleOnChange,
  borderRadius,
  dropdownIndicatorColor,
  singleValueColor,
}) => {
  return (
    <DropdownContainer
      defaultValue={defaultValue ? defaultValue : null}
      onChange={handleOnChange}
      options={options ? options : []}
      styles={dropdownStyle}
      isSearchable={false}
      customBorderRadius={borderRadius}
      customDropdownIndicatorColor={dropdownIndicatorColor}
      customSingleValueColor={singleValueColor}
    />
  );
};

export default Dropdown;

const DropdownContainer = styled(Select)`
  height: 100%;
`;
