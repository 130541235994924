import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ApplicationContext from '../../../utils/context';
import Burger from '../../Burger/Burger';
import Sidebar from '../../Sidebar/Sidebar';

const LayoutWithSidebar: React.FunctionComponent<{}> = ({ children }) => {
  const location = useLocation();

  const [currentSurgeryIndex, setCurrentSurgeryIndex] = useState('');
  const [currentChildIndex, setCurrentChildIndex] = useState('');
  const [currentChildHasSurgery, setCurrentChildHasSurgery] = useState(false);
  const [sidebar, setSidebar] = useState({ isOpen: false });
  const {
    currentChildIndexCxt,
    currentSurgeryIndexCxt,
    currentParentAndChildrenCxt,
  } = useContext<any>(ApplicationContext);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setSidebar({ isOpen: true });
    }
    window.addEventListener('resize', handleResize);

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const currentSurgeryIndexTmp = currentSurgeryIndexCxt?.currentSurgeryIndex;
    if (currentSurgeryIndexTmp || currentSurgeryIndexTmp === 0) {
      setCurrentSurgeryIndex(currentSurgeryIndexTmp);
    }
  }, [currentSurgeryIndexCxt]);

  useEffect(() => {
    const currentChildIndexTmp = currentChildIndexCxt?.currentChildIndex;
    if (currentChildIndexTmp || currentChildIndexTmp === 0) {
      setCurrentChildIndex(currentChildIndexTmp);
      if (
        currentParentAndChildrenCxt?.currentParentAndChildrenToEdit?.children?.[
          currentChildIndexTmp
        ]?.surgeries.length > 0
      ) {
        setCurrentChildHasSurgery(true);
      }
    }
  }, [currentChildIndexCxt]);

  const handleOutsideClick = (e: any) => {
    const elementSidebar = document?.getElementById('sidebar');
    const elementBurgerIcon = document?.getElementById('burger-icon');
    if (elementSidebar && elementBurgerIcon) {
      if (
        window.innerWidth <= 767 &&
        !elementSidebar.contains(e.target) &&
        !elementBurgerIcon.contains(e.target)
      ) {
        // Clicked outside of the sidebar
        setSidebar({ isOpen: false });
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setSidebar({ isOpen: false });
    } else {
      setSidebar({ isOpen: true });
    }
  };

  const handleDisplaySidebar = () => {
    setSidebar({ isOpen: !sidebar.isOpen });
  };

  return (
    <Root>
      <BurgerContainer
        onClick={() => handleDisplaySidebar()}
        sidebarIsOpen={sidebar.isOpen}
        id="burger-icon"
      >
        <Burger />
      </BurgerContainer>
      {/* Comment sidebar.isOpen because conflicts with onboarding. Instead handle display css */}
      {/* {sidebar.isOpen && ( */}
      <SideBarContainer
        mobileView={window.innerWidth <= 767}
        sidebarIsOpen={sidebar.isOpen}
        id="sidebar"
      >
        <Sidebar
          currentPath={location.pathname}
          surgeryIdx={currentSurgeryIndex}
          childIdx={currentChildIndex}
          childHasSurgery={currentChildHasSurgery}
        />
      </SideBarContainer>
      {/* )} */}
      <MainContainer>{children}</MainContainer>
    </Root>
  );
};

const Root = styled.div`
  min-height: 100vh;
  display: flex;
`;

const SideBarContainer = styled.div<{
  mobileView: boolean;
  sidebarIsOpen: boolean;
}>`
  display: ${(props) => (props.sidebarIsOpen ? 'block' : 'none')};
  z-index: 1000;
  width: 193px;
  transition: left 1s linear;
  height: 100vh;
  background: linear-gradient(#095c66 0%, #209aa1 100%);
  position: ${(props) => (props.mobileView ? 'absolute' : 'relative')};
  transform: ${(props) =>
    props.sidebarIsOpen ? 'translateX(0)' : 'translateX(100)'};

  /* @media screen and (max-height: 760px) { */
  /* overflow-y: auto; */
  /* } */
`;

const MainContainer = styled.div`
  width: calc(100% - 193px);
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }
  overflow-y: auto;
`;

const BurgerContainer = styled.div<{ sidebarIsOpen: boolean }>`
  position: absolute;
  z-index: 10001;
  top: 15px;
  left: ${(props) => (props.sidebarIsOpen ? '-20px' : '-15px')};
  display: none;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: block;
  }
`;

export default LayoutWithSidebar;
