import React from 'react';
import FormHead from '../components/FormHead/FormHead';
import NewPasswordForm from '../components/Forms/NewPasswordForm';
import {
  AuthContainer,
  CardContentContainer,
  NavContainer,
  PageContainer,
} from '../theme/themeComponents/containerComponents';
import { ResetPasswordCard } from '../theme/themeComponents/cardComponents';
import loginBackgroundImg from '../theme/images/loginBackground.png';
import { useTranslation } from 'react-i18next';


//TODO REFACTO ALL USELESS COMPONENTS MADE BY ETIENNE !!!!!!!!!!!!!!!!!!!!!!

const ResetPassword: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();

  return (
    <PageContainer image={loginBackgroundImg}>
      <NavContainer>
        <ResetPasswordCard>
          <CardContentContainer>
            <AuthContainer>
              <FormHead
                title={t('auth:resetPasswordTitle')}
                subtitle={t('auth:resetPasswordSubtitle')}
              />
              <NewPasswordForm />
            </AuthContainer>
          </CardContentContainer>
        </ResetPasswordCard>
      </NavContainer>
    </PageContainer>
  );
};

export default ResetPassword;
