import Phaser from '../lib/phaser.js';

export default class Pause extends Phaser.Scene {
  data = null;

  constructor() {
    super('pause');
  }

  init(data) {
    this.data = data.data;
  }

  create() {
    this.add
      .graphics()
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(
        this.scale.width / 2 - 100,
        this.scale.height / 2 - 150,
        200,
        300,
        32
      );

    const fontStyle = {
      fontFamily: 'Raleway',
      color: 'black',
      fontSize: 32,
      wordWrap: { width: this.scale.width - 400, useAdvanceWrap: true },
    };

    this.add
      .text(this.scale.width / 2, this.scale.height / 2, 'PAUSE', fontStyle)
      .setFontSize(36)
      .setOrigin(0.5, 3.4);

    this.add
      .text(this.scale.width / 2, this.scale.height / 2, 'Continuer', fontStyle)
      .setOrigin(0.5, 1.5)
      .setInteractive()
      .on('pointerdown', () => this.onEscDown());

    this.add
      .text(this.scale.width / 2, this.scale.height / 2, 'Volume', fontStyle)
      .setOrigin(0.5, -1);

    this.add
      .graphics()
      .fillStyle(0xaaaaaa, 1)
      .fillRoundedRect(
        this.scale.width / 2 - 50,
        this.scale.height / 2 + 100,
        100,
        5,
        2.5
      );

    this.scrollButton = this.add
      .graphics()
      .fillStyle(0x555555, 1)
      .fillCircle(
        this.scale.width / 2 - 50 + 100 * this.data.volume,
        this.scale.height / 2 + 102.5,
        6
      );

    const zone = this.add
      .zone(this.scale.width / 2, this.scale.height / 2, 101, 10)
      .setPosition(this.scale.width / 2, this.scale.height / 2 + 100)
      .setInteractive();

    zone.on('pointermove', (pointer) => this.setVolume(pointer));

    this.input.keyboard.on('keydown-' + 'ESC', () => this.onEscDown());
  }

  setVolume(pointer) {
    if (
      pointer.isDown //  &&
      //pointer.x >= this.scale.width / 2 - 50 &&
      //pointer.x <= this.scale.width / 2 + 50
    ) {
      this.scrollButton.x = pointer.x - this.scale.width / 2;
      this.data.volume = Number(((this.scrollButton.x + 50) / 100).toFixed(2));
      this.data.setVolume();
      console.log(this.data.volume);
    }
  }

  onEscDown() {
    this.game.scene.stop('pause');
    this.game.scene.resume('game');
  }
}
