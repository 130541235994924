import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { CardLayout } from '../components/CardLayout/CardLayout';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import TodoList from '../components/TodoList/TodoList';
import { Heading1, Heading2, Heading3 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';
import { getIndexSelectedLanguage } from '../utils/translation';
import Tour from 'reactour';

const getStatementOfTheDayQuery = loader(
  '../graphql/getStatementOfTheDay.graphql'
);

const getFactOfTheDayQuery = loader('../graphql/getFactOfTheDay.graphql');

interface HomeParentProps {}

const HomeParent: React.FC<HomeParentProps> = ({}) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [grantAccess, setGrantAccess] = useState(false);
  const [statementOfTheDay, setStatementOfTheDay] = useState({ value: '' });
  const [factOfTheDay, setFactOfTheDay] = useState({ title: '', value: '' });

  const { data: dataStatementOfTheDay } = useQuery(getStatementOfTheDayQuery, {
    variables: { language: i18n.language },
  });

  const { data: dataFactOfTheDay } = useQuery(getFactOfTheDayQuery, {
    variables: { language: i18n.language },
  });

  useEffect(() => {
    const cxt = currentParentAndChildrenCxt.currentParentAndChildrenToEdit;
    if (cxt) {
      if (!cxt.hasActivatedEmotionalHealth) {
        history.push('/parent/emotional-health/lock');
      } else {
        setGrantAccess(true);
      }
    }
  }, []);

  useEffect(() => {
    if (dataStatementOfTheDay) {
      const data = dataStatementOfTheDay?.statement_of_the_day;
      let rand = 0;
      let indexLanguage = 0;
      if (data) {
        rand = Math.floor(Math.random() * data.length);
        indexLanguage = getIndexSelectedLanguage(
          data?.[rand]?.statement_of_the_day_i18ns?.map(
            (f: any) => f.language.language_code
          ),
          i18n.language
        );
        setStatementOfTheDay({
          value:
            data?.[rand]?.statement_of_the_day_i18ns?.[indexLanguage]?.value,
        });
      }
    }
  }, [dataStatementOfTheDay]);

  useEffect(() => {
    if (dataFactOfTheDay) {
      const data = dataFactOfTheDay?.fact_of_the_day;
      let rand = 0;
      let indexLanguage = 0;
      if (data) {
        rand = Math.floor(Math.random() * data.length);
        indexLanguage = getIndexSelectedLanguage(
          data?.[rand]?.fact_of_the_day_i18ns?.map(
            (f: any) => f.language.language_code
          ),
          i18n.language
        );
        setFactOfTheDay({
          title: data?.[rand]?.fact_of_the_day_i18ns?.[indexLanguage]?.title,
          value: data?.[rand]?.fact_of_the_day_i18ns?.[indexLanguage]?.value,
        });
      }
    }
  }, [dataFactOfTheDay]);

  /* If the parent did not activate the emotional health space */
  if (!grantAccess) {
    return <></>;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() => history.push('/welcome')}
    >
      <Title>
        Bienvenue{' '}
        {currentParentAndChildrenCxt?.currentParentAndChildrenToEdit.name} sur
        votre espace parent
      </Title>
      <StatementOfTheDayContainer>
        <StatementOfTheDay>
          <span>Phrase du jour</span>
          <span>{statementOfTheDay.value}</span>
          <StatementOfTheDayBackground></StatementOfTheDayBackground>
        </StatementOfTheDay>
      </StatementOfTheDayContainer>
      <Container>
        <TodoListContainer data-tut="reactour__home-parent__todo">
          <TodoList />
        </TodoListContainer>
        <CardLayoutContainer data-tut="reactour__home-parent__fact-of-the-day">
          <CardLayout width={'366px'} height={'472px'} autoHeightMobile={true}>
            <CardTitle>{t('homeParent:didYouKnow')}</CardTitle>
            <CardContentContainer>
              <CardSubtitle>{factOfTheDay.title}</CardSubtitle>
              <CardContent>{factOfTheDay.value}</CardContent>
            </CardContentContainer>
          </CardLayout>
        </CardLayoutContainer>
      </Container>
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.lightGreenL3};
  padding: 20px 0;
  ${Heading1}
`;

const StatementOfTheDayContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    margin-top: 0;
  }
`;

const StatementOfTheDay = styled.div`
  width: 70%;
  position: relative;
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 30px;

  span:nth-child(1) {
    ${Heading2}
    margin-bottom: 10px;
  }
  span:nth-child(2) {
    ${Heading3}
    margin-bottom: 10px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const StatementOfTheDayBackground = styled.div`
  background: rgba(189, 210, 213, 0.69);
  opacity: 0.5;
  border-radius: 36px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    margin-top: 0px;
  }
`;

const CardLayoutContainer = styled.div`
  min-width: 366px;
  margin: 30px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    margin: 15px 0 0 0;
    min-width: unset;
  }
`;

const CardTitle = styled.div`
  ${Heading1}
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.blackMain};
  height: 15%;
`;

const CardContentContainer = styled.div`
  height: 85%;
  overflow-y: auto;
  margin-top: 5px;
`;

const CardSubtitle = styled.div`
  ${Heading2}
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  margin-top: 12px;
  margin-bottom: 19px;
  padding-right: 10px;
`;

const CardContent = styled.div`
  ${Heading3}
  color: ${(props) => props.theme.colors.blackMain};
  text-align: left;
  padding-right: 10px;
`;

const TodoListContainer = styled.div`
  width: 366px;
  height: 472px;
  margin: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 15px 0 0 0;
  }
`;

export default HomeParent;
