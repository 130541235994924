import { getFirebaseToken } from './auth';

export const createChild = async (data: {
  name: string;
  birthDate: string;
}): Promise<{
  error: string | null;
  child: {
    id: string;
    name: string;
    birthDate: string;
    avatar: string | null;
    surgeries: [];
  } | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/create-child`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, child: null };
    } else {
      return { error: null, child: resJson.child };
    }
  } catch (err) {
    console.log(err);
    return { error: 'Error', child: null };
  }
};
