import React, { useContext, useEffect, useRef, useState } from 'react';
import { getSignUrl } from '../utils/esign';
import HelloSign from 'hellosign-embedded';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/client';
import PdfViewer from '../components/PdfViewer/PdfViewer';
import { ESIGN_FILE_TYPE } from '../constants/gcp_types';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import ApplicationContext from '../utils/context';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import { Loader } from '../components/Loader/Loader';
import CustomCard from '../components/CustomCard/CustomCard';
import { useTranslation } from 'react-i18next';
import { displayToastNotification } from '../utils/toastNotification';

const getEsignDocumentsQuery = loader('../graphql/getEsignDocuments.graphql');

interface EsignDocuments {
  id: string;
  surgeryId: string;
  hellosignSignatureRequestId: string;
  filePath: string | null;
  name: string;
}

interface EsignDocumentsProps {}

const EsignDocuments: React.FC<EsignDocumentsProps> = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { helloSignClientCxt } = useContext<any>(ApplicationContext);
  const [loading, setLoading] = useState(false);

  const [signUrl, setSignUrl] = useState('');

  const [esignDocuments, setEsignDocuments] =
    useState<Array<EsignDocuments> | null>(null);

  const esignDocumentsRef = useRef<Array<EsignDocuments> | null>();

  const [sid, setSid] = useState('');

  const [documentName, setDocumentName] = useState('');

  const [getEsignDocuments, { data: dataEsignDocuments }] = useLazyQuery(
    getEsignDocumentsQuery
  );

  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   client.on('sign', (data) => {
  //     console.log('je signe');
  //     console.log(data);
  //     // const newEsignDocuments = esignDocumentsRef.current?.filter(
  //     //   (e) => e.hellosignSignatureRequestId !== data.signatureId
  //     // );
  //     // if (newEsignDocuments) {
  //     //   esignDocumentsRef.current = newEsignDocuments;
  //     //   setEsignDocuments(newEsignDocuments);
  //     // } else {
  //     //   esignDocumentsRef.current = null;
  //     //   setEsignDocuments(null);
  //     // }
  //   });
  //   return () => client.off('sign');
  // }, []);

  // const openFile = (name: string) => {
  //   setPdfState({ showPdfView: true, documentName: name });
  // };

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (surgeryId) {
      setSid(surgeryId);
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (sid) {
      getEsignDocuments({ variables: { surgeryId: sid } });
    }
  }, [sid]);

  useEffect(() => {
    if (dataEsignDocuments) {
      const dataTmp = dataEsignDocuments.esign_document.map((e: any) => {
        return {
          id: e.id,
          surgeryId: e.surgery_id,
          hellosignSignatureRequestId: e.hellosign_signature_request_id,
          filePath: e.file_path,
          name: e.esign_template.name,
        };
      });
      esignDocumentsRef.current = [...dataTmp];
      setEsignDocuments([...dataTmp]);
    }
  }, [dataEsignDocuments]);

  useEffect(() => {
    if (signUrl) {
      launchHelloSign();
    }
  }, [signUrl]);

  const handleClickOpenDocument = async (signatureRequestId: string) => {
    const { signUrl } = await getSignUrl(signatureRequestId);
    setLoading(false);
    if (signUrl) {
      setSignUrl(signUrl);
    }
  };

  const launchHelloSign = () => {
    helloSignClientCxt.helloSignClientId?.on('sign', () => {
      displayToastNotification('success', 'Le document a bien été signé');
      helloSignClientCxt.helloSignClientId?.off('sign');
    });
    helloSignClientCxt.helloSignClientId?.open(signUrl, {
      testMode: parseInt(process.env.REACT_APP_HELLOSIGN_TEST_MODE!),
      locale: HelloSign.locales.FR_FR,
      container: document.getElementById('container-signature-request')!,
    });
  };

  const toggle = () => setModal(!modal);

  const handleModal = (name: string) => {
    setDocumentName(name);
    toggle();
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && !modal && (
        <Container>
          <Title>{t('titles_and_subtitles:esignDocumentsTitle')}</Title>
          <Subtitle>{t('titles_and_subtitles:esignDocumentsText')}</Subtitle>
          <DocumentCardContainer>
            {esignDocuments?.map((document: any, i: number) => {
              if (document.filePath) {
                return (
                  <CustomCardContainer key={i}>
                    <CustomCard
                      title={document.name}
                      subtitle={'Ce document est déja signé'}
                      handleOnClick={() => handleModal(document.filePath)}
                    />
                  </CustomCardContainer>
                );
              } else {
                return (
                  <CustomCardContainer key={i}>
                    <CustomCard
                      title={document.name}
                      handleOnClick={() =>
                        handleClickOpenDocument(
                          document.hellosignSignatureRequestId
                        )
                      }
                    />
                  </CustomCardContainer>
                );
              }
            })}
          </DocumentCardContainer>
        </Container>
      )}
      {!loading && modal && (
        <PdfViewer
          toggle={toggle}
          documentName={documentName}
          fileCategory={ESIGN_FILE_TYPE.DOCUMENT}
        />
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const Container = styled.div`
  width: 100%;
`;

const DocumentCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
`;

const CustomCardContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  position: relative;
`;

export default EsignDocuments;
