import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import auth_fr from './locales/fr/auth.json';
import auth_en from './locales/en/auth.json';
import common_fr from './locales/fr/common.json';
import common_en from './locales/en/common.json';
import homeChildren_fr from './locales/fr/homeChildren.json';
import homeChildren_en from './locales/en/homeChildren.json';
import homeParent_fr from './locales/fr/homeParent.json';
import homeParent_en from './locales/en/homeParent.json';
import welcome_fr from './locales/fr/welcome.json';
import welcome_en from './locales/en/welcome.json';
import pages_titles_and_subtitles_fr from './locales/fr/pages-titles-and-subtitles.json';
import pages_titles_and_subtitles_en from './locales/en/pages-titles-and-subtitles.json';
import settings_fr from './locales/fr/settings.json';
import settings_en from './locales/en/settings.json';
import validation_fr from './locales/fr/validation.json';
import validation_en from './locales/en/validation.json';
import game_fr from './locales/fr/game.json';
import game_en from './locales/en/game.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { setMomentFr } from './lib/momentLocale';
import { LANGUAGES } from './constants/languages_types';

setMomentFr();

const resources = {
  fr: {
    auth: auth_fr,
    common: common_fr,
    welcome: welcome_fr,
    homeChildren: homeChildren_fr,
    homeParent: homeParent_fr,
    titles_and_subtitles: pages_titles_and_subtitles_fr,
    settings: settings_fr,
    validation: validation_fr,
    game: game_fr,
  },
  en: {
    auth: auth_en,
    welcome: welcome_en,
    common: common_en,
    homeChildren: homeChildren_en,
    homeParent: homeParent_en,
    titles_and_subtitles: pages_titles_and_subtitles_en,
    settings: settings_en,
    validation: validation_en,
    game: game_en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: LANGUAGES.EN_UK,
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
  });

i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});

export default i18n;
