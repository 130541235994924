/*
  This file is a .js because react-phone-number-input/input-mobile doesn't
  work with typescript file
*/
import { isValidPhoneNumber } from 'react-phone-number-input/input-mobile';
import * as Yup from 'yup';

export function ValidateEmailSchema(t) {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('validation:invalidEmail'))
      .required(t('validation:requiredEmail')),
  });
}

export function ValidateNewPasswordSchema(t) {
  return Yup.object().shape({
    password: Yup.string().required(t('validation:requiredNewPassword')),
    confirmPassword: Yup.string().required(
      t('validation:requiredConfirmPassword')
    ),
  });
}

export function ValidateInputSchema(t) {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('validation:invalidEmail'))
      .required(t('validation:requiredEmail')),
    password: Yup.string().required(t('validation:requiredPassword')),
  });
}

export function changePersonalInformationsSchema(t) {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, t('validation:tooShortFirstName'))
      .max(50, t('validation:tooLongFirstName'))
      .required(t('validation:requiredFirstName')),
    surname: Yup.string()
      .min(3, t('validation:tooShortLastName'))
      .max(50, t('validation:tooLongLastName'))
      .required(t('validation:requiredLastName')),
  });
}

export function validatePhoneNumber(t, values, errors) {
  if (values.phone && !isValidPhoneNumber(values.phone))
    errors.phone = t('validation:invalidCellPhoneNumber');
  else delete errors.phone;
}
