import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import ApplicationContext from '../../utils/context';
import SurveyForm from '../SurveyForm/SurveyForm';
import { useHistory, useLocation } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { surveyQuestionAndAnswerByLanguage } from '../../utils/survey';
import { Loader } from '../Loader/Loader';
import { SURVEY_TYPE_VALUE } from '../../constants/db_types';

const getSurgeryByIdQuery = loader('../../graphql/getSurgeryById.graphql');

const getQuestionAndSurveyByTypeQuery = loader(
  '../../graphql/getQuestionAndSurveyByType.graphql'
);

const getSurveyTypeByValueQuery = loader(
  '../../graphql/getSurveyTypeByValue.graphql'
);

interface SurveyData {
  surgery_id?: string | null;
  patient_id?: string;
}

interface SurveyProps {
  surveyTypeValue: string;
  surgeryId?: string;
  patientId?: string;
}

const Survey: React.FunctionComponent<SurveyProps> = ({
  surveyTypeValue,
  surgeryId,
  patientId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);

  const [getQuestionAndSurveyByType, { data: dataQuestion }] = useLazyQuery(
    getQuestionAndSurveyByTypeQuery
  );

  const [getSurveyTypeByValue, { data: dataSurveyTypeByValue }] = useLazyQuery(
    getSurveyTypeByValueQuery
  );

  const [surveyCreationData, setSurveyCreationData] =
    useState<SurveyData | null>(null);
  const [loading, setLoading] = useState(true);
  const [surveyType, setSurveyType] =
    useState<{
      id: string;
      value: string;
      label: string;
    } | null>(null);
  const { currentSurveysCxt } = useContext<any>(ApplicationContext);

  useEffect(() => {
    if (surveyTypeValue === SURVEY_TYPE_VALUE.CHILD_MOOD_EVALUATION) {
      getSurveyTypeByValue({ variables: { surveyTypeValue } });
      setSurveyCreationData({
        surgery_id: null,
        patient_id: patientId,
      });
    } else if (surveyTypeValue === SURVEY_TYPE_VALUE.PARENT_MOOD_EVALUATION) {
      getSurveyTypeByValue({ variables: { surveyTypeValue } });
      setSurveyCreationData({
        surgery_id: null,
      });
    } else if (surveyTypeValue === SURVEY_TYPE_VALUE.ANXIETY_EVALUATION) {
      getSurveyTypeByValue({ variables: { surveyTypeValue } });
      setSurveyCreationData({
        surgery_id: surgeryId,
        patient_id: patientId,
      });
    }
  }, []);
  useEffect(() => {
    if (
      surveyTypeValue !== SURVEY_TYPE_VALUE.CHILD_MOOD_EVALUATION &&
      surveyTypeValue !== SURVEY_TYPE_VALUE.PARENT_MOOD_EVALUATION &&
      surveyTypeValue !== SURVEY_TYPE_VALUE.ANXIETY_EVALUATION
    ) {
      const surgeryIdTmp = new URLSearchParams(location.search).get('sid');
      if (surgeryIdTmp) {
        getSurgeryById({ variables: { surgeryId: surgeryIdTmp } });
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [location]);

  useEffect(() => {
    if (dataSurgery) {
      const surgeryResponse = dataSurgery?.surgery?.[0];
      if (surgeryResponse) {
        setSurveyCreationData({
          surgery_id: surgeryResponse.id,
          patient_id: surgeryResponse.patient.id,
        });
      }
    }
  }, [dataSurgery]);

  useEffect(() => {
    /* survey type logic here if surveytype diff from child or parent mood test */
    if (
      surveyTypeValue !== SURVEY_TYPE_VALUE.CHILD_MOOD_EVALUATION &&
      surveyTypeValue !== SURVEY_TYPE_VALUE.PARENT_MOOD_EVALUATION &&
      surveyTypeValue !== SURVEY_TYPE_VALUE.ANXIETY_EVALUATION
    ) {
      if (currentSurveysCxt?.currentSurveysToEdit?.length > 0) {
        const surveyTypeTmp = currentSurveysCxt.currentSurveysToEdit.filter(
          (e: { survey_type: { id: string; value: string; label: string } }) =>
            e.survey_type.value === surveyTypeValue
        );
        if (surveyTypeTmp.length > 0) {
          setSurveyType({
            id: surveyTypeTmp[0].survey_type.id,
            value: surveyTypeTmp[0].survey_type.value,
            label: surveyTypeTmp[0].survey_type.label,
          });
        } else {
          history.push('/parent/surgeries');
        }
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [currentSurveysCxt]);

  useEffect(() => {
    /* Set survey type here if surveytype eq child or parent mood test */
    const surveyTypeTmp = dataSurveyTypeByValue?.survey_type?.[0];
    if (surveyTypeTmp) {
      setSurveyType({
        id: surveyTypeTmp.id,
        value: surveyTypeTmp.value,
        label: surveyTypeTmp.label,
      });
    }
  }, [dataSurveyTypeByValue]);

  useEffect(() => {
    if (surveyType?.id) {
      getQuestionAndSurveyByType({
        variables: { surveyTypeId: surveyType.id, language: i18n.language },
      });
    }
  }, [surveyType]);

  useEffect(() => {
    if (dataQuestion?.survey_question) {
      setLoading(false);
    }
  }, [dataQuestion]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <>
        {surveyCreationData && surveyType && (
          <SurveyForm
            surveyCreationData={surveyCreationData}
            questions={dataQuestion.survey_question.map((question: any) => {
              const newAnswers = question.survey_type.answers.map((e: any) => {
                return {
                  ...e,
                  value: surveyQuestionAndAnswerByLanguage(
                    e.survey_answer_i18ns,
                    i18n.language
                  ),
                };
              });
              const newQuestion = Object.assign({}, question, {
                skippable: false,
                conditionnal: false,
                skipped: false,
                value: surveyQuestionAndAnswerByLanguage(
                  question.survey_question_i18ns,
                  i18n.language
                ),
                survey_type: { ...question.survey_type, answers: newAnswers },
              });
              return newQuestion;
            })}
            surveyType={surveyType}
          />
        )}
      </>
    </>
  );
};

export default Survey;
