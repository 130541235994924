/**
 * Returns the timeline's date in an array of strings according to the surgery
 * date
*/
export function getTimelineLabels(surgeryDate: string) {
  const timeUnit = 'days';
  const dateFormat = 'DD/MM';
  const timeToAdd = [0, -7, -3, -1, 0];
  const array: string[] = [];

  const addTimeToDate = (
    currDate: string,
    timeValue: number,
    timeUnit: string,
    dateFormat: string,
  ) => {
    const moment = require('moment');

    return (moment(currDate).add(timeValue, timeUnit).format(dateFormat));
  }

  for (const time of timeToAdd) {
    array.push(
      addTimeToDate(surgeryDate, time, timeUnit, dateFormat)
    );
  }

  return (array)
}
