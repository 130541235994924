import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import i18n from '../../../i18n';
import { surveyQuestionAndAnswerByLanguage } from '../../../utils/survey';
import { BodyLarge } from '../../../theme/themeComponents/fonts';

const ArrowList = ({ element, labelId }) => (
  <ListItem>
    <ListItemIcon>
      <ArrowForwardIcon />
    </ListItemIcon>
    <ListItemTextStyled
      id={`list-label-${labelId}`}
      primary={surveyQuestionAndAnswerByLanguage(
        element.instruction_i18ns,
        i18n.language
      )}
    />
  </ListItem>
);

const ListItemTextStyled = styled(ListItemText)`
  span {
    font-size: ${BodyLarge};
  }
`;

ArrowList.propTypes = {
  element: PropTypes.object.isRequired,
  labelId: PropTypes.string.isRequired,
};

export default ArrowList;
