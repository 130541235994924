/**
 * This function returns the local storage variables and the dialog box's
 * content for the survey whose type is passed in parameter
*/
export function getSurveyTypeVariables(type: string) {
  const surveyVariables: {
    [key: string]: {
      localeStorageQuestionIndex: string,
      localeStorageAnswers: string,
      dialogTitle: string,
      dialogDescription: string,
    }
  } = {
    "phbq": {
      localeStorageQuestionIndex: "phbqQuestionIndex",
      localeStorageAnswers: "phbqAnswers",
      dialogTitle: "Evaluation du Comportement Post-Opératoire",
      dialogDescription: "Ce questionnaire permet d’évaluer l’impact de la \
chirurgie et de l’anesthésie sur le comportement de votre enfant. Il comporte \
11 questions sur les thèmes suivants: repas, anxiété général, angoisse de \
séparation, apathie, agressivité, sommeil.",
    },
    "modified-dental-anxiety": {
      localeStorageQuestionIndex: "modifiedDentalAnxietyQuestionIndex",
      localeStorageAnswers: "modifiedDentalAnxietyAnswers",
      dialogTitle: "Evaluation de l'anxiété de l'enfant avant la \
consultation dentaire",
      dialogDescription: "Ce questionnaire permet d’évaluer le niveau de \
stress de votre enfant avant la consultation dentaire. Il comporte 5 \
questions."
    },
    "dental-first-survey": {
      localeStorageQuestionIndex: "dentalFirstSurveyQuestionIndex",
      localeStorageAnswers: "dentalFirstSurveyAnswers",
      dialogTitle: "Questionnaire pré-consultation",
      dialogDescription: "Ce petit questionnaire permet aux dentistes \
d’avoir les informations de base sur vos habitudes dentaires. Il est \
recommandé de le remplir avant la consultation pour gagner du temps."
    },
    "stai-now": {
      localeStorageQuestionIndex: "staiNowQuestionIndex",
      localeStorageAnswers: "staiNowAnswers",
      dialogTitle: "Anxiété par rapport à l’intervention",
      dialogDescription: "Ce questionnaire évalue les sentiments \
d’appréhension, la tension, la nervosité et l’inquiétude que vous ressentez \
face à une situation.\n\nImaginons la situation suivante : dans quelques \
instants votre enfant se fait opérer. Ci-après figure un certain nombre de \
déclarations que les gens utilisent souvent pour se décrire. Lisez chacun des \
énoncés et choisissez la case qui convient le mieux à la façon dont vous vous \
sentez maintenant. Lisez chacun des énoncés et choisissez la case qui convient \
le mieux à la façon dont vous vous sentez, juste avant cette situation.\n\nIl \
n’existe ni bonnes ni mauvaises réponses. Ne passer pas trop de temps sur \
chacun des points, mais donnez la réponse qui semble décrire le mieux ce que \
vous ressentez dans cette situation.",
    },
    "stai-global": {
      localeStorageQuestionIndex: "staiGlobalQuestionIndex",
      localeStorageAnswers: "staiGlobalAnswers",
      dialogTitle: "Anxiété en général",
      dialogDescription: "Ce questionnaire évalue votre anxiété dans la vie \
en générale.\n\nCi-après figure un certain nombre de déclarations que les gens \
utilisent souvent pour se décrire. Lisez chacun des énoncés et utilisez la \
case qui convient le mieux à la façon dont vous vous sentez en général.\n\nIl \
n’existe ni bonnes ni mauvaises réponses. Ne passez pas trop de temps sur \
chacun des points, mais donnez la réponse qui semble décrire le mieux ce que \
vous ressentez généralement."
    },
  }

  return (surveyVariables[type]);
}
