import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from '../components/CustomButton/CustomButton';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import Survey from '../components/Survey/Survey';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';

interface HomeChildrenProps {}

interface SurgeryType {
  id: string;
  date: string;
  type: string;
  organizationName: string;
}

const HomeChildren: React.FunctionComponent<HomeChildrenProps> = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [currentChild, setCurrentChild] = useState<{
    id: string;
    name: string;
    surname: string;
    surgeries: Array<SurgeryType>;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [grantAccess, setGrantAccess] = useState(false);

  const { customModalCxt, currentParentAndChildrenCxt } =
    useContext<any>(ApplicationContext);

  useEffect(() => {
    if (currentParentAndChildrenCxt?.currentParentAndChildrenToEdit.id) {
      const cidx = new URLSearchParams(location.search).get('cidx');
      if (!cidx) {
        return history.push('/welcome');
      }

      const currentChild =
        currentParentAndChildrenCxt.currentParentAndChildrenToEdit.children[
          cidx
        ];
      if (!currentChild) {
        return history.push('/welcome');
      } else {
        customModalCxt?.setShowCustomModalChildMoodTest(true);
        setCurrentChild(currentChild);
        setLoading(false);
        if (
          currentParentAndChildrenCxt.currentParentAndChildrenToEdit
            .hasActivatedEmotionalHealth
        ) {
          setGrantAccess(true);
        }
      }
    }
  }, [currentParentAndChildrenCxt?.currentParentAndChildrenToEdit]);

  /* If the parent did not activate the emotional health space */
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageWrapper>
        <Title>
          {t('homeChildren:homeTitle')} {currentChild?.name}
        </Title>
        <Subtitle> {t('homeChildren:homeSubtitle')}</Subtitle>
        <Container>
          {grantAccess ? (
            <SurveyContainer>
              {customModalCxt?.showCustomModalChildMoodTest ? (
                <Survey
                  surveyTypeValue={SURVEY_TYPE_VALUE.CHILD_MOOD_EVALUATION}
                  patientId={currentChild?.id}
                />
              ) : (
                <ConfirmationSurvey>
                  <ConfirmationSurveyTitle>
                    Ta réponse a bien été enregistrée
                  </ConfirmationSurveyTitle>
                  <ConfirmationSurveyBackButton>
                    <CustomButton
                      content="Retour"
                      handleOnClick={() =>
                        customModalCxt?.setShowCustomModalChildMoodTest(true)
                      }
                    />
                  </ConfirmationSurveyBackButton>
                </ConfirmationSurvey>
              )}
            </SurveyContainer>
          ) : (
            <LockText>
              Tes parents peuvent débloquer cette fonctionnalité dans leur
              espace !
            </LockText>
          )}
        </Container>
      </PageWrapper>
    </>
  );
};

export default HomeChildren;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 30px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 65px;
  position: relative;
`;

const SurveyContainer = styled.div`
  background: ${(props) => props.theme.colors.lightGreenL2};
  box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.42);
  border-radius: 72px;
`;

const ConfirmationSurvey = styled.div`
  padding: 50px 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    padding: 50px 150px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 50px 20px;
  }
`;

const ConfirmationSurveyTitle = styled.div`
  ${Heading1}
  text-align: center;
`;

const ConfirmationSurveyBackButton = styled.div`
  margin-top: 50px;
`;

const LockText = styled.div`
  color: ${(props) => props.theme.colors.darkMain};
  ${Heading2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;
