import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import CustomButton from '../components/CustomButton/CustomButton';
import InputField from '../components/InputField/InputField';
import PasswordStrengthMeter from '../components/PasswordStrengthMeter/PasswordStrengthMeter';
import { ReactComponent as ParentChildSkateImage } from '../theme/images/parentChildSkate.svg';
import { BtnText } from '../theme/themeComponents/btnComponents';
import { ReactComponent as IconFacebook } from '../theme/icons/facebook.svg';
import { ReactComponent as IconInstagram } from '../theme/icons/instagram.svg';
import { ReactComponent as IconTwitter } from '../theme/icons/twitter.svg';
import { ReactComponent as Logo } from '../theme/images/logo/logoRow.svg';
import { ReactComponent as InfoIcon } from '../theme/icons/info.svg';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import { theme } from '../theme';
import { Heading5 } from '../theme/fonts';
import * as Yup from 'yup';
import { getUserByEmail, signupParent, validatePassword } from '../utils/auth';
import { displayToastNotification } from '../utils/toastNotification';
import { useTranslation } from 'react-i18next';
import { Mixpanel } from '../lib/mixpanel';

interface FormValues {
  email: string;
  password: string;
  childFirstName: string;
  childBirthDate: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Veuillez entrer un email')
    .email('Email invalide'),
  // .test('email-in-use', 'Cet email est déja utilisé', function (value) {
  //   console.log('validation email');
  //   return false;
  // })
  password: Yup.string()
    .required('Veuillez choisir un mot de passe')
    .test('password-validation', 'Mot de passe invalide', function (value) {
      return validatePassword(value!);
    }),
  childFirstName: Yup.string().required(
    'Veuillez entrer le prénom de votre enfant'
  ),
  childBirthDate: Yup.string().required(
    'Veuillez entrer la date de naissance de votre enfant'
  ),
});

const Signup: React.FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const initialValues = {
    email: '',
    password: '',
    childFirstName: '',
    childBirthDate: '',
  };

  useEffect(() => {
    const circleBackgroundElem = document.getElementById('circle-background');
    if (circleBackgroundElem) {
      circleBackgroundElem.style.display = 'none';
    }
    return () => {
      if (circleBackgroundElem) circleBackgroundElem.style.display = 'block';
    };
  }, []);

  const openSocialNetwork = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSubmit = async (
    values: FormValues,
    setFieldError: (field: string, errorMsg: string) => void,
    resetForm: () => void
  ) => {
    const resEmail = await getUserByEmail(values.email);
    if (resEmail.error) {
      setFieldError('email', resEmail.error);
      return;
    }

    /**
     *  Here we format a children array because initially (ex signup bot) it was like this
     *  We send an array of objects (name, birthDate)
     */
    const children = [
      {
        name: values.childFirstName,
        birthDate: values.childBirthDate,
      },
    ];
    let resSignUp = await signupParent(values.email, values.password, children);
    if (resSignUp.error) {
      displayToastNotification('error', resSignUp.error, 15000);
    } else {
      displayToastNotification(
        'success',
        'Votre inscription est terminée ! Vous allez être redirigé vers la page de connexion automatiquement ...',
        15000
      );
      setTimeout(() => {
        const params = Object.fromEntries(
          new URLSearchParams(window.location.search).entries()
        );
        Mixpanel?.finishSubscribe(params?.id);
        window.location.replace(`${process.env.REACT_APP_PARENT_URL}`);
      }, 6000);
      resetForm();
    }
  };

  return (
    <Page>
      <LeftContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <FormContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setFieldError, resetForm }) => {
              await handleSubmit(values, setFieldError, resetForm);
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              return (
                <SignupForm>
                  <FormTitle>Création du compte</FormTitle>
                  <InputFieldContainer>
                    <Field
                      name="email"
                      component={InputField}
                      inputLabel="Votre email"
                      inputPlaceholder={'Email'}
                      backgroundColor={theme.colors.darkGreenL1}
                      // validate={validateEmail}
                    />
                  </InputFieldContainer>
                  <InputFieldContainer>
                    <Field
                      name="password"
                      component={InputField}
                      inputType="password"
                      inputLabel="Mot de passe"
                      inputPlaceholder={'Mot de passe'}
                      backgroundColor={theme.colors.darkGreenL1}
                      showPasswordIcon
                      errorTopPosition={'65px'} // Custom position to handle password strength component position
                    />
                    <PasswordStrengthMeterContainer>
                      <PasswordStrengthMeter password={props.values.password} />
                    </PasswordStrengthMeterContainer>
                  </InputFieldContainer>

                  <InputFieldContainer>
                    <Field
                      name="childFirstName"
                      component={InputField}
                      inputLabel="Prénom de votre enfant"
                      inputPlaceholder="Prénom"
                      backgroundColor={theme.colors.darkGreenL1}
                    />
                  </InputFieldContainer>

                  {/* <InputFieldContainer>
                <Field
                  name={'childBirthDate'}
                  placeholder={'jj/mm/aaaa'}
                  component={DatePickerForm}
                  inputPlaceholder="Date de naissance de votre enfant"
                />
              </InputFieldContainer> */}
                  <InputFieldContainer>
                    <Field
                      name="childBirthDate"
                      inputType="date"
                      inputLabel="Date de naissance de votre enfant"
                      inputPlaceholder="jj/mm/aaaa"
                      backgroundColor={theme.colors.darkGreenL1}
                      component={InputField}
                    />
                  </InputFieldContainer>
                  <InfosContainer>
                    <InfoIcon />
                    <span>
                      Vous pouvez ajouter vos autres enfants une fois connecté.
                    </span>
                  </InfosContainer>
                  <ButtonContainer>
                    <CustomButton
                      type="submit"
                      content="Créer mon compte"
                      disabled={props.isSubmitting}
                      handleOnClick={() => {
                        if (
                          props.values.password &&
                          !validatePassword(props.values.password)
                        ) {
                          displayToastNotification(
                            'error',
                            t('common:passwordWeak'),
                            15000
                          );
                        }
                      }}
                    />
                  </ButtonContainer>
                  <LoginButton onClick={() => history.push('/')}>
                    Se connecter
                  </LoginButton>
                </SignupForm>
              );
            }}
          </Formik>
        </FormContainer>
      </LeftContainer>

      <RightContainer>
        <ImageSubcontainer>
          <ParentChildSkateImage style={{ width: '100%' }} />
        </ImageSubcontainer>
      </RightContainer>
      <SocialNetworkContainer>
        <IconFacebookContainer
          onClick={() =>
            openSocialNetwork('https://www.facebook.com/KoalouApp')
          }
        />
        <IconInstagramContainer
          onClick={() =>
            openSocialNetwork('https://www.instagram.com/KoalouApp')
          }
        />
        <IconTwitterContainer
          onClick={() => openSocialNetwork('https://twitter.com/KoalouApp')}
        />
      </SocialNetworkContainer>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  padding-top: 20px;
  padding-left: 20px;
`;

const LeftContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 5%;
  }
`;

const FormTitle = styled.div`
  margin-bottom: 10%;
  ${Heading1}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  }
`;

const SignupForm = styled(Form)`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    flex-direction: column;
    width: 50%;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
    width: 80%;
  }
`;

const InputFieldContainer = styled.div`
  width: 100%;
  margin: 15px 0;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
    margin: 20px;
  }
`;

const PasswordStrengthMeterContainer = styled.div`
  margin-top: 5px;
`;

//TODO delete btn text etienne's work
const LoginButton = styled(BtnText)`
  text-align: center;
  text-decoration: underline;
  margin-top: 10px;
  width: auto;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
    margin-bottom: 50px !important;
  }
`;

const RightContainer = styled.div`
  width: 35%;
  background: linear-gradient(
    180deg,
    #daece6 0%,
    rgba(255, 255, 255, 0.76) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const ImageSubcontainer = styled.div`
  width: calc(100% - 48px);
  margin: 0 24px 48px 24px;
`;

const SocialNetworkContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  & > svg {
    margin: 0 5px;
    cursor: pointer;
  }

  & > svg > path {
    /* fill: red; */
    /* stroke: red; */
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    top: 15px;
  }
`;

const iconDimension = css`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 16px;
    height: 16px;
  }
`;

const IconFacebookContainer = styled(IconFacebook)`
  ${iconDimension}
`;

const IconInstagramContainer = styled(IconInstagram)`
  ${iconDimension}
`;

const IconTwitterContainer = styled(IconTwitter)`
  ${iconDimension}
`;

const InfosContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGreenMain};
  ${Heading5};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > span {
    margin-left: 15px;
  }
  margin-top: 25px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
`;

export default Signup;
