import CountdownController from './CountdownController.js';

export default class DialogBox {
  /** @type {Phaser.Scene} */
  scene;

  /** @type {Phaser.GameObjects.Image} */
  image;

  /** @type {CountdownController} */
  countdown;

  dialogsArr;

  textContainer;

  running = false;

  /**
   *
   * @param {Phaser.Scene} scene
   * @param {string[]} dialogsArr
   */
  constructor(scene, dialogsArr) {
    this.scene = scene;
    this.dialogsArr = dialogsArr;
  }

  start(image) {
    this.image = image;

    this.textContainer = this.scene.add
      .graphics()
      .setScrollFactor(0)
      .fillStyle(0xffffff, 0.5)
      .fillRoundedRect(
        100,
        this.scene.scale.height - 150,
        this.scene.scale.width - 200,
        150
      )
      .setDepth(100);

    this.koalou = this.scene.add
      .image(this.scene.scale.width, this.scene.scale.height, this.image)
      .setScrollFactor(0)
      .setScale(0.5)
      .setOrigin(1, 1)
      .setDepth(100);

    const fontStyle = {
      fontFamily: 'Raleway',
      color: 'black',
      fontSize: 36,
      wordWrap: { width: this.scene.scale.width - 400, useAdvanceWrap: true },
    };

    this.currDialog = this.scene.add
      .text(100, this.scene.scale.height - 150, this.dialogsArr[0], fontStyle)
      .setPadding(16)
      .setScrollFactor(0)
      .setDepth(110);

    this.countdown = new CountdownController(this.scene);
    this.countdown.start(
      this.getDialog.bind(this),
      5000,
      this.dialogsArr.length
    );

    this.running = true;
  }

  stop() {
    this.textContainer.destroy();
    this.koalou.destroy();
    this.currDialog.destroy();
    this.running = false;
  }

  isRunning() {
    return this.running;
  }

  getDialog() {
    if (!this.countdown.timerEvent) return;
    const dialogIdx = this.dialogsArr.length - this.countdown.getRepeatCount();

    if (dialogIdx === this.dialogsArr.length - 1) {
      this.stop();
      return;
    }
    this.currDialog.text = this.dialogsArr[dialogIdx + 1];
  }
}
