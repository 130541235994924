import CountdownController from './CountdownController';

export default class ProgressionBar {
  /** @type {Phaser.Scene} */
  scene;

  /** @type {Phaser.GameObjects.Image} */
  progressingItem;

  /** @type {CountdownController} */
  countdown;

  bar;

  /**
   *
   * @param {Phaser.Scene} scene
   * @param {Phaser.GameObjects.Image} progressingItem
   * @param {CountdownController} countdown
   */
  constructor(scene, progressingItem, countdown) {
    this.scene = scene;
    this.progressingItem = progressingItem;
    this.countdown = countdown;
  }

  create() {
    this.bar = this.scene.add
      .graphics()
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(200, 30, this.scene.scale.width - 400, 10, 5)
      .setScrollFactor(0);

    this.progressingItem
      .setScale(0.1)
      .setPosition(200, 30)
      .setOrigin(0.5, 0.5)
      .setDepth(10);
  }

  update() {
    if (!this.countdown.timerEvent) return;

    this.progressingItem.setX(
      200 +
        this.countdown.timerEvent.getOverallProgress() *
          (this.scene.scale.width - 400)
    );
  }
}
