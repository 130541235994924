import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { ErrorOutline } from '@material-ui/icons';
import { loader } from 'graphql.macro';
import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';
import { surveyQuestionAndAnswerByLanguage } from '../../utils/survey';
import ArrowList from './listType/ArrowList';
import CheckBoxList from './listType/CheckboxList';

const getInstructionByTypeQuery = loader(
  '../../graphql/getInstructionByType.graphql'
);

interface InstructionListProps {
  listType: string;
  title?: string;
  type: string;
  surgeryCategoryId: string;
}

const InstructionList: React.FunctionComponent<InstructionListProps> = ({
  listType,
  title,
  type,
  surgeryCategoryId,
}) => {
  const { loading, error, data } = useQuery(getInstructionByTypeQuery, {
    variables: {
      type,
      language: i18n.language,
      surgeryCategoryId,
    },
  });

  return (
    <ListStyled subheader={<ListSubHeaderStyled>{title}</ListSubHeaderStyled>}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <ErrorOutline />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Can't retrieve instruction
        </>
      ) : (
        data &&
        data.instruction &&
        data.instruction.map((element: any, index: number) => {
          const labelId = surveyQuestionAndAnswerByLanguage(
            element.instruction_i18ns,
            i18n.language
          )
            .replace(/[^a-zA-Z ]/g, '')
            .replace(/\s/g, '-')
            .toLowerCase();

          if (element.active) {
            if (listType === 'list') {
              return (
                <ArrowList element={element} key={index} labelId={labelId} />
              );
            }
            if (listType === 'checkbox') {
              return (
                <CheckBoxList
                  element={element}
                  key={index}
                  labelId={labelId}
                  type={type}
                />
              );
            }
          }
          return null;
        })
      )}
    </ListStyled>
  );
};

const ListStyled = styled(List)`
  max-width: 660px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.creamL1};
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;

  &.MuiList-root {
    margin: 1rem;
  }

  .MuiListSubheader-root {
    font-size: 1.8rem;
  }

  &.MuiList-root {
    margin: 1rem;
    padding-top: 1rem;
  }

  .MuiFormGroup-root {
    padding: 1.6rem;
  }

  .MuiFormControlLabel-label {
    font-size: 1.6rem;
  }

  .MuiFormControlLabel-root {
    width: 100%;
    padding: 0 1.6rem;
  }
`;

const ListSubHeaderStyled = styled(ListSubheader)`
  padding: 0 !important;
  margin-bottom: 1.6rem;
  text-align: center;
  border-radius: 50%;
`;

export default InstructionList;
