export const SURVEY_TYPE_VALUE = {
  /* Related to a specific surgery */
  ANXIETY_EVALUATION: 'anxiety-test',
  PARENT_ANXIETY_EVALUATION: 'parent-anxiety-test',
  SYMPTOMATOLOGY: 'symptomatology-d-3',
  PAIN_EVALUATION: 'pain-test',
  PHBQ_EVALUATION: 'phbq',
  MODIFIED_DENTAL_ANXIETY: 'modified-dental-anxiety',
  DENTAL_FIRST_SURVEY: 'dental-first-survey',
  STAI_NOW: 'stai-now',
  STAI_GLOBAL: 'stai-global',
  COVID_CHECKER: 'covid-checker',
  /* Not related to surgery */
  PARENT_MOOD_EVALUATION: 'parent-mood-test',
  CHILD_MOOD_EVALUATION: 'child-mood-test',
};

export const APPLICATION_ROLE = {
  PARENT: 'parent',
  PRACTITIONER: 'practitioner',
};
