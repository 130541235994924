import React from 'react'
import PropTypes from 'prop-types'
import { BottomTextStyled } from './styles'

const BottomText = ({ bottomText, professor }) => (
  <BottomTextStyled professor={professor}>
    <p>{bottomText}</p>
  </BottomTextStyled>
)

BottomText.propTypes = {
  bottomText: PropTypes.string.isRequired,
  professor: PropTypes.bool.isRequired,
}

export default BottomText
