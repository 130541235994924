import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar1 from '../../theme/images/avatars/avatar1.png';
import Avatar2 from '../../theme/images/avatars/avatar2.png';
import Avatar3 from '../../theme/images/avatars/avatar3.png';
import Avatar4 from '../../theme/images/avatars/avatar4.png';
import Avatar5 from '../../theme/images/avatars/avatar5.png';
import Avatar6 from '../../theme/images/avatars/avatar6.png';
import Avatar7 from '../../theme/images/avatars/avatar7.png';
import Avatar8 from '../../theme/images/avatars/avatar8.png';
import Avatar9 from '../../theme/images/avatars/avatar9.png';
import Avatar10 from '../../theme/images/avatars/avatar10.png';
import Avatar11 from '../../theme/images/avatars/avatar11.png';
import Avatar12 from '../../theme/images/avatars/avatar12.png';
import Avatar13 from '../../theme/images/avatars/avatar13.png';
import Avatar14 from '../../theme/images/avatars/avatar14.png';
import Avatar15 from '../../theme/images/avatars/avatar15.png';
import Avatar16 from '../../theme/images/avatars/avatar16.png';
import Avatar17 from '../../theme/images/avatars/avatar17.png';
import Avatar18 from '../../theme/images/avatars/avatar18.png';
import Avatar19 from '../../theme/images/avatars/avatar19.png';
import Avatar20 from '../../theme/images/avatars/avatar20.png';
import { Heading4 } from '../../theme/themeComponents/fonts';

const avatarsParentFemaleImages = [
  { title: 'Avatar 1', image: Avatar1, name: 'avatar1' },
  { title: 'Avatar 2', image: Avatar2, name: 'avatar2' },
  { title: 'Avatar 3', image: Avatar3, name: 'avatar3' },
  { title: 'Avatar 4', image: Avatar4, name: 'avatar4' },
  { title: 'Avatar 5', image: Avatar5, name: 'avatar5' },
];

const avatarsParentMaleImages = [
  { title: 'Avatar 6', image: Avatar6, name: 'avatar6' },
  { title: 'Avatar 7', image: Avatar7, name: 'avatar7' },
  { title: 'Avatar 8', image: Avatar8, name: 'avatar8' },
  { title: 'Avatar 9', image: Avatar9, name: 'avatar9' },
  { title: 'Avatar 10', image: Avatar10, name: 'avatar10' },
];

const avatarsChildFemaleImages = [
  { title: 'Avatar 11', image: Avatar11, name: 'avatar11' },
  { title: 'Avatar 12', image: Avatar12, name: 'avatar12' },
  { title: 'Avatar 13', image: Avatar13, name: 'avatar13' },
  { title: 'Avatar 14', image: Avatar14, name: 'avatar14' },
  { title: 'Avatar 15', image: Avatar15, name: 'avatar15' },
];

const avatarsChildMaleImages = [
  { title: 'Avatar 16', image: Avatar16, name: 'avatar16' },
  { title: 'Avatar 17', image: Avatar17, name: 'avatar17' },
  { title: 'Avatar 18', image: Avatar18, name: 'avatar18' },
  { title: 'Avatar 19', image: Avatar19, name: 'avatar19' },
  { title: 'Avatar 20', image: Avatar20, name: 'avatar20' },
];

interface AvatarsProps {
  handleClick: (name: string) => void;
}

const Avatars: React.FC<AvatarsProps> = ({ handleClick }) => {
  const [currentAvatarSelected, setCurrentAvatarSelected] = useState('');

  const handleClickAvatar = (name: string) => {
    setCurrentAvatarSelected(name);
    setTimeout(() => {
      handleClick(name);
    }, 100);
  };

  return (
    <Container>
      <AvatarRow>
        {avatarsParentFemaleImages.map((e, i) => (
          <AvatarContainer
            key={i}
            onClick={() => handleClickAvatar(e.name)}
            isActive={currentAvatarSelected === e.name}
          >
            <Avatar>
              <img src={e.image} style={{ width: '100%', height: '100%' }} />
            </Avatar>
            <AvatarText>{e.title}</AvatarText>
          </AvatarContainer>
        ))}
      </AvatarRow>
      <AvatarRow>
        {avatarsParentMaleImages.map((e, i) => (
          <AvatarContainer
            key={i}
            onClick={() => handleClickAvatar(e.name)}
            isActive={currentAvatarSelected === e.name}
          >
            <Avatar>
              <img src={e.image} style={{ width: '100%', height: '100%' }} />
            </Avatar>
            <AvatarText>{e.title}</AvatarText>
          </AvatarContainer>
        ))}
      </AvatarRow>
      <AvatarRow>
        {avatarsChildFemaleImages.map((e, i) => (
          <AvatarContainer
            key={i}
            onClick={() => handleClickAvatar(e.name)}
            isActive={currentAvatarSelected === e.name}
          >
            <Avatar>
              <img src={e.image} style={{ width: '100%', height: '100%' }} />
            </Avatar>
            <AvatarText>{e.title}</AvatarText>
          </AvatarContainer>
        ))}
      </AvatarRow>
      <AvatarRow>
        {avatarsChildMaleImages.map((e, i) => (
          <AvatarContainer
            key={i}
            onClick={() => handleClickAvatar(e.name)}
            isActive={currentAvatarSelected === e.name}
          >
            <Avatar>
              <img src={e.image} style={{ width: '100%', height: '100%' }} />
            </Avatar>
            <AvatarText>{e.title}</AvatarText>
          </AvatarContainer>
        ))}
      </AvatarRow>
    </Container>
  );
};

const Container = styled.div``;

const AvatarContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.darkGreenMain : ''};
  border-radius: 24px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? props.theme.colors.white : '')};
  width: 84px;
  padding: 7px;
`;

const AvatarRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Avatar = styled.div``;

const AvatarText = styled.div`
  text-align: center;
  ${Heading4}
`;

export default Avatars;
