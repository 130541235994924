import React, { useEffect, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import RadioButtonComponent from '../RadioButtonComponent/RadioButtonComponent';
import styled from 'styled-components';

interface RadioGroupComponentProps {
  question: any;
  currentValue: string;
  answers: any;
  handleAnswerRadioButtonChange: (answerLabel: any, question: any) => void;
}

const RadioGroupComponent: React.FunctionComponent<RadioGroupComponentProps> = ({
  question,
  currentValue,
  answers,
  handleAnswerRadioButtonChange,
}) => {
  const [value, setValue] = useState(currentValue || '');
  const handleChange = (e: any) => {
    setValue(e.target.value);
    handleAnswerRadioButtonChange(e.target.value, question);
  };

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  return (
    <RadioGroupContainer onChange={handleChange}>
      {answers &&
        answers.length > 0 &&
        answers.map((answer: any) => {
          return (
            <RadioButtonComponent
              key={answer.order}
              checked={value === answer.value}
              value={answer.value}
            />
          );
        })}
    </RadioGroupContainer>
  );
};

export default RadioGroupComponent;

const RadioGroupContainer = styled(ButtonGroup)`
  display: flex;
  flex-direction: column;
`;
