import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { COOKIES } from '../../constants/cookies_type';
import { APPLICATION_ROLE } from '../../constants/db_types';
import i18n from '../../i18n';
import { client } from '../../lib/apolloClient';
import ApplicationContext from '../../utils/context';
import HydrateContext from '../HydrateContext/HydrateContext';
import SessionExpirationModal from '../SessionExpirationModal/SessionExpirationModal';

const getUserRoleQuery = loader('../../graphql/getUserRole.graphql');
const getUserSetLanguageQuery = loader(
  '../../graphql/getUserSetLanguage.graphql'
);
const getDefaultLanguageQuery = loader(
  '../../graphql/getDefaultLanguage.graphql'
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUserEmailCxt } = useContext(ApplicationContext);
  const [getUserRole, { data: userRole }] = useLazyQuery(getUserRoleQuery);
  const [role, setRole] = useState({ isLoading: true, value: null });
  const [loading, setLoading] = useState(true);

  const [getDefaultLanguage, { data: defaultLanguage }] = useLazyQuery(
    getDefaultLanguageQuery
  );

  useEffect(() => {
    if (currentUserEmailCxt.currentUserEmail) {
      const token = cookie.get(COOKIES.ROLE);

      const role = cookie.get(COOKIES.ROLE);
      if (!role) {
        getUserRole({
          variables: { email: currentUserEmailCxt.currentUserEmail },
        });
      } else {
        setRole({ isLoading: false, value: role });
      }
    } else {
      setRole({ isLoading: false, value: null });
    }
  }, []);

  useEffect(() => {
    if (userRole) {
      const role =
        userRole.user[0].user_application_roles[0].application_role.name;
      setRole({
        isLoading: false,
        value: role,
      });
      cookie.set(COOKIES.ROLE, APPLICATION_ROLE.PARENT);
    }
  }, [userRole]);

  useEffect(async () => {
    if (role) {
      let response = await client.query({
        query: getUserSetLanguageQuery,
        variables: {
          id: cookie.get(COOKIES.ID),
        },
        fetchPolicy: 'network-only',
      });
      if (response?.data?.user[0]?.language) {
        i18n.changeLanguage(response.data.user[0].language.language_code);
        setLoading(false);
      } else {
        getDefaultLanguage();
      }
    }
  }, [role]);

  useEffect(() => {
    if (defaultLanguage) {
      i18n.changeLanguage(defaultLanguage.language[0].language_code);
      setLoading(false);
    }
  }, [defaultLanguage]);

  if (loading) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!role.value) {
          return <Redirect to={{ pathname: '/' }} />;
        } else if (role.value !== APPLICATION_ROLE.PARENT) {
          return <Redirect to={{ pathname: '/unauthorized' }} />;
        } else {
          return (
            <SessionExpirationModal>
              <HydrateContext>
                <Component {...props} />
              </HydrateContext>
            </SessionExpirationModal>
          );
        }
      }}
    />
  );
};

export default PrivateRoute;

// const LoaderContainer = styled.div`
//   height: 100vh;
// `;
