import AmygdalesStory from '../conf/stories/amygdales.json';
import EctopieTesticulaireStory from '../conf/stories/ectopie_testiculaire.json';
import CirconcisionStory from '../conf/stories/circoncision.json';
import HypospadeStory from '../conf/stories/hypospade.json';
import IRMSousAGSTory from '../conf/stories/IRM_sous_AG.json';
import AerateurTransTympaniqueStory from '../conf/stories/aerateur_trans_tympanique.json';
import PremiereConsultationDentaire from '../conf/stories/premiere_consultation_dentaire.json';
import HernieInguinale from '../conf/stories/hernie_inguinale.json';
import HernieOmbilicale from '../conf/stories/hernie_ombilicale.json';
import AblationMaterielOrthopedique from '../conf/stories/ablation_materiel_orthopedique.json';
import DoigtSurnumeraire from '../conf/stories/doigt_surnumeraire.json';
import ChirurgieSimple from '../conf/stories/chirurgie_simple.json';
import ChirurgieComplexe from '../conf/stories/chirurgie_complexe.json';
import FractureMembreSuperieur from '../conf/stories/fracture_membre_superieur.json';
import Hydrocele from '../conf/stories/hydrocele.json';

export default {
  getStory(surgeryName: string) {
    const mapStoriesName = {
      Amygdales: AmygdalesStory,
      Circoncision: CirconcisionStory,
      'Ectopie testiculaire': EctopieTesticulaireStory,
      Hypospade: HypospadeStory,
      'IRM sous AG': IRMSousAGSTory,
      'Aérateur Trans Tympanique': AerateurTransTympaniqueStory,
      'Première Consultation Dentaire': PremiereConsultationDentaire,
      'Hernie Inguinale': HernieInguinale,
      'Hernie Ombilicale': HernieOmbilicale,
      'Ablation Matériel Orthopédique': AblationMaterielOrthopedique,
      'Doigt Surnuméraire': DoigtSurnumeraire,
      'Chirurgie Simple': ChirurgieSimple,
      'Chirurgie Complexe': ChirurgieComplexe,
      'Fracture Membre Supérieur': FractureMembreSuperieur,
      'Hydrocèle': Hydrocele,
    };

    //@ts-ignore
    return mapStoriesName[surgeryName];
  },

  isStoryAvailable(surgeryName: string) {
    return !!this.getStory(surgeryName);
  },
};
