import React from 'react';
import { Link } from 'react-router-dom';
import { EndArrowStyled } from './styles';

const EndArrow = ({ image, childIdx, surgeryIdx }) => (
  <EndArrowStyled image={image}>
    <Link to={`/children/story?cidx=${childIdx}&sidx=${surgeryIdx}`}></Link>
  </EndArrowStyled>
);

export default EndArrow;
