/* French */
export const childDevelopmentFr = [
  {
    age: ['2'],
    subtitle: '6 à 12 prochains mois',
    section: [
      {
        title: ['Marqueurs d’aptitudes physiques et motrices:'],
        list: [
          'Capable de tourner une poignée de porte',
          'Peut regarder un livre en tournant une page à la fois',
          'Peut construire une tour de 6 à 7 cubes',
          'Peut taper dans un ballon sans perdre l’équilibre',
          'Peut ramasser des objets debout, sans perdre l’équilibre (souvent vers 15 mois)',
          'Il peut courir avec une meilleure coordination (il peut encore avoir une posture large).',
          'Il est peut-être prêt à aller aux toilettes',
          'Il devrait avoir ses 16 premières dents, mais le nombre réel de dents peut varier considérablement.',
        ],
      },
      {
        title: ['Marqueurs sensoriels et cognitifs'],
        list: [
          'Il est capable d’enfiler des vêtements simples sans aide. (L’enfant est souvent plus doué pour enlever ses vêtements que pour les enfiler).',
          'Il est capable de communiquer ses besoins tels que la soif, la faim, l’envie d’aller aux toilettes.',
          'Peut organiser des phrases de 2 à 3 mots',
          'Peut comprendre un ordre en deux étapes tel que : ” Donne-moi le ballon, puis va chercher tes chaussures “.',
          'Il a une capacité d’attention accrue',
          'Sa vision est complètement développée.',
          'Le vocabulaire est passé à environ 50 à 300 mots, mais le vocabulaire des enfants en bonne santé peut varier considérablement.',
        ],
      },
      {
        title: ['Recommandations pour le jeu'],
        list: [
          'Permettez à l’enfant d’aider à la maison et de participer aux tâches quotidiennes de la famille.',
          'Encouragez les jeux actifs et fournissez suffisamment d’espace pour une activité physique saine.',
          'Encouragez le jeu qui implique la construction et la créativité',
          'Fournir des copies sûres des outils et équipements des adultes. De nombreux enfants aiment imiter des activités telles que la tonte de l’herbe ou le balayage du sol.',
          'Faites la lecture à l’enfant.',
          'Essayez d’éviter de regarder la télévision à cet âge',
          'Contrôlez le type de jeux auxquels l’enfant joue',
        ],
      },
    ],
  },
  {
    age: ['3'],
    subtitle: '6-12 mois suivants',
    section: [
      {
        title: ['Étapes physiques et motrices :'],
        list: [
          'Peut avoir le contrôle diurne des fonctions intestinales et vésicales (peut aussi avoir le contrôle nocturne)',
          'Peut brièvement se tenir en équilibre et sauter sur un pied',
          'Peut monter les escaliers en alternant les pieds (sans tenir la rampe).',
          'Peut construire une tour de blocs de plus de 9 cubes.',
          'Peut placer facilement de petits objets dans une petite ouverture',
          'Peut copier un cercle',
          'Peut pédaler sur un tricycle',
        ],
      },
      {
        title: [
          'Les étapes sensorielles, mentales et sociales sont les suivantes',
        ],
        list: [
          'Possède un vocabulaire de plusieurs centaines de mots',
          'Parle en phrases de 3 mots',
          'Pose souvent des questions',
          'Peut s’habiller seul, n’ayant besoin d’aide que pour les lacets, les boutons et autres attaches dans les endroits difficiles.',
          'Il met en scène des rencontres sociales par le biais d’activités ludiques.',
          'A peur de choses imaginaires',
          'Joue un peu en coopération (construire une tour de blocs ensemble).',
        ],
      },
      {
        title: ['Comment encourager le développement d’un enfant de 3 ans ?'],
        list: [
          'Fournir une aire de jeu sûre et une surveillance constante',
          'Prévoyez l’espace nécessaire à l’activité physique',
          'Aidez votre enfant à participer à des sports et à des jeux, et à en apprendre les règles.',
          'Visitez les centres d’intérêt locaux',
          'Lisez ensemble',
          'Encouragez votre enfant à participer aux petites tâches ménagères, comme mettre la table ou ramasser les jouets.',
          'Encouragez votre enfant à apprendre en répondant à ses questions.',
          'Encouragez votre enfant à utiliser des mots pour exprimer ses sentiments (plutôt que de jouer la comédie).',
        ],
      },
    ],
  },
  {
    age: ['4'],
    subtitle: '6-12 mois suivants',
    section: [
      {
        title: ['Physique et moteur ; Au cours de la quatrième année :'],
        list: [
          'Dort de 11 à 13 heures la nuit, le plus souvent sans sieste diurne.',
          'Montre un meilleur équilibre',
          'Saute sur un pied sans perdre l’équilibre',
          'Lance une balle à la main avec coordination',
          'Peut encore mouiller son lit',
        ],
      },
      {
        title: [
          'Marqueurs sensoriels et cognitifs ; L’enfant typique de 4 ans :',
        ],
        list: [
          'Possède un vocabulaire de plus de 1 000 mots',
          'Compose facilement des phrases de 4 ou 5 mots',
          'Peut utiliser des mots qu’il ne comprend pas complètement',
          'Essaie d’être très indépendant',
          'Parle de sujets personnels et familiaux aux autres',
          'A souvent des camarades de jeu imaginaires',
          'A une meilleure compréhension du temps',
          'Est capable de faire la différence entre deux objets, en se basant sur des éléments comme la taille et le poids.',
          'Manque de concepts moraux du bien et du mal',
          'Peut se rebeller si on attend trop de lui.',
        ],
      },
      {
        title: ['Le jeu :'],
        list: [
          'Encouragez et offrez un espace pour l’activité physique',
          'Montrez à votre enfant comment participer à des activités sportives et en respecter les règles.',
          'Encouragez le jeu et le partage avec les autres enfants',
          'Apprenez à votre enfant à faire de petites tâches, comme mettre la table.',
          'Lisez ensemble',
          'Exposez votre enfant à différents stimuli en visitant des centres d’intérêt locaux.',
          // 'Encourage creative play',
        ],
      },
    ],
  },
  {
    age: ['5'],
    subtitle: '6-12 mois suivants',
    section: [
      {
        title: [
          'Les étapes physiques et motrices d’un enfant typique de 5 ans comprennent :',
        ],
        list: [
          'Les premières dents d’adulte commencent à percer la gencive (la plupart des enfants n’ont pas leurs premières dents d’adulte avant l’âge de 6 ans).',
          'Meilleure coordination (les bras, les jambes et le corps travaillent ensemble).',
          'Il saute, saute et sautille avec un bon équilibre.',
          'Il reste en équilibre en se tenant sur un pied, les yeux fermés.',
          'Il fait preuve de plus d’habileté avec des outils simples et des ustensiles d’écriture.',
          'Peut copier un triangle',
          'Peut utiliser un couteau pour étaler des aliments mous',
        ],
      },
      {
        title: ['Étapes sensorielles et mentales'],
        list: [
          'Possède un vocabulaire de plus de 2 000 mots.',
          'Parle en phrases de 5 mots ou plus, et avec toutes les parties du discours.',
          'Peut identifier différentes pièces de monnaie',
          'Peut compter jusqu’à 10',
          'Peut nommer correctement les couleurs primaires, et peut-être beaucoup d’autres couleurs.',
          'Pose des questions plus profondes sur le sens et l’objectif',
          'Est plus responsable',
          'Il surmonte ses peurs d’enfance',
          'Accepte d’autres points de vue (mais peut ne pas les comprendre).',
          'Il aime imaginer et faire semblant en jouant (par exemple, il prétend faire un voyage sur la lune).',
        ],
      },
      {
        title: [
          'Voici quelques façons d’encourager le développement d’un enfant de 5 ans :',
        ],
        list: [
          'Lire ensemble',
          'Offrir suffisamment d’espace à l’enfant pour qu’il puisse être physiquement actif.',
          'Apprendre à l’enfant à participer à des sports et à des jeux et à en connaître les règles.',
          'Encourager l’enfant à jouer avec d’autres enfants, ce qui l’aide à développer ses aptitudes sociales.',
          'Jouer de manière créative avec l’enfant',
        ],
      },
    ],
  },
  {
    age: ['6', '7', '8', '9', '10', '11'],
    subtitle: 'Enfants - âge scolaire - 6 à 12 ans',
    section: [
      {
        title: [
          'Développement physique',
          'Les enfants d’âge scolaire ont le plus souvent une motricité souple et forte. Cependant, leur coordination (en particulier œil-main), leur endurance, leur équilibre et leurs capacités physiques varient.',
          'La motricité fine peut également varier considérablement. Ces aptitudes peuvent affecter la capacité d’un enfant à écrire proprement, à s’habiller correctement et à effectuer certaines tâches, comme faire le lit ou la vaisselle..',
          'Il y aura de grandes différences de taille, de poids et de corpulence entre les enfants de cette tranche d’âge. Il est important de se rappeler que les antécédents génétiques, ainsi que l’alimentation et l’exercice, peuvent affecter la croissance d’un enfant.',
          'Le sens de l’image corporelle commence à se développer vers l’âge de 6 ans. Les habitudes sédentaires des enfants d’âge scolaire sont liées à un risque d’obésité et de maladies cardiaques à l’âge adulte. Les enfants de ce groupe d’âge devraient faire une heure d’activité physique par jour.',
          'Il peut également y avoir une grande différence dans l’âge auquel les enfants commencent à développer les caractéristiques sexuelles secondaires. Pour les filles, les caractéristiques sexuelles',
        ],
        list: [
          'le développement des seins',
          'la croissance des poils des aisselles et du pubis.',
        ],
      },
      {
        title: ['Pour les garçons, ils comprennent :'],
        list: [
          'la croissance des poils des aisselles, de la poitrine et du pubis',
          'la croissance des testicules et du pénis.',
        ],
      },
      {
        title: [
          'L’école',
          'À l’âge de 5 ans, la plupart des enfants sont prêts à commencer à apprendre dans un cadre scolaire. Les premières années sont consacrées à l’apprentissage des notions de base.',
          'Au fil du temps, l’objectif devient plus complexe. La lecture porte davantage sur le contenu que sur l’identification des lettres et des mots.',
          'La capacité d’attention est importante pour réussir à l’école et à la maison. Un enfant de 6 ans doit être capable de se concentrer sur une tâche pendant au moins 15 minutes. À l’âge de 9 ans, un enfant devrait être capable de se concentrer pendant environ une heure.',
          'Il est important que l’enfant apprenne à gérer l’échec ou la frustration sans perdre son estime de soi. Il existe de nombreuses causes d’échec scolaire, notamment :',
        ],
        list: [
          'Les troubles de l’apprentissage, comme les difficultés de lecture.',
          'Les facteurs de stress, comme l’intimidation',
          'Les problèmes de santé mentale, comme l’anxiété ou la dépression',
        ],
        secondaryText: [
          'Si vous soupçonnez l’une de ces causes chez votre enfant, parlez-en à son enseignant ou à son prestataire de soins de santé.',
        ],
      },
      {
        title: [
          'Les enfants en début de scolarité devraient être capables d’utiliser des phrases simples, mais complètes, qui contiennent en moyenne 5 à 7 mots. Au fur et à mesure que l’enfant traverse les années d’école primaire, la grammaire et la prononciation deviennent normales. Les enfants utilisent des phrases plus complexes à mesure qu’ils grandissent.',
          'Les retards de langage peuvent être dus à des problèmes d’audition ou d’intelligence. En outre, les enfants qui ne sont pas en mesure de s’exprimer correctement peuvent être plus susceptibles d’avoir un comportement agressif ou de faire des crises de colère.',
          'Un enfant de 6 ans peut normalement suivre une série de 3 ordres consécutifs. À l’âge de 10 ans, la plupart des enfants peuvent suivre 5 ordres d’affilée. Les enfants qui ont un problème dans ce domaine peuvent essayer de le dissimuler en parlant à tort et à travers ou en faisant le clown. Ils demandent rarement de l’aide car ils ont peur d’être taquinés.',
        ],
      },
      {
        title: [
          'Comportement',
          'Les plaintes physiques fréquentes (comme les maux de gorge, les maux de ventre ou les douleurs dans les bras ou les jambes) peuvent être simplement dues à la conscience accrue du corps de l’enfant. Bien qu’il n’y ait souvent aucune preuve physique de ces plaintes, il convient de les examiner afin d’exclure d’éventuels problèmes de santé. Cela permettra également à l’enfant de savoir que ses parents se préoccupent de son bien-être.',
          'L’acceptation par les pairs devient plus importante pendant les années d’école. Les enfants peuvent adopter certains comportements pour faire partie du “groupe”. Le fait de parler de ces comportements avec votre enfant lui permettra de se sentir accepté dans le groupe, sans pour autant dépasser les limites des normes de comportement de la famille.',
          'À cet âge, les amitiés ont tendance à se nouer principalement avec des membres du même sexe. En fait, les jeunes enfants d’âge scolaire parlent souvent des membres du sexe opposé comme étant “étranges” ou “affreux”. Les enfants deviennent moins négatifs à l’égard du sexe opposé à mesure qu’ils se rapprochent de l’adolescence.',
          'Le mensonge, la tricherie et le vol sont autant d’exemples de comportements que les enfants d’âge scolaire peuvent “essayer” alors qu’ils apprennent à négocier les attentes et les règles que leur imposent la famille, les amis, l’école et la société. Les parents doivent gérer ces comportements en privé avec leur enfant (afin que les amis de l’enfant ne se moquent pas de lui). Les parents doivent faire preuve de pardon et punir d’une manière qui soit en rapport avec le comportement.',
          'Il est important que l’enfant apprenne à gérer l’échec ou la frustration sans perdre son estime de soi.',
        ],
      },
      {
        title: ['Pour les parents'],
        list: [
          'Si le développement physique de votre enfant semble hors norme, parlez-en à votre prestataire de soins.',
          'Si les compétences linguistiques semblent être en retard, demandez une évaluation de la parole et du langage.',
          'Restez en contact étroit avec les enseignants, les autres employés de l’école et les parents des amis de votre enfant afin d’être au courant des problèmes éventuels.',
          'Encouragez les enfants à s’exprimer ouvertement et à parler de leurs préoccupations sans craindre d’être punis.',
          'Les enfants d’aujourd’hui sont exposés, par le biais des médias et de leurs camarades, à de nombreux problèmes liés à la violence, à la sexualité et à la toxicomanie. Discutez ouvertement de ces questions avec vos enfants pour partager vos inquiétudes ou corriger les idées fausses. Vous devrez peut-être fixer des limites pour vous assurer que les enfants ne seront exposés à certains sujets que lorsqu’ils seront prêts.',
          'Les enfants d’âge scolaire devraient participer aux tâches familiales, comme mettre la table et nettoyer.',
        ],
      },
    ],
  },
  {
    age: ['12', '13', '14', '15', '16', '17', '18'],
    subtitle: 'Développement de l’adolescent 12 à 18 ans',
    section: [
      {
        title: [
          'Pendant l’adolescence, les enfants développent la capacité de :',
        ],
        list: [
          'Comprendre les idées abstraites. Il s’agit notamment de la compréhension des concepts mathématiques supérieurs et de l’élaboration de philosophies morales, y compris les droits et les privilèges.',
          'Établir et entretenir des relations satisfaisantes. Les adolescents apprennent à partager leur intimité sans se sentir inquiets ou inhibés.',
          'Évoluer vers un sens plus mature d’eux-mêmes et de leur but',
          'Remettre en question les anciennes valeurs sans perdre leur identité',
        ],
      },
      {
        title: [
          'Développement physique',
          'Au cours de l’adolescence, les jeunes subissent de nombreux changements qui les amènent à la maturité physique. Les changements précoces, prépubères, se produisent lorsque les caractéristiques sexuelles secondaires apparaissent.',
          'Filles :',
        ],
        list: [
          'Les filles peuvent commencer à développer des bourgeons mammaires dès l’âge de 8 ans. Les seins se développent complètement entre 12 et 18 ans.',
          'Les poils du pubis, des aisselles et des jambes commencent généralement à pousser vers l’âge de 9 ou 10 ans et atteignent leur forme adulte vers 13 ou 14 ans.',
          'Le début des règles survient généralement deux ans après l’apparition des premiers seins et des poils pubiens. Elle peut survenir dès l’âge de 9 ans ou jusqu’à l’âge de 16 ans. L’âge moyen des menstruations aux États-Unis est d’environ 12 ans.',
          'La poussée de croissance des filles atteint son maximum vers 11,5 ans et ralentit vers 16 ans.',
        ],
      },
      {
        title: ['Garçons :'],
        list: [
          'Les garçons peuvent commencer à remarquer que leurs testicules et leur scrotum grandissent dès l’âge de 9 ans. Très vite, le pénis commence à s’allonger. À 17 ou 18 ans, les organes génitaux ont généralement atteint leur taille et leur forme adultes.',
          'La croissance des poils pubiens, ainsi que des poils des aisselles, des jambes, de la poitrine et du visage, commence chez les garçons vers l’âge de 12 ans et atteint sa forme adulte vers 17 ou 18 ans.',
          'La puberté ne commence pas chez les garçons par un incident soudain, comme le début des menstruations chez les filles. Des émissions nocturnes régulières (rêves humides) marquent le début de la puberté chez les garçons. Les rêves humides commencent généralement entre 13 et 17 ans. L’âge moyen est d’environ 14 ans et demi.',
          'La voix des garçons change en même temps que le pénis grandit. Les émissions nocturnes se produisent au moment de la poussée de croissance.',
          'La poussée de croissance des garçons atteint son maximum vers l’âge de 13 ans et demi et ralentit vers l’âge de 18 ans.',
        ],
      },
      {
        title: [
          'Comportement',
          'Les changements physiques soudains et rapides que connaissent les adolescents les rendent très conscients d’eux-mêmes. Ils sont sensibles et s’inquiètent de leurs propres changements corporels. Ils peuvent faire des comparaisons douloureuses entre eux et leurs camarades.',
          'Les changements physiques ne se produisent pas toujours de façon régulière. Par conséquent, les adolescents peuvent passer par des stades gênants, tant au niveau de leur apparence que de leur coordination physique. Les filles peuvent être anxieuses si elles ne sont pas prêtes pour le début de leurs menstruations. Les garçons peuvent s’inquiéter s’ils ne connaissent pas les émissions nocturnes.',
          'Pendant l’adolescence, il est normal que les jeunes commencent à se séparer de leurs parents et à se forger leur propre identité. Dans certains cas, cela peut se faire sans problème de la part de leurs parents et des autres membres de la famille. Cependant, cela peut entraîner des conflits dans certaines familles, les parents essayant de garder le contrôle.',
          'Les amis prennent de l’importance à mesure que les adolescents s’éloignent de leurs parents dans leur quête d’une identité propre.',
        ],
        list: [
          'Leur groupe de pairs peut devenir un refuge sûr. Cela permet à l’adolescent de tester de nouvelles idées.',
          'Au début de l’adolescence, le groupe de pairs se compose le plus souvent d’amitiés non romantiques. Il s’agit souvent de “cliques”, de bandes ou de clubs. Les membres du groupe de pairs essaient souvent de se comporter et de s’habiller de la même façon, d’avoir des codes ou des rituels secrets et de participer aux mêmes activités.',
          'Lorsque le jeune arrive au milieu de l’adolescence (14 à 16 ans) et au-delà, le groupe de pairs s’élargit pour inclure des amitiés romantiques.',
        ],
      },
      {
        title: [
          'Au milieu ou à la fin de l’adolescence, les jeunes ressentent souvent le besoin d’établir leur identité sexuelle. Ils doivent se sentir à l’aise avec leur corps et leurs sentiments sexuels. Les adolescents apprennent à exprimer et à recevoir des avances intimes ou sexuelles. Les jeunes qui n’ont pas la chance de vivre de telles expériences peuvent avoir plus de mal à avoir des relations intimes à l’âge adulte.',
          'Les adolescents ont très souvent des comportements qui correspondent à plusieurs mythes de l’adolescence :',
        ],
        list: [
          'Le premier mythe est qu’ils sont “sur scène” et que l’attention des autres est constamment centrée sur leur apparence ou leurs actions. Il s’agit d’un égocentrisme normal. Cependant, il peut sembler (surtout aux adultes) à la limite de la paranoïa, de l’amour de soi (narcissisme), voire de l’hystérie.',
          'Un autre mythe de l’adolescence est l’idée que “ça ne m’arrivera jamais, seulement à l’autre personne”. “Cela” peut représenter le fait de tomber enceinte ou d’attraper une maladie sexuellement transmissible après avoir eu des rapports sexuels non protégés, de provoquer un accident de voiture en conduisant sous l’emprise de l’alcool ou de la drogue, ou l’un des nombreux autres effets négatifs des comportements à risque.',
        ],
      },
    ],
  },
];

/* ************************ */
/* English */

export const childDevelopmentEn = [
  {
    age: ['2'],
    subtitle: 'Next 6-12 months',
    section: [
      {
        title: ['Physical and motor skill markers:'],
        list: [
          'Able to turn a door knob',
          'Can look through a book turning one page at a time',
          'Can build a tower of 6 to 7 cubes',
          'Can kick a ball without losing balance',
          'Can pick up objects while standing, without losing balance(This often occurs by 15 months)',
          'Can run with better coordination (May still have a wide stance)',
          'May be ready for toilet training',
          'Should have the first 16 teeth, but the actual number of teeth can vary widely',
        ],
      },
      {
        title: ['Sensory and cognitive markers'],
        list: [
          'Able to put on simple clothes without help. (The child is often better at removing clothes than putting them on',
          'Able to communicate needs such as thirst, hunger, need to go to the bathroom',
          'Can organize phrases of 2 to 3 words',
          'Can understand 2-step command such as, Give me the ball and then get your shoes',
          'Has increased attention span',
          'Vision is fully developed',
          "Vocabulary has increased to about 50 to 300 words, but healthy children's vocabulary can vary widely",
        ],
      },
      {
        title: ['Play recommendations'],
        list: [
          'Allow the child to help around the house and take part in the daily family chores',
          'Encourage active play and provide enough space for healthy physical activity',
          'Encourage play that involves building and creativity',
          'Provide safe copies of adult tools and equipment. Many children like to mimic activities such as cutting the grass or sweeping the floor',
          'Read to the child',
          'Try to avoid television watching at this age',
          'Control the type of games the child plays',
        ],
      },
    ],
  },
  {
    age: ['3'],
    subtitle: 'Next 6-12 months',
    section: [
      {
        title: ['Physical and motor milestones :'],
        list: [
          'May have daytime control over bowel and bladder functions (may have nighttime control as well)',
          'Can briefly balance and hop on one foot',
          'May walk up stairs with alternating feet (without holding the rail)',
          'Can build a block tower of more than 9 cubes',
          'Can easily place small objects in a small opening',
          'Can copy a circle',
          'Can pedal a tricycle',
        ],
      },
      {
        title: ['Sensory, mental, and social milestones include'],
        list: [
          'Has a vocabulary of several hundred words',
          'Speaks in sentences of 3 words',
          'Often asks questions',
          'Can dress self, only needing help with shoelaces, buttons, and other fasteners in awkward places',
          'Acts out social encounters through play activities',
          'Fears imaginary things',
          'Has some cooperative play (building tower of blocks together)',
        ],
      },
      {
        title: ["Ways to encourage a 3-year-old's development"],
        list: [
          'Provide a safe play area and constant supervision',
          'Provide the necessary space for physical activity',
          'Help your child take part in -- and learn the rules of -- sports and games',
          'Visit local areas of interest',
          'Read together',
          'Encourage your child to help with small household chores, such as helping set the table or picking up toys',
          'Encourage your child to learn by answering their questions',
          'Encourage your child to use words to express feelings (rather than acting out)',
        ],
      },
    ],
  },
  {
    age: ['4'],
    subtitle: 'Next 6-12 months',
    section: [
      {
        title: [
          'Physical and motor; During the fourth year, a child typically : ',
        ],
        list: [
          'Sleeps 11 to 13 hours at night, most often without a daytime nap',
          'Shows improved balance',
          'Hops on one foot without losing balance',
          'Throws a ball overhand with coordination',
          'May still wet the bed',
        ],
      },
      {
        title: ['Sensory and cognitive markers; The typical 4-year-old : '],
        list: [
          'Has a vocabulary of more than 1,000 words',
          'Easily puts together sentences of 4 or 5 words',
          'May use words they do not fully understand',
          'Tries to be very independent',
          'Talks about personal family matters to others',
          'Commonly has imaginary playmates',
          'Has an increased understanding of time',
          'Is able to tell the difference between two objects, based on things like size and weight',
          'Lacks moral concepts of right and wrong',
          'Can rebel if too much is expected of them',
        ],
      },
      {
        title: ['Play : '],
        list: [
          'Encourage and provide space for physical activity',
          'Show your child how to participate in and follow the rules of sporting activities',
          'Encourage play and sharing with other children',
          'Teach your child to do small chores, such as setting the table',
          'Read together',
          'Expose your child to different stimuli by visiting local areas of interest',
          'Encourage creative play',
        ],
      },
    ],
  },
  {
    age: ['5'],
    subtitle: 'Next 6-12 months',
    section: [
      {
        title: [
          'Physical and motor skill milestones for a typical 5-year-old child include : ',
        ],
        list: [
          'First adult teeth start breaking through the gum (most children do not get their first adult teeth until age 6)',
          'Has better coordination (getting the arms, legs, and body to work together)',
          'Skips, jumps, and hops with good balance',
          'Stays balanced while standing on one foot with eyes closed',
          'Shows more skill with simple tools and writing utensils',
          'Can copy a triangle',
          'Can use a knife to spread soft foods',
        ],
      },
      {
        title: ['Sensory and mental milestones'],
        list: [
          'Has a vocabulary of more than 2,000 words',
          'Speaks in sentences of 5 or more words, and with all parts of speech',
          'Can identify different coins',
          'Can count to 10',
          'Can properly name the primary colors, and possibly many more colors',
          'Asks deeper questions that address meaning and purpose',
          'Is more responsible',
          'Outgrows earlier childhood fears',
          'Accepts other points of view (but may not understand them)',
          'Likes to imagine and pretend while playing (for example, pretends to take a trip to the moon)',
        ],
      },
      {
        title: ["Ways to encourage a 5-year-old's development include :"],
        list: [
          'Reading together',
          'Providing enough space for the child to be physically active',
          'Teaching the child how to take part in -- and learn the rules of -- sports and games',
          'Encouraging the child to play with other children, which helps develop social skills',
          'Playing creatively with the child',
        ],
      },
    ],
  },
  {
    age: ['6', '7', '8', '9', '10', '11'],
    subtitle: 'School-age children development',
    section: [
      {
        title: [
          'Physical development',
          'School-age children most often have smooth and strong motor skills. However, their coordination (especially eye-hand), endurance, balance, and physical abilities vary.',
          "Fine motor skills may also vary widely. These skills can affect a child's ability to write neatly, dress appropriately, and perform certain chores, such as making beds or doing dishes.",
          "There will be big differences in height, weight, and build among children of this age range. It is important to remember that genetic background, as well as nutrition and exercise, may affect a child's growth.",
          'A sense of body image begins developing around age 6. Sedentary habits in school-age children are linked to a risk for obesity and heart disease in adults. Children in this age group should get 1 hour of physical activity per day.',
          'There can also be a big difference in the age at which children begin to develop secondary sexual characteristics. For girls, secondary sex characteristics include:',
        ],
        list: ['Breast development', 'Underarm and pubic hair growth'],
      },
      {
        title: ['For boys, they include:'],
        list: [
          'Growth of underarm, chest, and pubic hair',
          'Growth of testicles and penis',
        ],
      },
      {
        title: [
          'School',
          'By age 5, most children are ready to start learning in a school setting. The first few years focus on learning the fundamentals.',
          'Over the time, the focus becomes more complex. Reading becomes more about the content than identifying letters and words.',
          'An ability to pay attention is important for success both at school and at home. A 6-year-old should be able to focus on a task for at least 15 minutes. By age 9, a child should be able to focus attention for about an hour.',
          'It is important for the child to learn how to deal with failure or frustration without losing self-esteem. There are many causes of school failure, including : ',
        ],
        list: [
          'Learning disabilities, such a reading disability',
          'Stressors, such as bullying',
          'Mental health issues, such as anxiety or depression',
        ],
        secondaryText: [
          "If you suspect any of these in your child, talk to your child's teacher or health care provider.",
        ],
      },
      {
        title: [
          'Early school-age children should be able to use simple, but complete, sentences that contain an average of 5 to 7 words. As the child goes through the elementary school years, grammar and pronunciation become normal. Children use more complex sentences as they grow.',
          'Language delays may be due to hearing or intelligence problems. In addition, children who are unable to express themselves well may be more likely to have aggressive behavior or temper tantrums.',
          'A 6-year-old child normally can follow a series of 3 commands in a row. By age 10, most children can follow 5 commands in a row. Children who have a problem in this area may try to cover it up with backtalk or clowning around. They will rarely ask for help because they are afraid of being teased.',
        ],
      },
      {
        title: [
          'Behavior',
          "Frequent physical complaints (such as sore throats, tummy aches, or arm or leg pain) may simply be due to a child's increased body awareness. Although there is often no physical evidence for such complaints, the complaints should be investigated to rule out possible health conditions. This will also assure the child that the parent is concerned about their well-being.",
          'Peer acceptance becomes more important during the school-age years. Children may take part in certain behaviors to be part of "the group." Talking about these behaviors with your child will allow the child to feel accepted in the group, without crossing the boundaries of the family\'s behavior standards.',
          'Friendships at this age tend to be mainly with members of the same sex. In fact, younger school-age children often talk about members of the opposite sex as being "strange" or "awful." Children become less negative about the opposite sex as they get closer to adolescence.',
          'Lying, cheating, and stealing are all examples of behaviors that school-age children may "try on" as they learn how to negotiate the expectations and rules placed on them by family, friends, school, and society. Parents should deal with these behaviors in private with their child (so that the child\'s friends don\'t tease them). Parents should show forgiveness, and punish in a way that is related to the behavior.',
          'It is important for the child to learn how to deal with failure or frustration without losing self-esteem.',
        ],
      },
      {
        title: ['Parenting tips'],
        list: [
          "If your child's physical development appears to be outside the norm, talk to your provider.",
          'If language skills appear to be lagging, request a speech and language evaluation.',
          "Keep close communication with teachers, other school employees, and parents of your child's friends so you are aware of possible problems.",
          'Encourage children to express themselves openly and talk about concerns without fear of punishment.',
          'Children today are exposed, through the media and their peers, to many issues dealing with violence, sexuality, and substance abuse. Discuss these issues openly with your children to share concerns or correct misconceptions. You may need to set limits to ensure children will be exposed to certain issues only when they are ready.',
          'School-age children should participate in family chores, such as setting the table and cleaning up.',
        ],
      },
    ],
  },
  {
    age: ['12', '13', '14', '15', '16', '17', '18'],
    subtitle: 'Adolescent development',
    section: [
      {
        title: ['During adolescence, children develop the ability to : '],
        list: [
          'Understand abstract ideas. These include grasping higher math concepts, and developing moral philosophies, including rights and privileges',
          'Establish and maintain satisfying relationships. Adolescents will learn to share intimacy without feeling worried or inhibited',
          'Move toward a more mature sense of themselves and their purpose',
          'Question old values without losing their identity',
        ],
      },
      {
        title: [
          'Physical development',
          'During adolescence, young people go through many changes as they move into physical maturity. Early, prepubescent changes occur when the secondary sexual characteristics appear',
          'Girls : ',
        ],
        list: [
          'Girls may begin to develop breast buds as early as 8 years old. Breasts develop fully between ages 12 and 18',
          'Pubic hair, armpit and leg hair usually begin to grow at about age 9 or 10, and reach adult patterns at about 13 to 14 years',
          'Menarche (the beginning of menstrual periods) typically occurs about 2 years after early breast and pubic hair appear. It may occur as early as age 9, or as late as age 16. The average age of menstruation in the United States is about 12 years',
          'Girls growth spurt peaks around age 11.5 and slows around age 16',
        ],
      },
      {
        title: ['Boys :'],
        list: [
          'Boys may begin to notice that their testicles and scrotum grow as early as age 9. Soon, the penis begins to lengthen. By age 17 or 18, their genitals are usually at their adult size and shape',
          'Pubic hair growth, as well as armpit, leg, chest, and facial hair, begins in boys at about age 12, and reaches adult patterns at about 17 to 18 years',
          'Boys do not start puberty with a sudden incident, like the beginning of menstrual periods in girls. Having regular nocturnal emissions (wet dreams) marks the beginning of puberty in boys. Wet dreams typically start between ages 13 and 17. The average age is about 14 and a half years',
          "Boys' voices change at the same time as the penis grows. Nocturnal emissions occur with the peak of the height spurt",
          "Boys' growth spurt peaks around age 13 and a half and slows around age 18",
        ],
      },
      {
        title: [
          'Behavior',
          'The sudden and rapid physical changes that adolescents go through make adolescents very self-conscious. They are sensitive, and worried about their own body changes. They may make painful comparisons about themselves with their peers.',
          'Physical changes may not occur in a smooth, regular schedule. Therefore, adolescents may go through awkward stages, both in their appearance and physical coordination. Girls may be anxious if they are not ready for the beginning of their menstrual periods. Boys may worry if they do not know about nocturnal emissions.',
          'During adolescence, it is normal for young people to begin to separate from their parents and make their own identity. In some cases, this may occur without a problem from their parents and other family members. However, this may lead to conflict in some families as the parents try to keep control.',
          'Friends become more important as adolescents pull away from their parents in a search for their own identity.',
        ],
        list: [
          'Their peer group may become a safe haven. This allows the adolescent to test new ideas.',
          'In early adolescence, the peer group most often consists of non-romantic friendships. These often include "cliques," gangs, or clubs. Members of the peer group often try to act alike, dress alike, have secret codes or rituals, and participate in the same activities.',
          'As the youth moves into mid-adolescence (14 to 16 years) and beyond, the peer group expands to include romantic friendships.',
        ],
      },
      {
        title: [
          'In mid- to late adolescence, young people often feel the need to establish their sexual identity. They need to become comfortable with their body and sexual feelings. Adolescents learn to express and receive intimate or sexual advances. Young people who do not have the chance for such experiences may have a harder time with intimate relationships when they are adults. ',
          'Adolescents very often have behaviors that are consistent with several myths of adolescence : ',
        ],
        list: [
          'The first myth is that they are "on stage" and other people\'s attention is constantly centered on their appearance or actions. This is normal self-centeredness. However, it may appear (especially to adults) to border on paranoia, self-love (narcissism), or even hysteria.',
          'Another myth of adolescence is the idea that "it will never happen to me, only the other person." "It" may represent becoming pregnant or catching a sexually-transmitted disease after having unprotected sex, causing a car crash while driving under the influence of alcohol or drugs, or any of the many other negative effects of risk-taking behaviors.',
        ],
      },
    ],
  },
];
