import React, { useEffect } from 'react';
import styled from 'styled-components';
// import firebase from 'firebase/app';
import 'firebase/auth';
import { logout } from '../utils/auth';
import { Heading2 } from '../theme/themeComponents/fonts';
import { Loader } from '../components/Loader/Loader';

const Unauthorized = () => {
  useEffect(() => {
    // firebase.auth().signOut();
    setTimeout(() => {
      logout(process.env.REACT_APP_ADMIN_URL);
      // window.location.replace(`${process.env.REACT_APP_ADMIN_URL}`);
    }, 4000);
  }, []);
  return (
    <Container>
      <Title>
        Vous n{"'"}avez pas les autorisations pour vous connecter sur cette
        page.
        <br />
        <br />
        Vous allez être redirigé vers la bonne page.
      </Title>

      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  ${Heading2}
  text-align: center;
`;

const LoaderContainer = styled.div`
  margin-top: 50px;
`;

export default Unauthorized;
