import React from 'react';
import styled from 'styled-components';
interface CustomButtonProps {
  content: string;
  type?: 'button' | 'submit' | 'reset';
  handleOnClick?: () => void;
  disabled?: boolean;
  size?: 'sm' | 'lg';
  backgroundColor?: string;
  borderRadius?: string;
  borderColor?: string;
  textColor?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  content,
  type,
  handleOnClick,
  disabled,
  size,
  backgroundColor,
  borderRadius,
  borderColor,
  textColor,
}) => {
  return (
    <ButtonStyled
      type={type}
      onClick={handleOnClick}
      disabled={disabled}
      size={size ? size : 'lg'}
      borderradius={borderRadius}
      backgroundcolor={backgroundColor}
      textcolor={textColor}
      bordercolor={borderColor}
    >
      {content}
    </ButtonStyled>
  );
};

export default CustomButton;

const ButtonStyled = styled.button<{
  bordercolor?: string;
  borderradius?: string;
  textcolor?: string;
  size?: 'sm' | 'lg';
  backgroundcolor?: string;
  disabled?: boolean;
}>`
  border: none;
  background: ${(props) =>
    props.backgroundcolor ||
    'linear-gradient(135.02deg, #095c66 0%, #209aa2 100%)'} !important;
  border-color: ${(props) =>
    props.bordercolor ? props.bordercolor : props.theme.colors.darkGreenL4};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : '6px'};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) =>
    props.textcolor ? props.textcolor : props.theme.colors.white};
  font-size: 14px;
  font-weight: 700;
  padding: ${({ size }) => (size === 'sm' ? '7px 15px' : '15px 42px')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
