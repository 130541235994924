import styled, { css } from 'styled-components';
import { BodyLarge } from './fonts';

export const TitleTypo = styled.p`
  font-size: 2.2rem;
  font-weight: 700;
`;

export const SubtitleTypo = styled.p`
  color: ${(props) => props.theme.colors.subtitle};
  font-style: ${(props) => props.theme.fonts.style.subtitle};
  font-size: 1.3rem;
  font-weight: 500;
`;

export const LabelTypo = styled.label`
  font-weight: ${(props) => props.theme.fonts.weight.label};
`;

export const ErrorMessageTypo = styled.div`
  ${BodyLarge}
  color: ${(props) => props.theme.colors.redMain};
  margin-top: 5px;
`;

export const SuccessMessageTypo = styled.p`
  color: ${(props) => props.theme.colors.success};
  font-size: ${(props) => props.theme.fonts.size.success};
`;

export const TextButtonTypo = css`
  color: ${(props) => props.theme.colors.textBtn};
  font-size: 14px;
`;

export const DialogContentTypo = styled.p`
  font-size: 1.7rem;
  padding: 2%;
  white-space: pre-wrap;
`;

export const DialogTitleTypo = styled.h1`
  align-self: flex-end;
`;

export const QuestionAnnotationTypo = styled.h2`
  color: #606060;
`;

export const CurrQuestionNbTypo = styled.span`
  color: ${(props) => props.theme.colors.timelineActive};
  font-size: 2rem;
`;

export const RadioBtnTypo = styled.p`
  font-size: 1.6rem;
  font-weight: 500 !important;
  padding-left: 1rem;
  margin: 0;
`;
