/* eslint-disable camelcase */
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COOKIES } from '../../constants/cookies_type';
import { SURVEY_TYPE_VALUE } from '../../constants/db_types';
import useForm from '../../lib/useForm';
import { Heading1 } from '../../theme/themeComponents/fonts';
import ApplicationContext from '../../utils/context';
import { createSurveyNotification } from '../../utils/survey';
import ChildMoodTest from '../Survey/ChildMoodTest/ChildMoodTest';
import ParentMoodTest from '../Survey/ParentMoodTest/ParentMoodTest';
import AnxietyTestForm from './AnxietyTestForm';
import CovidCheckerForm from './CovidCheckerForm';
import ParentAnxietyTestForm from './ParentAnxietyTestForm';
import SurveyFormComponent from './SurveyFormComponent';
import Symptomatology from './Symptomatology';
import validateSurveyForm from './validateSurveyForm';

/**
 * //TODO we have to refacto this file and the useForm file.
 * It became quiet messy
 * We have also to delete / replade all material UI dependencies
 **/

const addSurveyQuery = loader('../../graphql/addSurveyResultLinkedToSurgery.graphql');
const addSurveyResultQuery = loader('../../graphql/addSurveyResultDetailsLinkedToSurgery.graphql');

const SurveyForm = ({ questions, surveyCreationData, surveyType }) => {
  const { values, setValues, handleSubmit, handleAnswerRadioButtonChange } =
    useForm(createSurvey, validateSurveyForm, questions);

  const [addSurvey] = useMutation(addSurveyQuery);
  const [addSurveyResult] = useMutation(addSurveyResultQuery);

  const { customModalCxt } = useContext(ApplicationContext);
  const [state, setState] = useState({ surveyCreated: false });

  /* values initialization */
  useEffect(() => {
    if (values === {}) {
      setValues(Object.assign({}, values, { questions }));
    }
  }, []);

  useEffect(() => {
    /**
     * Get surveys answers from the local storage if they are set
     */
    const surgeryId = new URLSearchParams(window.location.search).get('sid');
    let localStorageValues = null;

    if (surveyType && surveyType.value === 'phbq') {
      localStorageValues = localStorage.getItem('phbqAnswers' + surgeryId);
    } else if (surveyType && surveyType.value === 'modified-dental-anxiety') {
      localStorageValues = localStorage.getItem(
        'modifiedDentalAnxietyAnswers' + surgeryId
      );
    } else if (surveyType && surveyType.value === 'dental-first-survey') {
      localStorageValues = localStorage.getItem(
        'dentalFirstSurveyAnswers' + surgeryId
      );
    } else if (surveyType && surveyType.value === 'stai-now') {
      localStorageValues = localStorage.getItem('staiNowAnswers' + surgeryId);
    } else if (surveyType && surveyType.value === 'stai-global') {
      localStorageValues = localStorage.getItem(
        'staiGlobalAnswers' + surgeryId
      );
    }
    if (localStorageValues) {
      const parsedLocalStorageValues = JSON.parse(localStorageValues);
      setValues(parsedLocalStorageValues);
    }
  }, []);

  function createSurveyResult(survey) {
    // eslint-disable-next-line array-callback-return
    const promises = values.questions.map((question) => {
      const { id, answer_id } = question;
      const surveyResultVariables = {
        survey_id: survey.id,
        type: surveyType.id,
        question_id: id,
        answer_id,
      };
      if (id && answer_id) {
        return new Promise((resolve, reject) => {
          addSurveyResult({
            variables: surveyResultVariables,
          })
            .then(async (...args) => {
              const [
                {
                  data: {
                    insert_survey_result_details_linked_to_surgery: {
                      returning: survey_result,
                    },
                  },
                },
              ] = args;
              // eslint-disable-next-line no-console
              console.info(
                'Survey Result created',
                JSON.stringify(survey_result, null, 2)
              );
              if (surveyType.value === SURVEY_TYPE_VALUE.ANXIETY_EVALUATION) {
                customModalCxt?.setShowCustomModalChildSurgeries(false);
              }
              resolve(true);
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error('Invalid input:', surveyResultVariables);
              // eslint-disable-next-line no-console
              console.error(e);
              reject();
              Sentry.captureException(e);
            });
        });
      }
    });

    Promise.all(promises).then(() => {
      /* if the survey type value is COVID_CHECKER */
      if (surveyType.value === SURVEY_TYPE_VALUE.COVID_CHECKER) {
        createSurveyNotification(survey.id);
      }
    });
  }

  function createSurvey() {
    const today = moment().format('YYYY-MM-DD');
    const surveyVariables = Object.assign({}, surveyCreationData, {
      type: surveyType.id,
      name: `${today}_${cookie.get(COOKIES.EMAIL)}`,
    });
    addSurvey({
      variables: surveyVariables,
    })
      .then((...args) => {
        const [
          {
            data: {
              insert_survey_result_linked_to_surgery: {
                returning: surveyCreated,
              },
            },
          },
        ] = args;
        console.info(
          'Survey created',
          JSON.stringify(surveyCreated[0], null, 2)
        );
        createSurveyResult(surveyCreated[0]);

        setState({ surveyCreated: true });
      })
      .catch((e) => {
        console.error('Invalid input:', surveyVariables);
        console.error(e);
        Sentry.captureException(e);
      });
  }

  if (
    state.surveyCreated === false &&
    surveyType &&
    surveyType.value === SURVEY_TYPE_VALUE.ANXIETY_EVALUATION
  ) {
    return (
      <>
        {
          <AnxietyTestForm
            handleSubmit={handleSubmit}
            questions={questions}
            handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
          />
        }
      </>
    );
  }
  if (
    state.surveyCreated === false &&
    surveyType &&
    (surveyType.value === SURVEY_TYPE_VALUE.PARENT_ANXIETY_EVALUATION ||
      surveyType.value === SURVEY_TYPE_VALUE.PAIN_EVALUATION)
  ) {
    return (
      <>
        {
          <ParentAnxietyTestForm
            handleSubmit={handleSubmit}
            questions={questions}
            handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
          />
        }
      </>
    );
  }
  if (
    state.surveyCreated === false &&
    surveyType &&
    surveyType.value === SURVEY_TYPE_VALUE.SYMPTOMATOLOGY
  ) {
    return (
      <>
        {
          <Symptomatology
            handleSubmit={handleSubmit}
            questions={questions}
            handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
          />
        }
      </>
    );
  }
  if (
    state.surveyCreated === false &&
    surveyType &&
    (surveyType.value === SURVEY_TYPE_VALUE.PHBQ_EVALUATION ||
      surveyType.value === SURVEY_TYPE_VALUE.MODIFIED_DENTAL_ANXIETY ||
      surveyType.value === SURVEY_TYPE_VALUE.DENTAL_FIRST_SURVEY ||
      surveyType.value === SURVEY_TYPE_VALUE.STAI_NOW ||
      surveyType.value === SURVEY_TYPE_VALUE.STAI_GLOBAL)
  ) {
    return (
      <>
        {
          <SurveyFormComponent
            handleSubmit={handleSubmit}
            questions={questions}
            handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
            values={values}
          />
        }
      </>
    );
  }

  /* Temporarily fix  for the child mood test because useForm must be deleted soon because not sclalable */
  if (
    state.surveyCreated === false &&
    surveyType &&
    surveyType.value === SURVEY_TYPE_VALUE.CHILD_MOOD_EVALUATION
  ) {
    return (
      <ChildMoodTest
        questions={questions}
        surveyCreationData={surveyCreationData}
        surveyType={surveyType}
      />
    );
  }

  if (
    state.surveyCreated === false &&
    surveyType &&
    surveyType.value === SURVEY_TYPE_VALUE.PARENT_MOOD_EVALUATION
  ) {
    return <ParentMoodTest questions={questions} surveyType={surveyType} />;
  }

  if (
    state.surveyCreated === false &&
    surveyType &&
    surveyType.value === SURVEY_TYPE_VALUE.COVID_CHECKER
  ) {
    return (
      <CovidCheckerForm
        handleSubmit={handleSubmit}
        questions={questions}
        handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
      />
    );
  }

  return (
    <SuccessContainer>
      <SuccessText>
        Nous avons bien enregistré vos réponses. Elles sont transmises à
        l’équipe médicale. Un professionnel de santé prendra contact avec vous,
        si nécessaire, après avoir vu vos réponses.
      </SuccessText>
    </SuccessContainer>
  );
};

const SuccessContainer = styled.div``;

const SuccessText = styled.div`
  ${Heading1};
`;

SurveyForm.propTypes = {
  questions: PropTypes.array.isRequired,
  surveyCreationData: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default SurveyForm;
