import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GlobalStyle, StorySequenceStyled, Background } from './styles';
import RightText from './RightText';
import LeftText from './LeftText';
import BottomText from './BottomText';
import NextArrow from './NextArrow';
import PreviousArrow from './PreviousArrow';
import Professor from './Professor';
import HomeArrow from './HomeArrow';
import EndArrow from './EndArrow';
import ImageNextArrow from '../../theme/images/stories/next_arrow.png';
import ImagePreviousArrow from '../../theme/images/stories/previous_arrow.png';
import ImageHomeArrow from '../../theme/images/stories/home_arrow.png';
import ImageEndArrow from '../../theme/images/stories/end_arrow.png';
import ImageProfessor from '../../theme/images/stories/professor.png';

const StorySequence = ({
  actualPart,
  bottomText,
  forceShowFullImage,
  leftText,
  nextPart,
  previousPart,
  professor,
  professorText,
  rightText,
  storyImageNumber,
  surgeryIdx,
  childIdx,
}) => {
  const [imageBackgroundUrl, setImageBackgroundUrl] = useState(null);
  useEffect(() => {
    if (storyImageNumber) {
      import(`../../theme/images/stories/${storyImageNumber}.jpg`).then(
        (image) => {
          setImageBackgroundUrl(image.default);
        }
      );
    }
  }, [storyImageNumber]);
  return (
    <>
      {imageBackgroundUrl && (
        <StorySequenceStyled>
          <GlobalStyle />
          <Background
            forceShowFullImage={forceShowFullImage}
            storyImageNumber={storyImageNumber}
            style={{ backgroundImage: 'url(' + imageBackgroundUrl + ')' }}
          />

          {bottomText && (
            <BottomText bottomText={bottomText} professor={professor} />
          )}
          {leftText && <LeftText leftText={leftText} />}
          {nextPart ? (
            <NextArrow
              nextPart={actualPart + 1}
              image={ImageNextArrow}
              surgeryIdx={surgeryIdx}
              childIdx={childIdx}
            />
          ) : (
            <EndArrow
              image={ImageEndArrow}
              surgeryIdx={surgeryIdx}
              childIdx={childIdx}
            />
          )}
          {previousPart ? (
            <PreviousArrow
              previousPart={actualPart - 1}
              image={ImagePreviousArrow}
              surgeryIdx={surgeryIdx}
              childIdx={childIdx}
            />
          ) : (
            <HomeArrow
              image={ImageHomeArrow}
              surgeryIdx={surgeryIdx}
              childIdx={childIdx}
            />
          )}
          {professor && (
            <Professor professorText={professorText} image={ImageProfessor} />
          )}
          {rightText && <RightText rightText={rightText} />}
        </StorySequenceStyled>
      )}
    </>
  );
};

StorySequence.defaultProps = {
  actualPart: null,
  bottomText: null,
  forceShowFullImage: false,
  leftText: null,
  nextPart: null,
  previousPart: null,
  professor: false,
  professorText: null,
  rightText: '',
  storyImageNumber: null,
};

StorySequence.propTypes = {
  actualPart: PropTypes.number,
  bottomText: PropTypes.string,
  forceShowFullImage: PropTypes.bool,
  leftText: PropTypes.string,
  nextPart: PropTypes.number,
  previousPart: PropTypes.number,
  professor: PropTypes.bool,
  // professorText: PropTypes.string,
  rightText: PropTypes.string,
  storyImageNumber: PropTypes.string,
};

export default StorySequence;
