import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

interface FlexProps {
  alignItems?: "center" | "flex-end" | "flex-start" | "stretch";
  children?: React.ReactNode;
  direction?: "row" | "column";
  justifyContent?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-between";
}

const Flex: React.FunctionComponent<FlexProps> = ({
  alignItems,
  children,
  direction,
  justifyContent,
}) => (
  <Layout
    alignItems={alignItems ? alignItems : ""}
    direction={direction ? direction : ""}
    justifyContent={justifyContent ? justifyContent : ""}
  >
    {children}
  </Layout>
);

const Layout = styled.div<{
  alignItems: string;
  direction: string;
  justifyContent: string;
}>`
  align-items: ${(props) => props.alignItems};
  display: flex;
  flex: 1;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justifyContent};
`;

Flex.defaultProps = {
  alignItems: "stretch",
  direction: "row",
  justifyContent: "flex-start",
};

export default Flex;
