import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Heading2, Heading3 } from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';
import { theme } from '../../theme/index';

interface CustomModalProps {
  showModal: boolean;
  handleCloseModal?: () => void;
  buttonContent?: string;
  textContent?: string;
  component?: React.ReactNode;
  backgroundColor?: string;
  shouldCloseOnOverlayClick?: boolean;
  borderRadius?: number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  showModal,
  handleCloseModal,
  buttonContent,
  textContent,
  component,
  backgroundColor,
  shouldCloseOnOverlayClick,
  borderRadius,
}) => {
  const [customStyle, setCustomStyle] = useState<any>();

  useEffect(() => {
    setCustomStyle({
      overlay: {
        zIndex: 99999999,
        background: `rgba(0, 0, 0, 0.6)`,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '100%',
        maxWidth: '1200px',
        borderRadius: borderRadius ? `${borderRadius}px` : '72px',
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.colors.lightGreenL2,
      },
    });
  }, []);

  return (
    <Modal
      isOpen={showModal}
      style={customStyle}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={() =>
        shouldCloseOnOverlayClick && handleCloseModal
          ? handleCloseModal()
          : null
      }
    >
      <ModalContent>
        <MainContainer>
          {textContent && <Text>{textContent}</Text>}
          {component && <Container>{component}</Container>}
        </MainContainer>
      </ModalContent>
      {buttonContent && (
        <ModalButtonContainer>
          <CustomButton
            handleOnClick={handleCloseModal}
            content={buttonContent}
          >
            {buttonContent}
          </CustomButton>
        </ModalButtonContainer>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Text = styled.div`
  white-space: pre-wrap;
  padding: 10px;
  ${Heading3}
`;
const Container = styled.div``;

export default CustomModal;
