import React from 'react';
import styled from 'styled-components';

interface QuestionAnnotationProps {
  currQuestion: any;
  totalQuestions: string;
}

const QuestionAnnotation: React.FunctionComponent<QuestionAnnotationProps> = ({
  currQuestion,
  totalQuestions,
}) => {
  return (
    <QuestionAnnotationContainer>
      {currQuestion}/{totalQuestions}
    </QuestionAnnotationContainer>
  );
};

const QuestionAnnotationContainer = styled.h2`
  font-weight: bold;
`;

export default QuestionAnnotation;
