import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import logo from '../../theme/images/logo/logo.png';
import { Heading4 } from '../../theme/themeComponents/fonts';
import { logout } from '../../utils/auth';
import { ReactComponent as IconActivities } from '../../theme/icons/activities.svg';
import { ReactComponent as IconHome } from '../../theme/icons/home.svg';
// import { ReactComponent as IconMedicalAppointment } from '../../theme/icons/medicalAppointment.svg';
import { ReactComponent as IconEmotionalHealth } from '../../theme/icons/emotionalHealth.svg';
import { ReactComponent as IconChildDevelopment } from '../../theme/icons/childDevelopment.svg';
import { ReactComponent as IconStories } from '../../theme/icons/stories.svg';
import { ReactComponent as IconProfile } from '../../theme/icons/profile.svg';
import { ReactComponent as IconLogout } from '../../theme/icons/logout.svg';
import { ReactComponent as IconSettings } from '../../theme/icons/settings.svg';
import { ReactComponent as IconGames } from '../../theme/icons/games.svg';
import { ReactComponent as IconArticles } from '../../theme/icons/articles.svg';
import { ReactComponent as IconActivitiesSelected } from '../../theme/icons/activitiesSelected.svg';
import { ReactComponent as IconHomeSelected } from '../../theme/icons/homeSelected.svg';
// import { ReactComponent as IconMedicalAppointmentSelected } from '../../theme/icons/medicalAppointmentSelected.svg';
import { ReactComponent as IconEmotionalHealthSelected } from '../../theme/icons/emotionalHealthSelected.svg';
import { ReactComponent as IconChildDevelopmentSelected } from '../../theme/icons/childDevelopmentSelected.svg';
import { ReactComponent as IconStoriesSelected } from '../../theme/icons/storiesSelected.svg';
import { ReactComponent as IconGamesSelected } from '../../theme/icons/gamesSelected.svg';
import { ReactComponent as IconArticlesSelected } from '../../theme/icons/articlesSelected.svg';
import Dropdown from '../Dropdown/Dropdown';
/* TODO delete after refacto */
interface SidebarProps {
  currentPath: string;
  surgeryIdx: string;
  childIdx: string;
  childHasSurgery: boolean;
}

const SIDEBAR_CATEGORIES = {
  SURGERIES: 'surgeries',
  EMOTIONAL_HEALTH: 'emotional-health',
};

const dropdownOptions = [
  { value: SIDEBAR_CATEGORIES.SURGERIES, label: 'Mes Rendez-vous médicaux' },
  { value: SIDEBAR_CATEGORIES.EMOTIONAL_HEALTH, label: 'Santé émotionnelle' },
];

const SideMenuList = (
  currentPath: string,
  surgeryIdx: string,
  childIdx: string,
  childHasSurgery: boolean,
  sidebarCategory: string
) => {
  const { t } = useTranslation();
  const pathRoot = currentPath.split('/')?.[1];
  if (pathRoot === 'parent') {
    if (sidebarCategory === SIDEBAR_CATEGORIES.SURGERIES) {
      return [];
    } else if (sidebarCategory === SIDEBAR_CATEGORIES.EMOTIONAL_HEALTH) {
      return [
        {
          text: 'Accueil', //TODO translation
          icon: <IconHome />,
          iconIsActive: (
            <IconHomeSelected style={{ width: '66px', height: '53px' }} />
          ),
          link: `/parent/emotional-health/home`,
          isActive: currentPath.startsWith('/parent/emotional-health/home'),
          dataTutorial: 'reactour__sidebar__home-parent',
        },
        {
          text: 'Suivi émotionel', //TODO translation
          icon: <IconEmotionalHealth />,
          iconIsActive: (
            <IconEmotionalHealthSelected
              style={{ width: '52px', height: '52px' }}
            />
          ),
          link: '/parent/emotional-health/my-space',
          isActive: currentPath.startsWith('/parent/emotional-health/my-space'),
          dataTutorial: 'reactour__sidebar__my-space-parent',
        },
        {
          text: "Évolution de l'enfant", //TODO translation
          icon: <IconChildDevelopment />,
          iconIsActive: (
            <IconChildDevelopmentSelected
              style={{ width: '52px', height: '52px' }}
            />
          ),
          link: '/parent/emotional-health/child-development',
          isActive: currentPath.startsWith(
            '/parent/emotional-health/child-development'
          ),
          dataTutorial: 'reactour__sidebar__child-development-parent',
        },
        {
          text: 'Articles',
          icon: <IconArticles />,
          iconIsActive: (
            <IconArticlesSelected style={{ width: '52px', height: '52px' }} />
          ),
          link: '/parent/emotional-health/articles',
          isActive: currentPath.startsWith('/parent/emotional-health/articles'),
          dataTutorial: 'reactour__sidebar__articles-parent',
        },
      ];
    } else {
      return [];
    }
  } else if (pathRoot === 'children') {
    if (sidebarCategory === SIDEBAR_CATEGORIES.SURGERIES) {
      return [
        {
          text: t('titles_and_subtitles:storyTitle'),
          icon: <IconStories />,
          iconIsActive: (
            <IconStoriesSelected style={{ width: '49px', height: '63px' }} />
          ),
          link: `/children/story?cidx=${childIdx}`,
          isActive: currentPath.startsWith('/children/story'),
          disabled: !childHasSurgery,
        },
      ];
    } else if (sidebarCategory === SIDEBAR_CATEGORIES.EMOTIONAL_HEALTH) {
      return [
        {
          text: 'Accueil', //TODO translation
          icon: <IconHome />,
          iconIsActive: (
            <IconHomeSelected style={{ width: '66px', height: '53px' }} />
          ),
          link: `/children/home?cidx=${childIdx}`,
          isActive: currentPath.startsWith('/children/home'),
        },

        {
          text: 'Activités', //TODO translation
          icon: <IconActivities style={{ width: '58px', height: '58px' }} />,
          iconIsActive: <IconActivitiesSelected />,
          link: `/children/activities?cidx=${childIdx}`,
          isActive: currentPath.startsWith('/children/activities'),
        },
        {
          text: 'Jeux', //TODO translation
          icon: <IconGames />,
          iconIsActive: <IconGamesSelected />,
          link: `/children/games?cidx=${childIdx}`,
          isActive: currentPath.startsWith('/children/games'),
        },
      ];
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  surgeryIdx,
  currentPath,
  childIdx,
  childHasSurgery,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [currentSidebarCategory, setCurrentSidebarCategory] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: '' });

  useEffect(() => {
    if (
      currentPath.startsWith('/parent/surgeries') ||
      currentPath.startsWith('/children/story')
    ) {
      setCurrentSidebarCategory(dropdownOptions[0]);
    } else if (
      currentPath.startsWith('/parent/emotional-health') ||
      currentPath.startsWith('/children')
    ) {
      setCurrentSidebarCategory(dropdownOptions[1]);
    }
  }, []);

  const handleOnChangeSidebarCategory = (data: {
    value: string;
    label: string;
  }) => {
    if (data.value === SIDEBAR_CATEGORIES.SURGERIES) {
      if (currentPath.startsWith('/parent')) {
        history.push('/parent/surgeries');
      } else if (currentPath.startsWith('/children')) {
        history.push(`/children/story?cidx=${childIdx}`);
      }
    } else if (data.value === SIDEBAR_CATEGORIES.EMOTIONAL_HEALTH) {
      if (currentPath.startsWith('/parent')) {
        history.push('/parent/emotional-health/home');
      } else if (currentPath.startsWith('/children')) {
        history.push(`/children/home?cidx=${childIdx}`);
      }
    }
    setCurrentSidebarCategory(data);
  };

  return (
    <Container>
      <LogoContainer onClick={() => history.push('/welcome')}>
        <img src={logo} style={{ width: '93px', height: '108px' }} />
      </LogoContainer>
      <DropdownContainer data-tut="reactour__sidebar__dropdown-title">
        {currentSidebarCategory.value && (
          <Dropdown
            options={dropdownOptions}
            defaultValue={currentSidebarCategory}
            handleOnChange={(data) => handleOnChangeSidebarCategory(data)}
            dropdownIndicatorColor="#FFF"
            singleValueColor="#FFF"
          />
        )}
      </DropdownContainer>
      <ItemsContainer>
        <ItemsMiddleContainer>
          {SideMenuList(
            currentPath,
            surgeryIdx,
            childIdx,
            childHasSurgery,
            currentSidebarCategory.value
          ).map((item: any, i: number) => {
            if (item.disabled) return null;
            return (
              <ItemLink
                key={i}
                onClick={() => history.push(item.link)}
                data-tut={item.dataTutorial}
              >
                {/* <ItemLinkIcon>{item.icon}</ItemLinkIcon> */}
                <ItemLinkIcon>
                  {item.isActive ? item.iconIsActive : item.icon}
                </ItemLinkIcon>
                <ItemLinkText isActive={item.isActive}>
                  {item.text}
                </ItemLinkText>
                <ItemLinkBackground isActive={item.isActive} />
              </ItemLink>
            );
          })}
        </ItemsMiddleContainer>
        <ItemsEndContainer>
          <ItemEndLink
            onClick={() => {
              history.push('/welcome');
            }}
          >
            <ItemEndLinkIcon>
              {<IconProfile style={{ width: '24px', height: '24px' }} />}
            </ItemEndLinkIcon>
            <ItemEndLinkText>{t('welcome:changeProfile')}</ItemEndLinkText>
          </ItemEndLink>
          <ItemEndLink onClick={() => history.push('/settings')}>
            <ItemEndLinkIcon2>
              {<IconSettings style={{ width: '22px', height: '22px' }} />}
            </ItemEndLinkIcon2>
            {/* quick dirty fix because svg stroke */}
            <ItemEndLinkText>Paramètres</ItemEndLinkText>
          </ItemEndLink>
          <ItemEndLink onClick={() => logout()}>
            <ItemEndLinkIcon>
              {<IconLogout style={{ width: '24px', height: '19px' }} />}
            </ItemEndLinkIcon>
            <ItemEndLinkText>{t('common:logoutButton')}</ItemEndLinkText>
          </ItemEndLink>
        </ItemsEndContainer>
      </ItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100% !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`;

const LogoContainer = styled.div`
  width: 130px;
  /* min-height: 200px; */
  height: 200px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid white;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const ItemsContainer = styled.div`
  width: 100%;
  /* height: calc(100% - 200px - 2px); */
  /* background-color: pink; */
  height: calc(100% - 200px - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    height: calc(100% - 60px - 60px);
  }
`;

const ItemsMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: orange; */
  max-height: calc(100vh - 130px);
  overflow-y: auto;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    max-height: calc(100%);
  }
`;

const ItemsEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 5px 5px 5px;
  height: 160px;
  /* background-color: red; */
`;

const activeItemLink = css`
  cursor: pointer;
  background: rgba(189, 210, 213, 0.69);
  opacity: 0.5;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  color: ${(props) => props.theme.colors.white} !important;
  border-radius: 36px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    border-radius: 0px;
  }
`;

const ItemLink = styled.div`
  width: 162px;
  height: 129px;

  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  position: relative;
  cursor: pointer;
  padding: 0px 6px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    height: 65px;
    align-items: initial;
    padding: 0px;
  }
`;

const ItemLinkIcon = styled.div`
  z-index: 10;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }

  & > svg > * {
    stroke: #fff;
  }
`;

const ItemLinkText = styled.div<{ isActive: boolean }>`
  margin-top: 10px;
  text-align: center;
  z-index: 10;
  font-weight: ${(props) => (props.isActive ? 'bold' : '')};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
    padding-left: 10px;
    text-align: left;
  }
`;

const ItemEndLink = styled.div`
  :hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  margin: 10px 5px;
  color: ${(props) => props.theme.colors.white};
  ${Heading4};
`;

const ItemEndLinkIcon = styled.div`
  margin-right: 15px;
  & > svg > * {
    fill: #fff;
  }
`;

const ItemEndLinkIcon2 = styled.div`
  margin-right: 15px;
  & > svg > * {
    stroke: #fff;
  }
`;

const ItemEndLinkText = styled.div``;

const ItemLinkBackground = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${(props) => (props.isActive ? activeItemLink : null)}
`;

const DropdownContainer = styled.div`
  width: 90%;
  color: ${(props) => props.theme.colors.white};
  height: 60px !important;
  z-index: 100;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 60px;
  }
`;

export default Sidebar;
