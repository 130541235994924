import styled, { css } from 'styled-components';
import { Card } from '@material-ui/core';

//Border
const BorderRadiusBig = css`
  border-radius: 3rem !important;
`;

//Flex
const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

//Size
const SizeMedium = css`
  width: ${(props) => props.theme.size.medium};
  max-width: ${(props) => props.theme.size.maxWidth.resetPasswordCard};
  height: ${(props) => props.theme.size.large};
`;

const SizeLarge = css`
  width: ${(props) => props.theme.size.large};
  max-width: ${(props) => props.theme.size.maxWidth.authCard};
  height: ${(props) => props.theme.size.large};
`;

const SizeMobile = css`
  width: ${(props) => props.theme.size.width.mobile};
  height: ${(props) => props.theme.size.height.mobile};
`;

const SizeTablet = css`
  width: ${(props) => props.theme.size.width.tablet};
  height: ${(props) => props.theme.size.height.tablet};
`;

const SizeDesktop = css`
  width: ${(props) => props.theme.size.width.desktop};
  height: ${(props) => props.theme.size.height.desktop};
`;

export const AuthCard = styled(Card)`
  ${FlexRow}
  ${BorderRadiusBig}
  ${SizeDesktop}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.tabletMax}) {
    /* ${SizeTablet} */
    height: 700px;
    width: 80%;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${SizeMobile}
    border-radius: 10px !important;
  }
`;

export const ResetPasswordCard = styled(Card)`
  ${FlexColumn}
  ${BorderRadiusBig}
  ${SizeTablet}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.mobilePortraitMax}) {
    ${SizeMobile}
  }
`;
