import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { BorderLightGrey } from './borderComponents';
import { TextButtonTypo } from './typoComponents';
import Help from '@material-ui/icons/Help';

//TODO refacto Etienne s work
//Color
const BlueBtn = css`
  &.MuiButtonBase-root {
    color: ${(props) => props.theme.colors.submitBtn};
    background: ${(props) => props.theme.colors.background.blueBtn};
  }
`;

const BluePastelBtn = css`
  &.MuiButtonBase-root {
    color: ${(props) => props.theme.colors.submitBtn};
    background: ${(props) => props.theme.colors.background.prevNextBtns};
  }
`;

const GreenBtn = css`
  &.MuiButtonBase-root {
    color: ${(props) => props.theme.colors.submitBtn};
    background: ${(props) => props.theme.colors.background.greenBtn};
  }
  &.MuiButton-root.Mui-disabled {
    background: silver !important;
  }
`;

//Size
const SizeThird = css`
  width: ${(props) => props.theme.size.surveyBtns};
  height: ${(props) => props.theme.size.elemHeight};
  margin-left: ${(props) => props.theme.margins.tiny};
  margin-right: ${(props) => props.theme.margins.tiny};
  margin-bottom: ${(props) => props.theme.margins.none};
  font-size: ${(props) => props.theme.fonts.size.btn};
  text-transform: ${(props) => props.theme.fonts.textTransform.btn};
  font-family: ${(props) => props.theme.fonts.family.theme};
`;

const SizeLarge = css`
  width: ${(props) => props.theme.size.full};
  height: ${(props) => props.theme.size.elemHeight};
  margin-bottom: ${(props) => props.theme.margins.tiny};
  text-transform: ${(props) => props.theme.fonts.textTransform.btn};
  font-family: ${(props) => props.theme.fonts.family.theme};
  font-size: 18px !important;
`;

export const BtnBlueThird = styled(Button)`
  border-radius: ${(props) => props.theme.border.radius.medium};
  ${BluePastelBtn}
  ${SizeThird}
`;

export const BtnGreenThird = styled(Button)`
  border-radius: ${(props) => props.theme.border.radius.medium};
  ${GreenBtn}
  ${SizeThird}
`;

export const BtnBlueLarge = styled(Button)`
  border-radius: ${(props) => props.theme.border.radius.medium};
  ${BlueBtn}
  ${SizeLarge}
`;

export const BtnWhiteLarge = styled(Button)`
  ${BorderLightGrey}
  ${SizeLarge}
`;

export const BtnText = styled.p`
  ${TextButtonTypo}
  width: ${(props) => props.theme.size.full};
  cursor: ${(props) => props.theme.cursor.textBtn};
  margin-bottom: ${(props) => props.theme.margins.tiny};
`;

export const HelpBtn = styled(Help)`
  font-size: 4rem !important;
  color: ${(props) => props.theme.colors.timelineActive};
`;
