import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COOKIES } from '../../../constants/cookies_type';
import { Heading1 } from '../../../theme/themeComponents/fonts';
import ApplicationContext from '../../../utils/context';
import CustomButton from '../../CustomButton/CustomButton';
import expression1 from '../../../theme/images/anxiety-evaluation/expression_1.png';
import expression2 from '../../../theme/images/anxiety-evaluation/expression_2.png';
import expression4 from '../../../theme/images/anxiety-evaluation/expression_4.png';
import expression5 from '../../../theme/images/anxiety-evaluation/expression_5.png';
import expression6 from '../../../theme/images/anxiety-evaluation/expression_6.png';

const addSurveyResultQuery = loader(
  '../../../graphql/addSurveyResultNotLinkedToSurgery.graphql'
);
const addSurveyResultDetailsQuery = loader(
  '../../../graphql/addSurveyResultDetailsNotLinkedToSurgery.graphql'
);
const addCommentOfSurveyQuery = loader(
  '../../../graphql/addCommentOfSurvey.graphql'
);

const moods = ['Triste', 'Apeuré', 'Fâché', 'Agacé', 'Content'];
interface Answer {
  id: string;
  value: number;
  image: any;
}
interface ChildMoodTestProps {
  questions: any;
  surveyCreationData: any;
  surveyType: any; //TODO props
}

const ChildMoodTest: React.FC<ChildMoodTestProps> = ({
  questions,
  surveyCreationData,
  surveyType,
}) => {
  const [addSurveyResult] = useMutation(addSurveyResultQuery);
  const [addSurveyResultDetails] = useMutation(addSurveyResultDetailsQuery);
  const [addCommentOfSurvey] = useMutation(addCommentOfSurveyQuery);

  const [answers, setAnswers] = useState<Array<Answer>>([]);
  const [currentAnswer, setCurrentAnswer] = useState<Answer | null>(null);
  const [moodComment, setMoodComment] = useState('');
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const { customModalCxt } = useContext<any>(ApplicationContext);

  useEffect(() => {
    const answers = questions?.[0]?.survey_type?.answers
      ?.map((answer: any) => {
        return answer;
      })
      .sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
      .map((e: any, i: number) => {
        let image = null;
        if (i === 0) image = expression6;
        else if (i === 1) image = expression5;
        else if (i === 2) image = expression4;
        else if (i === 3) image = expression2;
        else if (i === 4) image = expression1;
        return { id: e.id, value: e.order, image };
      });
    setAnswers(answers);
  }, []);

  const handleChange = (answer: Answer) => {
    setCurrentAnswer(answer);
  };

  function createSurveyResult() {
    const today = moment().format('YYYY-MM-DD');
    const surveyVariables = Object.assign({}, surveyCreationData, {
      type: surveyType.id,
      name: `${today}_${cookie.get(COOKIES.EMAIL)}`,
      parent_id: cookie.get(COOKIES.ID),
    });
    addSurveyResult({
      variables: surveyVariables,
    })
      .then((...args) => {
        const [
          {
            data: {
              insert_survey_result_not_linked_to_surgery: {
                returning: surveyCreated,
              },
            },
          },
        ] = args;
        console.info(
          'Survey created',
          JSON.stringify(surveyCreated[0], null, 2)
        );
        createSurveyResultDetails(surveyCreated[0].id);
      })
      .catch((err) => {
        console.error('Invalid input:', surveyVariables);
        console.error(err);
        Sentry.captureException(err);
      });
  }

  function createSurveyResultDetails(surveyId: string) {
    const surveyResultVariables = {
      survey_id: surveyId,
      type: surveyType.id,
      question_id: questions?.[0]?.id,
      answer_id: currentAnswer?.id,
    };
    addSurveyResultDetails({
      variables: surveyResultVariables,
    })
      .then((data) => {
        console.info('Survey Result created');
        const surveyResultDetailsId =
          data.data.insert_survey_result_details_not_linked_to_surgery
            .returning[0].id;
        if (moodComment.trim().length > 0) {
          addCommentOfSurvey({
            variables: {
              survey_result_details_id: surveyResultDetailsId,
              value: moodComment,
            },
          })
            .then(() => {
              customModalCxt?.setShowCustomModalChildMoodTest(false);
            })
            .catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            });
        } else {
          customModalCxt?.setShowCustomModalChildMoodTest(false);
        }
      })
      .catch((err) => {
        console.error('Invalid input:', surveyResultVariables);
        console.error(err);
        Sentry.captureException(err);
      });
  }

  const handleSubmit = () => {
    if (currentAnswer) {
      setDisabledSubmitBtn(true);
      createSurveyResult();
    }
  };

  return (
    <Container>
      <Title>{questions[0].value}</Title>
      <Content>
        {answers?.map((answer, i) => (
          <AnswerContainer
            key={i}
            isActive={currentAnswer?.id === answer.id}
            onClick={() => handleChange(answer)}
          >
            {answer.image && (
              <Image>
                <img src={answer.image} style={{ width: '100%' }} />
              </Image>
            )}
            <ImageText>{answer.value}</ImageText>
            <ImageText>{moods[i]}</ImageText>
          </AnswerContainer>
        ))}
      </Content>
      <InputContainer>
        <Input
          name="comment-survey"
          placeholder={'Tu veux me dire pourquoi ?'}
          onChange={(e) => setMoodComment(e.target.value)}
        />
      </InputContainer>
      <ButtonContainer>
        <CustomButton
          content={'valider'}
          handleOnClick={() => {
            handleSubmit();
          }}
          disabled={!!!currentAnswer || disabledSubmitBtn}
        />
      </ButtonContainer>
    </Container>
  );
};

export default ChildMoodTest;

const Container = styled.div`
  padding: 50px 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;
const Title = styled.div`
  ${Heading1}
`;

const Input = styled.input`
  border-style: none;
  height: 64px;
  border-width: 1px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  padding-left: 15px;
  padding-right: 15px;
  :focus {
    outline: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  flex-wrap: wrap;
`;

const Image = styled.div`
  width: 150px;
  height: 150px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100px;
    height: 100px;
  }
`;

const AnswerContainer = styled.div<{ isActive: boolean }>`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.lightGreenMain : ''};
  border-radius: 64px;
  cursor: pointer;
`;

const ImageText = styled.div`
  ${Heading1}
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
`;
