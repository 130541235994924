import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import styled from 'styled-components';
import CustomCard from '../CustomCard/CustomCard';

interface CardProps {
  title?: string;
  titleSize?: string;
  subtitle?: string;
  image?: any;
  backgroundImage?: any;
}

interface CardsProps {
  frontCard: CardProps;
  backCard: CardProps;
  flipDisabled?: boolean;
  triggerFlip?: number;
}

const CustomFlipCard: React.FunctionComponent<CardsProps> = ({
  frontCard,
  backCard,
  flipDisabled = false,
  triggerFlip,
}) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  useEffect(() => {
    if (triggerFlip === -1) {
      return;
    }
    if (triggerFlip) {
      setIsFlipped(!isFlipped);
    }
    if (triggerFlip === 0) {
      setIsFlipped(false);
    }
  }, [triggerFlip]);

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  return (
    <CustomFlipCardContainer onClick={(e) => !flipDisabled && handleClick(e)}>
      <CustomFlipCardInner isFlipped={isFlipped}>
        <CustomCard {...frontCard} />
        <CustomCard {...backCard} backSide={true} />
      </CustomFlipCardInner>
    </CustomFlipCardContainer>
  );
};

const CustomFlipCardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  width: inherit;
  height: inherit;
  perspective: 2000px;
`;

const CustomFlipCardInner = styled.div<{ isFlipped: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: ${(props) => (props.isFlipped ? 'rotateY(180deg)' : '')};
`;

export default CustomFlipCard;
