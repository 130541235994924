import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tour from 'reactour';
import styled from 'styled-components';
import { LinearGradientText } from '../../theme/components/ThemeComponents';
import { Heading4 } from '../../theme/themeComponents/fonts';
import ApplicationContext from '../../utils/context';
import CustomButton from '../CustomButton/CustomButton';

interface CustomTourProps {}

const CustomTour: React.FC<CustomTourProps> = () => {
  const location = useLocation();
  const history = useHistory();
  const { onboarding } = useContext<any>(ApplicationContext);

  const [state, setState] = useState({ isOpen: false, type: '' });

  useEffect(() => {
    if (onboarding?.onboardingState.show) {
      if (onboarding.onboardingState.type === 'indirect-signup') {
        sidebarMode('show');
      }
      setState({ isOpen: true, type: onboarding.onboardingState.type });
    }
  }, [onboarding?.onboardingState]);

  const renderStepContainer = (
    text: string,
    handleOnClick: () => void,
    btnContent: string,
    stepNumber: string
  ) => {
    return (
      <Step>
        <StepNumber>{stepNumber}</StepNumber>
        <StepText>{text}</StepText>
        <StepButton>
          <CustomButton handleOnClick={handleOnClick} content={btnContent} />
        </StepButton>
      </Step>
    );
  };

  const sidebarMode = (mode: 'show' | 'hidden' | 'remove') => {
    if (window.innerWidth <= 767) {
      const el = document.getElementById('sidebar');
      if (el && mode === 'show') {
        el.style.display = 'block';
      } else if (el && mode === 'hidden') {
        el.style.display = 'none';
      } else if (el && mode === 'remove') {
        el.style.removeProperty('display');
      }
    }
  };

  const stepsDirectSignup = [
    ...(location.pathname === '/welcome'
      ? [
          {
            selector: '[data-tut="reactour__welcome__parent-card"]',
            content: (props: any) =>
              renderStepContainer(
                " Vous trouverez ici les profils des membres de votre famille ainsi que les avatars choisis. Cliquez sur votre avatar pour accéder à l'espace parents !",
                () => props.goTo(1),
                'Suivant',
                '1/14'
              ),
          },
          {
            selector: '[data-tut="reactour__welcome__settings"]',
            content: (props: any) =>
              renderStepContainer(
                'Vous pouvez accéder ici à la page des paramètres où vous pouvez modifier vos informations personnelles, choisir les avatars, changer la langue et lire notre politique de confidentialité.',
                () => {
                  history.push('/parent/emotional-health/home');
                  props.goTo(0);
                },
                'Suivant',
                '2/14'
              ),
          },
        ]
      : location.pathname === '/parent/emotional-health/home'
      ? [
          {
            selector: '[data-tut="reactour__sidebar__dropdown-title"]',
            content: (props: any) =>
              renderStepContainer(
                'Dans cette section, vous pouvez trouver différents outils pour vous aider à gérer la santé émotionnelle de votre enfant',
                () => props.goTo(1),
                'Suivant',
                '3/14'
              ),
            action: () => {
              sidebarMode('show');
            },
          },
          {
            selector: '[data-tut="reactour__sidebar__home-parent"]',
            content: (props: any) =>
              renderStepContainer(
                " Ceci est votre page d'accueil où vous avez différents outils ...",
                () => {
                  sidebarMode('hidden');
                  props.goTo(2);
                },
                'Suivant',
                '4/14'
              ),
          },
          {
            selector: '[data-tut="reactour__home-parent__todo"]',
            content: (props: any) =>
              renderStepContainer(
                'La to-do liste vous aide à suivre vos tâches quotidiennes ...',
                () => props.goTo(3),
                'Suivant',
                '5/14'
              ),
          },
          {
            selector: '[data-tut="reactour__home-parent__fact-of-the-day"]',
            content: (props: any) =>
              renderStepContainer(
                " ... et vous pouvez profiter de petits articles sur le développement de l'enfant.",
                () => {
                  history.push('/parent/emotional-health/my-space');
                  sidebarMode('show');
                  props.goTo(0);
                },
                'Suivant',
                '6/14'
              ),
          },
        ]
      : location.pathname === '/parent/emotional-health/my-space'
      ? [
          {
            selector: '[data-tut="reactour__sidebar__my-space-parent"]',
            content: (props: any) =>
              renderStepContainer(
                'Vous retrouvez ici différentes catégories pour vous aider :',
                () => {
                  sidebarMode('hidden');
                  props.goTo(1);
                },
                'Suivant',
                '7/14'
              ),
          },
          {
            selector: '[data-tut="reactour__my-space-parent__mood-test"]',
            content: (props: any) =>
              renderStepContainer(
                'Vous pouvez entrer ce que vous ressentez,',
                () => props.goTo(2),
                'Suivant',
                '8/14'
              ),
          },
          {
            selector: '[data-tut="reactour__my-space-parent__mood-test"]',
            content: (props: any) =>
              renderStepContainer(
                "... et retrouver une suggestion d'activités faite par un psychologue pour votre enfant.",
                () => {
                  props.goTo(3);
                },
                'Suivant',
                '9/14'
              ),
            action: () => {
              document.getElementById('carousel__nav-arrow__right')?.click();
            },
          },
          {
            selector: '[data-tut="reactour__my-space-parent__activities"]',
            content: (props: any) =>
              renderStepContainer(
                ' ... et avoir un suivi de votre état émotionnel ainsi que celui de votre/vos enfant(s).',
                () => {
                  history.push('/parent/emotional-health/child-development');
                  sidebarMode('show');
                  props.goTo(0);
                },
                'Suivant',
                '10/14'
              ),
            action: () => {
              document.getElementById('carousel__nav-arrow__right')?.click();
            },
          },
        ]
      : location.pathname === '/parent/emotional-health/child-development'
      ? [
          {
            selector:
              '[data-tut="reactour__sidebar__child-development-parent"]',
            content: (props: any) =>
              renderStepContainer(
                "Ici, un outil dédié au développement de l'enfant",
                () => {
                  sidebarMode('hidden');
                  props.goTo(1);
                },
                'Suivant',
                '11/14'
              ),
          },
          {
            selector: '[data-tut="reactour__child-development-parent__main"]',
            content: (props: any) =>
              renderStepContainer(
                "Selon l'âge de votre enfant, vous pouvez trouver certains éléments généraux qui peuvent servir de repères sur le développement.",
                () => props.goTo(2),
                'Suivant',
                '12/14'
              ),
          },
          {
            selector:
              '[data-tut="reactour__child-development-parent__article-of-the-month"]',
            content: (props: any) =>
              renderStepContainer(
                "Dans cette section vous pouvez retrouver des conseils et repères sur l'évolution de votre enfant",
                () => props.goTo(3),
                'Suivant',
                '13/14'
              ),
          },
          {
            selector: '[data-tut="reactour__sidebar__articles-parent"]',
            content: (props: any) =>
              renderStepContainer(
                'Dans cette section vous pouvez retrouver différents articles detaillés sur la santé mentale et émotionnelle de votre enfant',
                () => {
                  sidebarMode('remove');
                  setState({ isOpen: false, type: '' });
                  onboarding.setOnboardingState({ show: false, type: null });
                  history.push('/parent/emotional-health/home');
                },
                'Terminer',
                '14/14'
              ),
          },
        ]
      : []),
  ];

  const stepsIndirectSignup = [
    {
      selector: '[data-tut="reactour__sidebar__dropdown-title"]',
      content: (props: any) =>
        renderStepContainer(
          "Le menu “Mes rendez-vous médicaux”, vous permet d'accéder facilement vos différents rdv.",
          () => {
            sidebarMode('hidden');
            props.goTo(1);
          },
          'Suivant',
          '1/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__evaluations"]',
      content: (props: any) =>
        renderStepContainer(
          "Ici, vous pouvez renseigner différentes évaluations sur votre anxiété et ou certains sympômes de votre enfant. Cela permettra à l'équipe médicale d'avoir les informations pour un meilleur suivi.",
          () => props.goTo(2),
          'Suivant',
          '2/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__guidelines"]',
      content: (props: any) =>
        renderStepContainer(
          "Vous trouverez ici toutes les consignes médicales communiquées par l'établissement pour votre intervention.",
          () => props.goTo(3),
          'Suivant',
          '3/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__documents"]',
      content: (props: any) =>
        renderStepContainer(
          "Vous trouverez ici tous les documents utiles (administratifs par exemple) fournis par l'établissement de santé.",
          () => props.goTo(4),
          'Suivant',
          '4/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__esign-documents"]',
      content: (props: any) =>
        renderStepContainer(
          "Vous trouverez ici les documents à signer transmis par l'établissement.",
          () => props.goTo(5),
          'Suivant',
          '5/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__operation-timeline"]',
      content: (props: any) =>
        renderStepContainer(
          "Vous trouverez ici les conseils de l'équipe Koalou (psychologue, pédiatre, etc) pour accompagner le parcours médical de votre enfant.",
          () => props.goTo(6),
          'Suivant',
          '6/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__what-put-in-case"]',
      content: (props: any) =>
        renderStepContainer(
          "Vous trouverez ici les conseils de l'équipe Koalou (psychologue, pédiatre, etc) pour accompagner le parcours médical de votre enfant.",
          () => props.goTo(7),
          'Suivant',
          '7/8'
        ),
    },
    {
      selector: '[data-tut="reactour__surgeries-health__prescription"]',
      content: (props: any) =>
        renderStepContainer(
          'Vous trouverez ici toutes les prescriptions données à votre enfant.',
          () => {
            sidebarMode('remove');
            setState({ isOpen: false, type: '' });
            onboarding.setOnboardingState({ show: false, type: null });
          },
          'Terminer',
          '8/8'
        ),
    },

    // {
    //   selector: '[data-tut="reactour__surgeries-health__call_day_before"]',
    //   content: (props: any) =>
    //     renderStepContainer(
    //       'Ici, vous pouvez choisir et réserver un créneau pour que le professionnel de la santé vous appelle.',
    //       () => props.goTo(8),
    //       'Suivant',
    //       '8/9'
    //     ),
    // },
    // {
    //   selector: '[data-tut="reactour__surgeries-health__prescription"]',
    //   content: (props: any) =>
    //     renderStepContainer(
    //       'Vous trouverez ici toutes les prescriptions données à votre enfant.',
    //       () => {
    //         sidebarMode('remove');
    //         setState({ isOpen: false, type: '' });
    //         onboarding.setOnboardingState({ show: false, type: null });
    //       },
    //       'Terminer',
    //       '9/9'
    //     ),
    // },
  ];

  const handleBodyScroll = (mode: 'open' | 'close') => {
    const scrollContainer = document.getElementById('page-wrapper__container');
    if (scrollContainer) {
      if (mode === 'open') {
        scrollContainer.style.overflowY = 'hidden';
        window.$crisp?.push(['do', 'chat:hide']);
      } else {
        scrollContainer.style.overflowY = 'auto';
        window.$crisp?.push(['do', 'chat:show']);
      }
    }
  };

  const accentColor = 'linear-gradient(to right, #2e666d, #89c9cd )';

  return (
    <Tour
      steps={
        state.type === 'direct-signup' ? stepsDirectSignup : stepsIndirectSignup
      }
      isOpen={state.isOpen}
      onRequestClose={() => {
        sidebarMode('remove');
        setState({ isOpen: false, type: '' });
      }}
      // update={location.pathname}
      maskClassName="mask"
      className="helper"
      rounded={5}
      accentColor={accentColor}
      onAfterOpen={() => handleBodyScroll('open')}
      onBeforeClose={() => handleBodyScroll('close')}
      maskSpace={0}
      disableDotsNavigation={true}
      badgeContent={(curr, tot) => null}
      startAt={0}
      disableInteraction
      closeWithMask={false}
    />
  );
};

export default CustomTour;

const Step = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepNumber = styled.div`
  ${Heading4}
  ${LinearGradientText}
`;

const StepText = styled.div`
  ${Heading4}
  color: ${({ theme }) => theme.colors.blackMain};
  margin: 10px 0;
`;

const StepButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
