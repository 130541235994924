import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { CardStyle1 } from '../../theme/components/ThemeComponents';
import { Heading3, Heading4 } from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';

const Symptomatology = ({
  handleSubmit,
  questions,
  handleAnswerRadioButtonChange,
}) => {
  const handleChange = (question) => (event) => {
    handleAnswerRadioButtonChange(
      event.target.checked ? 'true' : 'false',
      question
    );
  };
  useEffect(() => {
    questions.forEach((question) => {
      handleAnswerRadioButtonChange('false', question);
    });
  }, []);

  return (
    <Container>
      <CardContainer>
        <ListContainer>
          <ListHeader>
            <ListHeaderTitle>
              L'opération de votre enfant approche, veuillez remplir les
              informations suivantes :
            </ListHeaderTitle>
          </ListHeader>
          <Separator />

          <ListMain>
            {questions?.map((question, i) => (
              <FormControl key={i}>
                <ListRow>
                  <ListLabel>{question.value}</ListLabel>
                  <ListToggle>
                    <FormControlLabel
                      label={null}
                      onChange={handleChange(question)}
                      control={<Switch />}
                      labelPlacement="top"
                    />
                  </ListToggle>
                </ListRow>
              </FormControl>
            ))}
          </ListMain>
          <Separator />
        </ListContainer>
        <ButtonContainer>
          <CustomButton
            content="Soumettre"
            backgroundColor={theme.colors.darkGreenMain}
            borderRadius="24px"
            handleOnClick={handleSubmit}
          />
        </ButtonContainer>
      </CardContainer>
    </Container>
  );
};

Symptomatology.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  handleAnswerRadioButtonChange: PropTypes.func.isRequired,
};

export default Symptomatology;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  ${CardStyle1}
  width: 780px;
  padding: 20px 60px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 10px;
  }
`;

const ListContainer = styled.div``;

const ListHeader = styled.div`
  width: 100%;
  display: flex;
  ${Heading3}
  padding: 20px 0;
`;

const ListHeaderTitle = styled.div`
  width: 100%;
`;

const Separator = styled.div`
  background: ${({ theme }) => theme.colors.darkGreenMain};
  height: 2px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 10px 0;
`;

const ListMain = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ListRow = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
`;

const ListLabel = styled.div`
  width: 80%;
  ${Heading4}
`;

const ListToggle = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;
