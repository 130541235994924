import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { getFirebaseToken } from '../../utils/auth';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  toggle: () => void;
  documentName: string;
  fileCategory: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  toggle,
  documentName,
  fileCategory,
}) => {
  const { t } = useTranslation();
  const [fileContentBase64, setFileContentBase64] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [loadingFileContentBase64, setLoadingFileContentBase64] =
    useState(true);
  const [loading, setLoading] = useState(true);

  const downloadOrganizationFile = async (
    fileName: string,
    fileType: string
  ) => {
    const token = await getFirebaseToken();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_SERVER_URL}/download-organization-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ fileName, fileType }),
        }
      );
      if (res.ok) {
        const resJson = await res.json();
        return {
          dataBase64: resJson.data,
          error: null,
        };
      } else {
        return {
          dataBase64: null,
          error: 'Error while downloading the file',
        };
      }
    } catch (err) {
      return {
        dataBase64: null,
        error: 'Error while downloading the file',
      };
    }
  };

  useEffect(() => {
    async function downloadOrganizationFileAsync() {
      try {
        const data = await downloadOrganizationFile(documentName, fileCategory);
        setFileContentBase64(data.dataBase64);
        setLoadingFileContentBase64(false);
      } catch (err) {
        console.error(err);
      }
    }
    downloadOrganizationFileAsync();
  }, []);

  function onDocumentLoadSuccess(params: any) {
    setNumPages(params.numPages);
    setLoading(false);
  }

  /**
   * Creates an anchor element `<a></a>` with
   * the base64 pdf source and a filename with the
   * HTML5 `download` attribute then clicks on it.
   * @param  {string} pdf
   * @return {void}
   */
  function downloadPDF() {
    const linkSource = `data:application/pdf;base64,${fileContentBase64}`;
    const downloadLink = document.createElement('a');
    const originalDocumentName = documentName.substring(36 + 1); //Uuid length + slash
    downloadLink.href = linkSource;
    downloadLink.download = originalDocumentName;
    downloadLink.click();
  }

  if (loadingFileContentBase64) {
    return (
      <LoaderContainer>
        <LoaderText>{t('common:loading')}</LoaderText>
        <div title="loader" className="loader" />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      {fileContentBase64 && (
        <>
          {loading && (
            <LoaderContainer>
              <LoaderText>{t('common:loading')}</LoaderText>
              <div title="loader" className="loader" />
            </LoaderContainer>
          )}
          <DocumentContainer
            file={`data:application/pdf;base64,${fileContentBase64}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={''}
          >
            {!loading && (
              <>
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={downloadPDF}
                    size="small"
                    style={{ margin: 0 }}
                  >
                    {t('common:download')}
                  </Button>

                  <IconContainer className="btn-inner--icon" onClick={toggle}>
                    <CloseIcon />
                  </IconContainer>
                </ButtonContainer>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    loading={''}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </>
            )}
          </DocumentContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const DocumentContainer = styled(Document)`
  position: relative;
  & > .react-pdf__Page > .react-pdf__Page__canvas {
    margin: auto !important;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  top: 80px;
  right: 50px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoaderText = styled.div`
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  height: 100%;
  cursor: pointer;
  margin-left: 5px;
`;

export default PdfViewer;
