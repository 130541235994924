import Phaser from '../lib/phaser.js';
import skyBackground from '../../assets/skyBackground.png';
import homeArrow from '../../../../../theme/images/stories/home_arrow.png';
import prevArrow from '../../../../../theme/images/stories/previous_arrow.png';
import nextArrow from '../../../../../theme/images/stories/next_arrow.png';

export default class Help extends Phaser.Scene {
  /** @type {Phaser.GameObjects.Image} */
  homeArrowBtn;

  /** @type {Phaser.GameObjects.Image} */
  prevArrowBtn;

  /** @type {Phaser.GameObjects.Image} */
  nextArrowBtn;

  /** @type {Phaser.GameObjects.Text} */
  helpInstruction;

  /** @type {Number} */
  instructionIdx = 0;

  /** @type {Phaser.GameObjects.Text} */
  helpInstructions = [
    "La cohérence cardiaque, qu'est-ce que c'est ?",
    'La méthode de « cohérence cardiaque » est apparue aux États-Unis il y a une dizaine d’années, suite à des recherches en neurosciences et neuro-cardiologie à l’institut HeartMath.',
    "Il a été montré que cette méthode est très bénéfique pour la santé, et qu'elle a notamment un rôle important dans la prévention des maladies cardio-vasculaires. Le système nerveux autonome joue un rôle particulièrement important pour relier le cœur et le cerveau.",
    'Celui-ci est constitué de deux branches',
    'la branche dite « sympathique » qui accélère le cœur, augmente la sécrétion hormonale et active le cerveau émotionnel (limbique)',
    'la branche dite « para-sympathique » qui joue un rôle de frein et de protection sur l’un et l’autre',
    'Nos pensées, perceptions et réactions émotionnelles sont transmises du cerveau au cœur via ces deux branches du système nerveux autonome et sont liées au rythme cardiaque.',
    'Dans les états de stress, d’anxiété, de dépression ou de colère, la variabilité du rythme cardiaque entre les battements devient irrégulière et chaotique.',
    'Dans les états de bien-être, de bienveillance ou de gratitude, cette variabilité devient cohérente, c’est-à-dire que l’alternance d’accélérations et de décélérations du rythme cardiaque est régulière.',
    'Les deux branches du système nerveux autonome travaillent ensemble avec une efficacité maximale plutôt que de se combattre l’une l’autre.',
    'Monié, Benoît. « 27. Cohérence cardiaque », , Thérapies comportementales et cognitives. En 37 notions, sous la direction de Chapelle Frédéric, et al. Dunod, 2018, pp. 249-254.',
    "Préparation de l'exercice",
    'Avant de commencer l’exercice avec votre enfant, il est important de lui poser  3 questions',
    'Quelles émotions as-tu ressenties aujourd’hui ? (utilisez l’échelle de KOALOU pour vous aider)',
    'Quelles sont les émotions que tu n’aimes pas ? (ex : frustration, colère, peur, tristesse)',
    'Comment te sens-tu maintenant ?',
    "Comment se passe l'exercice ?",
    'Vous pouvez alors commencer à utiliser l’exercice de respiration. Lorsque l’avion monte inspirer avec votre enfant, lorsque l’avion descend, expirez.',
    'Pour obtenir le plus de bénéfice de la cohérence cardiaque, nous vous invitons à renouveler l’expérience 3 fois par jour, 6 respirations par minute pendant 5 minutes.',
  ];

  constructor() {
    super('help');
  }

  preload() {
    const bg1 = new Image();
    const homeArr = new Image();
    const prevArr = new Image();
    const nextArr = new Image();

    this.load.image('background2', (bg1.src = skyBackground));
    this.load.image('home-arrow', (homeArr.src = homeArrow));
    this.load.image('previous-arrow', (prevArr.src = prevArrow));
    this.load.image('next-arrow', (nextArr.src = nextArrow));
  }

  create() {
    //Add background stretched to fill main camera view
    const background2 = this.add.image(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      'background2'
    );
    const bg2ScaleX = this.cameras.main.width / background2.width;
    const bg2ScaleY = this.cameras.main.height / background2.height;
    const bg2Scale = Math.max(bg2ScaleX, bg2ScaleY + 1);
    background2.setScale(bg2Scale).setScrollFactor(0);

    //Create home arrow button
    this.homeArrowBtn = this.add
      .image(
        this.cameras.main.width / 10,
        this.cameras.main.height / 2,
        'home-arrow'
      )
      .setScrollFactor(0)
      .setScale(0.3)
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on('pointerdown', () => this.onHomeArrowClick());

    //Create previous arrow button
    this.prevArrowBtn = this.add
      .image(
        this.cameras.main.width / 10,
        this.cameras.main.height / 2,
        'previous-arrow'
      )
      .setVisible(false)
      .setScrollFactor(0)
      .setScale(0.3)
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on('pointerdown', () => this.onPrevArrowClick());

    //Create next arrow button
    this.nextArrowBtn = this.add
      .image(
        (this.cameras.main.width / 10) * 9,
        this.cameras.main.height / 2,
        'next-arrow'
      )
      .setScrollFactor(0)
      .setScale(0.3)
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on('pointerdown', () => this.onNextArrowClick());

    const fontStyle = {
      //fontFamily: 'font1',
      color: 'white',
      fontSize: 32,
      align: 'center',
      lineSpacing: 10,
      wordWrap: { width: this.cameras.main.width / 2, useAdvanceWrap: true },
    };

    this.helpInstruction = this.add
      .text(
        this.scale.width / 2,
        this.scale.height / 2,
        this.helpInstructions[this.instructionIdx],
        fontStyle
      )
      .setPadding({ bottom: 2 })
      .setScrollFactor(0)
      .setOrigin(0.5, 0.5)
      .setDepth(50);
    //.setShadow(0, 3, '#92C2D3', 0, true)
    //.setStroke('#92C2D3', 2);
  }

  update() {
    this.helpInstruction.text = this.helpInstructions[this.instructionIdx];
    if (this.instructionIdx === 0) {
      this.homeArrowBtn.setVisible(true);
      this.prevArrowBtn.setVisible(false);
    } else if (this.instructionIdx === this.helpInstructions.length - 1) {
      this.nextArrowBtn.setVisible(false);
    } else if (this.instructionIdx > 0) {
      this.homeArrowBtn.setVisible(false);
      this.prevArrowBtn.setVisible(true);
      this.nextArrowBtn.setVisible(true);
    }
  }

  onHomeArrowClick() {
    this.scene.stop('help');
    this.scene.start('game-menu');
  }

  onPrevArrowClick() {
    if (this.instructionIdx > 0) this.instructionIdx -= 1;
  }

  onNextArrowClick() {
    if (this.instructionIdx < this.helpInstructions.length - 1)
      this.instructionIdx += 1;
  }
}
