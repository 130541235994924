import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { BodyLarge, Heading3 } from '../../theme/themeComponents/fonts';
interface TodoListItemProps {
  index: number;
  task: string;
  onClick: () => void;
}

const TodoListItem: React.FunctionComponent<TodoListItemProps> = ({
  index,
  task,
  onClick,
}) => {
  return (
    <ItemContainer>
      <Item id={'todo_item_' + index}>
        <ItemHeader>{'Tâche ' + (index + 1)}</ItemHeader>
        <ItemBody>{task}</ItemBody>
        <DeleteIcon icon={faMinusCircle} onClick={onClick} />
      </Item>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  margin-bottom: 11px;
  position: relative;
`;

const Item = styled(ListGroup.Item)`
  font-size: 1rem;
  border-radius: 16px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ItemHeader = styled.div`
  color: ${(props) => props.theme.colors.darkGreenMain};
  ${Heading3};
`;

const ItemBody = styled.div`
  ${BodyLarge};
  overflow-wrap: break-word;
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${(props) => props.theme.colors.redL4};
  cursor: pointer;
`;

export default TodoListItem;
