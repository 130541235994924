import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import Survey from '../components/Survey/Survey';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';


const StaiNow: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(
          `/parent/surgeries/health?sid=${new URLSearchParams(
            location.search
          ).get('sid')}#showEvaluationsView`
        )
      }
    >
      <Survey surveyTypeValue={SURVEY_TYPE_VALUE['STAI_NOW']} />
    </PageWrapper>
  );
};

export default StaiNow;
