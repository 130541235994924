import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COOKIES } from '../../../constants/cookies_type';
import emoji1Image from '../../../theme/images/emojis/emoji1Regular.png';
import emoji2Image from '../../../theme/images/emojis/emoji2Regular.png';
import emoji3Image from '../../../theme/images/emojis/emoji3Regular.png';
import emoji4Image from '../../../theme/images/emojis/emoji4Regular.png';
import emoji5Image from '../../../theme/images/emojis/emoji5Regular.png';
import { Heading1, Heading3 } from '../../../theme/themeComponents/fonts';
import CustomButton from '../../CustomButton/CustomButton';
import { Loader } from '../../Loader/Loader';

const addParentSurveyNotLinkedToSurgeryQuery = loader(
  '../../../graphql/addParentSurveyNotLinkedToSurgery.graphql'
);

const emojisArray = [
  emoji1Image,
  emoji2Image,
  emoji3Image,
  emoji4Image,
  emoji5Image,
];

const emojisText = [
  'Très triste',
  'Triste',
  'Insatisfait',
  'Content',
  'Heureux',
];
interface ChildMoodTestProps {
  questions: any;
  surveyType: any; //TODO props
}

const ChildMoodTest: React.FC<ChildMoodTestProps> = ({
  questions,
  surveyType,
}) => {
  const [addParentSurveyNotLinkedToSurgery] = useMutation(
    addParentSurveyNotLinkedToSurgeryQuery
  );

  const [answers, setAnswers] = useState<Array<{ id: string; value: number }>>(
    []
  );
  const [currentAnswer, setCurrentAnswer] = useState<{
    id: string;
    value: number;
  } | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const answers = questions?.[0]?.survey_type?.answers
      ?.map((answer: any) => {
        return answer;
      })
      .map((e: any) => {
        return { id: e.id, value: e.order };
      })
      .sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
    setAnswers(answers);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer({
      id: answers[parseInt(e.target.value) - 1].id,
      value: parseInt(e.target.value),
    });
  };

  function createParentSurvey() {
    addParentSurveyNotLinkedToSurgery({
      variables: {
        name: `${moment().format('YYYY-MM-DD')}_${cookie.get(COOKIES.EMAIL)}`,
        parentId: cookie.get(COOKIES.ID),
        surveyTypeId: surveyType.id,
        surveyQuestionId: questions?.[0]?.id,
        surveyAnswerId: currentAnswer?.id,
      },
    })
      .then(() => {
        setLoading(false);
        setShowSuccessMessage(true);
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  }

  const handleSubmit = () => {
    if (currentAnswer) {
      setLoading(true);
      createParentSurvey();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      {showSuccessMessage ? (
        <Title style={{ textAlign: 'center' }}>
          Nous avons bien enregistré votre réponse.
        </Title>
      ) : (
        <>
          <Title>{questions[0].value}</Title>
          <Content>
            <RangeSliderContainer>
              <RangeSlider
                type="range"
                className={`slider`}
                id="parent-mood-test-range"
                onChange={handleChange}
                min={1}
                max={5}
                step={1}
                value={currentAnswer?.value ?? 1}
              />
              {/* <MiddleBar /> */}
              <RangeLabelContainer>
                {emojisArray.map((e: any, i: number) => (
                  <EmojiContainer key={i}>
                    <Emoji>
                      <img src={e} style={{ width: '100%' }} />
                    </Emoji>
                    <EmojiText>
                      <div
                        style={{
                          position: 'relative',
                          left: '-50%',
                        }}
                      >
                        {emojisText[i]}
                      </div>
                    </EmojiText>
                  </EmojiContainer>
                ))}
              </RangeLabelContainer>
            </RangeSliderContainer>
          </Content>

          <ButtonContainer>
            <CustomButton
              content={'valider'}
              handleOnClick={() => {
                handleSubmit();
              }}
              disabled={!!!currentAnswer}
            />
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default ChildMoodTest;

const Container = styled.div`
  width: 100%;
`;
const Title = styled.div`
  ${Heading1};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: center;
`;

const RangeSliderContainer = styled.div`
  width: 100%;
  position: relative;
`;

const RangeLabelContainer = styled.div`
  display: flex;
  margin: 0 9px;
  justify-content: space-between;

  /* div:nth-child(-n + 4) {
    width: 25%;
    text-align: left;
  }
  div:last-child() {
    text-align: right;
  } */
`;
const EmojiContainer = styled.div`
  position: relative;
`;

const Emoji = styled.div`
  width: 36px;
  height: 36px;
`;

const EmojiText = styled.div`
  ${Heading3}
  text-align: center;
  position: absolute;
  top: 40px;
  left: 50%;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

// const MiddleBar = styled.div`
//   position: absolute;
//   width: 100%;
//   border: 2px solid #ffffff;
//   top: 17px;
//   left: 0;
//   background-color: red;
//   z-index: 10;
// `;

const RangeSlider = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  :focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  ::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #ff9d8c;
    cursor: pointer;
    margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  ::-moz-range-thumb {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #ff9d8c;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  ::-ms-thumb {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #ff9d8c;
    cursor: pointer;
  }

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: linear-gradient(270deg, #aed1cd 0%, #ffebe8 100%);
    border-radius: 84px;
  }

  :focus::-webkit-slider-runnable-track {
    /* background: #367ebd; */
    /* border: 1px solid black; */
  }

  ::-moz-range-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: linear-gradient(270deg, #aed1cd 0%, #ffebe8 100%);
    border-radius: 84px;
  }

  ::-ms-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  /* input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  
  input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;


  input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;

  input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
  */
`;
