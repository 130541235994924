import React from 'react';
import styled from 'styled-components';

interface CircleBackgroundProps {
  type?: 'large' | 'regular';
}

//TODO WIP

const CircleBackground: React.FC<CircleBackgroundProps> = ({
  type = 'regular',
}) => {
  return (
    <Page id="circle-background">
      <Test>
        <Container>
          {/* {type === 'regular' && <Image src={backgroundRegular} />}
      {type === 'large' && <Image src={backgroundLarge} />} */}
          {/* <Circle /> */}
        </Container>
      </Test>
    </Page>
  );
};

export default CircleBackground;

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -100;
`;

const Test = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Container = styled.div`
  /* position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    width: auto;
  } */

  /*  */
  position: absolute;
  width: 120%;
  height: 115%;
  left: -10%;
  top: -60%;
  border-radius: 0 0 50% 50%;
  z-index: -100;
  background: linear-gradient(
    239.29deg,
    #095c66 12.03%,
    #209aa2 65.76%,
    #ffffff 128.84%
  );

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    /* border-radius: 0 0 20% 20%; */
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 150%;
    height: 120%;
    border-radius: 50%;
    top: -50%;
    left: -90px;
  }
`;

// const Image = styled.img`
//   width: 100%;
//   @media screen and (max-width: ${(props) =>
//       props.theme.breakpoints.laptop13Max}) {
//     /* width: 1200px; */
//   }
// `;

// const Circle = styled.div`
//   position: absolute;
//   width: 2213px;
//   height: 2213px;
//   left: -387px;
//   top: -1644px;

//   border-radius: 0 0 50% 50%;

//   border-radius: 50%;
//   z-index: -100;

//   background: linear-gradient(
//     239.29deg,
//     #095c66 12.03%,
//     #209aa2 65.76%,
//     #ffffff 128.84%
//   );
// `;
