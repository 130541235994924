import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../../theme/themeComponents/fonts';
import VisualAnalogueScale from '../VisualAnalogueScale/VisualAnalogueScale';
interface ParentAnxietyTestProps {
  question: any;
  answers: any;
  handleAnswerRadioButtonChange: (value: string, question: any) => void;
}

const ParentAnxietyTest: React.FunctionComponent<ParentAnxietyTestProps> = ({
  question,
  handleAnswerRadioButtonChange,
}) => {
  const handleChange = (value: string) => {
    handleAnswerRadioButtonChange(value, question);
  };
  return (
    <Container>
      <Title>{question.value}</Title>
      <VisualAnalogueScale handleChange={handleChange} />
    </Container>
  );
};

const Container = styled.div`
  width: 70%;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const Title = styled.div`
  ${Heading2}
`;

export default ParentAnxietyTest;
