import React from 'react';
import styled from 'styled-components';

interface LoaderProps {
  size?: 'sm' | 'auto';
}

export const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <LoaderContainer>
      {size === 'sm' ? (
        <div className="loader--small"></div>
      ) : (
        <div className="loader"></div>
      )}
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
