import Phaser from 'phaser';
import skyBackground from '../../assets/skyBackground.png';
import mountainBackground from '../../assets/mountain.png';
import hillsBackground from '../../assets/hillsBackground.png';
import cloud1 from '../../assets/cloud1.png';
import cloud2 from '../../assets/cloud2.png';
import cloud3 from '../../assets/cloud3.png';
import tree1 from '../../assets/tree1.png';
import tree2 from '../../assets/tree2.png';
import tree3 from '../../assets/tree3.png';
import tree4 from '../../assets/tree4.png';
import house1 from '../../assets/house1.png';
import house2 from '../../assets/house2.png';
import plane from '../../assets/plane.png';
import orange from '../../assets/orange.png';
import pause from '../../assets/pause.png';
import koalou from '../../../../../theme/images/homeChildren/expression5.png';
import gameView1 from '../../assets/howToPlay1.png';
import CountdownController from '../game/CountdownController';
import DialogBox from '../game/DialogBox';
import LiftoffAnimation from '../game/LiftoffAnimation';
import PlaneTrajectory from '../game/PlaneTrajectory';
import ProgressionBar from '../game/ProgressionBar';
import LandingAnimation from '../game/LandingAnimation';
import musicMain from '../../assets/Sounds/KoalouMusicMainPart.wav';
import musicIntro from '../../assets/Sounds/KoalouMusicIntro.wav';
import birds from '../../assets/Sounds/birdsAmbience.wav';
import planeEngine from '../../assets/Sounds/planeEngine.wav';

export default class Game extends Phaser.Scene {
  /** @type {Phaser.GameObjects.Image} */
  hills;

  /** @type {Phaser.Physics.Arcade.StaticGroup} */
  clouds;

  /** @type {Phaser.Physics.Arcade.StaticGroup} */
  groundObjects;

  /** @type {Phaser.Physics.Arcade.Sprite} */
  plane;

  /** @type {LiftoffAnimation} */
  liftoffAnimation;

  /** @type {PlaneTrajectory} */
  planeTrajectory;

  /** @type {CountdownController} */
  countdown;

  /** @type {ProgressionBar} */
  progressionBar;

  /** @type {DialogBox} */
  dialogBox;

  /** @type {Number} */
  acceleration = 60;

  /** @type {Phaser.Physics.Matter.Image} */
  filterSunset;

  /** @type {LandingAnimation} */
  landingAnimation;

  musicMain;

  musicIntro;

  birds;

  planeEngine;

  volume = 0.5;

  constructor() {
    super('game');
  }

  preload() {
    const bg1 = new Image();
    const bg2 = new Image();
    const bg3 = new Image();
    const cld1 = new Image();
    const cld2 = new Image();
    const cld3 = new Image();
    const t1 = new Image();
    const t2 = new Image();
    const t3 = new Image();
    const t4 = new Image();
    const h1 = new Image();
    const h2 = new Image();
    const player = new Image();
    const sunsetShader = new Image();
    const pauseSign = new Image();
    const koalouFace = new Image();
    const gameView = new Image();

    //Load backgrounds
    this.load.image('background3', (bg3.src = skyBackground));
    this.load.image('background2', (bg2.src = mountainBackground));
    this.load.image('background1', (bg1.src = hillsBackground));

    //Load pause button
    this.load.image('pauseBtn', (pauseSign.src = pause));

    //Load clouds
    this.load.image('cloud1', (cld1.src = cloud1));
    this.load.image('cloud2', (cld2.src = cloud2));
    this.load.image('cloud3', (cld3.src = cloud3));

    //Load ground objects
    this.load.image('tree1', (t1.src = tree1));
    this.load.image('tree2', (t2.src = tree2));
    this.load.image('tree3', (t3.src = tree3));
    this.load.image('tree4', (t4.src = tree4));
    this.load.image('house1', (h1.src = house1));
    this.load.image('house2', (h2.src = house2));

    //Load plane
    this.load.image('plane', (player.src = plane));

    //Load sunset filter
    this.load.image('sunsetFilter', (sunsetShader.src = orange));

    //Load koalou
    this.load.image('koalou', (koalouFace.src = koalou));

    //Load game view for how to play scene
    this.load.image('gameView', (gameView.src = gameView1));

    this.load.audio('musicMain', musicMain);
    this.load.audio('musicIntro', musicIntro);
    this.load.audio('birds', birds);
    this.load.audio('plane', planeEngine);
  }

  create() {
    this.musicMain = this.sound.add('musicMain');
    this.musicMain.loop = true;

    this.musicIntro = this.sound.add('musicIntro');
    this.musicIntro.loop = true;

    this.birds = this.sound.add('birds');
    this.birds.loop = true;
    this.birds.play({ volume: this.volume });

    this.planeSound = this.sound.add('plane');
    this.planeSound.loop = true;
    this.planeSound.play({ volume: this.volume });

    //Add background stretched to fill main camera view
    const background3 = this.add.image(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      'background3'
    );
    const bg3ScaleX = this.cameras.main.width / background3.width;
    const bg3ScaleY = this.cameras.main.height / background3.height;
    const bg3Scale = Math.max(bg3ScaleX, bg3ScaleY + 1);
    background3.setScale(bg3Scale).setScrollFactor(0);

    //Add filter stretched to fill main camera view
    this.filterSunset = this.add.image(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      'sunsetFilter'
    );
    const filterScaleX = this.cameras.main.width / this.filterSunset.width;
    const filterScaleY = this.cameras.main.height / this.filterSunset.height;
    const filterScale = Math.max(filterScaleX, filterScaleY);
    this.filterSunset.setScale(filterScale).setScrollFactor(0).setAlpha(0);

    //Add mountain in background
    const background2 = this.add.image(0, 0, 'background2');
    const bg2ScaleX = this.cameras.main.width / background2.width;
    const bg2ScaleY = this.cameras.main.height / background2.height;
    const bg2Scale = Math.max(bg2ScaleX, bg2ScaleY);
    background2.setOrigin(0, -0.5).setScale(bg2Scale).setScrollFactor(0.1);

    //Add hills in background
    this.hills = this.add
      .image(0, 0, 'background1')
      .setScale(1.8)
      .setOrigin(0, -0.29)
      .setScrollFactor(1);

    //Create pause button
    this.add
      .image(0, 0, 'pauseBtn')
      .setScrollFactor(0)
      .setScale(0.1)
      .setOrigin(-0.1, -0.1)
      .setInteractive()
      .on('pointerdown', () => this.onEscDown());

    //Create clouds
    this.clouds = this.add.group();
    const cloudsArr = ['cloud1', 'cloud2', 'cloud3'];
    for (let i = 0; i < 2; ++i) {
      const x = 1000 * i + 500;
      const y = this.cameras.main.height / 4 - 50;

      this.clouds.create(x, y, Phaser.Utils.Array.GetRandom(cloudsArr));
    }

    //Create ground objects
    this.groundObjects = this.add.group();
    const groundObjectsArr = [
      'tree1',
      'tree2',
      'tree3',
      'tree4',
      'house1',
      'house2',
    ];
    for (let i = 0; i < 2; ++i) {
      const x = 1000 * (i + 1);
      const y = this.cameras.main.height - 50;

      this.groundObjects
        .create(x, y, Phaser.Utils.Array.GetRandom(groundObjectsArr))
        .setScale(0.75);
    }

    //Create plane
    this.plane = this.physics.add
      .sprite(450, this.cameras.main.height + 250, 'plane')
      .setAngle(-10)
      .setScale(0.3)
      .setMaxVelocity(99.6, 150)
      .setDepth(50);

    //Set camera to follow plane
    this.cameras.main
      .startFollow(this.plane, false, 1, 1, -this.scale.width / 3, 150)
      .setDeadzone(0, this.scale.height / 2);

    this.cameras.main.setFollowOffset(this.cameras.main.followOffset.x, 0);

    this.liftoffAnimation = new LiftoffAnimation(this, this.plane);
    this.liftoffAnimation.start();
    this.planeTrajectory = new PlaneTrajectory(this, this.plane);

    this.dialogBox = new DialogBox(this, [
      "Salut ! C'est Koalou !",
      'Fais comme moi pour contrôler ta respiration.',
      "Inspire quand l'avion monte.",
      'Expire quand il descend.',
      'Inspire...',
      'Expire...',
      'Inspire...',
      'Expire...',
      "C'est bien, maintenant continue sans moi !",
    ]);

    this.countdown = new CountdownController(this);

    //Create progression bar
    const progressingPlane = this.add
      .image(0, 0, 'plane')
      .setAngle(10)
      .setScrollFactor(0);
    this.progressionBar = new ProgressionBar(
      this,
      progressingPlane,
      this.countdown
    );
    this.progressionBar.create();

    //Create esc event
    this.input.keyboard.on('keydown-' + 'ESC', () => this.onEscDown());

    //Launch how to play
    this.scene.pause('game');
    this.scene.launch('how-to-play', {
      data: [
        {
          text: "Bienvenue dans l'exercice de cohérence cardiaque ! Cet exercice va t'aider à contrôler ta respiration et te détendre.",
        },
        {
          text: "Tu vas d'abord dire comment tu te sens avant de commencer.",
          img: 'gameView',
        },
        {
          text: "Ensuite tu vas essayer de suivre les mouvements de l'avion de Koalou avec ta réspiration pendant 5 minutes.",
          img: 'gameView',
        },
        {
          text: "Quand l'avion monte, tu prends une grande inspiration pendant 5 secondes.",
          img: 'gameView',
        },
        {
          text: 'Et quand il redescends, tu expires tranquillement pendant 5 secondes.',
          img: 'gameView',
        },
        {
          text: "Ne t'inquiètes pas, Koalou sera là pour t'aider au début.",
          img: 'gameView',
        },
        {
          text: "Et pour finir, tu vas dire comment tu te sens après l'exercice.",
          img: 'gameView',
        },
        {
          text: 'Pour un maximum de bénéfice, essaye de faire cet exercice 3 fois par jour !',
        },
      ],
    });

    this.landingAnimation = new LandingAnimation(this, this.plane);
  }

  onEscDown() {
    this.game.scene.pause('game');
    this.scene.launch('pause', {
      data: this,
    });
  }

  finishGame() {
    console.log('Game over');
    this.landingAnimation.start();
  }

  setVolume() {
    this.musicIntro.volume = this.volume + (this.volume ? 0.2 : 0);
    this.musicMain.volume = this.volume + (this.volume ? 0.2 : 0);
    this.birds.volume = this.volume;
    this.planeSound.volume = this.volume;
  }

  update() {
    if (this.liftoffAnimation) {
      if (!this.musicIntro.isPlaying)
        this.musicIntro.play({ volume: this.volume + (this.volume ? 0.2 : 0) });
      if (this.liftoffAnimation.onCompleted()) {
        this.countdown.start(this.finishGame.bind(this), 306000);
        this.dialogBox.start('koalou');
        delete this.liftoffAnimation;
        this.liftoffAnimation = null;
        this.musicIntro.loop = false;
      } else this.liftoffAnimation.update();
    } else if (this.countdown.timerEvent) {
      if (!this.musicIntro.isPlaying && !this.musicMain.isPlaying) {
        this.musicMain.play({ volume: this.volume + (this.volume ? 0.2 : 0) });
      }
      this.planeTrajectory.update();
    } else if (this.landingAnimation) {
      if (this.landingAnimation.onCompleted()) {
        delete this.landingAnimation;
        this.landingAnimation = null;
        this.tweens.add({
          targets: this.musicMain,
          volume: 0,
          duration: 5000,
        });
        //this.game.scene.pause('game');
        this.scene.launch('ask-for-feelings', {
          data: [
            'Comment tu te sens maintenant ?',
            "Bravo ! C'est terminé, à bientôt !",
          ],
        });
      } else {
        this.landingAnimation.update();
        if (this.plane.y < this.scale.height / 2) {
          this.hills.setX(this.plane.x + 100).setFlipX(true);
        }
      }
    }

    this.countdown.update();
    this.progressionBar.update();

    //Move offscreen clouds forward to reuse them
    if (this.clouds) {
      this.clouds.children.iterate((child) => {
        const cloud = child;
        const scrollX = this.cameras.main.scrollX;
        if (cloud.x <= scrollX - 250) {
          const cloudsArr = ['cloud1', 'cloud2', 'cloud3'];
          this.clouds.create(
            cloud.x + 2000,
            cloud.y,
            Phaser.Utils.Array.GetRandom(cloudsArr)
          );
          cloud.destroy();
        }
      });
    }

    //Move offscreen ground objects forward to reuse them
    if (this.groundObjects) {
      this.groundObjects.children.iterate((child) => {
        const groundObject = child;
        const scrollX = this.cameras.main.scrollX;
        if (groundObject.x <= scrollX - 250) {
          const groundObjectsArr = [
            'tree1',
            'tree2',
            'tree3',
            'tree4',
            'house1',
            'house2',
          ];
          this.groundObjects
            .create(
              groundObject.x + 2000,
              groundObject.y,
              Phaser.Utils.Array.GetRandom(groundObjectsArr)
            )
            .setScale(0.75);
          groundObject.destroy();
        }
      });
    }

    //Sunset filter progress
    if (this.filterSunset.alpha < 0.5) {
      this.filterSunset.setAlpha(this.filterSunset.alpha + 0.00003);
    }
  }
}
