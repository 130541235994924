import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import activitiesImage from '../../../theme/images/spaceParent/activities.png';
import emotionImage from '../../../theme/images/spaceParent/emotion.png';
// import statementOfTheDayImage from '../../../theme/images/spaceParent/statementOfTheDay.png';
// import todoListImage from '../../../theme/images/spaceParent/todoList.png';
import trackingImage from '../../../theme/images/spaceParent/tracking.png';
import CustomCard from '../../CustomCard/CustomCard';
interface CarouselProps {
  handleKey: (value: string) => void;
}

const Carousel: React.FunctionComponent<CarouselProps> = ({ handleKey }) => {
  // const keysArr = ['emotions', 'daily', 'activities', 'dashboard', 'todo'];
  const keysArr = ['emotions', 'activities', 'dashboard'];
  const [position, setPosition] = useState<number>(1);

  const downPosition = () => {
    if (position > 1) setPosition(position - 1);
  };

  const upPosition = () => {
    if (position < 3) setPosition(position + 1);
  };

  const handleNavButtonClick = (newPosition: number) => {
    setPosition(newPosition);
  };

  useEffect(() => {
    handleKey(keysArr[position - 1]);
  }, [position]);

  return (
    <CarouselContainer>
      <CarouselMain position={position}>
        <CarouselItem
          style={{ visibility: position - 1 > 1 ? 'hidden' : 'visible' }}
          data-tut="reactour__my-space-parent__mood-test"
        >
          <CustomCard
            title="Emotions"
            key="emotions"
            image={emotionImage}
            titleDownPosition={true}
          />
        </CarouselItem>
        {/* <CarouselItem
          style={{ visibility: position - 1 > 2 ? 'hidden' : 'visible' }}
        >
          <CustomCard
            title="Phrase du jour"
            key="daily"
            image={statementOfTheDayImage}
            titleDownPosition={true}
          />
        </CarouselItem> */}
        <CarouselItem
          style={{
            visibility:
              position - 1 > 2 || position + 1 < 2 ? 'hidden' : 'visible',
          }}
          data-tut="reactour__my-space-parent__activities"
        >
          <CustomCard
            title="Activités"
            key="activities"
            image={activitiesImage}
            titleDownPosition={true}
          />
        </CarouselItem>
        <CarouselItem
          style={{ visibility: position + 1 < 3 ? 'hidden' : 'visible' }}
          data-tut="reactour__my-space-parent__dashboard"
        >
          <CustomCard
            title="Suivi"
            key="dashboard"
            image={trackingImage}
            titleDownPosition={true}
          />
        </CarouselItem>
        {/* <CarouselItem
          style={{ visibility: position + 1 < 5 ? 'hidden' : 'visible' }}
        >
          <CustomCard
            title="To-Do"
            key="todo"
            image={todoListImage}
            titleDownPosition={true}
          />
        </CarouselItem> */}
        <NavArrowsContainer>
          <NavArrowContainer
            onClick={downPosition}
            arrowPosition="left"
            hidebtn={position === 1}
          >
            <NavArrowIcon icon={faChevronLeft} />
            <NavArrowBackground />
          </NavArrowContainer>
          <NavArrowContainer
          id="carousel__nav-arrow__right"
            onClick={upPosition}
            arrowPosition="right"
            hidebtn={position === 3}
          >
            <NavArrowIcon icon={faChevronRight} />
            <NavArrowBackground />
          </NavArrowContainer>
        </NavArrowsContainer>
      </CarouselMain>
      <NavButtonsContainer>
        <NavButton
          isActive={position === 1}
          onClick={() => handleNavButtonClick(1)}
        />
        <NavButton
          isActive={position === 2}
          onClick={() => handleNavButtonClick(2)}
        />
        <NavButton
          isActive={position === 3}
          onClick={() => handleNavButtonClick(3)}
        />
        {/* <NavButton
          isActive={position === 4}
          onClick={() => handleNavButtonClick(4)}
        />
        <NavButton
          isActive={position === 5}
          onClick={() => handleNavButtonClick(5)}
        /> */}
      </NavButtonsContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CarouselMain = styled.div<{ position: number }>`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 600px;
  --position: ${(props) => props.position};
  pointer-events: none;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const CarouselItem = styled.div`
  position: absolute;
  width: 300px;
  height: 100%;
  --r: calc(var(--position) - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transition: all 0.25s linear;
  /* transform: rotateY(calc(-10deg * var(--r))) */
  transform: translateX(calc(-300px * var(--r)))
    translateZ(calc(-100px * var(--abs)));
  z-index: calc(var(--position) - var(--abs));

  &:nth-of-type(1) {
    --offset: 1;
  }
  &:nth-of-type(2) {
    --offset: 2;
  }
  &:nth-of-type(3) {
    --offset: 3;
  }
  /* &:nth-of-type(4) {
    --offset: 4;
  }
  &:nth-of-type(5) {
    --offset: 5;
  } */
`;

const NavArrowsContainer = styled.div`
  width: 870px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
`;

const NavArrowContainer = styled.div<{
  arrowPosition: string;
  hidebtn: boolean;
}>`
  width: 50px;
  height: 100px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: ${(props) =>
    props.arrowPosition === 'left' ? '24px 0 0 24px' : '0 24px 24px 0'};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.arrowPosition === 'left' ? 'flex-start' : 'flex-end'};
  padding: 10px;
  position: relative;
  cursor: pointer;
  visibility: ${(props) => (props.hidebtn ? 'hidden' : 'visible')};
`;

const NavArrowIcon = styled(FontAwesomeIcon)`
  opacity: 2 !important;
  pointer-events: auto;
  font-size: 3rem;
  color: ${(props) => props.theme.colors.white};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    color: ${(props) => props.theme.colors.darkGreenMain};
  }
`;

const NavArrowBackground = styled.div``;

const NavButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
`;

const NavButton = styled.div<{ isActive: boolean }>`
  width: 21px !important;
  height: 21px !important;
  border-radius: 50%;
  border: 2px solid #fdfaf6;
  box-sizing: border-box;
  background: ${(props) => (props.isActive ? '#fdfaf6' : 'transparent')};
  margin: 0 5px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.creamL1};
  }
`;

export default Carousel;
