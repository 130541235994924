import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import ApplicationContext from '../utils/context';
import { ReactComponent as BooksImage } from '../theme/images/storiesChildren/books.svg';
import CustomButton from '../components/CustomButton/CustomButton';
import { Mixpanel } from '../lib/mixpanel';
import expression1 from '../theme/images/anxiety-evaluation/expression_1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import Survey from '../components/Survey/Survey';

interface StoryChildrenProps {}

type ViewType = 'SURGERIES_VIEW' | 'ANXIETY_TEST_VIEW' | 'STORY_VIEW';
interface SurgeryType {
  id: string;
  date: string;
  type: string;
  organizationName: string;
}

const getLastSurveyQuery = loader('../graphql/getLastSurvey.graphql');

const StoryChildren: React.FC<StoryChildrenProps> = ({}) => {
  const history = useHistory();
  const location = useLocation();

  const [
    getLastSurveyChildAnxietyTest,
    { data: dataLastSurveyChildAnxietyTest },
  ] = useLazyQuery(getLastSurveyQuery, { fetchPolicy: 'network-only' });

  const [currentChild, setCurrentChild] = useState<{
    id: string;
    name: string;
    surname: string;
    surgeries: Array<SurgeryType>;
  } | null>(null);

  const [viewType, setViewType] = useState<ViewType>('SURGERIES_VIEW');
  const [currentSurgeryIndex, setCurrentSurgeryIndex] = useState(0);
  const [currentSurgeryId, setCurrentSurgeryId] = useState<string | null>(null);
  const [urlParams, setUrlParams] = useState<{
    cidx: string;
    sidx: string;
  } | null>(null);

  const {
    currentParentAndChildrenCxt,
    currentSurgeryIndexCxt,
    customModalCxt,
  } = useContext<any>(ApplicationContext);

  useEffect(() => {
    const cidx = new URLSearchParams(location.search).get('cidx');

    if (!cidx) {
      return history.push('/welcome');
    }
    const currentChild =
      currentParentAndChildrenCxt?.currentParentAndChildrenToEdit.children[
        cidx!
      ];
    if (!currentChild) {
      return history.push('/welcome');
    }

    setCurrentChild(currentChild);
    setUrlParams({ cidx, sidx: '' });
  }, []);

  useEffect(() => {
    if (dataLastSurveyChildAnxietyTest) {
      const lastDataSurveyTmp =
        dataLastSurveyChildAnxietyTest?.survey_result_linked_to_surgery?.[0];
      if (lastDataSurveyTmp) {
        if (!moment().isSame(lastDataSurveyTmp.created_at, 'day')) {
          //If the child has not filled the anxiety test today for this specific surgery, we display it
          setViewType('ANXIETY_TEST_VIEW');
          customModalCxt?.setShowCustomModalChildSurgeries(true);
        } else {
          setViewType('STORY_VIEW');
        }
      } else {
        //If the child has never filled the anxiety test for this specific surgery, we display it
        setViewType('ANXIETY_TEST_VIEW');
        customModalCxt?.setShowCustomModalChildSurgeries(true);
      }
    }
  }, [dataLastSurveyChildAnxietyTest]);

  const handleOnClickSurgery = () => {
    const s = currentChild?.surgeries?.[currentSurgeryIndex];
    if (!s) return;
    setCurrentSurgeryId(s.id);
    setUrlParams({ ...urlParams!, sidx: currentSurgeryIndex.toString() });
    currentSurgeryIndexCxt.setCurrentSurgeryIndex(currentSurgeryIndex);
    getLastSurveyChildAnxietyTest({
      variables: {
        patientId: currentChild?.id,
        surveyTypeValue: SURVEY_TYPE_VALUE.ANXIETY_EVALUATION,
        surgeryId: s.id,
      },
    });
  };

  const incSurgeryIndex = () => {
    const surgeriesLength = currentChild?.surgeries?.length;
    if (!surgeriesLength) return;
    if (currentSurgeryIndex + 1 < surgeriesLength) {
      setCurrentSurgeryIndex(currentSurgeryIndex + 1);
    } else {
      setCurrentSurgeryIndex(0);
    }
  };

  const decSurgeryIndex = () => {
    const surgeriesLength = currentChild?.surgeries?.length;
    if (!surgeriesLength) return;
    if (currentSurgeryIndex - 1 >= 0) {
      setCurrentSurgeryIndex(currentSurgeryIndex - 1);
    } else {
      setCurrentSurgeryIndex(surgeriesLength - 1);
    }
  };

  if (!urlParams || !currentChild) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <Title>{'Histoire'}</Title>
      {currentChild.surgeries.length === 0 ? (
        <NoSurgeriesText>
          Tu n'as aucun rendez-vous médical de prévu.
        </NoSurgeriesText>
      ) : (
        <Container>
          {viewType === 'SURGERIES_VIEW' && (
            <SurgeryChoiceContainer>
              <LeftContent>
                <ImageContainer>
                  <img src={expression1} style={{ width: '100%' }} />
                </ImageContainer>
              </LeftContent>
              <RightContent>
                <SurgeriesContainer>
                  <SurgeriesHeader>
                    <ChevronIcon
                      icon={faChevronLeft}
                      onClick={() => decSurgeryIndex()}
                    />
                    <SurgeriesTitle>Opérations</SurgeriesTitle>
                    <ChevronIcon
                      icon={faChevronRight}
                      onClick={() => incSurgeryIndex()}
                    />
                  </SurgeriesHeader>
                  <SurgeriesContent>
                    <SurgeriesLabel>Opération</SurgeriesLabel>
                    <SurgeriesText>
                      {currentChild?.surgeries?.[currentSurgeryIndex]?.type}
                    </SurgeriesText>
                    <SurgeriesLabel>Organisation</SurgeriesLabel>
                    <SurgeriesText>
                      {
                        currentChild?.surgeries?.[currentSurgeryIndex]
                          ?.organizationName
                      }
                    </SurgeriesText>
                    <SurgeriesLabel>Date</SurgeriesLabel>
                    <SurgeriesText>
                      {moment(
                        currentChild?.surgeries?.[currentSurgeryIndex]?.date
                      ).format('LL')}
                    </SurgeriesText>
                  </SurgeriesContent>
                  <ButtonContainer>
                    <CustomButton
                      content={'Commencer'}
                      handleOnClick={() => handleOnClickSurgery()}
                    />
                  </ButtonContainer>
                </SurgeriesContainer>
              </RightContent>
            </SurgeryChoiceContainer>
          )}
          {customModalCxt?.showCustomModalChildSurgeries &&
            viewType === 'ANXIETY_TEST_VIEW' && (
              <SurveyContainer>
                <Survey
                  surveyTypeValue={SURVEY_TYPE_VALUE.ANXIETY_EVALUATION}
                  surgeryId={currentSurgeryId!}
                  patientId={currentChild.id}
                />
              </SurveyContainer>
            )}
          {(viewType === 'STORY_VIEW' ||
            (viewType === 'ANXIETY_TEST_VIEW' &&
              !customModalCxt?.showCustomModalChildSurgeries)) && (
            <StoryCardContainer>
              <StoryCardImg>
                <StoryCardTitle>
                  Lisons un livre pour te préparer à l'opération
                </StoryCardTitle>
                <BooksImage style={{ width: '100%' }} />
              </StoryCardImg>
              <ButtonContainer>
                <CustomButton
                  content={'Lire'}
                  handleOnClick={() => {
                    Mixpanel?.childReadTheStory();
                    history.push(
                      `/children/story/part/1?cidx=${urlParams?.cidx}&sidx=${urlParams?.sidx}`
                    );
                  }}
                />
              </ButtonContainer>
            </StoryCardContainer>
          )}
        </Container>
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 30px 0;
  ${Heading1}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SurgeryChoiceContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.creamL1};
  box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.42);
  border-radius: 72px;
  width: 949px;
  height: 550px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 700px;
    height: 520px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    height: auto;
  }
`;

const StoryCardContainer = styled.div`
  width: 600px;
  height: 566px;
  position: absolute;
  right: 100px;
  bottom: 50px;
  background: ${(props) => props.theme.colors.creamL1};
  box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.42);
  border-radius: 72px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    right: 50px;
    bottom: 50px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    position: relative;
    right: 0px;
    bottom: 0px;
  }
`;

const StoryCardTitle = styled.div`
  ${Heading1}
  text-align: center;
  position: absolute;
  width: 100%;
  top: 20px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    ${Heading2}
    top: 60px;
  }
`;
const StoryCardImg = styled.div`
  text-align: center;
  margin-top: 30px;
  position: relative;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 20px;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const RightContent = styled.div`
  width: 50%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 90%;
`;

const SurgeriesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  user-select: none;
`;

const SurgeriesHeader = styled.div`
  border-bottom: 1px solid #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SurgeriesTitle = styled.div`
  ${Heading1}
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const SurgeriesContent = styled.div`
  padding-top: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    padding: 0;
  }
`;
const SurgeriesLabel = styled.div`
  ${Heading2}
  margin: 20px 0;
`;

const SurgeriesText = styled.div`
  ${Heading2}
  font-weight: 400;
`;

const SurveyContainer = styled.div`
  background: ${(props) => props.theme.colors.creamL1};
  box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.42);
  border-radius: 72px;
  max-width: 1100px;
`;

const NoSurgeriesText = styled.div`
  color: ${(props) => props.theme.colors.darkMain};
  ${Heading2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;

export default StoryChildren;
