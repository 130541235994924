import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { NextArrowStyled } from './styles';

const NextArrow = ({ nextPart, image, childIdx, surgeryIdx }) => (
  <NextArrowStyled image={image}>
    <Link
      to={`/children/story/part/${nextPart}?cidx=${childIdx}&sidx=${surgeryIdx}`}
    ></Link>
  </NextArrowStyled>
);

NextArrow.propTypes = {
  nextPart: PropTypes.number.isRequired,
};

export default NextArrow;
