import React, { useContext, useState } from 'react';
import EmotionChart from '../EmotionChart/EmotionChart';
//import AnxietyChart from '../AnxietyChart/AnxietyChart';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { Loader } from '../Loader/Loader';
import ApplicationContext from '../../utils/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Heading2, Heading3 } from '../../theme/themeComponents/fonts';
import moment from 'moment';

const getParentEmotion = loader('../../graphql/getParentEmotion.graphql');
const getChildrenEmotions = loader('../../graphql/getChildrenEmotions.graphql');

interface ChartsCardProps {
  label: string;
  children?: boolean;
}

const formatDate = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  );
};

const getAverageFeeling = (data: any, children: boolean) => {
  let totalValue = 0;
  let valuesNumber = 0;

  for (let value of data) {
    totalValue += children
      ? value.survey_result_details_not_linked_to_surgeries?.[0]?.survey_answer
          .order
      : value.survey_result_details_not_linked_to_surgeries?.[0]?.survey_answer
          ?.order;
    valuesNumber++;
  }
  return valuesNumber ? Math.round(totalValue / valuesNumber) : 0.5;
};

const getChartData = (
  data: any,
  endDate: string,
  startDate: Date,
  children: boolean = false
) => {
  let currDate = startDate;
  const chartData: number[] = [NaN];

  let dataTmp = [];
  if (children) {
    dataTmp = data?.filter(
      (e: any) => e?.survey_result_details_not_linked_to_surgeries?.[0]
    );
  } else {
    dataTmp = data?.filter((e: any) => e?.created_at);
  }

  do {
    currDate = new Date(currDate.getTime() + 24 * 60 * 60 * 1000);
    chartData.push(
      getAverageFeeling(
        dataTmp.filter((e: any) =>
          children
            ? moment(
                e?.survey_result_details_not_linked_to_surgeries?.[0]
                  ?.created_at
              ).isSame(currDate, 'day')
            : moment(e?.created_at).isSame(currDate, 'day')
        ),
        children
      )
    );
  } while (formatDate(currDate) !== endDate);

  return chartData;
};

const ChartsCard: React.FunctionComponent<ChartsCardProps> = ({
  label,
  children = false,
}) => {
  const date = new Date();
  const currDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [currChild, setCurrChild] = useState<number>(0);
  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);
  const { data: childrenData, loading: childrenLoading } = useQuery(
    getChildrenEmotions,
    {
      variables: { date: formatDate(currDate) },
      fetchPolicy: 'network-only',
    }
  );

  const { data: parentData, loading: parentLoading } = useQuery(
    getParentEmotion,
    {
      variables: { date: formatDate(currDate) },
      fetchPolicy: 'network-only',
    }
  );

  const prevChild = () => {
    if (currChild > 0) setCurrChild(currChild - 1);
  };

  const nextChild = () => {
    if (currChild < childrenData.patient.length - 1)
      setCurrChild(currChild + 1);
  };

  if (parentLoading || childrenLoading) {
    return <Loader />;
  }

  return (
    <ChartsCardContainer>
      <ChartsCardHeader>
        <ChartsCardTitleContainer>
          {children && (
            <NavArrowButton variant="light">
              <NavArrowIcon icon={faChevronLeft} onClick={prevChild} />
            </NavArrowButton>
          )}
          <ChartsCardTitle>
            {children
              ? currentParentAndChildrenCxt.currentParentAndChildrenToEdit
                  .children[currChild].name
              : currentParentAndChildrenCxt.currentParentAndChildrenToEdit.name}
          </ChartsCardTitle>
          {children && (
            <NavArrowButton variant="light">
              <NavArrowIcon icon={faChevronRight} onClick={nextChild} />
            </NavArrowButton>
          )}
        </ChartsCardTitleContainer>
        {/*<TimeLapsButtons>
          <TimeLapsBtn>Semaine</TimeLapsBtn>
          <TimeLapsBtn>Mois</TimeLapsBtn>
        </TimeLapsButtons>*/}
      </ChartsCardHeader>
      <ChartsCardBody>
        <ChartTitle>Emotions</ChartTitle>
        <ChartContainer>
          <EmotionChart
            label={label}
            data={
              children
                ? getChartData(
                    childrenData.patient[currChild]
                      .survey_result_not_linked_to_surgeries,
                    formatDate(date),
                    currDate,
                    children
                  )
                : getChartData(
                    parentData.survey_result_not_linked_to_surgery,
                    formatDate(date),
                    currDate
                  )
            }
            currDay={(date.getDay() + 1) % 7}
          />
        </ChartContainer>
      </ChartsCardBody>
    </ChartsCardContainer>
  );
};

const ChartsCardContainer = styled.div`
  width: 350px;
  height: 100%;
  background: #fdfaf6;
  border-radius: 3rem;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const ChartsCardHeader = styled.div`
  width: 80%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const ChartsCardTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ChartsCardTitle = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  ${Heading2};
`;

const NavArrowButton = styled(Button)`
  background: transparent;
  border-color: transparent;
  width: 30px !important;
  padding: 0;
  margin: 0 !important;
  padding-top: 5px;
`;

const NavArrowIcon = styled(FontAwesomeIcon)`
  pointer-events: auto;
  font-size: 2rem;
  color: grey;
`;

const TimeLapsButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeLapsBtn = styled(Button)`
  width: 50%;
  margin: 0 !important;
`;

const ChartsCardBody = styled.div`
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

const ChartTitle = styled.p`
  ${Heading3};
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 60%;
`;

export default ChartsCard;
