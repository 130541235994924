import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomFlipCard from '../components/CustomFlipCard/CustomFlipCard';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import { getIndexSelectedLanguage } from '../utils/translation';
import activityBg1Image from '../theme/images/activitiesChildren/activityBg1.png';
import activityBg2Image from '../theme/images/activitiesChildren/activityBg2.png';
import activityBg3Image from '../theme/images/activitiesChildren/activityBg3.png';
import activityBg4Image from '../theme/images/activitiesChildren/activityBg4.png';
import activityBg5Image from '../theme/images/activitiesChildren/activityBg5.png';
import activityBg6Image from '../theme/images/activitiesChildren/activityBg6.png';
import { Mixpanel } from '../lib/mixpanel';
import ApplicationContext from '../utils/context';

interface ActivitiesChildrenProps {}

const getSuggestedActivitiesQuery = loader(
  '../graphql/getSuggestedActivities.graphql'
);

const imagesArray = [
  activityBg1Image,
  activityBg2Image,
  activityBg3Image,
  activityBg4Image,
  activityBg5Image,
  activityBg6Image,
];

const ActivitiesChildren: React.FC<ActivitiesChildrenProps> = ({}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [loading, setLoading] = useState(true);
  const [grantAccess, setGrantAccess] = useState(false);
  const { data: dataActivities } = useQuery(getSuggestedActivitiesQuery, {
    variables: {
      language: i18n.language,
    },
  });

  useEffect(() => {
    if (
      dataActivities &&
      currentParentAndChildrenCxt?.currentParentAndChildrenToEdit.id
    ) {
      if (
        currentParentAndChildrenCxt.currentParentAndChildrenToEdit
          .hasActivatedEmotionalHealth
      ) {
        setGrantAccess(true);
      }
      setLoading(false);
    }
  }, [
    dataActivities,
    currentParentAndChildrenCxt?.currentParentAndChildrenToEdit,
  ]);

  const selectBgImage = (index: number) => {
    if (index + 1 <= imagesArray.length - 1) {
      return imagesArray[index];
    } else {
      return imagesArray[index % imagesArray.length];
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      handleOnClickPreviousPage={() =>
        history.push(
          `/children/home?cidx=${new URLSearchParams(location.search).get(
            'cidx'
          )}`
        )
      }
    >
      <Container>
        <Title>Activités</Title>
        {!grantAccess && (
          <LockText>
            Tes parents peuvent débloquer cette fonctionnalité dans leur espace
            !
          </LockText>
        )}
        {grantAccess && dataActivities && (
          <SuggestedActivitiesContainer>
            {dataActivities?.suggested_activity.map((e: any, i: number) => {
              const indexLanguage = getIndexSelectedLanguage(
                e.suggested_activity_i18ns.map(
                  (f: any) => f.language.language_code
                ),
                i18n.language
              );
              const suggestedActivity =
                e.suggested_activity_i18ns?.[indexLanguage];
              return (
                <CardContainer
                  key={i}
                  onClick={() => {
                    Mixpanel?.childReadActivityDescription(
                      suggestedActivity.title
                    );
                  }}
                >
                  <CustomFlipCard
                    frontCard={{
                      title: suggestedActivity.title,
                      titleSize: 'md',
                      backgroundImage: selectBgImage(i),
                    }}
                    backCard={{ subtitle: suggestedActivity.value }}
                  />
                </CardContainer>
              );
            })}
          </SuggestedActivitiesContainer>
        )}
      </Container>
    </PageWrapper>
  );
};

export default ActivitiesChildren;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 30px 0;
  ${Heading1}
`;

const SuggestedActivitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CardContainer = styled.div`
  width: 223px;
  height: 324px;
  margin: 20px;
`;

const LockText = styled.div`
  color: ${(props) => props.theme.colors.darkMain};
  ${Heading2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`;
