import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import StorySequence from '../components/StorySequence/StorySequence';
import ApplicationContext from '../utils/context';
import stories from '../utils/stories';

const Story: React.FunctionComponent<{}> = ({}) => {
  const { i18n } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  let { part } = useParams<any>();
  const [partOne, setPartOne] = useState<any>(undefined);
  const [nextPreviousPart, setNextPreviousPart] = useState<any>(null);
  const [urlParams, setUrlParams] = useState<{
    cidx: string;
    sidx: string;
  } | null>(null);
  // const [translationIsReady, setTranslationIsReady] = useState(false);
  useEffect(() => {
    const cidx = new URLSearchParams(location.search).get('cidx');
    const sidx = new URLSearchParams(location.search).get('sidx');

    if (!cidx || !sidx) {
      return history.push('/welcome');
    }
    const currentSurgeryTmp =
      currentParentAndChildrenCxt?.currentParentAndChildrenToEdit?.children[
        cidx!
      ]?.surgeries[sidx!];
    if (!currentSurgeryTmp) {
      return history.push('/welcome');
    } else {
      const story = stories.getStory(currentSurgeryTmp.story);
      if (!story) {
        console.error('No story for this surgery_type');
        return;
      }
      let partInt = parseInt(part);
      const partOneFromStory =
        story &&
        story.filter((part: any) => part.partNumber === partInt).shift();

      const nextPart = !!story
        .filter((item: any) => item.partNumber === partInt + 1)
        .shift();
      const previousPart = !!story
        .filter((item: any) => item.partNumber === partInt - 1)
        .shift();

      setNextPreviousPart({ nextPart, previousPart });
      setPartOne(partOneFromStory);
      setUrlParams({ cidx, sidx });
    }
  }, [part]);

  //TODO wip test translation story, todo fallback if language is not available
  // useEffect(() => {
  //   if (partOne) {
  //    const language = i18n.language;
  //     if (language === 'fr') {
  //       partOne.bottomText = partOne.bottomTextFr;
  //     } else if (language === 'en') {
  //       partOne.bottomText = partOne.bottomTextEn;
  //     }
  //     setTranslationIsReady(true);
  //   }
  // }, [partOne]);

  return (
    <>
      {/* translationIsReady &&  */}
      {partOne && nextPreviousPart && setUrlParams && (
        <StorySequence
          actualPart={parseInt(part)}
          bottomText={partOne?.bottomText}
          forceShowFullImage={partOne?.forceShowFullImage}
          nextPart={nextPreviousPart.nextPart ? parseInt(part + 1) : null}
          previousPart={
            nextPreviousPart.previousPart ? parseInt(part + 1) : null
          }
          professor={partOne?.showProfessor}
          professorText={partOne?.professorText}
          rightText={partOne?.rightText}
          leftText={partOne?.leftText}
          storyImageNumber={partOne?.imageNumber}
          childIdx={urlParams?.cidx}
          surgeryIdx={urlParams?.sidx}
        />
      )}
    </>
  );
};

export default Story;
