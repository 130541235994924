import { LANGUAGES } from '../constants/languages_types';

export const getIndexSelectedLanguage = (
  array: Array<string>,
  selectedLanguage: string
) => {
  let index = array.findIndex((e) => e === selectedLanguage);
  if (index < 0) {
    index = array.findIndex((e) => e === LANGUAGES.FR_FR);
    if (index < 0) return 0;
    return index;
  } else {
    return index;
  }
};
