import { FormControlLabel } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import expression1 from '../../theme/images/anxiety-evaluation/expression_1.png';
import expression2 from '../../theme/images/anxiety-evaluation/expression_2.png';
import expression3 from '../../theme/images/anxiety-evaluation/expression_3.png';
import expression4 from '../../theme/images/anxiety-evaluation/expression_4.png';
import expression5 from '../../theme/images/anxiety-evaluation/expression_5.png';
import expression6 from '../../theme/images/anxiety-evaluation/expression_6.png';
import { Heading3 } from '../../theme/themeComponents/fonts';

const renderImage = (index) => {
  switch (index) {
    case 1:
      return <CardMediaStyled image={expression1} />;
    case 2:
      return <CardMediaStyled image={expression2} />;
    case 3:
      return <CardMediaStyled image={expression3} />;
    case 4:
      return <CardMediaStyled image={expression4} />;
    case 5:
      return <CardMediaStyled image={expression5} />;
    case 6:
      return <CardMediaStyled image={expression6} />;

    default:
      break;
  }
};
const AnxietyAnswer = ({ answer, checked, imageNumber, value }) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <CardContainer isActive={checked}>
      <FormControlLabelStyled
        control={
          <>
            <Text>{value}</Text>
            {renderImage(answer.order)}
            <CardContent>
              <Grid container item xs={12} justify="center" alignItems="center">
                <div style={{ display: 'none' }}>
                  <AnswerRadioButton value={value} />
                </div>
              </Grid>
            </CardContent>
          </>
        }
        value={answer.order}
        labelPlacement="bottom"
      />
    </CardContainer>
  </Grid>
);

const CardContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.lightGreenMain : ''};
  border-radius: 64px;
`;
const Text = styled.div`
  ${Heading3}
  margin: 20px 0;
  text-align: center;
`;

const CardMediaStyled = styled(CardMedia)`
  border-radius: 50%;
  border: solid 2px #4798b6;
  height: 175px;
  width: 175px;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  height: 100%;
  justify-content: flex-end;
  margin-left: 0 !important;
  width: 100%;
`;

const AnswerRadioButton = styled(Radio)``;

AnxietyAnswer.propTypes = {
  answer: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  imageNumber: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default AnxietyAnswer;
