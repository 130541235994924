import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl } from '@material-ui/core';
import AnxietyTest from '../AnxietyTest/AnxietyTest';
import Flex from '../Layouts/Flex/Flex';
import CustomButton from '../CustomButton/CustomButton';

const AnxietyTestForm = ({
  handleSubmit,
  questions,
  handleAnswerRadioButtonChange,
}) => (
  <form className="needs-validation" onSubmit={handleSubmit}>
    <FormControl>
      {questions &&
        questions.length > 0 &&
        questions.map((question) => {
          const {
            survey_type: { answers },
          } = question;
          return (
            <Box key={question.id}>
              <AnxietyTest
                key={question.id}
                question={question}
                answers={answers}
                handleAnswerRadioButtonChange={handleAnswerRadioButtonChange}
              />
            </Box>
          );
        })}
      <Flex alignItems="center" direction="column" justifyContent="center">
        <div style={{ margin: '20px 0' }}>
          <CustomButton type="submit" content={'Soumettre'} />
        </div>
      </Flex>
    </FormControl>
  </form>
);

AnxietyTestForm.propTypes = {
  handleAnswerRadioButtonChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
};

export default AnxietyTestForm;
