import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { BodyMain, Heading1 } from '../theme/fonts';
import { Heading2 } from '../theme/themeComponents/fonts';
import moment from 'moment';
import { LinearGradientText } from '../theme/components/ThemeComponents';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Loader } from '../components/Loader/Loader';

const getArticlesQuery = loader('../graphql/getArticles.graphql');

interface Articles {
  title: string;
  featureImage: string;
  content: string;
  date: string;
}

interface ArticlesParentProps {}

const ArticlesParent: React.FC<ArticlesParentProps> = () => {
  const history = useHistory();

  const [articles, setArticles] = useState<Articles[]>();
  const [articleIndex, setArticleIndex] = useState<number | null>(null);

  const { loading, data: articlesData } = useQuery(getArticlesQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (articlesData?.article) {
      const articlesTmp = articlesData?.article.map((e: any) => {
        return {
          title: e.title,
          featureImage: e.feature_image,
          content: e.html,
          date: e.created_at,
        };
      });
      setArticles(articlesTmp);
    }
  }, [articlesData]);

  useEffect(() => {
    if (articleIndex !== null) {
      const s = document.getElementById('article-full-content');
      if (s) {
        s.innerHTML =
          `<h1 id="article-title">${articles?.[articleIndex].title}</h1>` +
          articles?.[articleIndex].content;
      }
    }
  }, [articleIndex]);

  const articleIsShowed = () => {
    if (articleIndex !== null) return true;
    else return false;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        articleIsShowed()
          ? setArticleIndex(null)
          : history.push('/parent/emotional-health/home')
      }
    >
      <Title>Articles</Title>
      {!articleIsShowed() && (
        <ArticlesContainer>
          {articles?.map((e, i) => {
            return (
              <ArticleBox
                key={i}
                onClick={() => {
                  setArticleIndex(i);
                }}
              >
                <ArticleImage>
                  {console.log(e.featureImage)}
                  <img src={e.featureImage} />
                </ArticleImage>
                <ArticleInfos>
                  <ArticleTitle>{e.title}</ArticleTitle>
                  <ArticleDate>
                    {moment(e.date).format('DD/MM/YYYY')}
                  </ArticleDate>
                </ArticleInfos>
              </ArticleBox>
            );
          })}
        </ArticlesContainer>
      )}
      {articleIsShowed() && (
        <ArticleFullContent id="article-full-content"></ArticleFullContent>
      )}
    </PageWrapper>
  );
};

export default ArticlesParent;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const ArticleFullContent = styled.div`
  & > * {
    text-align: justify;
    text-justify: inter-word;
  }
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 50px;
  margin-right: 30px;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  & img {
    max-width: 50%;

    @media screen and (max-width: ${(props) =>
        props.theme.breakpoints.mobilePortraitMax}) {
      max-width: 100%;
    }
  }
  & #article-title {
    ${LinearGradientText};
    padding: 56px 0;
    text-align: center;
    ${Heading1}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    margin-right: unset;
  }
`;

const ArticlesContainer = styled.div``;

const ArticleBox = styled.article`
  cursor: pointer;
  display: flex;
  padding: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.creamL1};
  border-radius: 12px;
  max-width: 780px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    max-width: 100%;
    flex-direction: column;
  }
`;

const ArticleTitle = styled.div`
  ${Heading2}
`;

const ArticleImage = styled.div`
  /* width: 100px; */
  /* height: 100px; */
  margin-right: 25px;

  & img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    height: auto;
  }
`;

const ArticleDate = styled.div`
  margin-top: 10px;
  ${BodyMain}
`;

const ArticleInfos = styled.div``;
