import React from 'react';
import styled from 'styled-components';

interface CardLayoutProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
  autoHeightMobile?: boolean;
}

export const CardLayout: React.FC<CardLayoutProps> = ({
  children,
  width,
  height,
  backgroundColor,
  autoHeightMobile,
}) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      autoHeightMobile={autoHeightMobile}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{
  backgroundColor?: string;
  width?: string;
  height?: string;
  autoHeightMobile?: boolean;
}>`
  position: relative;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.creamL1};
  padding: 40px;
  border-radius: 48px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    height: ${(props) => (props.autoHeightMobile ? '100%' : '')};
    padding: 20px;
  }
`;
