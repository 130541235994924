import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCog,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';
import { logout } from '../../utils/auth';
import { useTranslation } from 'react-i18next';
interface PageWrapperProps {
  settingsIconOn?: boolean;
  previousPageIconOn?: boolean;
  logoutIconOn?: boolean;
  handleOnClickPreviousPage?: () => void;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  settingsIconOn,
  previousPageIconOn,
  logoutIconOn,
  handleOnClickPreviousPage,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const displayNavbar = () => {
    return !!logoutIconOn || !!previousPageIconOn || !!settingsIconOn;
  };

  return (
    <Page>
      {/* {displayNavbar() && ( */}
      <OptionsContainer paddingBurgerIcon={location.pathname !== '/welcome'}>
        <LeftContainer>
          {logoutIconOn && (
            <IconContainer onClick={() => logout()}>
              <Icon icon={faSignOutAlt} />
              <IconText>{t('common:logoutButton')}</IconText>
            </IconContainer>
          )}
          {previousPageIconOn && (
            <IconContainer
              onClick={() =>
                handleOnClickPreviousPage ? handleOnClickPreviousPage() : null
              }
            >
              <Icon icon={faArrowLeft} />
              <IconText>Retour</IconText>
            </IconContainer>
          )}
        </LeftContainer>
        <RightContainer>
          {settingsIconOn && (
            <IconContainer
              onClick={() => history.push('/settings')}
              data-tut="reactour__welcome__settings"
            >
              <IconText>Paramètres</IconText>
              <Icon icon={faCog} />
            </IconContainer>
          )}
        </RightContainer>
      </OptionsContainer>
      {/* )} */}

      <Container displayNavbar={displayNavbar()} id="page-wrapper__container">
        {children}
      </Container>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  position: relative;
`;

const Container = styled.div<{ displayNavbar: boolean }>`
  width: 100%;
  /* height: ${(props) =>
    props.displayNavbar ? `calc(100vh - 64px)` : '100vh'}; */
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding-left: 50px;
  padding-bottom: 20px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 0 10px 10px 10px;
  }
`;

const OptionsContainer = styled.div<{ paddingBurgerIcon: boolean }>`
  width: 100%;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  height: 64px;
  user-select: none;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding-left: ${(props) => (props.paddingBurgerIcon ? '70px' : '0')};
  }
`;

const LeftContainer = styled.div``;
const RightContainer = styled.div``;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled(FontAwesomeIcon)`
  margin: 0 10px;
`;
const IconText = styled.div``;
