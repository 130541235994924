import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import cookie from 'js-cookie';
import { COOKIES } from '../constants/cookies_type';
import * as Sentry from '@sentry/react';
import { APPLICATION_ROLE } from '../constants/db_types';
import { Mixpanel } from '../lib/mixpanel';
import i18n from 'i18next';
import { isProductionEnv } from './env';

const cookiesExpirationInTwoHour = 2 / 24;

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MESUREMENT_ID,
  });
}

const clearCookies = () => {
  for (const [key, value] of Object.entries(COOKIES)) {
    cookie.remove(value, { secure: true });
  }
};

const logout = async (path?: string) => {
  clearCookies();
  localStorage.removeItem('curratedPatient');
  localStorage.removeItem('questionIndex');
  localStorage.removeItem('questions');
  localStorage.removeItem('dentalFirstSurveyQuestionIndex');
  localStorage.removeItem('dentalFirstSurveyAnswers');
  Mixpanel?.logout();
  if (path) {
    window.location.href = path;
  }
  Sentry.setContext('koalou user', {});
  await firebase.auth().signOut();
};

const loginWithEmailAndPassword = (userData: {
  email: string;
  password: string;
  error: string;
}) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(userData.email, userData.password)
    .then(() => firebase.auth().currentUser?.getIdTokenResult(true))
    .then((idTokenResult: any) => {
      if (
        idTokenResult &&
        !idTokenResult.claims['https://hasura.io/jwt/claims']
      ) {
        return { error: 'HASURA_CLAIMS_PENDING' };
      } else if (
        idTokenResult &&
        idTokenResult.claims['https://hasura.io/jwt/claims']
      ) {
        if (
          idTokenResult.claims['https://hasura.io/jwt/claims'][
            'x-hasura-default-role'
          ] !== APPLICATION_ROLE.PARENT
        ) {
          return { error: 'ROLE_NOT_ALLOWED' };
        } else {
          setAuthenticationCookies(idTokenResult, userData.email);
          const hasuraClaims =
            idTokenResult?.claims['https://hasura.io/jwt/claims'];
          Mixpanel?.login(
            hasuraClaims['x-hasura-user-id'],
            idTokenResult?.claims.email,
            hasuraClaims['x-hasura-default-role'],
            hasuraClaims['x-hasura-allowed-roles']
          );
        }
      } else return getErrorMessage('');
    })
    .then((error) => {
      return error ? error : { msg: 'auth/ok' };
    })
    .catch((error) => {
      return getErrorMessage(error);
    });
};

const resettingPassword = async (email: string) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/reset-password`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email: email,
          type: APPLICATION_ROLE.PARENT,
        }),
      }
    );
    if (res.ok) {
      // console.log('Reset password email sent successfully !');
      return { msg: 'auth/ok' };
    } else {
      const resJson = await res.json();
      return getErrorMessage(resJson.error);
    }
  } catch (err) {
    return getErrorMessage(err);
  }
};

const savingNewPassword = async (
  userData: {
    password: string;
    confirmPassword: string;
    error: string;
  },
  actionCode: string
) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(() => {
        firebase
          .auth()
          .confirmPasswordReset(actionCode, userData.password)
          .then(() => {
            // console.log('New password successfully changed');
            resolve({ msg: 'auth/ok' });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
            reject(getErrorMessage(err));
          });
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        reject(getErrorMessage(err));
      });
  });
};

// eslint-disable-next-line consistent-return
const getErrorMessage = (error: any) => {
  if (
    error.code === 'auth/wrong-password' ||
    error.code === 'auth/invalid-email'
  ) {
    console.error('email/password authentication error : ', error);
    return { error: i18n.t('validation:authError') };
  } else if (error.code === 'auth/user-not-found') {
    console.error('email not found : ', error);
    return { error: i18n.t('validation:userNotFound') };
  } else if (error.code === 'auth/invalid-action-code') {
    console.error('invalid action code : ', error);
    return { error: i18n.t('validation:invalidActionCode') };
  } else {
    console.error('unexpected authentication error : ', error);
    return { error: i18n.t('validation:unexpectedError') };
  }
};

const setAuthenticationCookies = (
  idTokenResult: firebase.auth.IdTokenResult,
  email: string
) => {
  const { token } = idTokenResult;
  const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

  const productionEnv = isProductionEnv();
  cookie.set(COOKIES.TOKEN, token, { secure: productionEnv });
  cookie.set(COOKIES.ID, hasuraClaim['x-hasura-user-id'], {
    secure: productionEnv,
  });
  cookie.set(COOKIES.ROLE, hasuraClaim['x-hasura-default-role'], {
    secure: productionEnv,
  });
  cookie.set(COOKIES.EMAIL, email, { secure: productionEnv });
  cookie.set(
    COOKIES.ALLOWED_ROLES,
    JSON.stringify(hasuraClaim['x-hasura-allowed-roles']),
    { secure: productionEnv }
  );

  cookie.set(
    COOKIES.EXPIRATION_SESSION,
    (Date.now() + 1000 * 60 * 60 * 2).toString(),
    { secure: productionEnv }
  );
};

const getFirebaseToken = async () => {
  const user = await firebase.auth().currentUser;
  if (!user) {
    return null;
  } else {
    return await user
      .getIdToken()
      .then((token: any) => {
        return token;
      })
      .catch((err: any) => {
        console.error(err);
        if (err) throw err;
      });
  }
};

const checkIfCookiesAreSet = () => {
  let inc = 0;
  let defaultCookiesNbr = 0;
  Object.entries(COOKIES).map((e) => {
    defaultCookiesNbr++;
    if (cookie.get(e[1])) {
      inc++;
    }
    return null;
  });
  return inc === defaultCookiesNbr;
};

const signupParent = async (
  email: string,
  password: string,
  children: Array<{
    name: string;
    birthDate: string;
  }>
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/signup-parent`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
          children,
        }),
      }
    );
    if (res.ok) {
      return { msg: 'sinup-parent/ok' };
    } else {
      return {
        error: 'Une erreur est survenue',
      };
    }
  } catch (err) {
    return { error: 'Une erreur est survenue' };
  }
};

const getUserByEmail = async (email: string) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/check-user-email-signup`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      }
    );
    if (res.status === 500) {
      return {
        error: 'Une erreur est survenue',
      };
    } else {
      const resJson = await res.json();
      if (resJson.error) {
        return { error: 'Email invalide', role: null };
      } else if (resJson.role === APPLICATION_ROLE.PARENT) {
        return { error: 'Email non disponible', role: APPLICATION_ROLE.PARENT };
      } else if (resJson.role === APPLICATION_ROLE.PRACTITIONER) {
        return {
          error: 'Email non disponible',
          role: APPLICATION_ROLE.PRACTITIONER,
        };
      } else {
        return { error: null };
      }
    }
  } catch (err) {
    return {
      error: "Erreur. Veuillez contacter Koalou pour obtenir de l'aide",
    };
  }
};

const validatePassword = (password: string) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{6,}$/;
  if (regex.test(password)) {
    return true;
  } else {
    return false;
  }
};

export {
  loginWithEmailAndPassword,
  logout,
  savingNewPassword,
  resettingPassword,
  getFirebaseToken,
  checkIfCookiesAreSet,
  signupParent,
  getUserByEmail,
  validatePassword,
};
