import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { COOKIES } from '../constants/cookies_type';
import { Heading1 } from '../theme/themeComponents/fonts';
import { displayToastNotification } from '../utils/toastNotification';
import ApplicationContext from '../utils/context';
import { useHistory } from 'react-router-dom';

interface EmotionalHealthLockParentProps {}

const updateParentHasActivatedEmotionalHealthQuery = loader(
  '../graphql/updateParentHasActivatedEmotionalHealth.graphql'
);

const EmotionalHealthLockParent: React.FC<EmotionalHealthLockParentProps> =
  () => {
    const [updateParentHasActivatedEmotionalHealth] = useMutation(
      updateParentHasActivatedEmotionalHealthQuery
    );
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleClickUnlock = async () => {
      try {
        await updateParentHasActivatedEmotionalHealth({
          variables: { parentId: cookie.get(COOKIES.ID) },
        });
        setLoading(true);
        setTimeout(() => {
          history.push('/welcome?showOnboarding=direct-signup');
        }, 1500);
      } catch (err) {
        displayToastNotification('error', 'Une erreur est survenue');
        console.log(err);
      }
    };

    return (
      <PageWrapper>
        <Container>
          <Title data-tut="reactour__title">Santé émotionnelle</Title>
          <Text>
            {loading ? (
              <Loader />
            ) : (
              <p>
                Pour débloquer cette fonctionnalité et en bénéficier, veuillez{' '}
                <span onClick={() => handleClickUnlock()}> cliquer ici</span>
              </p>
            )}
          </Text>
        </Container>
      </PageWrapper>
    );
  };

export default EmotionalHealthLockParent;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
  height: 20%;
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.darkMain};
  ${Heading1};
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p > span {
    color: ${(props) => props.theme.colors.darkGreenMain};
    cursor: pointer;
  }
`;

const Container = styled.div`
  height: calc(100% - 64px);
`;
