import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomFlipCard from '../../components/CustomFlipCard/CustomFlipCard';
import activityBg1Image from '../../theme/images/activitiesChildren/activityBg1.png';
import expression1 from '../../theme/images/anxiety-evaluation/expression_1.png';
import expression4 from '../../theme/images/anxiety-evaluation/expression_4.png';
import expression6 from '../../theme/images/anxiety-evaluation/expression_6.png';
import { Heading1, Heading2 } from '../../theme/themeComponents/fonts';

const imagesArray = [
  {
    image: expression1,
    value: 0,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
  {
    image: expression1,
    value: 0,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
  {
    image: expression4,
    value: 1,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
  {
    image: expression4,
    value: 1,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
  {
    image: expression6,
    value: 2,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
  {
    image: expression6,
    value: 2,
    flipDisabled: false,
    success: false,
    triggerFlip: -1,
  },
];

type GameStep = 'MENU' | 'PLAYING' | 'END';

interface MemoryGameProps {}

const MemoryGame: React.FC<MemoryGameProps> = ({}) => {
  const { t } = useTranslation();
  const [randomDeck, setRandomDeck] =
    useState<Array<{
      image: any;
      value: number;
      flipDisabled: boolean;
      success: boolean;
      triggerFlip: number;
    }> | null>(null);

  const [currentStep, setCurrentStep] = useState<GameStep>('MENU');

  useEffect(() => {
    initGame();
  }, []);

  const initGame = () => {
    const imagesArrayTmp = imagesArray.map((e) => ({ ...e, triggerFlip: 0 }));
    const randomDeckTmp = imagesArrayTmp.sort(() => Math.random() - 0.5);
    setRandomDeck([...randomDeckTmp]);
  };

  const resetGame = () => {
    setCurrentStep('PLAYING');
    initGame();
    document.getElementById('memory-game-container')!.style.pointerEvents =
      'auto';
  };

  const checkGameSuccess = () => {
    if (
      randomDeck!.filter((e) => e.success).length ===
      randomDeck!.length - 2
    ) {
      setCurrentStep('END');
      return true;
    }
    return false;
  };

  const handleClickSelectCard = (index: number) => {
    if (randomDeck?.[index].flipDisabled === false) {
      const selectedCards = randomDeck.filter(
        (e) => e.flipDisabled && !e.success
      );
      if (selectedCards.length === 0) {
        let randomDeckTmp = [...randomDeck];
        randomDeckTmp[index].flipDisabled = true;
        setRandomDeck(randomDeckTmp);
      } else if (selectedCards.length === 1) {
        // both card are equal
        if (randomDeck[index].value === selectedCards[0].value) {
          if (checkGameSuccess()) {
            const randomDeckTmp = randomDeck.map((e) => {
              return {
                ...e,
                flipDisabled: true,
                success: true,
                triggerFlip: -1,
              };
            });
            setRandomDeck(randomDeckTmp);
          } else {
            const randomDeckTmp = randomDeck.map((e) => {
              if (e.value === randomDeck[index].value) {
                return { ...e, flipDisabled: true, success: true };
              } else {
                return { ...e };
              }
            });
            setRandomDeck(randomDeckTmp);
          }
        } else {
          //reset because not the same card
          document.getElementById(
            'memory-game-container'
          )!.style.pointerEvents = 'none';
          setTimeout(() => {
            const randomDeckTmp = randomDeck.map((e, i) => {
              if (index === i || (!e.success && e.flipDisabled)) {
                return {
                  ...e,
                  flipDisabled: false,
                  triggerFlip: e.triggerFlip + 1,
                };
              } else if (!e.success) {
                return { ...e, flipDisabled: false };
              } else {
                return { ...e };
              }
            });
            setRandomDeck(randomDeckTmp);
            document.getElementById(
              'memory-game-container'
            )!.style.pointerEvents = 'auto';
          }, 2000);
        }
      }
    }
  };

  return (
    <div>
      <Title>{t('game:pageTitle')}</Title>
      <Subtitle>Memory Game</Subtitle>
      <MemoryGameContainer id="memory-game-container">
        {currentStep === 'MENU' && (
          <InfosContainer>
            <InfosContent>
              {t('game:memoryGame.startTitle')}
              <br />
              <br />
              {t('game:memoryGame.startSubtitle')}
            </InfosContent>
            <StartButtonContainer>
              <CustomButton
                content={t('game:memoryGame.startButton')}
                handleOnClick={() => setCurrentStep('PLAYING')}
              />
            </StartButtonContainer>
          </InfosContainer>
        )}
        {currentStep === 'PLAYING' &&
          randomDeck?.map((e, i) => (
            <MemoryCardContainer
              key={i}
              onClick={(e) => {
                handleClickSelectCard(i);
              }}
            >
              <CustomFlipCard
                triggerFlip={e.triggerFlip}
                flipDisabled={e.flipDisabled}
                frontCard={{ backgroundImage: activityBg1Image }}
                backCard={{ backgroundImage: e.image }}
              />
            </MemoryCardContainer>
          ))}
        {currentStep === 'END' && (
          <InfosContainer>
            <InfosContent>{t('game:memoryGame.restartTitle')}</InfosContent>
            <StartButtonContainer>
              <CustomButton
                content={t('game:memoryGame.restartButton')}
                handleOnClick={() => resetGame()}
              />
            </StartButtonContainer>
          </InfosContainer>
        )}
      </MemoryGameContainer>
    </div>
  );
};

export default MemoryGame;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 60px 0;
  ${Heading1}
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2};
`;

const MemoryGameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const InfosContainer = styled.div`
  text-align: center;
  padding: 62px;
  background: ${(props) => props.theme.colors.creamL1};
  box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.42);
  border-radius: 48px;
  width: 669px;
  height: 498px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    height: auto;
    padding: 20px;
  }
`;

const InfosContent = styled.div`
  ${Heading1}
`;

const StartButtonContainer = styled.div`
  margin-top: 50px;
`;

const MemoryCardContainer = styled.div`
  width: 223px;
  height: 324px;
  position: relative;
  margin: 20px;
  user-select: none;
`;
