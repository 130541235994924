function validateSurveyForm(values) {
  const errors = {}

  if (values.questions && Object.keys(values.questions).length > 0) {
    // eslint-disable-next-line array-callback-return
    values.questions.map(question => {
      if (!question.answer_id && !question.skippable) {
        errors[question.id] = 'Veuillez entrer un choix'
      }
    })
  }
  return errors
}
export default validateSurveyForm
