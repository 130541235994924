import mixpanel from 'mixpanel-browser';
import { isProductionEnv } from '../utils/env';

if (isProductionEnv()) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KOALOU_PARENT_TOKEN);
}

export const login = (id, email, role, allowedRoles) => {
  mixpanel.identify(id);
  mixpanel.people.set({
    $email: email,
    Role: role,
    'Allowed Roles': allowedRoles,
    'Last Connection': new Date().toISOString(),
  });
  mixpanel.people.increment('Number of connections');
  mixpanel.people.set_once({
    'First Connection': new Date().toISOString(),
  });
  mixpanel.track('Login');
};

export const logout = () => {
  mixpanel.track('Logout');
  mixpanel.reset();
};

export const accessToParentSpace = () => {
  mixpanel.track('Access to parent space');
};

export const accessToMedicalAppointment = () => {
  mixpanel.track('Access to medical appointment');
};

export const changeProfileToParent = () => {
  mixpanel.track('Change profile to parent');
};

export const changeProfileToChildren = () => {
  mixpanel.track('Change profile to children');
};

export const childReadTheStory = () => {
  mixpanel.track('Child read the story');
};

export const childReadActivityDescription = (title) => {
  mixpanel.track('Child read activity description', {
    'Activity Title': title,
  });
};

export const finishSubscribe = id => {
  mixpanel.track('User finished to subscribe', {
    trackingId: id,
    date : new Date().toISOString()
  })
}

export let Mixpanel = isProductionEnv()
  ? {
      login,
      logout,
      accessToParentSpace,
      accessToMedicalAppointment,
      changeProfileToParent,
      changeProfileToChildren,
      childReadTheStory,
      childReadActivityDescription,
      finishSubscribe
    }
  : null;
