import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Card, MenuItem, Select } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Avatars from '../components/Avatars/Avatars';
import CustomButton from '../components/CustomButton/CustomButton';
import CustomModal from '../components/CustomModal/CustomModal';
import InputField from '../components/InputField/InputField';
import PhoneInputField from '../components/InputField/PhoneInputField';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import PdfViewer from '../components/PdfViewer/PdfViewer';
import { LANGUAGES } from '../constants/languages_types';
import Avatar1 from '../theme/images/avatars/avatar1.png';
import Avatar10 from '../theme/images/avatars/avatar10.png';
import Avatar11 from '../theme/images/avatars/avatar11.png';
import Avatar12 from '../theme/images/avatars/avatar12.png';
import Avatar13 from '../theme/images/avatars/avatar13.png';
import Avatar14 from '../theme/images/avatars/avatar14.png';
import Avatar15 from '../theme/images/avatars/avatar15.png';
import Avatar16 from '../theme/images/avatars/avatar16.png';
import Avatar17 from '../theme/images/avatars/avatar17.png';
import Avatar18 from '../theme/images/avatars/avatar18.png';
import Avatar19 from '../theme/images/avatars/avatar19.png';
import Avatar2 from '../theme/images/avatars/avatar2.png';
import Avatar20 from '../theme/images/avatars/avatar20.png';
import Avatar3 from '../theme/images/avatars/avatar3.png';
import Avatar4 from '../theme/images/avatars/avatar4.png';
import Avatar5 from '../theme/images/avatars/avatar5.png';
import Avatar6 from '../theme/images/avatars/avatar6.png';
import Avatar7 from '../theme/images/avatars/avatar7.png';
import Avatar8 from '../theme/images/avatars/avatar8.png';
import Avatar9 from '../theme/images/avatars/avatar9.png';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';
import { displayToastNotification } from '../utils/toastNotification';
import {
  changePersonalInformationsSchema,
  validatePhoneNumber,
} from '../utils/validationRules';
import { orderBy, capitalize } from 'lodash';
// import ApplicationContext from '../utils/context';

/* TODO lazy loading ? */
const avatarsImages = [
  { image: Avatar1, name: 'avatar1' },
  { image: Avatar2, name: 'avatar2' },
  { image: Avatar3, name: 'avatar3' },
  { image: Avatar4, name: 'avatar4' },
  { image: Avatar5, name: 'avatar5' },
  { image: Avatar6, name: 'avatar6' },
  { image: Avatar7, name: 'avatar7' },
  { image: Avatar8, name: 'avatar8' },
  { image: Avatar9, name: 'avatar9' },
  { image: Avatar10, name: 'avatar10' },
  { image: Avatar11, name: 'avatar11' },
  { image: Avatar12, name: 'avatar12' },
  { image: Avatar13, name: 'avatar13' },
  { image: Avatar14, name: 'avatar14' },
  { image: Avatar15, name: 'avatar15' },
  { image: Avatar16, name: 'avatar16' },
  { image: Avatar17, name: 'avatar17' },
  { image: Avatar18, name: 'avatar18' },
  { image: Avatar19, name: 'avatar19' },
  { image: Avatar20, name: 'avatar20' },
];

/* TODO REFACTO ALLLLLLLLLLLLLLLLLLLL */

const getParentQuery = loader('../graphql/getParent.graphql'); //TODO delete this and read from CONTEXT
const getLanguageIdQuery = loader('../graphql/getLanguageId.graphql');
const updateParentPersonalInformationsQuery = loader(
  '../graphql/updateParentPersonalInformations.graphql'
);
const updateUserSetLanguageQuery = loader(
  '../graphql/updateUserSetLanguage.graphql'
);

const updateAvatarParentQuery = loader('../graphql/updateAvatarParent.graphql');
const updateAvatarPatientQuery = loader(
  '../graphql/updateAvatarPatient.graphql'
);

const Settings: React.FunctionComponent<{}> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    loading: loadingPatientInfosQuery,
    data: dataParent,
    refetch,
  } = useQuery(getParentQuery, {
    variables: { parentId: cookie.get('koalou-parent-id') },
  });

  const [updateParentPersonalInformations, { loading: loadingParentMutation }] =
    useMutation(updateParentPersonalInformationsQuery);

  const [
    getLanguageId,
    { loading: loadingLanguageIdQuery, data: dataLanguageIdQuery },
  ] = useLazyQuery(getLanguageIdQuery);

  const [updateUserSetLanguage, { loading: loadingUserMutation }] = useMutation(
    updateUserSetLanguageQuery
  );

  // const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [updateAvatarParent] = useMutation(updateAvatarParentQuery);
  const [updateAvatarPatient] = useMutation(updateAvatarPatientQuery);

  const [pdfState, setPdfState] = useState({
    showPdfView: false,
    documentName: '',
  });

  const [currentAvatarIndex, setCurrentAvatarIndex] = useState<number | null>(
    null
  );
  const [showAvatarsModal, setShowAvatarsModal] = useState(false);

  const [userData, setUserData] = useState<{
    id: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    avatar: string | null;
    patientName: string;
    patientSurname: string;
    children: Array<{ id: string; name: string; avatar: string | null }> | [];
    language: string;
  }>({
    id: '',
    name: '',
    surname: '',
    email: '',
    phone: '',
    avatar: '',
    patientName: '',
    patientSurname: '',
    children: [],
    language: i18n.language,
  });

  useEffect(() => {
    if (dataParent) {
      setUserData(
        Object.assign({}, userData, {
          id: dataParent.parent[0].id,
          name: dataParent.parent[0].name ? dataParent.parent[0].name : '',
          surname: dataParent.parent[0].surname
            ? dataParent.parent[0].surname
            : '',
          email: dataParent.parent[0].email,
          phone: dataParent.parent[0].phone ? dataParent.parent[0].phone : '',
          avatar: dataParent.parent[0].avatar,
          children: orderBy(
            dataParent.parent[0].parent_patients.map((e: any) => {
              return {
                id: e.patient.id,
                name: capitalize(e.patient.name),
                avatar: e.patient.avatar,
              };
            }),
            ['name'],
            ['asc']
          ),

          // patientName: dataParent.parent[0].parent_patients[0].patient.name,
          // patientSurname:
          //   dataParent.parent[0].parent_patients[0].patient.surname,
        })
      );
    }
  }, [loadingPatientInfosQuery, dataParent]);

  useEffect(() => {
    if (!loadingParentMutation) refetch();
  }, [loadingParentMutation]);

  const handleSubmitPersonalInformations = (values: {
    name: string;
    surname: string;
    email: string;
    phone: string;
  }) => {
    updateParentPersonalInformations({
      variables: {
        parent_id: cookie.get('koalou-parent-id'),
        name: values.name,
        surname: values.surname,
        phone: values.phone,
      },
    });

    displayToastNotification('success', t('validation:toastSettingsSuccess'));
  };

  const handleChangeLanguage = (e: any) => {
    setUserData(Object.assign({}, userData, { language: e.target.value }));
  };

  useEffect(() => {
    if (!loadingLanguageIdQuery && dataLanguageIdQuery) {
      updateUserSetLanguage({
        variables: {
          parent_id: cookie.get('koalou-parent-id'),
          language: dataLanguageIdQuery.language[0].id,
        },
      });
    }
  }, [loadingLanguageIdQuery, dataLanguageIdQuery]);

  useEffect(() => {
    if (!loadingUserMutation && userData.language !== i18n.language) {
      i18n.changeLanguage(userData.language);
      displayToastNotification('success', t('validation:toastLanguageSuccess'));
    }
  }, [loadingUserMutation]);

  const handleSubmitLanguage = async (e: any) => {
    e.preventDefault();
    getLanguageId({ variables: { language_code: userData.language } });
  };

  const openFile = (name: string) => {
    setPdfState({ showPdfView: true, documentName: name });
  };

  const handleClickAvatar = async (avatarName: string) => {
    if (currentAvatarIndex === null) {
      return;
    }
    setShowAvatarsModal(false);
    if (currentAvatarIndex === -1) {
      setUserData({ ...userData, avatar: avatarName });
      // const cxtTmp = {
      //   ...currentParentAndChildrenCxt.currentParentAndChildrenToEdit,
      //   avatar: avatarName,
      // };
      await updateAvatarParent({
        variables: { parentId: userData.id, avatar: avatarName },
      });
      refetch();
    } else if (currentAvatarIndex >= 0) {
      const childrenTmp = [...userData.children];
      childrenTmp[currentAvatarIndex].avatar = avatarName;
      setUserData({ ...userData, children: childrenTmp });
      await updateAvatarPatient({
        variables: {
          patientId: userData.children[currentAvatarIndex].id,
          avatar: avatarName,
        },
      });
      refetch();
    }
  };

  const getAvatar = (avatarName: string | null) => {
    const avatar = avatarsImages.filter((e) => e.name === avatarName);
    if (avatar.length > 0) {
      return avatar[0].image;
    }
    return '';
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() => history.goBack()}
    >
      {!pdfState.showPdfView && (
        <>
          <Title>{t('settings:settingsTitle')}</Title>
          <Container>
            <SettingSection elevation={0}>
              <SectionTitle>
                {t('settings:settingsPersonalInformationsTitle')}
              </SectionTitle>
              <SectionContent>
                <SettingDescription>
                  {t('settings:settingsPersonalInformationsText')}
                </SettingDescription>
                <ElementsToSet>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      name: userData.name,
                      surname: userData.surname,
                      email: userData.email,
                      phone: userData.phone,
                    }}
                    validationSchema={() => changePersonalInformationsSchema(t)}
                    onSubmit={(values: {
                      name: string;
                      surname: string;
                      email: string;
                      phone: string;
                    }) => handleSubmitPersonalInformations(values)}
                  >
                    {(props) => (
                      <SettingForm>
                        <SetNames>
                          <SettingField size="small">
                            <Field
                              name="name"
                              component={InputField}
                              inputLabel={t('common:firstNameLabel')}
                              inputType="text"
                              inputPlaceholder={t(
                                'common:firstNamePlaceholder'
                              )}
                            />
                          </SettingField>
                          <SettingField size="small">
                            <Field
                              name="surname"
                              component={InputField}
                              inputLabel={t('common:lastNameLabel')}
                              inputType="text"
                              inputPlaceholder={t('common:lastNamePlaceholder')}
                            />
                          </SettingField>
                        </SetNames>
                        <SettingField>
                          <Field
                            name="phone"
                            component={PhoneInputField}
                            //inputDefaultCountry="FR"
                            inputLabel={t('common:cellPhoneNumberLabel')}
                            inputType="phone"
                            inputPlaceholder={t(
                              'common:cellPhoneNumberPlaceholder'
                            )}
                            validate={() =>
                              validatePhoneNumber(t, props.values, props.errors)
                            }
                          />
                        </SettingField>
                        <SettingField>
                          <Field
                            name="email"
                            component={InputField}
                            inputLabel={t('common:emailInputLabel')}
                            inputType="email"
                            inputDisabled={true}
                          />
                        </SettingField>
                        <CustomButtonContainer>
                          <CustomButton
                            type="submit"
                            disabled={
                              props.values.name === userData.name &&
                              props.values.surname === userData.surname &&
                              props.values.phone === userData.phone
                            }
                            content={t('common:registerButton')}
                          />
                        </CustomButtonContainer>
                      </SettingForm>
                    )}
                  </Formik>
                </ElementsToSet>
              </SectionContent>
            </SettingSection>

            <SettingSection elevation={0}>
              <SectionTitle>Choisir votre avatar</SectionTitle>
              <AvatarContainer>
                <AvatarSubContainer
                  onClick={() => {
                    setCurrentAvatarIndex(-1);
                    setShowAvatarsModal(true);
                  }}
                  isActive={currentAvatarIndex === -1}
                >
                  <CurrentAvatar>
                    <img
                      src={getAvatar(userData.avatar)}
                      style={{ width: '100', height: '100%' }}
                    />
                  </CurrentAvatar>
                  <CurrentAvatarText>Vous</CurrentAvatarText>
                </AvatarSubContainer>
                {userData.children.map((e: any, i: number) => (
                  <AvatarSubContainer
                    key={i}
                    onClick={() => {
                      setCurrentAvatarIndex(i);
                      setShowAvatarsModal(true);
                    }}
                    isActive={currentAvatarIndex === i}
                  >
                    <CurrentAvatar>
                      <img
                        src={getAvatar(e.avatar)}
                        style={{ width: '100', height: '100%' }}
                      />
                    </CurrentAvatar>
                    <CurrentAvatarText>{e.name}</CurrentAvatarText>
                  </AvatarSubContainer>
                ))}
              </AvatarContainer>
              <CustomModal
                showModal={showAvatarsModal}
                component={
                  <Avatars
                    handleClick={(name: string) => handleClickAvatar(name)}
                  />
                }
                handleCloseModal={() => {
                  setCurrentAvatarIndex(null);
                  setShowAvatarsModal(false);
                }}
                shouldCloseOnOverlayClick={true}
              />
            </SettingSection>

            {/* <SettingSection elevation={0}>
          <SectionTitle variant="h4" paragraph>
            {t('settings:settingsChildInformationsTitle')}
          </SectionTitle>
          <SectionContent>
            <SettingDescription>
              {t('settings:settingsChildInformationsText')}
            </SettingDescription>
            <ElementsToSet>
              <Formik
                enableReinitialize
                initialValues={{
                  patientName: userData.patientName,
                  patientSurname: userData.patientSurname,
                }}
                validationSchema={() => changePersonalInformationsSchema(t)}
                onSubmit={(values: {
                  patientName: string;
                  patientSurname: string;
                }) => {}}
              >
                <SettingForm>
                  <SetNames>
                    <SettingField size="small">
                      <Field
                        name="patientName"
                        component={InputField}
                        inputLabel={t('common:firstNameLabel')}
                        inputName="patientName"
                        inputType="text"
                        inputPlaceholder={t('common:firstNamePlaceholder')}
                        inputDisabled={true}
                      />
                    </SettingField>
                    <SettingField size="small">
                      <Field
                        name="patientSurname"
                        component={InputField}
                        inputLabel={t('common:lastNameLabel')}
                        inputName="patientSurname"
                        inputType="text"
                        inputPlaceholder={t('common:lastNamePlaceholder')}
                        inputDisabled={true}
                      />
                    </SettingField>
                  </SetNames>
                </SettingForm>
              </Formik>
            </ElementsToSet>
          </SectionContent>
        </SettingSection> */}
            <SettingSection elevation={0}>
              <SectionTitle>{t('settings:settingsLanguageTitle')}</SectionTitle>
              <SectionContent>
                <SettingDescription>
                  {t('settings:settingsLanguageText')}
                </SettingDescription>
                <ElementsToSet>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      language: userData.language,
                    }}
                    onSubmit={handleSubmitLanguage}
                  >
                    <SelectForm onSubmit={handleSubmitLanguage}>
                      <SelectField>
                        <Label>{t('common:languageLabel')}</Label>
                        <Field
                          as={SelectLanguage}
                          name="language"
                          disableUnderline
                          id="select"
                          value={userData.language}
                          onChange={handleChangeLanguage}
                        >
                          <MenuItemStyled value={LANGUAGES.FR_FR}>
                            {t('settings:settingsLanguageFr')}
                          </MenuItemStyled>
                          <MenuItemStyled value={LANGUAGES.EN_UK}>
                            {t('settings:settingsLanguageEn')}
                          </MenuItemStyled>
                        </Field>
                      </SelectField>
                      <CustomButtonContainer>
                        <CustomButton
                          type="submit"
                          disabled={userData.language === i18n.language}
                          content={t('common:registerButton')}
                        />
                      </CustomButtonContainer>
                    </SelectForm>
                  </Formik>
                </ElementsToSet>
              </SectionContent>
            </SettingSection>
            <SettingSection elevation={0}>
              <SectionTitle>
                {t('settings:settingsPrivacyPolicy')} /
                {t('settings:settingsTermsAndConditions')}
              </SectionTitle>
              <div style={{ marginBottom: '15px' }}>
                <div style={{ marginLeft: '30px' }}>
                  <div>{t('settings:settingsPrivacyPolicy')}</div>
                  <div>
                    <CustomButton
                      content="ouvrir"
                      handleOnClick={() => openFile('privacy_policy.pdf')}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: '30px', marginTop: '15px' }}>
                  <div>{t('settings:settingsTermsAndConditions')}</div>
                  <div>
                    <CustomButton
                      content="ouvrir"
                      handleOnClick={() => openFile('terms_and_conditions.pdf')}
                    />
                  </div>
                </div>
              </div>
            </SettingSection>
          </Container>
        </>
      )}

      {pdfState.showPdfView && (
        <PdfViewer
          toggle={() => setPdfState({ showPdfView: false, documentName: '' })}
          documentName={pdfState.documentName}
          fileCategory="koalou-document"
        />
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SettingSection = styled(Card)`
  border-radius: 12px !important;
  width: 70%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
  margin: 16px 0 16px 0;
  background-color: ${(props) => props.theme.colors.darkGreenL1} !important;
`;

const SectionTitle = styled.div`
  ${Heading2}
  margin: 20px !important;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
  margin-bottom: 16px;
`;

const SettingDescription = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    padding: 16px;
  }
  padding-top: 10px;
  padding-left: 30px;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.blackMain};
`;

const ElementsToSet = styled.div`
  width: 60%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
  display: flex;
  justify-content: center;
`;

const SettingForm = styled(Form)`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SetNames = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SettingField = styled.div<{ size?: string }>`
  width: ${(props) => (props.size === 'small' ? '45%' : '100%')};
  margin: 0;
  margin-bottom: 16px;
`;

const SelectForm = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SelectField = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const SelectLanguage = styled(Select)`
  width: 100%;
  height: 3rem;
  background: white;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:focus-visible {
    background: red;
  }
`;

const MenuItemStyled = styled(MenuItem)``;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const CustomButtonContainer = styled.div`
  margin-top: 20px;
`;

const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 50px;
`;

const AvatarSubContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.darkGreenMain : ''};
  border-radius: 24px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? props.theme.colors.white : '')};
`;

const CurrentAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.darkGreenL2};
  margin: 5px 20px;
`;

const CurrentAvatarText = styled.div`
  ${Heading2}
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
`;

export default Settings;
