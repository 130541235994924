//TODO REFACTO ETIENNE S WORK !!!!!!!!!!!!!!!!!!!!!!!!!!!!
const background = {
  repeat: {
    noRepeat: 'no-repeat',
  },
  attachement: {
    fixed: 'fixed',
  },
  size: {
    cover: 'cover',
  },
};

const border = {
  style: {
    solid: 'solid',
  },
  radius: {
    medium: '1rem',
  },
};

const colors = {
  // TODO DELETE
  //Blue
  blue: '#3E6BEC !important',
  bluePastel: '#4798B6 !important',
  //Green
  green: 'green',
  mediumSeaGreen: 'mediumSeaGreen !important',
  //Grey
  grey: 'grey',
  greySubtle: '#E9E9EC:',
  //Red
  redDarker: '#CB3434',
  redDark: '#EB5757',
  // redMain: '#F38A7C',
  redLighter: '#F5BAB1',
  redSubtle: '#FEF4F4',
  //White
  white: '#FFF !important',

  /* ******************************** */
  /* *****  New theme colors  ******* */
  /* ******************************** */

  /*  */
  _white: '#FFF',
  blackMain: '#000000',
  blackL4: '#000000CC',
  blackL3: '#00000099',
  blackL2: '#00000066',
  blackL1: '#00000033',
  /*  */
  darkGreenMain: '#2E666D',
  darkGreenL4: '#58858A',
  darkGreenL3: '#82A3A7',
  darkGreenL2: '#ABC2C5',
  darkGreenL1: '#D5E0E2',
  /*  */
  lightGreenMain: '#AED1CD',
  lightGreenL4: '#BEDAD7',
  lightGreenL3: '#CEE3E1',
  lightGreenL2: '#DFEDEB',
  lightGreenL1: '#EFF6F5',
  /*  */
  creamMain: '#F6E9DD',
  creamL4: '#F6E9DD',
  creamL3: '#F8EFE5',
  creamL2: '#FBF4EE',
  creamL1: '#FDFAF6',
  /*  */
  salmonMain: '#FF9D8C',
  salmonL4: '#FFB1A3',
  salmonL3: '#FFC4BA',
  salmonL2: '#FFD8D1',
  salmonL1: '#FFEBE8',
  /*  */
  redMain: '#F76D7D',
  redL4: '#F98A97',
  redL3: '#FAA7B1',
  redL2: '#FCC5CB',
  redL1: '#FDE2E5',
};

const cursor = {
  pointer: 'pointer',
};

const fonts = {
  size: {
    big: '1.2rem !important',
    bigger: '1.5rem',
    large: '1.8rem !important',
  },
  style: {
    italic: 'italic',
  },
  weight: {
    big: '700',
  },
  textTransform: {
    none: 'none !important',
  },
  family: 'Raleway, Arial, serif !important',
};

const margins = {
  none: '0 !important',
  tiny: '8px !important',
  small: '16px !important',
  medium: '48px !important',

  auto: 'auto',
  min: {
    auto: '5rem',
  },
};

const position = {
  absolute: 'absolute',
};

const size = {
  medium: '50%',
  large: '80%',
  full: '100%',
  width: {
    mobile: '320px',
    tablet: '380px',
    desktop: '780px',
  },
  height: {
    mobile: '550px',
    tablet: '600px',
    desktop: '660px',
  },
  maxWidth: {
    small: '500px',
    medium: '1000px',
  },
  minHeight: {
    large: '700px',
    mobilePortrait: '500px',
  },
  view: {
    fullWidth: '100vw',
    fullHeight: '100vh',
  },
  border: '2px',
  logoImg: '40px',
  logoText: '80px',
  elemHeight: '5rem',
  surveyBtns: '120px !important',
  gamePreview: '250px',
};

const text = {
  align: {
    right: 'right',
  },
};

const _mainTheme = {
  background: {
    page: {
      repeat: background.repeat.noRepeat,
      attachement: background.attachement.fixed,
      size: background.size.cover,
    },
  },
  border: {
    small: size.border,
    solid: border.style.solid,
    radius: {
      medium: border.radius.medium,
    },
  },
  breakpoints: {
    mobilePortraitMin: '320px',
    mobilePortraitMax: '425px',
    mobileLandscapeMin: '426px',
    mobileLandscapeMax: '767px',
    tabletMin: '768px',
    tabletMax: '991px',
    laptop13Min: '992px',
    laptop13Max: '1199px',
    laptop15Min: '1200px',
    laptop15Max: '1599px',
    desktop21Min: '1600px',
    desktop21Max: '1999px',
    desktop27Min: '2000px',
  },
  colors: {
    //TODO check before delete unused colors
    border: colors.greySubtle,
    error: colors.redDarker,
    success: colors.green,
    textBtn: colors.grey,
    subtitle: colors.grey,
    submitBtn: colors.white,
    timelineActive: colors.bluePastel,
    background: {
      blueBtn: colors.blue,
      prevNextBtns: colors.bluePastel,
      greenBtn: colors.mediumSeaGreen,
      imgAuthPage: colors.greySubtle,
    },

    /* ********* */
    /* New theme */
    /* ********* */
    darkGreenMain: colors.darkGreenMain,
    darkGreenL4: colors.darkGreenL4,
    darkGreenL3: colors.darkGreenL3,
    darkGreenL2: colors.darkGreenL2,
    darkGreenL1: colors.darkGreenL1,
    /*  */
    lightGreenMain: colors.lightGreenMain,
    lightGreenL4: colors.lightGreenL4,
    lightGreenL3: colors.lightGreenL3,
    lightGreenL2: colors.lightGreenL2,
    lightGreenL1: colors.lightGreenL1,
    /*  */
    creamMain: colors.creamMain,
    creamL4: colors.creamL4,
    creamL3: colors.creamL3,
    creamL2: colors.creamL2,
    creamL1: colors.creamL1,
    /*  */
    salmonMain: colors.salmonMain,
    salmonL4: colors.salmonL4,
    salmonL3: colors.salmonL3,
    salmonL2: colors.salmonL2,
    salmonL1: colors.salmonL1,
    /*  */
    redMain: colors.redMain,
    redL4: colors.redL4,
    redL3: colors.redL3,
    redL2: colors.redL2,
    redL1: colors.redL1,
    /*  */
    white: colors._white,
    blackMain: colors.blackMain,
    blackL1: colors.blackL1,
    blackL2: colors.blackL2,
    blackL3: colors.blackL3,
    blackL4: colors.blackL4,
  },
  cursor: {
    textBtn: cursor.pointer,
  },
  fonts: {
    size: {
      textBtn: fonts.size.big,
      btn: fonts.size.large,
      inputField: fonts.size.bigger,
      error: fonts.size.bigger,
      success: fonts.size.bigger,
    },
    style: {
      subtitle: fonts.style.italic,
    },
    weight: {
      label: fonts.weight.big,
    },
    textTransform: {
      btn: fonts.textTransform.none,
    },
    family: {
      theme: fonts.family,
    },
  },
  margins: {
    none: margins.none,
    tiny: margins.tiny,
    small: margins.small,
    medium: margins.medium,
    auto: margins.auto,
    min: {
      auto: margins.min.auto,
    },
  },
  position: {
    errorMessage: position.absolute,
  },
  size: {
    medium: size.medium,
    large: size.large,
    full: size.full,
    width: {
      mobile: size.width.mobile,
      tablet: size.width.tablet,
      desktop: size.width.desktop,
    },
    height: {
      mobile: size.height.mobile,
      tablet: size.height.tablet,
      desktop: size.height.desktop,
    },
    maxWidth: {
      authCard: size.maxWidth.medium,
      resetPasswordCard: size.maxWidth.small,
    },
    minHeight: {
      authCard: size.minHeight.large,
      resetPasswordCard: size.minHeight.large,
      mobilePortrait: size.minHeight.mobilePortrait,
    },
    view: {
      fullWidth: size.view.fullWidth,
      fullHeight: size.view.fullHeight,
    },
    logoImg: size.logoImg,
    logoText: size.logoText,
    elemHeight: size.elemHeight,
    surveyBtns: size.surveyBtns,
    gamePreview: size.gamePreview,
  },
  text: {
    position: {
      right: text.align.right,
    },
  },
  /* New typography */
  textStyles: {
    typography: {
      heading: {
        h1: {
          fontFamily: 'Raleway',
          fontWeight: 700,
          fontSize: '30px',
          lineHeight: '36px',
        },
        h2: {
          fontFamily: 'Raleway',
          fontWeight: 700,
          fontSize: '22px',
          lineHeight: '26px',
        },
        h3: {
          fontFamily: 'Raleway',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '19px',
        },
        h4: {
          fontFamily: 'Raleway',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '17px',
        },
        h5: {
          fontFamily: 'Raleway',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
      body: {
        extraLarge: {
          fontFamily: 'Raleway',
          fontWeight: 500,
          fontSize: '22px',
          lineHeight: '26px',
        },
        large: {
          fontFamily: 'Raleway',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px',
        },
        main: {
          fontFamily: 'Raleway',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
        },
        small: {
          fontFamily: 'Raleway',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
    },
  },
};

export interface Theme {
  theme: typeof _mainTheme;
}
export const theme = _mainTheme;
