import React from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import Survey from '../components/Survey/Survey';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';
import { Heading1 } from '../theme/themeComponents/fonts';

const CovidChecker: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(
          `/parent/surgeries/health?sid=${new URLSearchParams(
            location.search
          ).get('sid')}#showEvaluationsView`
        )
      }
    >
      <Title>Questionnaire COVID-19</Title>
      <SurveyContainer>
        <Survey surveyTypeValue={SURVEY_TYPE_VALUE['COVID_CHECKER']} />
      </SurveyContainer>
    </PageWrapper>
  );
};

export default CovidChecker;

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;

const SurveyContainer = styled.div`
  margin-top: 30px;
`;
