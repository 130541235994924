import { useLazyQuery } from '@apollo/client';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Carousel from '../components/Carousel/CarouselV1/Carousel';
import ChartsCard from '../components/ChartsCard/ChartsCard';
import CustomFlipCard from '../components/CustomFlipCard/CustomFlipCard';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import Survey from '../components/Survey/Survey';
import { SURVEY_TYPE_VALUE } from '../constants/db_types';
import { Mixpanel } from '../lib/mixpanel';
import activityBg1Image from '../theme/images/activitiesChildren/activityBg1.png';
import activityBg2Image from '../theme/images/activitiesChildren/activityBg2.png';
import activityBg3Image from '../theme/images/activitiesChildren/activityBg3.png';
import activityBg4Image from '../theme/images/activitiesChildren/activityBg4.png';
import activityBg5Image from '../theme/images/activitiesChildren/activityBg5.png';
import activityBg6Image from '../theme/images/activitiesChildren/activityBg6.png';
import ApplicationContext from '../utils/context';
import { getIndexSelectedLanguage } from '../utils/translation';

const getSuggestedActivitiesQuery = loader(
  '../graphql/getSuggestedActivities.graphql'
);

const imagesArray = [
  activityBg1Image,
  activityBg2Image,
  activityBg3Image,
  activityBg4Image,
  activityBg5Image,
  activityBg6Image,
];

const SpaceParent: React.FunctionComponent<{}> = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { currentParentAndChildrenCxt } = useContext<any>(ApplicationContext);

  const [getActivities, { data: dataActivities }] = useLazyQuery(
    getSuggestedActivitiesQuery
  );

  const [grantAccess, setGrantAccess] = useState(false);
  const [carouselKey, setCarouselKey] = useState('');

  useEffect(() => {
    const cxt = currentParentAndChildrenCxt.currentParentAndChildrenToEdit;
    if (cxt) {
      if (!cxt.hasActivatedEmotionalHealth) {
        history.push('/parent/emotional-health/lock');
      } else {
        Mixpanel?.accessToParentSpace();
        setGrantAccess(true);
      }
    }
  }, []);

  useEffect(() => {
    if (carouselKey === 'activities') {
      getActivities({ variables: { language: i18n.language } });
    }
  }, [carouselKey]);

  const selectBgImage = (index: number) => {
    if (index + 1 <= imagesArray.length - 1) {
      return imagesArray[index];
    } else {
      return imagesArray[index % imagesArray.length];
    }
  };

  const mooveActivitesScrollBar = (e: any, direction: 'right' | 'left') => {
    const div = document.getElementById('suggested-activities-container');
    if (!div) return;
    if (direction === 'right') {
      div.scrollLeft += 400;
    } else if (direction === 'left') {
      div.scrollLeft -= 400;
    }
    e.preventDefault();
  };

  /* If the parent did not activate the emotional health space */
  if (!grantAccess) {
    return <></>;
  }

  const renderDynamicView = (key: string) => {
    if (key === 'emotions') {
      return (
        <SurveyContainer>
          <Survey surveyTypeValue={SURVEY_TYPE_VALUE.PARENT_MOOD_EVALUATION} />
        </SurveyContainer>
      );
    } else if (key === 'activities') {
      return (
        <SuggestedActivitiesContainer id="suggested-activities-container">
          <SuggestedActivitiesArrowContainer
            direction="left"
            onClick={(e) => mooveActivitesScrollBar(e, 'left')}
          >
            <SuggestedActivitiesArrowIcon icon={faChevronLeft} />
          </SuggestedActivitiesArrowContainer>
          {dataActivities?.suggested_activity?.map((e: any, i: number) => {
            const indexLanguage = getIndexSelectedLanguage(
              e.suggested_activity_i18ns.map(
                (f: any) => f.language.language_code
              ),
              i18n.language
            );
            const suggestedActivity =
              e.suggested_activity_i18ns?.[indexLanguage];
            return (
              <CardContainer key={i}>
                <CustomFlipCard
                  frontCard={{
                    title: suggestedActivity.title,
                    titleSize: 'md',
                    backgroundImage: selectBgImage(i),
                  }}
                  backCard={{ subtitle: suggestedActivity.value }}
                />
              </CardContainer>
            );
          })}
          <SuggestedActivitiesArrowContainer
            direction="right"
            onClick={(e) => mooveActivitesScrollBar(e, 'right')}
          >
            <SuggestedActivitiesArrowIcon icon={faChevronRight} />
          </SuggestedActivitiesArrowContainer>
        </SuggestedActivitiesContainer>
      );
    } else if (key === 'dashboard') {
      return (
        <ChartsCardContainer>
          <ChartCardBox>
            <ChartsCard label="Parent" />
          </ChartCardBox>
          <ChartCardBox>
            <ChartsCard label="Children" children={true} />
          </ChartCardBox>
        </ChartsCardContainer>
      );
    }
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push('/parent/emotional-health/home')
      }
    >
      <CarouselContainer>
        <Carousel handleKey={(v: string) => setCarouselKey(v)} />
      </CarouselContainer>
      <DynamicView>{renderDynamicView(carouselKey)}</DynamicView>
    </PageWrapper>
  );
};

const CarouselContainer = styled.div`
  position: relative;
  height: 40%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    height: 300px;
  }
`;

const DynamicView = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    height: auto;
    padding-top: 60px;
  }
  position: relative;
`;

const SurveyContainer = styled.div`
  width: 50%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const SuggestedActivitiesContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 80%;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
    width: 100%;
    overflow-x: none;
  }
`;

const SuggestedActivitiesArrowContainer = styled.div<{
  direction: 'left' | 'right';
}>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 45%;
  left: ${(props) => (props.direction === 'left' ? '50px' : '')};
  right: ${(props) => (props.direction === 'right' ? '50px' : '')};
  cursor: pointer;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const SuggestedActivitiesArrowIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.dark};
`;

const CardContainer = styled.div`
  width: 223px;
  height: 324px;
  margin: 10px 30px;
  position: relative;
`;

const ChartsCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-wrap: wrap;
    height: auto;
  }
`;

const ChartCardBox = styled.div`
  margin: 20px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    height: 400px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    height: 400px;
    padding: 0;
  }
`;

export default SpaceParent;
