import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CustomCard from '../components/CustomCard/CustomCard';
import { Loader } from '../components/Loader/Loader';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import PdfViewer from '../components/PdfViewer/PdfViewer';
import { ORGANIZATION_FILE_TYPE } from '../constants/gcp_types';
import { Heading1, Heading2 } from '../theme/themeComponents/fonts';

const getSurgeryByIdQuery = loader('../graphql/getSurgeryById.graphql');
const getGuidelinesQuery = loader('../graphql/getGuidelines.graphql');

const Guidelines: React.FunctionComponent<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [sid, setSid] = useState('');
  const [getSurgeryById, { data: dataSurgery }] = useLazyQuery(
    getSurgeryByIdQuery
  );
  const [getGuidelines, { data: dataGuidelines }] = useLazyQuery(
    getGuidelinesQuery
  );
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [documentName, setDocumentName] = useState('');

  useEffect(() => {
    const surgeryId = new URLSearchParams(location.search).get('sid');
    if (surgeryId) {
      setSid(surgeryId);
      getSurgeryById({ variables: { surgeryId } });
    } else {
      history.push('/parent/surgeries');
    }
  }, [location]);

  useEffect(() => {
    if (dataSurgery) {
      if (dataSurgery.surgery?.length > 0) {
        getGuidelines({
          variables: {
            organizationId: dataSurgery?.surgery?.[0]?.organization.id,
            surgeryTypeId: dataSurgery.surgery[0].surgery_type?.id,
          },
        });
      } else {
        history.push('/parent/surgeries');
      }
    }
  }, [dataSurgery]);

  useEffect(() => {
    if (dataGuidelines) {
      setLoading(false);
    }
  }, [dataGuidelines]);

  const toggle = () => setModal(!modal);

  const handleModal = (name: string) => {
    setDocumentName(name);
    toggle();
  };

  return (
    <PageWrapper
      previousPageIconOn={true}
      handleOnClickPreviousPage={() =>
        history.push(`/parent/surgeries/health?sid=${sid}`)
      }
    >
      {loading && <Loader />}
      {!loading && !modal && (
        <Container>
          <Title>{t('titles_and_subtitles:guidelinesTitle')}</Title>
          <Subtitle>{t('titles_and_subtitles:guidelinesText')}</Subtitle>
          <DocumentCardContainer>
            {dataGuidelines?.organization_guideline?.map(
              (document: any, i: number) => {
                return (
                  <CustomCardContainer key={i}>
                    <CustomCard
                      title={'PDF'}
                      subtitle={document.name}
                      handleOnClick={() => handleModal(document.document_name)}
                    />
                  </CustomCardContainer>
                );
              }
            )}
          </DocumentCardContainer>
        </Container>
      )}
      {!loading && modal && (
        <PdfViewer
          toggle={toggle}
          documentName={documentName}
          fileCategory={ORGANIZATION_FILE_TYPE.GUIDELINE}
        />
      )}
    </PageWrapper>
  );
};

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading1}
`;
const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 0;
  ${Heading2}
`;

const Container = styled.div`
  width: 100%;
`;

const DocumentCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
`;

const CustomCardContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 10px;
  position: relative;
`;

export default Guidelines;
