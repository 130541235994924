import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading2 } from '../../theme/themeComponents/fonts';
interface VisualAnalogueScaleProps {
  handleChange: (value: string) => void;
}

//TODO efacto delete material ui dep
const VisualAnalogueScale: React.FunctionComponent<VisualAnalogueScaleProps> = ({
  handleChange,
}) => {
  const [value, setValue] = useState('0');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    setValue('0');
  }, []);
  useEffect(() => {
    handleChange(value);
  }, [value]);

  return (
    <Container>
      <RangeSliderContainer>
        <RangeSlider
          type="range"
          className={`slider`}
          id="parent-mood-test-range"
          onChange={handleRadioChange}
          min={0}
          max={10}
          step={1}
          value={value}
        />
        <RangeLabelContainer>
          {Array.from(Array(11).keys()).map((e, i) => (
            <div key={i}>{e}</div>
          ))}
        </RangeLabelContainer>
      </RangeSliderContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const RangeSliderContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const RangeLabelContainer = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: space-between;
  ${Heading2}
`;

const RangeSlider = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  :focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  ::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #f76d7d;
    cursor: pointer;
    margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  ::-moz-range-thumb {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #f76d7d;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  ::-ms-thumb {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #f76d7d;
    cursor: pointer;
  }

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: linear-gradient(270deg, #f76d7d 0%, #fde2e5 100%);
    border-radius: 84px;
  }

  :focus::-webkit-slider-runnable-track {
    /* background: #367ebd; */
    /* border: 1px solid black; */
  }

  ::-moz-range-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: linear-gradient(270deg, #f76d7d 0%, #fde2e5 100%);
    border-radius: 84px;
  }

  ::-ms-track {
    width: 100%;
    height: 40px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  /* input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  
  input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;


  input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;

  input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
  */
`;

VisualAnalogueScale.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default VisualAnalogueScale;
