import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import * as Sentry from '@sentry/react';
import cookie from 'js-cookie';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COOKIES } from '../../constants/cookies_type';
import {
  BtnBlueLarge,
  BtnText,
} from '../../theme/themeComponents/btnComponents';
import { ErrorMessageTypo } from '../../theme/themeComponents/typoComponents';
import { loginWithEmailAndPassword, logout } from '../../utils/auth';
import { ValidateInputSchema } from '../../utils/validationRules';
import InputField from '../InputField/InputField';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Heading3 } from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';
import { Loader } from '../Loader/Loader';

const updateUserConnection = loader(
  '../../graphql/updateUserConnection.graphql'
);
interface LoginFormProps {
  handleAssistanceButton: (e: any) => void;
  assistanceButtonText: string;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  handleAssistanceButton,
  assistanceButtonText,
}) => {
  const { t } = useTranslation();
  let history = useHistory();

  const [updateUser] = useMutation(updateUserConnection);
  const [error, setError] = useState<string>('');
  const [showAccountNotReadyView, setShowAccountNotReadyView] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmitEmailPassword = async (values: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      //@ts-ignore
      const { error } = await loginWithEmailAndPassword(values);
      if (error) {
        if (error === 'HASURA_CLAIMS_PENDING') {
          setLoading(false);
          setShowAccountNotReadyView(true);
        } else if (error === 'ROLE_NOT_ALLOWED') {
          // history.push('/unauthorized');
          logout('/unauthorized');
        } else {
          setLoading(false);
          setError(error);
        }
      } else {
        Sentry.setContext('koalou user', {
          email: cookie.get(COOKIES.EMAIL),
          role: cookie.get(COOKIES.ROLE),
          id: cookie.get(COOKIES.ID),
        });
        try {
          const res = await updateUser({
            variables: { userId: cookie.get(COOKIES.ID) },
          });

          const user = res.data.update_user.returning[0];

          /* If it s the first connection */
          if (user.connection === 1) {
            /* if it s a direct signup  we show the onboarding direct signup */
            const patientsSurgeries = user.parent?.parent_patients.filter(
              (e: any) => e.patient.surgeries.length > 0
            );

            if (user.parent.direct_signup) {
              history.push('/welcome?showOnboarding=direct-signup');
              return;
            } else {
              history.push(
                `/parent/surgeries/health?sid=${patientsSurgeries[0].patient.surgeries[0].id}&showOnboarding=indirect-signup`
              );
              return;
            }
          }
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        history.push('/welcome');
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={() => ValidateInputSchema(t)}
      onSubmit={(values: { email: string; password: string }) => {
        handleSubmitEmailPassword(values);
      }}
    >
      {(props) => {
        return (
          <Form>
            {!loading && showAccountNotReadyView && (
              <>
                <NoClaimsText>
                  Votre compte est en cours d'activation. <br />
                  Merci de réessayer dans quelques minutes
                </NoClaimsText>
                <SubmitButton onClick={() => setShowAccountNotReadyView(false)}>
                  Se connecter
                </SubmitButton>
              </>
            )}

            {!loading && !showAccountNotReadyView && (
              <div>
                <InputFieldContainer>
                  <Field
                    name="email"
                    component={InputField}
                    inputLabel={t('common:emailInputLabel')}
                    inputName="email"
                    inputType="email"
                    inputPlaceholder={t('common:emailInputPlaceholder')}
                  />
                </InputFieldContainer>
                <InputFieldContainer>
                  <Field
                    name="password"
                    component={InputField}
                    inputLabel={t('common:passwordInputLabel')}
                    inputName="password"
                    inputType="password"
                    inputPlaceholder={t('common:passwordInputPlaceholder')}
                  />
                </InputFieldContainer>
                <AssistanceButton onClick={handleAssistanceButton}>
                  {assistanceButtonText}
                </AssistanceButton>
                <SubmitButtonContainer>
                  <CustomButton type="submit" content={t('auth:loginButton')} />
                </SubmitButtonContainer>
                <SignupButton onClick={() => history.push('/signup')}>
                  S'inscrire
                </SignupButton>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </div>
            )}
            {loading && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const AssistanceButton = styled(BtnText)`
  text-align: right;
`;

const SubmitButton = BtnBlueLarge;

const InputFieldContainer = styled.div`
  height: 5rem;
  margin-bottom: 16px;
`;

const NoClaimsText = styled.h2`
  margin-bottom: 120px;
  ${Heading3}
`;

const SubmitButtonContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  margin-top: 50px;
`;

const ErrorMessage = styled(ErrorMessageTypo)`
  text-align: center;
`;

const SignupButton = styled(BtnText)`
  text-align: center;
  text-decoration: underline;
  margin-top: 10px;
`;

export default LoginForm;
