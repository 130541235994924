import React from 'react';
import Phaser from 'phaser';
import { IonPhaser } from '@ion-phaser/react';
import Game from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/Game.js';
import GameMenu from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/GameMenu.js';
import Pause from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/Pause.js';
import Help from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/Help.js';
import styled from 'styled-components';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import HowToPlay from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/HowToPlay.js';
import AskForFeelings from '../../games/KoalouBreathing/KoalouBreathing/src/scenes/AskForFeelings.js';

const FirstGame: React.FunctionComponent = () => {
  const game = {
    type: Phaser.AUTO,
    scale: {
      parent: 'game-container',
      mode: Phaser.Scale.FIT,
      width: 1024,
      height: 768,
    },
    scene: [/*GameMenu,*/ Game, Pause, Help, HowToPlay, AskForFeelings],
    physics: {
      default: 'arcade',
      arcade: {
        //debug: true,
      },
    },
  };

  const history = useHistory();
  const location = useLocation();

  return (
    <PageWrapper
      handleOnClickPreviousPage={() =>
        history.push(
          `/children/games?cidx=${new URLSearchParams(location.search).get(
            'cidx'
          )}`
        )
      }
    >
      <div style={{ paddingRight: '3%' }}>
        <GameContainer id="game-container">
          <InitializeFont fontname="Raleway">.</InitializeFont>
          <InitializeFont fontname="font2">.</InitializeFont>
          <IonPhaser game={game} />
        </GameContainer>
      </div>
    </PageWrapper>
  );
};

const GameContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InitializeFont = styled.div<{ fontname: string }>`
  font-family: ${(props) => props.fontname};
  position: absolute;
  left: -1000px;
  visibility: hidden;
`;

export default FirstGame;
