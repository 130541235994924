import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

interface SurveyButtonsProps {
  displayPrevBtn: boolean;
  displayNextBtn: boolean;
  previousQuestion: () => void;
  nextQuestion: () => void;
  clearLocalStorage: () => void;
}

const SurveyButtons: React.FunctionComponent<SurveyButtonsProps> = ({
  displayPrevBtn,
  displayNextBtn,
  previousQuestion,
  nextQuestion,
  clearLocalStorage,
}) => {
  return (
    <SurveyBtnsContainer>
      <SurveyBtn
        onClick={previousQuestion}
        hidebtn={!displayPrevBtn ? 'true' : ''}
      >
        Précédent
      </SurveyBtn>
      {displayNextBtn ? (
        <SurveyBtn onClick={nextQuestion}>Suivant</SurveyBtn>
      ) : (
        <SurveyBtn type="submit" onClick={clearLocalStorage}>
          Soumettre
        </SurveyBtn>
      )}
    </SurveyBtnsContainer>
  );
};

const SurveyBtnsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SurveyBtn = styled(Button)<{ hidebtn: boolean }>`
  width: 250px;
  height: 56px;
  background: #58858a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  border: none;
  font-size: 30px;
  font-weight: bold;
  margin-top: 40px;
  visibility: ${(props) => (props.hidebtn ? 'hidden' : 'visible')};
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  :focus,
  :hover {
    background: #58858a;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    font-size: 22px;
    width: 200px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    font-size: 16px;
    width: 150px;
  }
`;

export default SurveyButtons;
