import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ButtonStyled = styled(Button)`
  margin-top: 4rem !important;
  background-color: ${(props) => props.theme.colors.darkGreenL4} !important;
`;

const TypographyStyled = styled(Typography)`
  font-size: 1.6rem !important;
`;

export { ButtonStyled, TypographyStyled };
