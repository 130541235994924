import { useMutation, useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import {
  BodyLarge,
  Heading3,
  Heading4,
} from '../../theme/themeComponents/fonts';
import CustomButton from '../CustomButton/CustomButton';
import CustomModal from '../CustomModal/CustomModal';
import { Loader } from '../Loader/Loader';
import TodoListItem from '../TodoListItem/TodoListItem';

const getTodoListEntries = loader('../../graphql/getTodoListEntries.graphql');
const addTodoListentry = loader('../../graphql/addTodoListEntry.graphql');
const updateTodoListentry = loader('../../graphql/updateTodoListEntry.graphql');

const TodoList: React.FunctionComponent<{}> = () => {
  const { data, loading } = useQuery(getTodoListEntries, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [entriesArr, setEntriesArr] =
    useState<{ id: string; value: string }[] | undefined>();
  const [addEntry] = useMutation(addTodoListentry);
  const [updateEntry] = useMutation(updateTodoListentry);

  const [currentTask, setCurrentTask] = useState('');
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const deleteTask = (id: string, index: number) => {
    document.getElementById('todo_item_' + index)?.classList.add('slide-out');
    setTimeout(() => {
      updateEntry({ variables: { id: id } });
      setEntriesArr(entriesArr?.filter((e: any) => e.id !== id));
      document
        .getElementById('todo_item_' + index)
        ?.classList.remove('slide-out');
    }, 500);
  };

  const handleSubmit = () => {
    if (currentTask.trim().length > 0) {
      const testDiv = document.getElementById('todo-list-body');

      addEntry({
        variables: { value: currentTask },
      }).then((...args) => {
        const [
          {
            data: {
              insert_parent_todo_list: {},
            },
          },
        ] = args;
        const entriesArrClone = cloneDeep(entriesArr);
        entriesArrClone?.push(
          args[0].data.insert_parent_todo_list.returning[0]
        );
        setEntriesArr(entriesArrClone);
        setCurrentTask('');
        setShowAddTaskModal(false);
        /* scroll down the list */
        testDiv?.scrollTo({
          top: testDiv.scrollHeight,
          behavior: 'smooth',
        });
      });
    }
  };

  useEffect(() => {
    if (data) {
      setEntriesArr(data.parent_todo_list);
    }
  }, [data]);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <TodoListContainer>
      <TodoListHeader>
        <TodoListTitle>To-Do</TodoListTitle>
      </TodoListHeader>
      <TodoListBody className="hide-scrollbar" id="todo-list-body">
        <div>
          {entriesArr?.map(
            (task: { id: string; value: string }, index: number) => {
              return (
                <TodoListItem
                  key={index}
                  index={index}
                  task={task.value}
                  onClick={() => deleteTask(task.id, index)}
                ></TodoListItem>
              );
            }
          )}
        </div>
      </TodoListBody>
      <TodoListFooter>
        <AddItemContainer onClick={() => setShowAddTaskModal(true)}>
          <FontAwesomeIcon icon={faPlus} />
          <AddItemText>Ajouter une tâche</AddItemText>
        </AddItemContainer>
        <CustomModal
          showModal={showAddTaskModal}
          handleCloseModal={() => setShowAddTaskModal(false)}
          shouldCloseOnOverlayClick={true}
          component={
            <ModalContainer>
              <ModalContent>
                <ModalTitle>
                  Tâche {entriesArr ? entriesArr.length + 1 : ''}
                  <span>{currentTask.length}/140</span>
                </ModalTitle>
                <ModalInput
                  placeholder="Ma tâche"
                  cols={35}
                  rows={15}
                  maxLength={140}
                  value={currentTask}
                  onChange={(e) => setCurrentTask(e.target.value)}
                />
              </ModalContent>
              <CustomButtonContainer>
                <CustomButton
                  content="Ajouter"
                  backgroundColor={theme.colors.white}
                  borderColor={theme.colors.white}
                  borderRadius={'24px'}
                  textColor={theme.colors.redL3}
                  handleOnClick={handleSubmit}
                />
              </CustomButtonContainer>
            </ModalContainer>
          }
          backgroundColor={theme.colors.darkGreenL1}
          borderRadius={46}
        />
        {/* <Formik
          initialValues={{ newTask: '' }}
          onSubmit={(values: { newTask: string }, actions) =>
            handleSubmit(values, actions)
          }
        >
          <Form>
            <AddTaskInputField>
              <Field
                id="newTaskInput"
                name="newTask"
                component={InputField}
                inputType="text"
                inputPlaceholder="Ajouter une tâche"
                placeholderColor={theme.colors.redL3}
                icon={faPlus}
                iconColor={theme.colors.redL3}
              ></Field>
            </AddTaskInputField>
          </Form>
        </Formik> */}
      </TodoListFooter>
    </TodoListContainer>
  );
};

const LoaderContainer = styled.div`
  height: 100%;
`;

const TodoListContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.darkGreenL1};
  border: 0.5px solid ${(props) => props.theme.colors.darkGreenL2};
  border-radius: 36px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.31);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const TodoListHeader = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.blackL4};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`;

const TodoListTitle = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  font-feature-settings: 'pnum' on, 'lnum' on;
`;

const TodoListBody = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TodoListFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 2px;
  margin-top: 20px;
`;

const AddItemContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 16px 19px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.redL3};
`;

const AddItemText = styled.div`
  ${Heading4};
  margin-left: 10px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  ${Heading3};
  color: ${(props) => props.theme.colors.darkGreenMain};
  margin-bottom: 10px;

  & > span {
    ${BodyLarge}
    color: ${(props) => props.theme.colors.blackMain};
    margin-left: 10px;
  }
`;

const ModalContent = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 24px;
  padding: 21px 11px;
`;

const ModalInput = styled.textarea`
  ${BodyLarge};
  border: none;
  resize: none;
  &:focus {
    outline: none !important;
  }
`;

const CustomButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default TodoList;
